import { useStyles } from "../../../theme/appStyles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import {
  useCreateProductMutation,
  useGetAllMainCategoryQuery,
  useGetLastProductIdQuery,
  useGetPrinterQuery,
  useGetProductBrandQuery,
  useGetProductTagQuery,
  useGetSubCategoryByArrayQuery,
} from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import {
  ICategory,
  ILastProductId,
  IPrinter,
  IProduct,
  IProductBrand,
  IProductTag,
} from "../../../Api/Interface/api.interface";
import LoopingTable from "../Components/productLooping";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Switch, { SwitchProps } from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import { SizeOfLevelType } from "../../../Core/Enum/enum";
import CustomSwitch from "../../../Components/Switch/CustomSwitch";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { HexColorPicker } from "react-colorful";
import Barcode from "react-barcode";
import AppsIcon from "@mui/icons-material/Apps";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import NewProductBrand from "../../ProductBrand/Edit/index";
import NewPrinter from "../../Printer/Edit/index";
import NewProductTag from "../../ProductTags/Edit/index";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { uploadImage } from "../../../Components/UploadFile/upload";

const steps = ["Basic Details", "Looping Table", "Done"];

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 30, // Increased thumb size
    height: 30, // Increased thumb size
  },
  "& .MuiSwitch-track": {
    borderRadius: 34 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#757575" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`, // Using SVG path for check
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`, // Using SVG path for minus
      right: 12,
    },
  },
}));

const isPrinterArray = (data: any): data is IPrinter[] => {
  return (
    Array.isArray(data) &&
    data.every(
      (item) =>
        "id" in item && "printerName" in item && "printerDescription" in item
    )
  );
};

// Image imports
const img1 = require("../../../Images/img1.jpg");
const img2 = require("../../../Images/img2.jpg");
const img3 = require("../../../Images/img3.jpg");

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const gridHeight = isMobile ? "30px" : "400px";

  const [newProduct, { isLoading }] = useCreateProductMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const { data: categoryData } = useGetAllMainCategoryQuery({
    departmentId: "",
    coursingId: "",
    roleId: null,
    taxeId: "",
    createdDateStart: null,
    createdDateEnd: null,
    searchText: "",
    numberOfLength: 0,
    isActive: true,
    categoryIsLooping: null,
    createdDate: null,
  });
  const { data: productBrandData, isLoading: ProductBrandLoading } =
    useGetProductBrandQuery({
      searchText: "",
      isActive: true,
    });
  const { data: productTagData, isLoading: ProductTagLoading } =
    useGetProductTagQuery({
      searchText: "",
      isActive: true,
    });
  const { data: printerData, isLoading: PrinterLoading } = useGetPrinterQuery({
    searchText: "",
  });

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<
    number | null
  >(null);

  const [selectedSubToSubCategoryId, setSelectedSubToSubCategoryId] = useState<
    number | null
  >(null);

  const [selectedSubToSubToSubCategoryId, setSelectedSubToSubToSubCategoryId] =
    useState<number | null>(null);

  const [openProductCategory, setOpenProductCategory] = useState(false);
  const [openProductBrand, setOpenProductBrand] = useState(false);
  const [openProductTag, setOpenProductTag] = useState(false);
  const [openPrinter, setOpenPrinter] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>("#FFFFFF");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const { data: newLastProductIdData, isLoading: lastProductIdIsLoading } =
    useGetLastProductIdQuery();
  const [showBarcode, setShowBarcode] = useState(false);

  const [openLoopingCategoryDialog, setOpenLoopingCategoryDialog] =
    useState(false);

  const [openGallery, setOpenGallery] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  );
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [productDetails, setProductDetails] = useState({
    id: "",
    productDetailsIsLooping: false,
    productsDetailsLoopingConstant: "",
    productName: "",
  });

  const lastProductId = useMemo(() => {
    return (newLastProductIdData?.data as ILastProductId)?.lastInsertedId ?? 0;
  }, [newLastProductIdData?.data]);

  const productBrandList = useMemo(() => {
    return productBrandData?.data as IProductBrand[];
  }, [productBrandData?.data]);

  const productTagList = useMemo(() => {
    return productTagData?.data as IProductTag[];
  }, [productTagData?.data]);

  const printerList: IPrinter[] = useMemo(() => {
    if (!printerData || !isPrinterArray(printerData.data)) {
      return [];
    }
    return printerData.data;
  }, [printerData]);

  const { data: subCategoryData, refetch: refetchSubCategories } =
    useGetSubCategoryByArrayQuery({
      parent_ids: selectedCategoryId ? [Number(selectedCategoryId)] : [],
    });

  const { data: subCategory2Data, refetch: refetchS2bCategories } =
    useGetSubCategoryByArrayQuery({
      parent_ids: selectedSubCategoryId
        ? [Number(selectedCategoryId), Number(selectedSubCategoryId)]
        : [],
    });

  const { data: subCategory3Data, refetch: refetchS3bCategories } =
    useGetSubCategoryByArrayQuery({
      parent_ids: selectedSubCategoryId
        ? [
            Number(selectedCategoryId),
            Number(selectedSubCategoryId),
            Number(selectedSubToSubCategoryId),
          ]
        : [],
    });
  const categoryList = useMemo(() => {
    return categoryData?.data as ICategory[];
  }, [categoryData?.data]);

  const subCategoryList = useMemo(() => {
    return subCategoryData?.data as ICategory[];
  }, [subCategoryData?.data]);

  const subToSubCategoryList = useMemo(() => {
    return subCategory2Data?.data as ICategory[];
  }, [subCategory2Data?.data]);

  const subToSubToSubCategoryList = useMemo(() => {
    return subCategory3Data?.data as ICategory[];
  }, [subCategory3Data?.data]);

  const formik = useFormik<IProduct>({
    initialValues: {
      productName: "",
      productCategoryId: "",
      mainCatId: "",
      productDetailsIsLooping: false,
      productsDetailsLoopingConstant: "",
      productShortDescription: "",
      productLongDescription: "",
      productConversionUnit: [],
      productBrandId: "",
      productTagIds: [],
      productViewOnline: false,
      isActive: true,
      productPrinterIds: [],
      productIcon: "",
      productButtonColor: "",
      productBarcode: "",
      productCanSell: true,
      productImg: "",
      productPrice: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const productCategoryId = selectedSubToSubToSubCategoryId
          ? Number(selectedSubToSubToSubCategoryId)
          : selectedSubToSubCategoryId
          ? Number(selectedSubToSubCategoryId)
          : selectedSubCategoryId
          ? Number(selectedSubCategoryId)
          : selectedCategoryId
          ? Number(selectedCategoryId)
          : String(values.mainCatId);

        const formData = new FormData();
        let productImg: string | undefined = "";

        if (selectedImage) {
          setIsImageUploading(true);
          formData.append("productImg", selectedImage);
          productImg = (await uploadImage(selectedImage)) || "";
          setIsImageUploading(false);
        }

        const temData = {
          productName: values.productName,
          productCategoryId: productCategoryId,
          productDetailsIsLooping: values.productDetailsIsLooping,
          productsDetailsLoopingConstant: values.productsDetailsLoopingConstant,
          productShortDescription: values.productShortDescription,
          productLongDescription: values.productLongDescription,
          productConversionUnit: values.productConversionUnit,
          productBrandId: values.productBrandId,
          productTagIds: values.productTagIds,
          productViewOnline: values.productViewOnline,
          isActive: values.isActive,
          productPrinterIds: values.productPrinterIds,
          productIcon: values.productIcon,
          productButtonColor: values.productButtonColor,
          productBarcode: values.productBarcode,
          productCanSell: values.productCanSell,
          productPrice: values.productPrice,
          productImg,
        };

        const addProductResponse = await newProduct(temData).unwrap();
        if (!addProductResponse.status) {
          showErrorMessage(addProductResponse.message);
        } else {
          showMessage(addProductResponse.message);
          resetForm();
          setSelectedImage(undefined);
          setOpenGallery(false);
          setSelectedCategoryId(null);
          setSelectedSubCategoryId(null);
          setSelectedSubToSubCategoryId(null);
          setSelectedSubToSubToSubCategoryId(null);
          setProductDetails({
            id: addProductResponse.data.id,
            productDetailsIsLooping:
              addProductResponse.data.productDetailsIsLooping,
            productsDetailsLoopingConstant:
              addProductResponse.data.productsDetailsLoopingConstant,
            productName: values.productName,
          });
          if (addProductResponse.data.productDetailsIsLooping) {
            setActiveStep(1); // Move to looping step if looping is enabled
          } else {
            setActiveStep(2); // Move directly to done step if not looping
          }
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return formik.values.productName === "" ||
      formik.values.productName === undefined
      ? false
      : true;
  }, [formik]);

  const resizeImage = (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          // Calculate the new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Canvas is empty"));
            }
          }, file.type);
        };

        img.onerror = (error) => reject(error);
      };
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
      const maxSize = 2048 * 1024;

      if (!validTypes.includes(file.type)) {
        showErrorMessage("The file must be an image (jpeg, png, jpg, gif).");
        setSelectedImage(undefined);
      } else if (file.size > maxSize) {
        showErrorMessage("The file must be smaller than 2 MB.");
        setSelectedImage(undefined);
      } else {
        try {
          const resizedImage = await resizeImage(file, 500, 500);
          setSelectedImage(resizedImage);
        } catch (error) {
          showErrorMessage("Failed to resize the image.");
          setSelectedImage(undefined);
        }
      }
    } else {
      setSelectedImage(undefined);
    }
  };

  const handleGallerySelection = (selectedImage: string) => {
    fetch(selectedImage)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "image.jpg", { type: blob.type });
        setSelectedImage(file);
      });
    setOpenGallery(false);
  };

  const openGalleryDialog = () => {
    setOpenGallery(true);
  };

  const closeGalleryDialog = () => {
    setOpenGallery(false);
  };

  const galleryImages = [img2, img3, img2, img3, img2, img3];

  const handlePrinterToggle = (printerId: number) => {
    const { productPrinterIds } = formik.values;
    const updatedPrinterIds = productPrinterIds.includes(printerId)
      ? productPrinterIds.filter((id) => id !== printerId)
      : [...productPrinterIds, printerId];
    formik.setFieldValue("productPrinterIds", updatedPrinterIds);
  };

  const handleColorChange = (newColor: string) => {
    setSelectedColor(newColor);
    formik.setFieldValue("productButtonColor", newColor);
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (lastProductId) {
      const productCategoryId = selectedSubToSubToSubCategoryId
        ? Number(selectedSubToSubToSubCategoryId)
        : selectedSubToSubCategoryId
        ? Number(selectedSubToSubCategoryId)
        : selectedSubCategoryId
        ? Number(selectedSubCategoryId)
        : selectedCategoryId
        ? Number(selectedCategoryId)
        : "";

      const newProductBarcode =
        String(lastProductId + 1) +
        (productCategoryId || "") +
        (formik.values.productBrandId || "");
      formik.setFieldValue("productBarcode", newProductBarcode);
    }
  }, [
    lastProductId,
    selectedSubToSubCategoryId,
    selectedSubCategoryId,
    selectedCategoryId,
    selectedSubToSubToSubCategoryId,
    formik.values.productBrandId,
  ]);

  const handleNext = () => {
    formik.handleSubmit();
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOkay = () => {
    setActiveStep(0);
    formik.resetForm();
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
    setSelectedSubToSubCategoryId(null);
    setSelectedSubToSubToSubCategoryId(null);
  };

  const handleMainCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const categoryId = event.target.value as number;
    setSelectedCategoryId(categoryId);
    setSelectedSubCategoryId(null);
    setSelectedSubToSubCategoryId(null);
    setSelectedSubToSubToSubCategoryId(null);
    formik.setFieldValue("mainCatId", categoryId);

    const selectedCategory = categoryList.find(
      (category) => category.id == categoryId
    );

    if (selectedCategory) {
      formik.setFieldValue(
        "productDetailsIsLooping",
        selectedCategory.categoryIsLooping
      );
      formik.setFieldValue(
        "productsDetailsLoopingConstant",
        selectedCategory.categoryLoopingConstant
      );
    }
  };

  const handleSubCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const subCategoryId = event.target.value as number;
    setSelectedSubCategoryId(subCategoryId);
    setSelectedSubToSubCategoryId(null);
    setSelectedSubToSubToSubCategoryId(null);

    const selectedCategory = subCategoryList.find(
      (category) => category.id == subCategoryId
    );

    if (selectedCategory) {
      formik.setFieldValue(
        "productDetailsIsLooping",
        selectedCategory.categoryIsLooping
      );
      formik.setFieldValue(
        "productsDetailsLoopingConstant",
        selectedCategory.categoryLoopingConstant
      );
    }
  };

  const handleSubToSubCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedSubToSubCategoryId = event.target.value as number;
    setSelectedSubToSubCategoryId(selectedSubToSubCategoryId);
    setSelectedSubToSubToSubCategoryId(null);

    const selectedCategory = subToSubCategoryList.find(
      (category) => category.id == selectedSubToSubCategoryId
    );

    if (selectedCategory) {
      formik.setFieldValue(
        "productDetailsIsLooping",
        selectedCategory.categoryIsLooping
      );
      formik.setFieldValue(
        "productsDetailsLoopingConstant",
        selectedCategory.categoryLoopingConstant
      );
    }
  };

  const handleSubToSubToSubCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedSubToSubToSubCategoryId = event.target.value as number;
    setSelectedSubToSubToSubCategoryId(selectedSubToSubToSubCategoryId);

    const selectedCategory = subToSubToSubCategoryList.find(
      (category) => category.id == selectedSubToSubToSubCategoryId
    );

    if (selectedCategory) {
      formik.setFieldValue(
        "productDetailsIsLooping",
        selectedCategory.categoryIsLooping
      );
      formik.setFieldValue(
        "productsDetailsLoopingConstant",
        selectedCategory.categoryLoopingConstant
      );
    }
  };

  const handleLoopingTableComplete = () => {
    setActiveStep(2);
  };

  if (
    isLoading ||
    ProductBrandLoading ||
    ProductTagLoading ||
    PrinterLoading ||
    lastProductIdIsLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const cancelFunction = () => {
    formik.resetForm();
    setSelectedImage(undefined);
    setOpenGallery(false);
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
    setSelectedSubToSubCategoryId(null);
    setSelectedSubToSubToSubCategoryId(null);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.stepperCard}>
            <Grid
              className={classes.stepperCardGrid}
              sx={{
                height: { gridHeight },
                p: 2,
              }}
            >
              <Stepper
                activeStep={activeStep}
                orientation={isMobile ? "horizontal" : "vertical"}
                sx={{ height: "100%" }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          {activeStep === 0 ? (
            <form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Add Image
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} p={"24px"}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className={classes.imgBox}>
                          {selectedImage && (
                            <img
                              src={URL.createObjectURL(selectedImage)}
                              alt="Uploaded"
                              className={classes.SelectImageBox}
                            />
                          )}
                          <div>
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="icon-button-file-1"
                              type="file"
                              onChange={handleImageChange}
                            />
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <label htmlFor="icon-button-file-1">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <CloudUploadIcon sx={{ fontSize: 45 }} />
                                </IconButton>
                              </label>
                              <IconButton
                                color="primary"
                                aria-label="select from gallery"
                                component="span"
                                onClick={openGalleryDialog}
                              >
                                <AppsIcon sx={{ fontSize: 45 }} />
                              </IconButton>
                              <div>PNG, JPG and JPEG are allowed</div>
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                         Category
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Main Category
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              select
                              fullWidth
                              size="small"
                              className={classes.textField}
                              value={selectedCategoryId || ""}
                              onChange={handleMainCategoryChange}
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem
                                value=""
                                disabled
                                style={{ color: "gray" }}
                              >
                                Select an option
                              </MenuItem>
                              {categoryList &&
                                categoryList.map((category) => (
                                  <MenuItem
                                    key={category.id}
                                    value={category.id}
                                  >
                                    {category.categoryName}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      </Grid>

                      {selectedCategoryId && subCategoryList.length > 0 && (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Sub Category
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              display="flex"
                              alignItems="center"
                            >
                              <TextField
                                select
                                fullWidth
                                size="small"
                                className={classes.textField}
                                value={
                                  selectedSubCategoryId === null
                                    ? ""
                                    : selectedSubCategoryId
                                }
                                onChange={handleSubCategoryChange}
                                InputLabelProps={{ shrink: true }}
                              >
                                <MenuItem
                                  value=""
                                  disabled
                                  style={{ color: "gray" }}
                                >
                                  Select an option
                                </MenuItem>
                                {subCategoryList.map((subcategory) => (
                                  <MenuItem
                                    key={subcategory.id}
                                    value={subcategory.id}
                                  >
                                    {subcategory.categoryName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {selectedCategoryId &&
                        selectedSubCategoryId &&
                        subToSubCategoryList.length > 0 && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center">
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                  Sub-to-Sub Category
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  select
                                  fullWidth
                                  size="small"
                                  className={classes.textField}
                                  value={
                                    selectedSubToSubCategoryId === null
                                      ? ""
                                      : selectedSubToSubCategoryId
                                  }
                                  onChange={handleSubToSubCategoryChange}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  <MenuItem
                                    value=""
                                    disabled
                                    style={{ color: "gray" }}
                                  >
                                    Select an option
                                  </MenuItem>
                                  {subToSubCategoryList.map(
                                    (subToSubcategory) => (
                                      <MenuItem
                                        key={subToSubcategory.id}
                                        value={subToSubcategory.id}
                                      >
                                        {subToSubcategory.categoryName}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                      {selectedCategoryId &&
                        selectedSubCategoryId &&
                        selectedSubToSubCategoryId &&
                        subToSubToSubCategoryList.length > 0 && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center">
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                  Sub-to-Sub-to-Sub Category
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  select
                                  fullWidth
                                  size="small"
                                  className={classes.textField}
                                  value={
                                    selectedSubToSubToSubCategoryId === null
                                      ? ""
                                      : selectedSubToSubToSubCategoryId
                                  }
                                  onChange={handleSubToSubToSubCategoryChange}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  <MenuItem
                                    value=""
                                    disabled
                                    style={{ color: "gray" }}
                                  >
                                    Select an option
                                  </MenuItem>
                                  {subToSubToSubCategoryList.map(
                                    (subToSubToSubcategory) => (
                                      <MenuItem
                                        key={subToSubToSubcategory.id}
                                        value={subToSubToSubcategory.id}
                                      >
                                        {subToSubToSubcategory.categoryName}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                        Service Information
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                             Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Name"
                              size="small"
                              {...formik.getFieldProps("productName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Conversion unit
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              select
                              size="small"
                              className={classes.textField}
                              SelectProps={{
                                multiple: true,
                                native: false,
                              }}
                              defaultValue=""
                              InputLabelProps={{ shrink: true }}
                              {...formik.getFieldProps("productConversionUnit")}
                            >
                              <MenuItem value="" disabled>
                                Select some option
                              </MenuItem>

                              {Object.entries(SizeOfLevelType).map(
                                ([key, value], index) => (
                                  <MenuItem key={index} value={value}>
                                    {key}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                             Brand
                            </Typography>
                          </Grid>
                          <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                            <TextField
                              select
                              size="small"
                              className={classes.textField}
                              defaultValue=""
                              InputLabelProps={{ shrink: true }}
                              {...formik.getFieldProps("productBrandId")}
                            >
                              <MenuItem
                                value=""
                                disabled
                                style={{ color: "gray" }}
                              >
                                Select an option
                              </MenuItem>
                              {productBrandList &&
                                productBrandList.map((productBrand) => (
                                  <MenuItem
                                    key={productBrand.id}
                                    value={productBrand.id}
                                  >
                                    {productBrand.productBrandName}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            display="flex"
                            sx={{
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              textAlign: "end",
                            }}
                          >
                            <Button
                              variant="contained"
                              className={classes.editButton}
                              onClick={() => {
                                setOpenProductBrand(true);
                              }}
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                               Tag
                            </Typography>
                          </Grid>
                          <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                            <TextField
                              select
                              size="small"
                              sx={{ width: "100%" }}
                              SelectProps={{
                                multiple: true,
                                native: false,
                              }}
                              defaultValue={[]}
                              InputLabelProps={{ shrink: true }}
                              {...formik.getFieldProps("productTagIds")}
                              onChange={(event) => {
                                const {
                                  target: { value },
                                } = event;
                                formik.setFieldValue(
                                  "productTagIds",
                                  typeof value === "string"
                                    ? value.split(",")
                                    : value
                                );
                              }}
                            >
                              {productTagList && productTagList.length > 0 ? (
                                productTagList.map(
                                  (productTag: IProductTag) => (
                                    <MenuItem
                                      key={productTag.id}
                                      value={productTag.id}
                                    >
                                      {productTag.tagName}
                                    </MenuItem>
                                  )
                                )
                              ) : (
                                <MenuItem
                                  value=""
                                  disabled
                                  style={{ color: "gray" }}
                                >
                                  Select an option
                                </MenuItem>
                              )}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            display="flex"
                            sx={{
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              textAlign: "end",
                            }}
                          >
                            <Button
                              variant="contained"
                              className={classes.editButton}
                              onClick={() => {
                                setOpenProductTag(true);
                              }}
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                               Price
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Price"
                              size="small"
                              {...formik.getFieldProps("productPrice")}
                              className={classes.textField}
                              type="number"
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Short Description
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Short Description"
                              size="small"
                              multiline
                              rows={3}
                              {...formik.getFieldProps(
                                "productShortDescription"
                              )}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">Barcode</Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className={classes.barCodeBox}>
                              <Grid container spacing={2}>
                                <Grid item lg={4} md={9} sm={12} xs={12}>
                                  <TextField
                                    placeholder="Enter Barcode"
                                    size="small"
                                    {...formik.getFieldProps("productBarcode")}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                      sx: { fontSize: 14 },
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      sx: { fontSize: 14 },
                                    }}
                                  />
                                  <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ mt: 2 }}
                                    className={classes.addButton}
                                    onClick={() => setShowBarcode(true)}
                                  >
                                    Generate
                                  </Button>
                                </Grid>

                                <Grid
                                  item
                                  lg={8}
                                  md={9}
                                  sm={12}
                                  xs={12}
                                  textAlign={"end"}
                                >
                                  {showBarcode &&
                                    formik.values.productBarcode && (
                                      <Box mt={2}>
                                        <Barcode
                                          value={formik.values.productBarcode}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">Printer</Typography>
                          </Grid>
                          <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                            <Box className={classes.printerBox}>
                              <Grid container spacing={1}>
                                {printerList.map((printer) => (
                                  <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    sm={6}
                                    xs={6}
                                    key={printer.id}
                                  >
                                    <Typography variant="subtitle1">
                                      {printer.printerName}
                                    </Typography>
                                    <IOSSwitch
                                      color="primary"
                                      sx={{ mr: 2 }}
                                      checked={formik.values.productPrinterIds.includes(
                                        printer.id
                                      )}
                                      onChange={() =>
                                        handlePrinterToggle(printer.id)
                                      }
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            display="flex"
                            sx={{
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              textAlign: "end",
                            }}
                          >
                            <Button
                              variant="contained"
                              className={classes.editButton}
                              onClick={() => {
                                setOpenPrinter(true);
                              }}
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Button Color
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className={classes.colorBox}>
                              <Grid container>
                                <Grid item lg={6} md={9} sm={12} xs={12}>
                                  <Box
                                    sx={{
                                      marginLeft: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: selectedColor,
                                        marginLeft: 1,
                                        border: "1px solid #d3d3d3",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  lg={6}
                                  md={9}
                                  sm={12}
                                  xs={12}
                                  alignItems={"flex-end"}
                                  textAlign={"end"}
                                >
                                  <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={toggleColorPicker}
                                  >
                                    <ColorLensIcon sx={{ fontSize: 45 }} />
                                  </IconButton>
                                  {showColorPicker && (
                                    <HexColorPicker
                                      color={selectedColor}
                                      onChange={handleColorChange}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={4} md={4} sm={4} xs={6}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">Active</Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CustomSwitch
                              color="primary"
                              sx={{ mr: 2 }}
                              formik={formik}
                              name="isActive"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={4} md={4} sm={4} xs={6}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Can Sell
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CustomSwitch
                              color="primary"
                              sx={{ mr: 2 }}
                              formik={formik}
                              name="productCanSell"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={4} md={4} sm={4} xs={6}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Product View Online
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CustomSwitch
                              color="primary"
                              sx={{ mr: 2 }}
                              formik={formik}
                              name="productViewOnline"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Long Description
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Long Description .."
                              size="small"
                              multiline
                              rows={8}
                              {...formik.getFieldProps(
                                "productLongDescription"
                              )}
                              className={classes.discriptionTextField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                  <Card className={classes.card}>
                    <Grid container spacing={3} p={"24px"}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          className={classes.addButton}
                          onClick={cancelFunction}
                        >
                          Cancel
                        </Button>
                        <Box m={0.5}></Box>

                        <Button
                          variant="contained"
                          startIcon={<SaveAltIcon />}
                          className={classes.addButton}
                          onClick={() => formik.handleSubmit()}
                          disabled={!formValid || isLoading}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
              <Dialog open={openGallery} onClose={closeGalleryDialog}>
                <Grid className={classes.Dialog}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                        Select an Image from Gallery
                      </Typography>
                    </Grid>
                  </Grid>
                  <DialogContent>
                    <ImageList
                      sx={{ width: 500, height: 450 }}
                      cols={3}
                      rowHeight={164}
                    >
                      {galleryImages.map((imagePath) => (
                        <ImageListItem key={imagePath}>
                          <img
                            src={`${imagePath}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${imagePath}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt="Gallery"
                            loading="lazy"
                            onClick={() => handleGallerySelection(imagePath)}
                            style={{ cursor: "pointer" }}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </DialogContent>
                </Grid>
              </Dialog>

              {openProductBrand && (
                <NewProductBrand
                  handleCloseDialog={() => setOpenProductBrand(false)}
                  openModel={openProductBrand}
                />
              )}

              {openProductTag && (
                <NewProductTag
                  handleCloseDialog={() => setOpenProductTag(false)}
                  openModel={openProductTag}
                />
              )}

              {openPrinter && (
                <NewPrinter
                  handleCloseDialog={() => setOpenPrinter(false)}
                  openModel={openPrinter}
                />
              )}
            </form>
          ) : activeStep === 1 ? (
            <LoopingTable
              productName={productDetails.productName}
              productId={productDetails.id}
              productDetailsIsLooping={productDetails.productDetailsIsLooping}
              productsDetailsLoopingConstant={
                productDetails.productsDetailsLoopingConstant
              }
              loopingTableComplete={handleLoopingTableComplete}
            />
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      py={2}
                      alignContent={"center"}
                      textAlign={"center"}
                    >
                      <Typography className={classes.cardTitle} p={10}>
                        Product Created Successfully
                      </Typography>
                      <Button
                        variant="contained"
                        startIcon={<TaskAltIcon />}
                        className={classes.addButton}
                        onClick={handleOkay}
                        disabled={isLoading}
                      >
                        Done
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
