export const appColor = {
  white: "#FFFFFF",
  primary: "#1976d2",
  black: " #000000",

  website: {
    100: "#005555",
    90: "#016B73",
    80: "#028090",
    70: "#73BFB9", //shadow
    60: "#ACDECD", //light green
    50: "#E4FDE1", //light light green
    40: "#043794", //bule
    30: "#F45B69", //red
    20: "#490B3D", //brown
    10: "#F1B814", //yellow
    0: "#E3E5E5",
  },

  greenSmoke: {
    100: "#717C7D",
    80: "#8D9697",
    70: "#84b475",
    60: "#AAB0B1",
    40: "#C6CBCB",
    20: "#E3E5E5",
  },

  blue: {
    10: "#F0FEFF",
    100: "#155d27"
  },

  green: {
    10: '#008000'
  },

  grey: {
    90: '#757575'
  }
};
