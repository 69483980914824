import React from "react";
import MiniDrawer from "./Drawer/Menu/Menu";
import AppRoutes from "./Drawer/Routes";
import { Box } from "@mui/material";
import MainLayout from "./Pages/Main";
import { BASENAME } from "./config";
import { BrowserRouter } from "react-router-dom";
import LoginProvider from "./Pages/Login/LoginProvider";
import withAppProviders from "./withAppProviders";

const App: React.FC = () => (
  <Box data-testid="pos-ui-app">
    <MainLayout>
      <BrowserRouter basename={BASENAME}>
        <LoginProvider>
          <Box>
            <MiniDrawer>
              <AppRoutes />
            </MiniDrawer>
          </Box>
        </LoginProvider>
      </BrowserRouter>
    </MainLayout>
  </Box>
);

export default withAppProviders(App);
