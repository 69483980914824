import React, { useState } from "react";
import {
  Grid,
  Card,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStyles } from "../../../theme/appStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { useNotifier } from "../../../Core/Notifier";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const CategoryForm = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { showErrorMessage, showMessage } = useNotifier();
   const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      categoryName: "",
    },
    validationSchema: yup.object({
      categoryName: yup
        .string()
        .required("Category name is required")
        .max(50, "Category name must not exceed 50 characters"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
     
        try {
          const formData = new FormData();
          formData.append("category_name", values.categoryName);
      
          const response = await axios.post(`${API_URL}/save-expense-category`, formData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
       
            showMessage("Category saved successfully!");
            formik.resetForm();
         
        } catch (error) {
          showErrorMessage("An error occurred while saving the category.");
        } finally {
          setLoading(false);
        }
      }
  });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card>
            <Grid
              container
              justifyContent="space-between"
              sx={{ borderBottom: 2, padding: 2 }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pt={2}
                alignContent={"center"}
              >
                <Typography className={classes.cardTitle}>
                  Create Expense Category
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item lg={9} md={9} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                    {/* Category Name Field */}
                    <Grid item xs={7}>
                      <Typography variant="subtitle1">Category Name</Typography>
                      <TextField
                        placeholder="Enter category name"
                        size="small"
                        {...formik.getFieldProps("categoryName")}
                        error={
                          formik.touched.categoryName &&
                          Boolean(formik.errors.categoryName)
                        }
                        helperText={
                          formik.touched.categoryName &&
                          formik.errors.categoryName
                        }
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              {/* Submit and Cancel Buttons */}
              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        className={classes.addButton}
                        startIcon={<CloseIcon />}
                        onClick={() =>navigate('/expense/list')}
                        // onClick={() => formik.resetForm()}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>
                      <Button
                        variant="contained"
                        className={classes.addButton}
                        startIcon={<SaveAltIcon />}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoryForm;
