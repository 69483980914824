export type EnumLiteralsOf<T extends object> = T[keyof T];

export type AttoDeskStores = EnumLiteralsOf<typeof AttoDeskStores>;

export const AttoDeskStores = Object.freeze({
  DEFAULT: "DEFAULT" as const,
  PRODUCT: "PRODUCT" as const,
  CATEGORY: "CATEGORY" as const,
  // SALEDETAILS: 'SALEDETAILS' as const,
});

export const RooleType = Object.freeze({
  FOOD: 1 as const,
  Beverages: 2 as const,
  Alcohol: 3 as const,
  Desserts: 4 as const,
});

export const KitchenPrinterType = Object.freeze({
  KDS: 1 as const,
  PVF: 2 as const,
  LMS: 3 as const,
  KDF: 4 as const,
});

export const SizeOfLevelType = Object.freeze({
  Bottle: 1 as const,
  Combo: 2 as const,
  Each: 3 as const,
  gal: 4 as const,
  gm: 5 as const,
  kg: 6 as const,
  Large: 7 as const,
  li: 8 as const,
  lb: 9 as const,
  Medium: 10 as const,
  oz: 11 as const,
  small: 12 as const,
});

export const LoopingConst = Object.freeze({
  Mobile: 1 as const,
  Computer: 2 as const,
});

export const ItemServiceChargeType = Object.freeze({
  None: 0 as const,
  Pay: 1 as const,
});

export const CategoryLevel = Object.freeze({
  Category: 0 as const,
  SubCategory: 1 as const,
  SubToSubCategory: 2 as const,
  SubToSubToSubCategory: 3 as const,
});

export const TableColumnTypeLevel = Object.freeze({
  string: "string" as const,
  integer: "integer" as const,
  boolean: "boolean" as const,
  // date: 3 as const,
});

export const TableColumnFieldLevel = Object.freeze({
  TextField: "TextField" as const,
  Switch: "Switch" as const,
  Selector: "Selector" as const,
  // MultipleSelector: "MultipleSelector" as const,
  // date: 3 as const,
});

export const CollectionType = Object.freeze({
  OneTime: "OneTime" as const,
  Monthly: "Monthly" as const,
  EveryTwoMonth: "EveryTwoMonth" as const,
  EveryThreeMonth: "EveryThreeMonth" as const,
  Custom: "Custom" as const,
});

export const ReminderMedianType = Object.freeze({
  sms: "sms" as const,
  email: "e-mail" as const
});

export const TransactionCollectionDate = Object.freeze({
  1: "1" as const,
  2: "2" as const,
  3: "3" as const,
  4: "4" as const,
  5: "5" as const,
  6: "6" as const,
  7: "7" as const,
  8: "8" as const,
  9: "9" as const,
  10: "10" as const,
  11: "11" as const,
  12: "12" as const,
  13: "13" as const,
  14: "14" as const,
  15: "15" as const,
  16: "16" as const,
  17: "17" as const,
  18: "18" as const,
  19: "19" as const,
  20: "20" as const,
  21: "21" as const,
  22: "22" as const,
  23: "23" as const,
  24: "24" as const,
  25: "25" as const,
  26: "26" as const,
  27: "27" as const,
  28: "28" as const,
  29: "29" as const,
  30: "30" as const,
  31: "31" as const
});


export const WorkOrderStatus = Object.freeze({
  Paid: 0 as const,
  Cancel: 1 as const,
  Pending: 2 as const,
});

export const TransferType = Object.freeze({
  OneWayTransfer: "0" as const,
  RoundTrip: "1" as const,
  MultiDestination: "2" as const,
});

export const ProductType = Object.freeze({
  Service: "0" as const,
  Product: "1" as const,
});