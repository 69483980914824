import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Box,
  Button,
  TextField,
  InputAdornment,
  Card,
  Typography,
} from "@mui/material";
import {
  useDeleteDepartmentMutation,
  useGetDepartmentQuery,
  useGetPrinterQuery,
} from "../../../Api/attoDeskApi";
import { IDepartment, IPrinter } from "../../../Api/Interface/api.interface";
import NewPopUpDepartment from "../Edit/index";
import DeletePopup from "../../../Components/Delete/DeletePopup";
import { useNotifier } from "../../../Core/Notifier";
import useDebounce from "../../../Components/SearchDebonce/useDebounce";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../theme/appStyles";
import CustomCheckbox from "../../../Components/Checkbox.tsx";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import Pagination from "../../../Components/Pagination";

const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { showErrorMessage, showMessage } = useNotifier();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const [selectedCoursing, setSelectedCoursing] = useState<IDepartment | null>(
    null
  );
  const { data: printerData, isLoading: printerLoading } = useGetPrinterQuery({
    searchText: "",
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [coursingToDelete, setCoursingToDelete] = useState<IDepartment | null>(
    null
  );

  const { data, isLoading, isError, refetch } = useGetDepartmentQuery({
    searchText: currentSearchQuery,
  });

  const printerList = useMemo(() => {
    return printerData?.data as IPrinter[];
  }, [printerData?.data]);

  const printerBrandMap = useMemo(() => {
    const map = new Map();
    printerList?.forEach((printer) => {
      map.set(printer.id, printer.printerName);
    });
    return map;
  }, [printerList]);

  useEffect(() => {
    if (debouncedSearchQuery.length >= 4 || debouncedSearchQuery === "") {
      setCurrentSearchQuery(debouncedSearchQuery);
      refetch();
    }
  }, [debouncedSearchQuery]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (coursing: IDepartment) => {
    setSelectedCoursing(coursing);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedCoursing(null);
    setOpenDialog(false);
  };

  const handleOpenDeletePopup = (coursing: IDepartment) => {
    setCoursingToDelete(coursing);
    setOpenDeleteCategory(true);
  };

  const handleCloseDeletePopup = () => {
    setCoursingToDelete(null);
    setOpenDeleteCategory(false);
  };

  const [deleteCategory] = useDeleteDepartmentMutation();
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategory(id).unwrap();
      if (response.status) {
        showMessage("Deleted successfully");
        setOpenDeleteCategory(false);
      } else {
        showErrorMessage("Failed to delete the department");
      }
    } catch (error) {
      showErrorMessage("Failed to delete the department");
    }
  };

  if (isLoading || printerLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );

  const coursings: IDepartment[] = Array.isArray(data?.data)
    ? (data?.data as IDepartment[])
    : [];

  const handleSearch = () => {
    setCurrentSearchQuery(searchQuery);
  };

  const resetFields = () => {
    setSearchQuery("");
    setCurrentSearchQuery("");
    refetch();
  };

  const handleAddProductClick = () => {
    navigate("/department/create");
  };

  return (
    <Card className={classes.card}>
      <Grid
        container
        justifyContent={"space-between"}
        spacing={5}
        sx={{ borderBottom: 2, padding: 2 }}
        className={classes.titleGrid}
      >
        <Grid item lg={4} md={4} sm={4} xs={4} pt={2} alignContent={"center"}>
          <Typography className={classes.cardTitle}>New Department</Typography>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          xs={8}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            sx={{ display: "flex" }}
          >
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CiSearch className={classes.searchWidgetIcon} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.formControlList,
                  },
                }}
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Deparment Name .."
                sx={{ width: "100%" }}
                size="small"
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                className={classes.addButton}
                onClick={handleAddProductClick}
              >
                Add Department
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeaderRow}>
              <TableRow>
                <TableCell className={classes.tablheader}>
                  <CustomCheckbox name="checkbox" />
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>#</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Department Name</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Printers</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Description</strong>
                </TableCell>
                <TableCell className={classes.tablheader} sx={{textAlign: "right"}}>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isError || coursings.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={20} align="center">
                    {isError ? "Error fetching data" : "No data available"}
                  </TableCell>
                </TableRow>
              ) : (
                coursings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: IDepartment, index) => (
                    <TableRow key={row.id} className={classes.tableRowHover}>
                      <TableCell className={classes.tablCell}>
                        <CustomCheckbox name="checkbox" />
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tablCell}
                      >
                        {row.departmentName}
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {row.DepartmentPrinterIds?.map(
                          (id) => printerBrandMap.get(id) || ""
                        ).join(", ")}
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {row.description}
                      </TableCell>
                      <TableCell className={classes.tablCell}>
                        <Grid
                          container
                          spacing={1}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.editButtonList}
                              onClick={() => handleOpenDialog(row)}
                            >
                              <EditSharpIcon className={classes.editListIcon} />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.deleteButtonList}
                              onClick={() => handleOpenDeletePopup(row)}
                            >
                              <DeleteOutlineSharpIcon
                                className={classes.deleteButtonList}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <Grid
            spacing={5}
            p={"18px"}
            py={4}
            justifyContent={"end"}
            container
            textAlign={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid
              sx={{
                textAlign: "end",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pagination
                count={coursings.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </TableContainer>
        {selectedCoursing && (
          <NewPopUpDepartment
            openModel={openDialog}
            handleCloseDialog={handleCloseDialog}
            data={selectedCoursing}
          />
        )}
        {coursingToDelete && (
          <DeletePopup
            open={openDeleteCategory}
            handleCloseDelete={handleCloseDeletePopup}
            onConfirm={async () => {
              await handleDelete(coursingToDelete.id.toString());
            }}
            title="Delete Department"
            content={`Are you sure you want to delete "${coursingToDelete.departmentName}"?`}
          />
        )}
      </Grid>
    </Card>
  );
};

export default Index;
