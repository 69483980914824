import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "../Components/Loader";

import Dashboard from "../Pages/Dashboard";

import CreateProduct from "../Pages/Product/Create";
import ListProduct from "../Pages/Product/List";
import ProductDetails from "../Pages/Product/View/index";

import CreateCategory from "../Pages/Category/Create";
import ListCategory from "../Pages/Category/List";

import CreateLooping from "../Pages/Looping/Create";
import ListLooping from "../Pages/Looping/List";

import CreateDepartment from "../Pages/Department/Create";
import ListDepartment from "../Pages/Department/List";

import CreateCoursing from "../Pages/Coursing/Create";
import ListCoursing from "../Pages/Coursing/List";

import CreateProductBrand from "../Pages/ProductBrand/Create";
import ListProductBrand from "../Pages/ProductBrand/List";

import CreateProductTags from "../Pages/ProductTags/Create";
import ListProductTags from "../Pages/ProductTags/List";

import CreateCustomer from "../Pages/Customer/Create";
import ListCustomer from "../Pages/Customer/List";
import CustomerTransaction from "../Pages/CustomerTransaction";

import CreateTax from "../Pages/Tax/Create";
import ListTax from "../Pages/Tax/List";

import CreatePrinter from "../Pages/Printer/Create";
import ListPrinter from "../Pages/Printer/List";

import CreateCompany from "../Pages/CompanyInfo/Create";
import ListCompany from "../Pages/CompanyInfo/List";
import EditCompany from "../Pages/CompanyInfo/Edit";

import CreateQuote from "../Pages/Quote/Create";
import ListQuote from "../Pages/Quote/List";
import EditQuote from "../Pages/Quote/Edit";

import HistoryWorkOrder from "../Pages/WorkOrder/History";

import HistoryInvoice from "../Pages/Invoice/History";
import InvoiceList from "../Pages/Invoice/List";
import CreateInvoice from "../Pages/Invoice/Create";

import CreateDriver from "../Pages/Driver/Create";
import ListDriver from "../Pages/Driver/List";
import DriverHistory from "../Pages/Driver/View";

import CustomerProject from "../Pages/CustomerTransaction/Create";
import InvoceCreateWithProject from "../Pages/Invoice/Create/invoceCreateWithProject";

import ListEmail from "../Pages/Email/List";
import CreateEmail from "../Pages/Email/Create";

import ListNote from "../Pages/Note/List";
import CreateNote from "../Pages/Note/Create";

import CreateExpense from "../Pages/Expense/Create";
import ListExpense from "../Pages/Expense/List";
import EditExpense from "../Pages/Expense/Edit";


import CreateExpenseCategory from "../Pages/ExpenseCategory/Create";
import ListExpenseCategory from "../Pages/ExpenseCategory/List";
import EditExpenseCategory from "../Pages/ExpenseCategory/Edit";

import CreateDeposit from "../Pages/Deposit/Create";
import ListDeposit from "../Pages/Deposit/List";


/**
 * AppRoutes will load the app routes.
 * @returns
 */
const AppRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<Loader />}>{/* <Fallback /> */}</Suspense>
        }
        path="/fallback"
      />
      <Route
  path="/"
  element={
    <Suspense fallback={<Loader />}>
      <Navigate to="/expense/list" />
    </Suspense>
  }
/>
      <Route
        path="/dashboard"
        element={
          <Suspense fallback={<Loader />}>
            <Dashboard />
          </Suspense>
        }
      />
      <Route
        path="/product/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListProduct />
          </Suspense>
        }
      />
      <Route
        path="/product/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateProduct />
          </Suspense>
        }
      />
      <Route
        path="/category/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListCategory />
          </Suspense>
        }
      />
      <Route
        path="/category/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateCategory />
          </Suspense>
        }
      />
        <Route
        path="/expense/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListExpense />
          </Suspense>
        }
      />
      <Route
        path="/expense/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateExpense />
          </Suspense>
        }
      />
       <Route
        path="/expense/:id"
        element={
          <Suspense fallback={<Loader />}>
            <EditExpense />
          </Suspense>
        }
      />

<Route
        path="/expense-category/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListExpenseCategory />
          </Suspense>
        }
      />
      <Route
        path="/expense-category/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateExpenseCategory />
          </Suspense>
        }
      />
       <Route
        path="/expense-category/:id"
        element={
          <Suspense fallback={<Loader />}>
            <EditExpenseCategory />
          </Suspense>
        }
      />

<Route
        path="/deposit/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListDeposit />
          </Suspense>
        }
      />
      <Route
        path="/deposit/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateDeposit />
          </Suspense>
        }
      />
    
      <Route
        path="/looping/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListLooping />
          </Suspense>
        }
      />
      <Route
        path="/looping/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateLooping />
          </Suspense>
        }
      />
      <Route
        path="/department/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListDepartment />
          </Suspense>
        }
      />
      <Route
        path="/department/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateDepartment />
          </Suspense>
        }
      />
      <Route
        path="/coursing/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListCoursing />
          </Suspense>
        }
      />
      <Route
        path="/coursing/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateCoursing />
          </Suspense>
        }
      />
      <Route
        path="/brand/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListProductBrand />
          </Suspense>
        }
      />
      <Route
        path="/brand/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateProductBrand />
          </Suspense>
        }
      />
      <Route
        path="/tag/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListProductTags />
          </Suspense>
        }
      />
      <Route
        path="/tag/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateProductTags />
          </Suspense>
        }
      />
      <Route
        path="/customer/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListCustomer />
          </Suspense>
        }
      />
      <Route
        path="/customer/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateCustomer />
          </Suspense>
        }
      />
      <Route
        path="/tax/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListTax />
          </Suspense>
        }
      />
      <Route
        path="/tax/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateTax />
          </Suspense>
        }
      />
      <Route
        path="/printer/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListPrinter />
          </Suspense>
        }
      />
      <Route
        path="/printer/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreatePrinter />
          </Suspense>
        }
      />
      <Route
        path="/customer-history/:id"
        element={
          <Suspense fallback={<Loader />}>
            <CustomerTransaction />
          </Suspense>
        }
      />
      <Route
        path="/product-history/:id"
        element={
          <Suspense fallback={<Loader />}>
            <ProductDetails />
          </Suspense>
        }
      />
      <Route
        path="/company/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateCompany />
          </Suspense>
        }
      />
      <Route
        path="/company/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListCompany />
          </Suspense>
        }
      />
      <Route
        path="/company-history/:id"
        element={
          <Suspense fallback={<Loader />}>
            <EditCompany />
          </Suspense>
        }
      />
      <Route
        path="/quote/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateQuote />
          </Suspense>
        }
      />
      <Route
        path="/quote/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListQuote />
          </Suspense>
        }
      />
      <Route
        path="/quote-history/:id"
        element={
          <Suspense fallback={<Loader />}>
            <EditQuote />
          </Suspense>
        }
      />
      <Route
        path="/work-order/history/:id"
        element={
          <Suspense fallback={<Loader />}>
            <HistoryWorkOrder />
          </Suspense>
        }
      />
      <Route
        path="/invoice/history/:id"
        element={
          <Suspense fallback={<Loader />}>
            <HistoryInvoice />
          </Suspense>
        }
      />
      <Route
        path="/invoice/list"
        element={
          <Suspense fallback={<Loader />}>
            <InvoiceList />
          </Suspense>
        }
      />
      <Route
        path="/team/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateDriver />
          </Suspense>
        }
      />
      <Route
        path="/team/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListDriver />
          </Suspense>
        }
      />
      <Route
        path="/team-history/:id"
        element={
          <Suspense fallback={<Loader />}>
            <DriverHistory />
          </Suspense>
        }
      />
      <Route
        path="/invoice/create/:id"
        element={
          <Suspense fallback={<Loader />}>
            <CreateInvoice />
          </Suspense>
        }
      />
      <Route
        path="/customer-history/project/:id"
        element={
          <Suspense fallback={<Loader />}>
            <CustomerProject />
          </Suspense>
        }
      />
      <Route
        path="/invoice/create/customer/:customerId/project/:projectId"
        element={
          <Suspense fallback={<Loader />}>
            <InvoceCreateWithProject />
          </Suspense>
        }
      />
      <Route element={<Navigate replace to="/dashboard" />} path="*" />
      <Route
        path="/email/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListEmail />
          </Suspense>
        }
      />
    
      <Route
        path="/email/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateEmail />
          </Suspense>
        }
      />

        <Route
        path="/note/list"
        element={
          <Suspense fallback={<Loader />}>
            <ListNote />
          </Suspense>
        }
      />
    
      <Route
        path="/note/create"
        element={
          <Suspense fallback={<Loader />}>
            <CreateNote />
          </Suspense>
        }
      />

    </Routes>
  );
};

export default AppRoutes;
