import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import {
  useCreateCustomerMutation,
  useCreateWorkOrderMutation,
  useGetAllSearchCompanyQuery,
  useGetCompanyQuery,
  useGetCustomerQuery,
  useGetLastWorkOrderIdQuery,
  useGetPriceHistoryQuery,
  useGetProductQuery,
  useGetTaxQuery,
} from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { useStyles } from "../../../theme/appStyles";
import { useNavigate } from "react-router";
import useDebounce from "../../../Components/SearchDebonce/useDebounce";
import { CiSearch } from "react-icons/ci";
import {
  ICompanyDetails,
  ICustomer,
  IPriceHistoryPayload,
  IProductPopUP,
  ITaxes,
} from "../../../Api/Interface/api.interface";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../Components/Validation/validationUtils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toDate } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import {
  ProductType,
  TransferType,
  WorkOrderStatus,
} from "../../../Core/Enum/enum";
import AddIcon from "@mui/icons-material/Add";
import { FaTrash } from "react-icons/fa";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import NewCompanyPopUp from "../../CompanyInfo/Components/NewCompanyPopUp";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { uploadImage } from "../../../Components/UploadFile/upload";

const generateWorkOrderNumber = (workOrderId: number) => {
  const date = new Date();
  const year = date.getFullYear().toString().slice(2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `WO${year}${month}${day}-${workOrderId.toString().padStart(2, "0")}`;
};

interface IProduct {
  productName: string;
  productImg: String | null;
  numberOfPassengers: number | null;
  transferType: string;
  transferTypeFrom: string;
  transferTypeTo: string;
  transferTypeDescription: string;
  departureDateTime: Dayjs | null;
  returnDateTime: Dayjs | null;
  departureAddress: string;
  destinationAddress: string;
  multiDestination: string;
  quantity: number | null;
  price: number | null;
  tax: number;
  total: number;
  taxId: number | null;
  unitPriceWithDiscount: number | null;
  unitTotalWithDiscount: number;
  productType: string;
  productLongDescription: string;
  productShortDescription: string;
}

interface IDiscount {
  value: number | null;
  description: string;
}

interface IProduct {
  productName: string;
  transferTypeTo: string;
  transferTypeFrom: string;
}

const Index: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [newTax, { isLoading }] = useCreateWorkOrderMutation();
  const { showErrorMessage, showMessage } = useNotifier();

  // const [searchQuery, setSearchQuerffy] = useState<string>("");
  // const [currentSearchQuery, setCurrentSearchQuery] = useState<string>("");
  // const debouncedSearchQuery = useDebounce(searchQuery, 300);
  // const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const [searchQueryCustomer, setSearchQueryCustomer] = useState<string>("");
  const [currentSearchQueryCustomer, setCurrentSearchQueryCustomer] =
    useState<string>("");
  const debouncedSearchQueryCustomer = useDebounce(searchQueryCustomer, 300);
  const [showDropdownCustomer, setShowDropdownCustomer] =
    useState<boolean>(false);

  const [searchQueryProduct, setSearchQueryProduct] = useState<string>("");
  const [currentSearchQueryProduct, setCurrentSearchQueryProduct] =
    useState<string>("");
  const debouncedSearchQueryProduct = useDebounce(searchQueryProduct, 300);
  const [showDropdownProductIndex, setShowDropdownProductIndex] = useState<
    number | null
  >(null);

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const [selectedDefultCompanyId, setSelectedDefultCompanyId] =
    useState<string>("");

  const [averagePrice, setAveragePrice] = useState<number | null>(null);
  const [nextPrice, setNextPrice] = useState<number | null>(null);
  const [quoteWorkOrderNo, setQuoteWorkOrderNo] = useState<String | null>(null);
  const [updated_at, setUpdated_at] = useState<Date | null>(null);
  const [priceId, setPriceId] = useState<number | null>(null);

  const [currentPriceQueryParams, setCurrentPriceQueryParams] =
    useState<IPriceHistoryPayload | null>(null);

  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
      const maxSize = 2048 * 1024; // 2 MB

      if (!validTypes.includes(file.type)) {
        showErrorMessage("The file must be an image (jpeg, png, jpg, gif).");
        return;
      } else if (file.size > maxSize) {
        showErrorMessage("The file must be smaller than 2 MB.");
        return;
      } else {
        try {
          setIsImageUploading(true);
          const uploadedImageUrl = await uploadImage(file);
          const updatedRows = [...productRows];
          updatedRows[index].productImg = uploadedImageUrl;
          setProductRows(updatedRows);
          setIsImageUploading(false);
        } catch (error) {
          showErrorMessage("Failed to upload the image.");
          setIsImageUploading(false);
        }
      }
    }
  };

  const { data: taxData, isLoading: taxLoading } = useGetTaxQuery({
    searchText: "",
  });

  const taxList = useMemo(() => {
    return taxData?.data as ITaxes[];
  }, [taxData?.data]);

  // const defaultTax = useMemo(() => {
  //   const foundDefaultTax = taxList.find((tax) => tax.isDefault === true);
  //   return foundDefaultTax ? foundDefaultTax : taxList[0] || null;
  // }, [taxList]);

  const [productRows, setProductRows] = useState<IProduct[]>([
    {
      productName: "",
      productImg: "",
      numberOfPassengers: null,
      transferType: TransferType.OneWayTransfer,
      transferTypeFrom: "",
      transferTypeTo: "",
      transferTypeDescription: "",
      departureDateTime: null,
      returnDateTime: null,
      departureAddress: "",
      destinationAddress: "",
      multiDestination: "",
      quantity: 1,
      price: null,
      tax: 0,
      total: 0,
      taxId: null,
      unitPriceWithDiscount: null,
      unitTotalWithDiscount: 0,
      productType: ProductType.Service,
      productLongDescription: "",
      productShortDescription: "",
    },
  ]);
  const [discounts, setDiscounts] = useState<IDiscount[]>([
    { value: null, description: "" },
  ]);
  const [discountValue, setDiscountValue] = useState<number>(0);
  const [discountDescription, setDiscountDescription] = useState<string>("");
  const [subTotal, setSubTotal] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [grandTotalEithDiscount, setGrandTotalWithDiscount] =
    useState<number>(0);

  const [selectedCompanyLogo, setSelectedCompanyLogo] = useState<string | null>(
    null
  );
  const [openCompany, setOpenCompany] = useState(false);

  const {
    data: companyData,
    isLoading: companyIsLoading,
    isError,
    refetch,
  } = useGetCompanyQuery({
    searchText: "",
    id: "",
  });

  const { data: workOrderLastData, isLoading: workOrderIsLoading } =
    useGetLastWorkOrderIdQuery();

  const {
    data: customerData,
    isLoading: customerIsLoading,
    isError: cutomerError,
    refetch: cutomerRefetch,
  } = useGetCustomerQuery({
    searchText: currentSearchQueryCustomer,
    id: "",
  });

  const {
    data: productData,
    isLoading: productIsLoading,
    refetch: productRefetch,
  } = useGetProductQuery({
    productBrandId: null,
    productCategoryId: null,
    productTagIds: null,
    productViewOnline: null,
    createdDateStart: null,
    createdDateEnd: null,
    searchText: currentSearchQueryProduct,
    createdDate: null,
    id: null,
  });

  const initialParams: IPriceHistoryPayload = {
    productName: "",
    transferTypeFrom: "",
    transferTypeTo: "",
  };

  const {
    data: priceHistoryData,
    isLoading: priceHistoryIsLoading,
    isError: priceHistoryError,
    refetch: priceHistoryRefetch,
  } = useGetPriceHistoryQuery(
    currentPriceQueryParams ? currentPriceQueryParams : initialParams,
    {
      skip: false,
    }
  );

  const companyList: ICompanyDetails[] = Array.isArray(companyData?.data)
    ? (companyData?.data as ICompanyDetails[])
    : [];

  const rawData = workOrderLastData?.data;
  const workOrderId =
    typeof rawData === "number"
      ? rawData
      : parseInt(rawData as string, 10) || 0;

  useEffect(() => {
    if (
      priceHistoryData &&
      Array.isArray(priceHistoryData.data) &&
      priceHistoryData.data.length > 0
    ) {
      setAveragePrice(priceHistoryData.data[0]?.averagePrice || null);
      setNextPrice(priceHistoryData.data[0]?.price || null);
      setQuoteWorkOrderNo(priceHistoryData.data[0]?.quoteWorkOrderNo || null);
      setUpdated_at(priceHistoryData.data[0]?.updated_at || null);
      setPriceId(priceHistoryData.data[0]?.workOrderId || null);
    } else {
      setAveragePrice(null);
      setNextPrice(null);
      setQuoteWorkOrderNo(null);
      setUpdated_at(null);
      setPriceId(null);
    }
  }, [priceHistoryData]);

  const generatedWorkOrderNo = generateWorkOrderNumber(workOrderId);

  // useEffect(() => {
  //   if (debouncedSearchQuery.length >= 0 || debouncedSearchQuery === "") {
  //     setCurrentSearchQuery(debouncedSearchQuery);
  //     refetch();
  //   }
  // }, [debouncedSearchQuery, refetch]);

  // const handleFocusOrClick = () => {
  //   setShowDropdown(true);
  //   if (currentSearchQuery === "") {
  //     refetch();
  //   }
  // };

  const handleFocusOrClickCustomer = () => {
    setShowDropdownCustomer(true);
    if (currentSearchQueryCustomer === "") {
      refetch();
    }
  };

  useEffect(() => {
    if (
      debouncedSearchQueryCustomer.length >= 2 ||
      debouncedSearchQueryCustomer === ""
    ) {
      setCurrentSearchQueryCustomer(debouncedSearchQueryCustomer);
      cutomerRefetch();
    }
  }, [debouncedSearchQueryCustomer, cutomerRefetch]);

  useEffect(() => {
    if (
      debouncedSearchQueryProduct.length >= 2 ||
      debouncedSearchQueryProduct === ""
    ) {
      setCurrentSearchQueryProduct(debouncedSearchQueryProduct);
      productRefetch();
    }
  }, [debouncedSearchQueryProduct, productRefetch]);

  // useEffect(() => {
  //   const totalSubTotal = productRows.reduce((acc, row) => acc + row.total, 0);
  //   setSubTotal(totalSubTotal);

  //   const calculatedTotalDiscount = discounts.reduce(
  //     (acc, discount) => acc + (discount.value),
  //     0
  //   );
  //   setTotalDiscount(calculatedTotalDiscount);

  //   const totalGrandTotal = totalSubTotal - calculatedTotalDiscount;
  //   setGrandTotal(totalGrandTotal);
  // }, [productRows, discounts]);

  useEffect(() => {
    const totalSubTotal = productRows.reduce((acc, row) => acc + row.total, 0);
    setSubTotal(totalSubTotal);

    const calculatedTotalDiscount = discounts.reduce(
      (acc, discount) => acc + (discount.value ?? 0),
      0
    );
    setTotalDiscount(calculatedTotalDiscount);

    const totalGrandTotal = totalSubTotal - calculatedTotalDiscount;
    setGrandTotal(totalGrandTotal);

    const unitPrice = calculatedTotalDiscount / totalSubTotal;
    setUnitPrice(unitPrice);

    const totalSubTotalWithDiscount = productRows.reduce(
      (acc, row) => acc + row.unitTotalWithDiscount,
      0
    );
    setGrandTotalWithDiscount(totalSubTotalWithDiscount);
  }, [productRows, discounts]);

  const productList: IProductPopUP[] = Array.isArray(productData?.data)
    ? (productData?.data as IProductPopUP[])
    : [];

  const customerList: ICustomer[] = Array.isArray(customerData?.data)
    ? (customerData?.data as ICustomer[])
    : [];

  const today = dayjs();

  const handleProductSelect = (product: IProductPopUP, index: number) => {
    const updatedRows = [...productRows];
    updatedRows[index].productName = product.productName;
    updatedRows[index].productImg = product.productImg || "";

    setProductRows(updatedRows);
    setShowDropdownProductIndex(null);
    updatePriceQueryParams(updatedRows[index]);
  };

  const handleAddRow = () => {
    setProductRows((prev) => [
      ...prev,
      {
        productName: "",
        productImg: "",
        numberOfPassengers: null,
        transferType: TransferType.OneWayTransfer,
        transferTypeFrom: "",
        transferTypeTo: "",
        transferTypeDescription: "",
        departureDateTime: null,
        returnDateTime: null,
        departureAddress: "",
        destinationAddress: "",
        multiDestination: "",
        quantity: 1,
        price: null,
        tax: 0,
        total: 0,
        taxId: 1,
        unitPriceWithDiscount: null,
        unitTotalWithDiscount: 0,
        productType: ProductType.Service,
        productLongDescription: "",
        productShortDescription: "",
      },
    ]);
    setSearchQueryProduct("");
  };

  // const handleFieldChange = <K extends keyof IProduct>(
  //   index: number,
  //   field: K,
  //   value: IProduct[K]
  // ) => {
  //   const updatedRows = [...productRows];
  //   updatedRows[index][field] = value;

  //   updatedRows[index].total =
  //     updatedRows[index].price *
  //     updatedRows[index].quantity *
  //     (1 + updatedRows[index].tax / 100);

  //   setProductRows(updatedRows);
  // };

  // const handleFieldChange = <K extends keyof IProduct>(
  //   index: number,
  //   field: K,
  //   value: IProduct[K]
  // ) => {
  //   const updatedRows = [...productRows];
  //   updatedRows[index][field] = value;

  //   const price = updatedRows[index].price ?? 0;
  //   const quantity = updatedRows[index].quantity ?? 0;
  //   const tax = updatedRows[index].tax ?? 0;

  //   updatedRows[index].total = price * quantity * (1 + tax / 100);

  //   setProductRows(updatedRows);
  // };

  const handleFieldChange = <K extends keyof IProduct>(
    index: number,
    field: K,
    value: IProduct[K]
  ) => {
    const updatedRows = [...productRows];
    updatedRows[index][field] = value;

    const price = updatedRows[index].price ?? 0;
    const quantity = updatedRows[index].quantity ?? 0;
    const tax = updatedRows[index].tax ?? 0;

    updatedRows[index].total = price * quantity;

    const totalSubTotal = updatedRows.reduce((acc, row) => acc + row.total, 0);

    const totalDiscountValue = discounts.reduce(
      (acc, discount) => acc + (discount.value ?? 0),
      0
    );

    const unitPriceDiscount = totalDiscountValue / totalSubTotal;

    const priceWithDiscount = price * (1 - unitPriceDiscount);

    const unitProductPriceWithDiscountAndTax =
      priceWithDiscount * (1 + tax / 100);

    const totalWithDiscountAndTax =
      priceWithDiscount * quantity * (1 + tax / 100);

    updatedRows[index].unitPriceWithDiscount =
      unitProductPriceWithDiscountAndTax;
    updatedRows[index].unitTotalWithDiscount = totalWithDiscountAndTax;

    setProductRows(updatedRows);

    if (
      updatedRows[index].productName &&
      updatedRows[index].transferTypeFrom &&
      updatedRows[index].transferTypeTo
    ) {
      updatePriceQueryParams(updatedRows[index]);
      setCurrentIndex(index);
    }
  };

  const updatePriceQueryParams = (row: IProduct) => {
    const { productName, transferTypeFrom, transferTypeTo } = row;

    if (productName && transferTypeFrom && transferTypeTo) {
      const newParams: IPriceHistoryPayload = {
        productName,
        transferTypeFrom,
        transferTypeTo,
      };

      setCurrentPriceQueryParams(newParams);

      if (newParams) {
        priceHistoryRefetch();
      }
    } else {
      console.error("Incomplete parameters for price query.");
    }
  };

  useEffect(() => {
    const totalSubTotal = productRows.reduce((acc, row) => acc + row.total, 0);
    setSubTotal(totalSubTotal);

    const calculatedTotalDiscount = discounts.reduce(
      (acc, discount) => acc + (discount.value ?? 0),
      0
    );
    setTotalDiscount(calculatedTotalDiscount);

    const unitPriceDiscount = totalDiscount / totalSubTotal;
    setUnitPrice(unitPriceDiscount);

    const updatedRows = productRows.map((row) => {
      const price = row.price ?? 0;
      const quantity = row.quantity ?? 0;
      const tax = row.tax ?? 0;

      const priceWithDiscount = price * (1 - unitPriceDiscount);

      const unitProductPriceWithDiscountAndTax =
        priceWithDiscount * (1 + tax / 100);

      const totalWithDiscountAndTax =
        priceWithDiscount * quantity * (1 + tax / 100);

      return {
        ...row,
        unitPriceWithDiscount: unitProductPriceWithDiscountAndTax,
        unitTotalWithDiscount: totalWithDiscountAndTax,
      };
    });

    setProductRows(updatedRows);

    // Calculate the grand total with discount
    const totalSubTotalWithDiscount = updatedRows.reduce(
      (acc, row) => acc + row.unitTotalWithDiscount,
      0
    );
    setGrandTotalWithDiscount(totalSubTotalWithDiscount);
  }, [productRows, discounts]);

  const handleProductNameChange = (index: number, value: string) => {
    setSearchQueryProduct(value);
    handleFieldChange(index, "productName", value);
  };

  const handleDeleteRow = (index: number) => {
    const updatedRows = productRows.filter((_, i) => i !== index);
    setProductRows(updatedRows);
  };

  const handleDiscountChange = (
    index: number,
    field: keyof IDiscount,
    value: any
  ) => {
    const updatedDiscounts = [...discounts];
    updatedDiscounts[index] = {
      ...updatedDiscounts[index],
      [field]: value,
    };
    setDiscounts(updatedDiscounts);
  };

  const handleOpenDialog = () => {
    setDiscountValue(0);
    setDiscountDescription("");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveDiscount = () => {
    setDiscounts((prev) => [
      ...prev,
      { value: discountValue, description: discountDescription },
    ]);
    handleCloseDialog();
  };

  const handleDeleteDiscount = (index: number) => {
    const updatedDiscounts = discounts.filter((_, i) => i !== index);
    setDiscounts(updatedDiscounts);
  };

  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyLogo: "",
      companyProvince: "",
      companyCity: "",
      companyPostalCode: "",
      companyStreetNumber: "",
      companyStreetName: "",
      companyMobileNumber: "",
      companyEmail: "",
      companyAddress: "",
      quoteWorkOrderNo: "",
      quoteWorkOrderIssueDate: null,
      quoteWorkOrderDueDate: null,
      quoteWorkOrderStatus: "",
      customerCompanyName: "",
      customerLastName: "",
      customerMobileNum: "",
      customerEmail: "",
      customerAddress: "",
      quoteWorkOrderSale: [],
      quoteWorkOrderSaleDiscount: "",
      quoteWorkOrderSaleSubTotal: "",
      quoteWorkOrderSaleGrandTotal: "",
      quoteWorkOrderSaleDiscountDescription: [],
      quoteWorkOrderCompanyId: "",
      quoteWorkOrderCustomerId: "",
      quoteWorkOrderCustomerRemark: "",
      quoteWorkOrderCustomerMemo: "",
    },

    enableReinitialize: true,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      const mobileNumberError = validatePhoneNumber(values.companyMobileNumber);
      if (mobileNumberError) {
        errors.companyMobileNumber = mobileNumberError;
      }

      const emailError = validateEmail(values.companyEmail);
      if (emailError) {
        errors.companyEmail = emailError;
      }

      const customerMobileNumberError = validatePhoneNumber(
        values.customerMobileNum
      );
      if (customerMobileNumberError) {
        errors.customerMobileNum = customerMobileNumberError;
      }

      const customerEmailError = validateEmail(values.customerEmail);
      if (customerEmailError) {
        errors.customerEmail = customerEmailError;
      }

      return errors;
    },

    onSubmit: async (values, { resetForm }) => {
      try {
        const temData = {
          companyName: values.companyName,
          companyLogo: values.companyLogo,
          companyProvince: values.companyProvince,
          companyCity: values.companyCity,
          companyPostalCode: values.companyPostalCode,
          companyStreetNumber: values.companyStreetNumber,
          companyStreetName: values.companyStreetName,
          companyMobileNumber: values.companyMobileNumber,
          companyEmail: values.companyEmail,
          companyAddress: values.companyAddress,
          quoteWorkOrderNo: generatedWorkOrderNo,
          quoteWorkOrderIssueDate: values.quoteWorkOrderIssueDate,
          quoteWorkOrderDueDate: values.quoteWorkOrderDueDate,
          quoteWorkOrderStatus: String(values.quoteWorkOrderStatus),
          customerCompanyName: values.customerCompanyName,
          customerLastName: values.customerLastName,
          customerMobileNum: values.customerMobileNum,
          customerEmail: values.customerEmail,
          customerAddress: values.customerAddress,
          quoteWorkOrderSale: productRows,
          quoteWorkOrderDiscount: String(totalDiscount),
          quoteWorkOrderSubTotal: String(subTotal),
          quoteWorkOrderSaleGrandTotal: String(grandTotalEithDiscount),
          quoteWorkOrderSaleDiscountDescription: discounts,
          quoteWorkOrderCompanyId: String(values.quoteWorkOrderCompanyId),
          quoteWorkOrderCustomerId: String(values.quoteWorkOrderCustomerId),
          quoteWorkOrderCustomerRemark: values.quoteWorkOrderCustomerRemark,
          quoteWorkOrderCustomerMemo: values.quoteWorkOrderCustomerMemo,
        };
        const addCompanyResponse = await newTax(temData).unwrap();
        if (!addCompanyResponse.status) {
          showErrorMessage(addCompanyResponse.message);
        } else {
          showMessage(addCompanyResponse.message);
          cancelFunction();
          // loadingFunction();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (workOrderId !== null) {
      const generatedWorkOrderNo = generateWorkOrderNumber(workOrderId);
      formik.setFieldValue("quoteWorkOrderNo", generatedWorkOrderNo);
    }
  }, [workOrderId]);

  const formatAddress = (
    streetNumber: string,
    streetName: string,
    postalCode: string,
    city: string,
    province: string
  ) => {
    return `${streetNumber} ${streetName},\n${postalCode} ${city},\n${province}`;
  };

  const handleSelect = (companyId: string) => {
    const selectedCompany = companyList.find(
      (company) => String(company.id) === companyId
    );
    if (selectedCompany) {
      // setSearchQuery(selectedCompany.companyName);

      const formattedAddress = formatAddress(
        selectedCompany.companyStreetNumber,
        selectedCompany.companyStreetName,
        selectedCompany.companyPostalCode,
        selectedCompany.companyCity,
        selectedCompany.companyProvince
      );

      formik.setFieldValue("companyName", selectedCompany.companyName);
      formik.setFieldValue("companyProvince", selectedCompany.companyProvince);
      formik.setFieldValue("companyCity", selectedCompany.companyCity);
      formik.setFieldValue(
        "companyPostalCode",
        selectedCompany.companyPostalCode
      );
      formik.setFieldValue(
        "companyStreetNumber",
        selectedCompany.companyStreetNumber
      );
      formik.setFieldValue(
        "companyStreetName",
        selectedCompany.companyStreetName
      );

      formik.setFieldValue(
        "companyMobileNumber",
        selectedCompany.companyMobileNumber
      );

      formik.setFieldValue("companyEmail", selectedCompany.companyEmail);
      formik.setFieldValue("quoteWorkOrderCompanyId", selectedCompany.id);

      formik.setFieldValue("companyAddress", formattedAddress);
      setSelectedCompanyLogo(selectedCompany.CompanyLogo);
    }
  };

  // const defaultCompany = companyList.find(
  //   (company) => company.isDefault === 1
  // );

  // const selectedCompanyConst = defaultCompany || companyList[0];

  useEffect(() => {
    if (companyList && companyList.length > 0) {
      const defaultCompany = companyList.find(
        (company) => company.isDefault === 1
      );

      const selectedCompany = defaultCompany || companyList[0];

      if (selectedCompany) {
        setSelectedDefultCompanyId(String(selectedCompany.id));

        const formattedAddress = formatAddress(
          selectedCompany.companyStreetNumber,
          selectedCompany.companyStreetName,
          selectedCompany.companyPostalCode,
          selectedCompany.companyCity,
          selectedCompany.companyProvince
        );

        formik.setFieldValue("companyName", selectedCompany.companyName);
        formik.setFieldValue(
          "companyProvince",
          selectedCompany.companyProvince
        );
        formik.setFieldValue("companyCity", selectedCompany.companyCity);
        formik.setFieldValue(
          "companyPostalCode",
          selectedCompany.companyPostalCode
        );
        formik.setFieldValue(
          "companyStreetNumber",
          selectedCompany.companyStreetNumber
        );
        formik.setFieldValue(
          "companyStreetName",
          selectedCompany.companyStreetName
        );
        formik.setFieldValue(
          "companyMobileNumber",
          selectedCompany.companyMobileNumber
        );
        formik.setFieldValue("companyEmail", selectedCompany.companyEmail);
        formik.setFieldValue("quoteWorkOrderCompanyId", selectedCompany.id);
        formik.setFieldValue("companyAddress", formattedAddress);
        setSelectedCompanyLogo(selectedCompany.CompanyLogo);
      }
    }
  }, [companyList]);

  const handleSelectCustomer = (customerId: string) => {
    const selectedCustomer = customerList.find(
      (customer) => String(customer.id) === customerId
    );
    if (selectedCustomer) {
      setSearchQueryCustomer(
        // (selectedCustomer.customerFirstName || " ") +
        //   " " +
        //   (selectedCustomer.customerLastName || " ")
        selectedCustomer.customerCompanyName
      );

      formik.setFieldValue(
        "customerCompanyName",
        selectedCustomer.customerCompanyName
      );
      formik.setFieldValue(
        "customerLastName",
        selectedCustomer.customerLastName
      );
      formik.setFieldValue(
        "customerMobileNum",
        selectedCustomer.customerMobileNum
      );
      formik.setFieldValue("customerEmail", selectedCustomer.customerEmail);
      formik.setFieldValue("customerAddress", selectedCustomer.customerAddress);
      formik.setFieldValue("quoteWorkOrderCustomerId", selectedCustomer.id);
    }
    setShowDropdownCustomer(false);
  };

  const cancelFunction = () => {
    formik.resetForm();
    // setSearchQuery("");
    // setCurrentSearchQuery("");
    setSearchQueryCustomer("");
    setCurrentSearchQueryCustomer("");
    setSearchQueryProduct("");
    setCurrentSearchQueryProduct("");
    setShowDropdownProductIndex(null);
    // setDiscount(0);
    setDiscountDescription("");
    setSubTotal(0);
    setGrandTotal(0);
    setSelectedCompanyLogo(null);
    // handleSelect(String(selectedCompanyConst));
    setProductRows([
      {
        productName: "",
        productImg: "",
        numberOfPassengers: null || 0,
        transferType: TransferType.OneWayTransfer,
        transferTypeFrom: "",
        transferTypeTo: "",
        transferTypeDescription: "",
        departureDateTime: null,
        returnDateTime: null,
        departureAddress: "",
        destinationAddress: "",
        multiDestination: "",
        quantity: 1,
        price: null || 0,
        tax: null || 0,
        total: 0,
        taxId: null,
        unitPriceWithDiscount: null,
        unitTotalWithDiscount: 0,
        productType: ProductType.Service,
        productLongDescription: "",
        productShortDescription: "",
      },
    ]);
    setDiscounts([{ value: null || 0, description: "" }]);
    setTotalDiscount(0);
  };

  const formValid = useMemo(() => {
    return formik.values.companyName === "" ||
      formik.values.companyName === undefined ||
      formik.values.customerMobileNum === "" ||
      formik.values.customerMobileNum === undefined ||
      formik.values.customerCompanyName === "" ||
      formik.values.customerCompanyName === undefined ||
      formik.values.quoteWorkOrderStatus === "" ||
      formik.values.quoteWorkOrderStatus === undefined
      ? false
      : true;
  }, [formik]);

  useEffect(() => {
    if (formik.values.quoteWorkOrderIssueDate === null) {
      formik.setFieldValue("quoteWorkOrderIssueDate", dayjs());
    }
  }, [formik.values.quoteWorkOrderIssueDate]);

  // useEffect(() => {
  //   if (formik.values.quoteWorkOrderStatus === "") {
  //     if (companyList && companyList.length > 0) {
  //       setSelectedCompanyId(String(companyList[0].id));
  //     }
  //     formik.setFieldValue("quoteWorkOrderStatus", selectedCompanyId);
  //   }
  // }, [formik.values.quoteWorkOrderStatus, companyList]);

  // const loadingFunction = () => {
  //   if (
  //     isLoading ||
  //     taxLoading ||
  //     workOrderIsLoading ||
  //     productIsLoading ||
  //     companyIsLoading ||
  //     customerIsLoading ||
  //     priceHistoryIsLoading
  //   ) {
  //     return (
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         height="10vh"
  //       >
  //         <CircularProgress />
  //       </Box>
  //     );
  //   }
  // };

  return (
    <div>
      <Card className={classes.quotesCard}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} padding={2}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ borderBottom: 2, paddingBottom: 5 }}
              className={classes.titleGrid}
            >
              <Grid container spacing={5}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={3} md={3} sm={4} xs={4}>
                      {selectedCompanyLogo ? (
                        <img
                          src={selectedCompanyLogo}
                          alt="Company Logo"
                          className={classes.quoteImg}
                        />
                      ) : (
                        <img alt="Company Logo" className={classes.quoteImg} />
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={9}
                      md={9}
                      sm={8}
                      xs={8}
                      display="flex"
                      sx={{
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        textAlign: "end",
                      }}
                    >
                      <Button
                        variant="contained"
                        className={classes.editButton}
                        onClick={() => {
                          setOpenCompany(true);
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Work Order Number :
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Invoice Number :"
                            size="small"
                            {...formik.getFieldProps("quoteWorkOrderNo")}
                            className={classes.textField}
                            InputProps={{
                              readOnly: true,
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Status :</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            SelectProps={{
                              native: false,
                            }}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                            {...formik.getFieldProps("quoteWorkOrderStatus")}
                          >
                            <MenuItem value="" disabled>
                              Select an option
                            </MenuItem>

                            {Object.entries(WorkOrderStatus).map(
                              ([key, value], index) => (
                                <MenuItem key={index} value={value}>
                                  {key}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Grid
                    container
                    spacing={3}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Issue Date :
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={formik.values.quoteWorkOrderIssueDate}
                              onChange={(date) =>
                                formik.setFieldValue(
                                  "quoteWorkOrderIssueDate",
                                  date
                                )
                              }
                              defaultValue={today}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  sx: { width: "100%" },
                                  InputProps: {
                                    sx: { fontSize: 14 },
                                  },
                                  InputLabelProps: {
                                    sx: { fontSize: 14 },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Due Date :
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={formik.values.quoteWorkOrderDueDate}
                              onChange={(date) =>
                                formik.setFieldValue(
                                  "quoteWorkOrderDueDate",
                                  today
                                )
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  sx: { width: "100%" },
                                  InputProps: {
                                    sx: { fontSize: 14 },
                                  },
                                  InputLabelProps: {
                                    sx: { fontSize: 14 },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ borderBottom: 2, paddingBottom: 5 }}
              className={classes.titleGrid}
            >
              <Grid container spacing={5}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography className={classes.h4Quoto}>
                            Issue From :
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CiSearch
                                    className={classes.searchWidgetIcon}
                                  />
                                </InputAdornment>
                              ),
                              classes: {
                                root: classes.formControlListQuto,
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              setShowDropdown(true);
                            }}
                            placeholder="Company Name .."
                            size="small"
                            defaultValue={""}
                            onFocus={handleFocusOrClick}
                            onClick={handleFocusOrClick}
                            sx={{ flexGrow: 1, width: "100%" }}
                            InputLabelProps={{ shrink: true }}
                          />

                          {showDropdown && (
                            <Paper className={classes.dropdownPaper}>
                              {companyIsLoading ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <CircularProgress size={24} />
                                </div>
                              ) : (
                                companyList.length > 0 &&
                                companyList.map((company) => (
                                  <MenuItem
                                    key={company.id}
                                    onClick={() =>
                                      handleSelect(String(company.id))
                                    }
                                  >
                                    {company.companyName}
                                  </MenuItem>
                                ))
                              )}
                            </Paper>
                          )}
                        </Grid> */}
                        <Grid item>
                          <TextField
                            select
                            size="small"
                            InputProps={{
                              classes: {
                                root: classes.formControlListQutoWithoutSearch,
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            className={classes.textField}
                            value={selectedDefultCompanyId}
                            onChange={(event) => {
                              const selectedId = event.target.value;
                              setSelectedDefultCompanyId(selectedId);
                              handleSelect(selectedId);
                            }}
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem
                              value=""
                              disabled
                              style={{ color: "gray" }}
                            >
                              Select an option
                            </MenuItem>
                            {companyList &&
                              companyList.map((company) => (
                                <MenuItem
                                  key={company.id}
                                  value={String(company.id)}
                                >
                                  {company.companyName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Company Name"
                        size="small"
                        {...formik.getFieldProps("companyName")}
                        className={classes.textField}
                        InputProps={{
                          readOnly: true,
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Company Address"
                        size="small"
                        value={formik.values.companyAddress}
                        className={classes.textField}
                        InputProps={{
                          readOnly: true,
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        multiline
                        rows={5}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Phone Number"
                        size="small"
                        {...formik.getFieldProps("companyMobileNumber")}
                        className={classes.textField}
                        InputProps={{
                          readOnly: true,
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        error={
                          formik.touched.companyMobileNumber &&
                          Boolean(formik.errors.companyMobileNumber)
                        }
                        helperText={
                          formik.touched.companyMobileNumber &&
                          formik.errors.companyMobileNumber
                        }
                      />
                    </Grid>

                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Email"
                        size="small"
                        {...formik.getFieldProps("companyEmail")}
                        className={classes.textField}
                        InputProps={{
                          readOnly: true,
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        error={
                          formik.touched.companyEmail &&
                          Boolean(formik.errors.companyEmail)
                        }
                        helperText={
                          formik.touched.companyEmail &&
                          formik.errors.companyEmail
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Grid
                    container
                    spacing={3}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography className={classes.h4Quoto}>
                            Issue For:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CiSearch
                                    className={classes.searchWidgetIcon}
                                  />
                                </InputAdornment>
                              ),
                              classes: {
                                root: classes.formControlListQuto,
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            value={searchQueryCustomer}
                            onChange={(e) => {
                              setSearchQueryCustomer(e.target.value);
                              setShowDropdownCustomer(true);
                            }}
                            placeholder="Name & phone number .."
                            size="small"
                            defaultValue={""}
                            onFocus={handleFocusOrClickCustomer}
                            onClick={handleFocusOrClickCustomer}
                            sx={{ flexGrow: 1, width: "100%" }}
                            InputLabelProps={{ shrink: true }}
                          />

                          {showDropdownCustomer && (
                            <Paper className={classes.dropdownPaper}>
                              {customerIsLoading ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <CircularProgress size={24} />
                                </div>
                              ) : (
                                customerList.length > 0 &&
                                customerList.map((company) => (
                                  <MenuItem
                                    key={company.id}
                                    onClick={() =>
                                      handleSelectCustomer(String(company.id))
                                    }
                                  >
                                    {/* {(company.customerFirstName || "") +
                                      " " +
                                      (company.customerLastName || "")} */}

                                    {company.customerCompanyName}
                                  </MenuItem>
                                ))
                              )}
                            </Paper>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Name"
                        size="small"
                        {...formik.getFieldProps("customerCompanyName")}
                        className={classes.textField}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Address"
                        size="small"
                        {...formik.getFieldProps("customerAddress")}
                        className={classes.textField}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        multiline
                        rows={5}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Phone Number"
                        size="small"
                        {...formik.getFieldProps("customerMobileNum")}
                        className={classes.textField}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        error={
                          formik.touched.customerMobileNum &&
                          Boolean(formik.errors.customerMobileNum)
                        }
                        helperText={
                          formik.touched.customerMobileNum &&
                          formik.errors.customerMobileNum
                        }
                      />
                    </Grid>

                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Email"
                        size="small"
                        {...formik.getFieldProps("customerEmail")}
                        className={classes.textField}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        error={
                          formik.touched.customerEmail &&
                          Boolean(formik.errors.customerEmail)
                        }
                        helperText={
                          formik.touched.customerEmail &&
                          formik.errors.customerEmail
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={5}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <TableContainer>
                      <Table className={classes.table}>
                        <TableHead className={classes.tableHeaderRow}>
                          <TableRow>
                            <TableCell className={classes.tablheader}>
                              <strong>Services</strong>
                            </TableCell>
                            <TableCell className={classes.tablheader}>
                              <strong>Quantity</strong>
                            </TableCell>
                            <TableCell className={classes.tablheader}>
                              <strong>Price</strong>
                            </TableCell>
                            <TableCell className={classes.tablheader}>
                              <strong>Tax</strong>
                            </TableCell>
                            <TableCell className={classes.tablheader}>
                              <strong>Unit Price</strong>
                            </TableCell>
                            <TableCell className={classes.tablheader}>
                              <strong>Total</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productRows.map((product, index) => (
                            <TableRow key={index}>
                              <TableCell scope="row">
                                <Grid container spacing={1}>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                      placeholder="Product Name .."
                                      size="small"
                                      sx={{
                                        width: "100%",
                                        minWidth: "200px",
                                      }}
                                      value={product.productName}
                                      onChange={(e) =>
                                        handleProductNameChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <CiSearch
                                              className={
                                                classes.searchWidgetIcon
                                              }
                                            />
                                          </InputAdornment>
                                        ),
                                        classes: {
                                          root: classes.formControlListQuto,
                                          notchedOutline:
                                            classes.notchedOutline,
                                        },
                                      }}
                                      onFocus={() =>
                                        setShowDropdownProductIndex(index)
                                      }
                                    />

                                    {showDropdownProductIndex === index && (
                                      <Paper className={classes.dropdownPaper}>
                                        {productIsLoading ? (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              padding: "10px",
                                            }}
                                          >
                                            <CircularProgress size={24} />
                                          </div>
                                        ) : (
                                          productList.length > 0 &&
                                          productList.map((Product) => (
                                            <MenuItem
                                              key={Product.id}
                                              onClick={() =>
                                                handleProductSelect(
                                                  Product,
                                                  index
                                                )
                                              }
                                            >
                                              <img
                                                src={String(Product.productImg)}
                                                className={
                                                  classes.quoteImgSelctMenu
                                                }
                                                alt="img"
                                              />
                                              {Product.productName}
                                            </MenuItem>
                                          ))
                                        )}
                                      </Paper>
                                    )}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell scope="row">
                                <Grid container spacing={1}>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                      placeholder="Quantity"
                                      size="small"
                                      type="number"
                                      sx={{
                                        width: "100%",
                                        minWidth: "100px",
                                      }}
                                      value={product.quantity}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "quantity",
                                          Number(e.target.value)
                                        )
                                      }
                                      className={classes.textField}
                                      InputProps={{
                                        sx: {
                                          fontSize: 14,
                                          border: "none",
                                        },
                                        classes: {
                                          root: classes.textFieldQuote,
                                          notchedOutline:
                                            classes.notchedOutline,
                                        },
                                      }}
                                      InputLabelProps={{
                                        sx: {
                                          fontSize: 14,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell scope="row">
                                <Grid container spacing={1}>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                      placeholder="Price"
                                      size="small"
                                      type="number"
                                      sx={{ width: "100%", minWidth: "100px" }}
                                      value={product.price}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "price",
                                          Number(e.target.value)
                                        )
                                      }
                                      className={classes.textField}
                                      InputProps={{
                                        sx: {
                                          fontSize: 14,
                                        },
                                        classes: {
                                          root: classes.textFieldQuote,
                                          notchedOutline:
                                            classes.notchedOutline,
                                        },
                                      }}
                                      InputLabelProps={{
                                        sx: {
                                          fontSize: 14,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell scope="row">
                                <Grid container spacing={1}>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                      select
                                      size="small"
                                      defaultValue=""
                                      value={product.tax}
                                      onChange={(e) => {
                                        const selectedTax = taxList.find(
                                          (tax) =>
                                            tax.percentage ===
                                            String(e.target.value)
                                        );

                                        if (selectedTax) {
                                          // Update both tax and taxId
                                          handleFieldChange(
                                            index,
                                            "tax",
                                            Number(selectedTax.percentage)
                                          ); // tax already a number
                                          handleFieldChange(
                                            index,
                                            "taxId",
                                            selectedTax.id
                                          ); // store taxId
                                        }
                                      }}
                                      className={classes.textField}
                                      InputProps={{
                                        sx: {
                                          fontSize: 14,
                                        },
                                        classes: {
                                          root: classes.textFieldQuote,
                                          notchedOutline:
                                            classes.notchedOutline,
                                        },
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                        sx: {
                                          fontSize: 14,
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        value="0"
                                        style={{ color: "gray" }}
                                      >
                                        NO TAX - 0%
                                      </MenuItem>
                                      {taxList &&
                                        taxList.map((tax) => (
                                          <MenuItem
                                            key={tax.id}
                                            value={tax.percentage}
                                          >
                                            {`${tax.taxName} - ${tax.percentage}%`}
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell scope="row">
                                <Grid container spacing={1}>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                      placeholder="Price"
                                      size="small"
                                      type="number"
                                      sx={{
                                        width: "100%",
                                        minWidth: "100px",
                                      }}
                                      value={
                                        product.unitPriceWithDiscount != null
                                          ? (isNaN(
                                              product.unitPriceWithDiscount
                                            )
                                              ? 0
                                              : product.unitPriceWithDiscount
                                            ).toFixed(2)
                                          : "0.00"
                                      }
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "unitPriceWithDiscount",
                                          Number(e.target.value)
                                        )
                                      }
                                      className={classes.textField}
                                      InputProps={{
                                        sx: {
                                          fontSize: 14,
                                        },
                                        classes: {
                                          root: classes.textFieldQuote,
                                          notchedOutline:
                                            classes.notchedOutline,
                                        },
                                      }}
                                      InputLabelProps={{
                                        sx: {
                                          fontSize: 14,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell scope="row">
                                <Grid container spacing={1}>
                                  <Grid item lg={10} md={10} sm={10} xs={10}>
                                    <TextField
                                      placeholder="Total"
                                      size="small"
                                      type="number"
                                      sx={{
                                        width: "100%",
                                        minWidth: "100px",
                                      }}
                                      value={
                                        product.unitTotalWithDiscount != null // Check for null explicitly
                                          ? (isNaN(
                                              product.unitTotalWithDiscount
                                            )
                                              ? 0
                                              : product.unitTotalWithDiscount
                                            ).toFixed(2) // Handle NaN
                                          : "0.00" // Fallback to "0.00" if null
                                      }
                                      // value={product.unitTotalWithDiscount.toFixed(
                                      //   2
                                      // )}
                                      className={classes.textField}
                                      InputProps={{
                                        readOnly: true,
                                        sx: {
                                          fontSize: 14,
                                        },

                                        classes: {
                                          root: classes.textFieldQuote,
                                          notchedOutline:
                                            classes.notchedOutline,
                                        },
                                      }}
                                      InputLabelProps={{
                                        sx: {
                                          fontSize: 14,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item lg={2} md={2} sm={2} xs={2}>
                                    <IconButton
                                      color="error"
                                      onClick={() => handleDeleteRow(index)}
                                    >
                                      <FaTrash />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      py={2}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        className={classes.addButton}
                        onClick={handleAddRow}
                      >
                        Product
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ borderBottom: 2, paddingBottom: 5 }}
              className={classes.titleGrid}
            >
              <Grid container spacing={3}>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <Grid container spacing={3} alignItems="center">
                    {discounts.map((discount, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={1}>
                          <Grid item lg={8} md={8} sm={6} xs={6}>
                            <TextField
                              placeholder="Description"
                              sx={{ width: "100%" }}
                              size="small"
                              value={discount.description}
                              onChange={(e) =>
                                handleDiscountChange(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                                classes: {
                                  root: classes.textFieldQuote,
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item lg={3} md={3} sm={5} xs={5}>
                            <TextField
                              placeholder="Discount"
                              sx={{ width: "100%" }}
                              size="small"
                              type="number"
                              value={discount.value}
                              onChange={(e) =>
                                handleDiscountChange(
                                  index,
                                  "value",
                                  Number(e.target.value)
                                )
                              }
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                                classes: {
                                  root: classes.textFieldQuote,
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item lg={1} md={1} sm={1} xs={1}>
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteDiscount(index)}
                            >
                              <FaTrash />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      py={2}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        className={classes.addButton}
                        onClick={handleOpenDialog}
                      >
                        Discount
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          alignContent={"center"}
                          textAlign={"right"}
                        >
                          <Typography className={classes.h5}>
                            Sub Total :
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            placeholder="Discount"
                            size="small"
                            value={subTotal.toFixed(2)}
                            className={classes.textField}
                            InputProps={{
                              readOnly: true,
                              sx: {
                                fontSize: 14,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              classes: {
                                root: classes.textFieldQuote,
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          alignContent={"center"}
                          textAlign={"right"}
                        >
                          <Typography className={classes.h5}>
                            Total Discount :
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            placeholder="Discount"
                            sx={{ width: "100%" }}
                            size="small"
                            value={totalDiscount.toFixed(2)}
                            className={classes.textField}
                            InputProps={{
                              readOnly: true,
                              sx: {
                                fontSize: 14,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              classes: {
                                root: classes.textFieldQuote,
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          alignContent={"center"}
                          textAlign={"right"}
                        >
                          <Typography className={classes.h5}>
                            Grand Amount :
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            placeholder="Discount"
                            sx={{ width: "100%" }}
                            size="small"
                            value={
                              isNaN(grandTotalEithDiscount)
                                ? "0.00"
                                : (grandTotalEithDiscount ?? 0).toFixed(2)
                            } // Ensure value is a valid number
                            className={classes.textField}
                            InputProps={{
                              readOnly: true,
                              sx: {
                                fontSize: 14,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              classes: {
                                root: classes.textFieldQuote,
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ borderBottom: 2, paddingBottom: 5 }}
              className={classes.titleGrid}
            >
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container alignItems="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="subtitle1">Memo :</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Enter Memo .."
                        size="small"
                        multiline
                        rows={8}
                        {...formik.getFieldProps("quoteWorkOrderCustomerMemo")}
                        className={classes.discriptionTextField}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container alignItems="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="subtitle1">Remark :</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder="Enter Remark .."
                        size="small"
                        multiline
                        rows={8}
                        {...formik.getFieldProps(
                          "quoteWorkOrderCustomerRemark"
                        )}
                        className={classes.discriptionTextField}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ borderBottom: 2, paddingBottom: 5 }}
              className={classes.titleGrid}
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    className={classes.addButton}
                    onClick={cancelFunction}
                  >
                    Cancel
                  </Button>
                  <Box m={0.5}></Box>

                  <Button
                    variant="contained"
                    startIcon={<SaveAltIcon />}
                    className={classes.addButton}
                    onClick={() => formik.handleSubmit()}
                    disabled={!formValid || isLoading}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <Grid className={classes.Dialog}>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={5}
                sx={{ borderBottom: 2, padding: 2 }}
                className={classes.titleGrid}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  pt={2}
                  alignContent={"center"}
                >
                  <Typography className={classes.cardTitle}>
                    Set Discount
                  </Typography>
                </Grid>
              </Grid>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container alignItems="center">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="subtitle1">Discount :</Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          placeholder="Enter Invoice Number :"
                          size="small"
                          value={discountValue}
                          onChange={(e) =>
                            setDiscountValue(Number(e.target.value))
                          }
                          className={classes.textField}
                          InputProps={{
                            sx: {
                              fontSize: 14,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: 14,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container alignItems="center">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="subtitle1">
                          Description :
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          placeholder="Enter Description .."
                          size="small"
                          multiline
                          rows={8}
                          value={discountDescription}
                          onChange={(e) =>
                            setDiscountDescription(e.target.value)
                          }
                          className={classes.discriptionTextField}
                          InputProps={{
                            sx: {
                              fontSize: 14,
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: 14,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ paddingRight: "24px", p: 4, py: 3, gap: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<CloseIcon />}
                      className={classes.addButton}
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </Button>
                    <Box m={0.5}></Box>

                    <Button
                      variant="contained"
                      startIcon={<SaveAltIcon />}
                      className={classes.addButton}
                      onClick={handleSaveDiscount}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Grid>
          </Dialog>

          {openCompany && (
            <NewCompanyPopUp
              handleCloseDialog={() => setOpenCompany(false)}
              openModel={openCompany}
            />
          )}
        </form>
      </Card>
    </div>
  );
};

export default Index;
