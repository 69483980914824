import * as React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import usePagination from "@mui/material/usePagination";

const List = styled("ul")(({ theme }) => ({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  alignItems: "center",
  border: `1px solid ${theme.palette.cardAdd.borderColor}`,
  width: "100%",
  borderRadius: "8px",
}));

const PageButton = styled(Button)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    fontWeight: "normal",
    minWidth: "32px",
    height: "33px",
    padding: "0",
    margin: 0,
    boxShadow: "none",
    // border: `1px solid ${theme.palette.cardAdd.borderColor}`,
    backgroundColor: selected
      ? theme.palette.primary.main
      : theme.palette.background.paper,
    color: selected
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary,
    textTransform: "none",
    "&:hover": {
      backgroundColor: selected
        ? theme.palette.primary.dark
        : theme.palette.action.hover,
    },
  })
);

const ListItem = styled("li")(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.cardAdd.borderColor}`,
  margin: 0,
  padding: 0,
  display: "flex",
  alignItems: "center",
}));

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const Pagination: React.FC<{
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLElement>, page: number) => void;
}> = ({ count, page, rowsPerPage, onPageChange }) => {
  const { items } = usePagination({
    count: Math.ceil(count / rowsPerPage),
    page: page + 1,
  });

  return (
    <nav>
      <List>
        {items.map(({ page: itemPage, type, selected, ...item }, index) => {
          const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            if (itemPage !== null) {
              onPageChange(event, itemPage - 1);
            }
          };

          const { onClick, ...itemProps } = item;

          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <PageButton
                type="button"
                onClick={handleClick}
                {...itemProps}
                selected={selected}
              >
                {itemPage}
              </PageButton>
            );
          } else {
            children = (
              <Button
                type="button"
                onClick={handleClick}
                {...itemProps}
                sx={{
                  minWidth: "32px",
                  height: "33px",
                  padding: "0",
                  px: "6px",
                  textTransform: "none",
                  //   color: "#424e5a"
                  ":disabled": {
                    color: "#424e5a",
                    fontWeight: 200
                  },
                }}
              >
                {capitalizeFirstLetter(type)}
              </Button>
            );
          }

          return <ListItem key={index}>{children}</ListItem>;
        })}
      </List>
    </nav>
  );
};

export default Pagination;
