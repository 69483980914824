import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../config";
import {
  IApiCustomerResponse,
  IApiLoopingResponse,
  IApiResponse,
  IApiResponseInvoice,
  IApiResponseWorkOrder,
  ICategoryFilterPayload,
  ICategoryPayload,
  ICustomerProjectPayload,
  ICustomerTransactionPayload,
  IGetByCategoryArrayPayload,
  IGetByCategoryPayload,
  IInvoiceListPayload,
  ILoopingTableListPayload,
  IPriceHistoryPayload,
  IProductPayload,
  IRemovedorkOrderByCustomerProjectPayload,
  ISearchIsActivePayload,
  ISearchPayload,
  ISelectCustomerPayload,
  IUpdateStatuInvoicePayload,
  IUpdateWorkOrderByCustomerProjectPayload,
  IUser,
  IWorkOrderByCustomerProjectPayload,
  IWorkOrderHistoryPayload,
  IEmail,
  IText,
  INote,
  INoteEmail,
  IActivityHistoryApiResponse,
  IActivityHistoryProjectApiResponse,
} from "./Interface/api.interface";
/**
 * Holds all the API callbacks
 * @returns RTK Implementation for backend
 */

export const attoDeskApi = createApi({
  reducerPath: "attoDeskApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("token") || ""}`
      );
      return headers;
    },
  }),
  tagTypes: [
    "User",
    "category",
    "company",
    "department",
    "coursing",
    "tax",
    "product",
    "printer",
    "productBrand",
    "productTag",
    "productCategory",
    "modifier",
    "productGetByCategory",
    "looping",
    "customer",
    "quote",
    "workOrderHistroy",
    "invoice",
    "driver",
    "email",
    "note",
    "noteEmail",
    "emailTemplate",
    "noteTemplate",
    "activityHistory",
    "activityHistoryProject",
    "text"
  ],
  endpoints: (builder) => ({
    getUser: builder.query<IApiResponse, void>({
      query: (request) => {
        return {
          url: "/user/userInfo",
          method: "POST",
          body: request,
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["User"],
    }),
    login: builder.mutation<IApiResponse, IUser>({
      query: (request) => {
        return {
          url: "/login",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
    }),
    createCategory: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/category",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["category", "productCategory"],
    }),
    createCompany: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/company",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["company"],
    }),
    createDepartment: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/department",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["department"],
    }),
    createCoursing: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/coursing",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["coursing"],
    }),
    createTax: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/tax",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["tax", "product", "category"],
    }),
    createProductBrand: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/product/brand",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["productBrand", "product"],
    }),
    createProductTag: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/product/tags",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["productTag", "product"],
    }),
    createProductCategory: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/product/category",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["productCategory", "product"],
    }),
    createProduct: builder.mutation<IApiLoopingResponse, Object>({
      query: (request) => {
        return {
          url: "/product",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["product"],
    }),
    createPrinter: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/printer",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["printer", "product"],
    }),
    getCategory: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/category/getAll",
          method: "POST",
        };
      },
      providesTags: ["product", "category"],
      keepUnusedDataFor: 0,
    }),
    getAllMainCategory: builder.query<IApiResponse, ICategoryPayload>({
      query: (request) => {
        return {
          url: "/main-category/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["product", "category"],
      keepUnusedDataFor: 0,
    }),
    deleteCoursing: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/coursing/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["coursing"],
    }),
    deleteDepartment: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/department/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["department"],
    }),
    deletePrinter: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/printer/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["printer"],
    }),
    deleteEmailTemplate: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/email-template/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["emailTemplate"],
    }),
    deleteNoteTemplate: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/note-template/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["noteTemplate"],
    }),
    deleteProductBrand: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/product/brand/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["productBrand"],
    }),
    deleteProductTag: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/product/tags/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["productTag"],
    }),
    deleteTax: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/tax/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["tax"],
    }),
    deleteProductCategory: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/product/category/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["productCategory"],
    }),
    deleteCategory: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/category/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["category", "productCategory"],
    }),
    getProduct: builder.query<IApiResponse, IProductPayload>({
      query: (request) => {
        return {
          url: "/product/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["productTag", "product", "quote"],
      keepUnusedDataFor: 0,
    }),
    createGetAllProduct: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/product/getAll",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["productTag", "product"],
    }),
    deleteProduct: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/product/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["product"],
    }),
    getLastProductId: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/product/last-id",
          method: "GET",
        };
      },
      providesTags: ["product"],
      keepUnusedDataFor: 0,
    }),
    createModifier: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/modifier",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["modifier"],
    }),
    getAllModifier: builder.query<IApiResponse, ISearchPayload>({
      query: (request) => {
        return {
          url: "/modifier/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["modifier"],
      keepUnusedDataFor: 0,
    }),
    deleteModifier: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/modifier/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["modifier"],
    }),
    getDepartment: builder.query<IApiResponse, ISearchPayload>({
      query: (request) => {
        return {
          url: "/department/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["department", "category"],
      keepUnusedDataFor: 0,
    }),
    getTax: builder.query<IApiResponse, ISearchPayload>({
      query: (request) => {
        return {
          url: "/tax/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["tax", "category", "product"],
      keepUnusedDataFor: 0,
    }),
    getCoursing: builder.query<IApiResponse, ISearchPayload>({
      query: (request) => {
        return {
          url: "/coursing/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["coursing", "category"],
      keepUnusedDataFor: 0,
    }),
    getPrinter: builder.query<IApiResponse, ISearchPayload>({
      query: (request) => {
        return {
          url: "/printer/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["printer", "product"],
      keepUnusedDataFor: 0,
    }),
    getEmailTemplate: builder.query<IApiResponse, ISearchPayload>({
      query: (request) => {
        return {
          url: "/email-template/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["emailTemplate"],
      keepUnusedDataFor: 0,
    }),
    getNoteTemplate: builder.query<IApiResponse, ISearchPayload>({
      query: (request) => {
        return {
          url: "/note-template/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["noteTemplate"],
      keepUnusedDataFor: 0,
    }),
    getProductTag: builder.query<IApiResponse, ISearchIsActivePayload>({
      query: (request) => {
        return {
          url: "/product/tags/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["productTag", "product"],
      keepUnusedDataFor: 0,
    }),
    getProductBrand: builder.query<IApiResponse, ISearchIsActivePayload>({
      query: (request) => {
        return {
          url: "/product/brand/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["productBrand", "product"],
      keepUnusedDataFor: 0,
    }),
    getProductCategory: builder.query<IApiResponse, ISearchIsActivePayload>({
      query: (request) => {
        return {
          url: "/product/category/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["productCategory", "product"],
      keepUnusedDataFor: 0,
    }),
    // getSubCategoryByCategoryId: builder.query<IApiResponse, ISearchIsActivePayload>({
    //   query: (request) => {
    //     return {
    //       url: '/product/category/getAll',
    //       method: "POST",
    //       body: request,
    //     };
    //   },
    //   providesTags:['productCategory','product'],
    //   keepUnusedDataFor: 0,
    // }),
    getSubCategoryByCategoryId: builder.query<
      IApiResponse,
      IGetByCategoryPayload
    >({
      query: (request) => {
        return {
          url: "/product/get-by-categories",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["productGetByCategory", "product"],
      keepUnusedDataFor: 0,
    }),
    getSubCategoryByArray: builder.query<
      IApiResponse,
      IGetByCategoryArrayPayload
    >({
      query: (request) => {
        return {
          url: "/category/category-fiter/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["productGetByCategory", "product", "productCategory"],
      keepUnusedDataFor: 0,
    }),
    getProductSubCategory: builder.query<IApiResponse, ISearchIsActivePayload>({
      query: (request) => {
        return {
          url: "/category/sub-category/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["productCategory", "product"],
      keepUnusedDataFor: 0,
    }),
    getAllCategoryName: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/all-category/getAll",
          method: "POST",
        };
      },
      providesTags: ["productCategory"],
      keepUnusedDataFor: 0,
    }),
    getCtegoryFilter: builder.query<IApiResponse, ICategoryFilterPayload>({
      query: (request) => {
        return {
          url: "/category/filter",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["product"],
      keepUnusedDataFor: 0,
    }),
    getLoopingTable: builder.query<IApiResponse, ISearchPayload>({
      query: (request) => {
        return {
          url: "/looping/list-tables",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["category", "product", "looping"],
      keepUnusedDataFor: 0,
    }),
    createLoopingTable: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/looping/create-table",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["looping", "product"],
    }),
    getAllLoopingTable: builder.query<IApiResponse, ILoopingTableListPayload>({
      query: (request) => {
        return {
          url: "/looping/list-tables",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["looping", "product"],
      keepUnusedDataFor: 0,
    }),
    deleteLoopingTable: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/looping/delete-table",
          method: "DELETE",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["looping", "product"],
    }),
    updateLoopingTable: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/looping/update-table",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["looping", "product"],
    }),
    insertOrUpdateDataLoopingTable: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/looping/table/insert-update-data",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["product", "looping"],
    }),
    getCustomer: builder.query<IApiResponse, ILoopingTableListPayload>({
      query: (request) => {
        return {
          url: "/customer/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["customer"],
      keepUnusedDataFor: 0,
    }),
    getActivityHistory: builder.query<IActivityHistoryApiResponse, ILoopingTableListPayload>({
      query: (request) => {
        return {
          url: "/customer/activityHistory/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["activityHistory"],
      keepUnusedDataFor: 0,
    }),

    getProjectActivityHistory: builder.query<IActivityHistoryProjectApiResponse, ICustomerProjectPayload>({
      query: (request) => {
        return {
          url: "/customer/project/activityHistory/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["activityHistoryProject"],
      keepUnusedDataFor: 0,
    }),
    deleteCustomer: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/customer/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["customer"],
    }),
    createCustomer: builder.mutation<IApiCustomerResponse, Object>({
      query: (request) => {
        return {
          url: "/customer",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["customer"],
    }),
    getCustomerTransaction: builder.query<
      IApiResponse,
      ICustomerTransactionPayload
    >({
      query: (request) => {
        return {
          url: "/customer/transaction/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["customer"],
      keepUnusedDataFor: 0,
    }),
    deleteCustomerTransaction: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/customer/transaction/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["customer"],
    }),
    createCustomerTransaction: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/customer/transaction",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["customer"],
    }),
    getCompany: builder.query<IApiResponse, ILoopingTableListPayload>({
      query: (request) => {
        return {
          url: "/company/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["company"],
      keepUnusedDataFor: 0,
    }),
    deleteCompany: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/company/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["company"],
    }),
    getAllSearchCompany: builder.query<IApiResponse, ILoopingTableListPayload>({
      query: (request) => {
        return {
          url: "/company/search/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["company"],
      keepUnusedDataFor: 0,
    }),
    createWorkOrder: builder.mutation<IApiResponseWorkOrder, Object>({
      query: (request) => {
        return {
          url: "/work-order",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["quote"],
    }),
    getLastWorkOrderId: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/work-order/next-id",
          method: "GET",
        };
      },
      providesTags: ["quote"],
      keepUnusedDataFor: 0,
    }),
    getWorkOrder: builder.query<IApiResponse, ILoopingTableListPayload>({
      query: (request) => {
        return {
          url: "/work-order/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["quote", "workOrderHistroy"],
      keepUnusedDataFor: 0,
    }),
    updateInvoiceStatus: builder.query<
      IApiResponse,
      IUpdateStatuInvoicePayload
    >({
      query: (request) => {
        return {
          url: "/work-order/update-invoice",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["workOrderHistroy"],
      keepUnusedDataFor: 0,
    }),
    getAllInvoice: builder.query<IApiResponse, IInvoiceListPayload>({
      query: (request) => {
        return {
          url: "/invoice/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["invoice"],
      keepUnusedDataFor: 0,
    }),
    getAllWorkOrderHistory: builder.query<
      IApiResponse,
      IWorkOrderHistoryPayload
    >({
      query: (request) => {
        return {
          url: "/work-order-history",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["workOrderHistroy"],
      keepUnusedDataFor: 0,
    }),
    createDriver: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/driver",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["driver"],
    }),
    getDriver: builder.query<IApiResponse, ILoopingTableListPayload>({
      query: (request) => {
        return {
          url: "/driver/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["driver"],
      keepUnusedDataFor: 0,
    }),
    deleteDriver: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/driver/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["driver"],
    }),
    getPriceHistory: builder.query<IApiResponse, IPriceHistoryPayload>({
      query: (request) => {
        return {
          url: "/work-order/price",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["quote"],
      keepUnusedDataFor: 0,
    }),
    getCustomerProject: builder.query<IApiResponse,  ICustomerProjectPayload >({
      query: (request) => {
        return {
          url: "/customer/project/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["customer"],
      keepUnusedDataFor: 0,
    }),
    deleteCustomerProject: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/customer/project/${Id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["customer"],
    }),
    createCustomerProject: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/customer/project",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["customer"],
    }),
    getWorkOrderByCustomerProject: builder.query<
      IApiResponse,
      IWorkOrderByCustomerProjectPayload
    >({
      query: (request) => {
        return {
          url: "/work-order/project/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["customer"],
      keepUnusedDataFor: 0,
    }),
    getSelectorWorkOrderByCustomerProject: builder.query<IApiResponse, ISelectCustomerPayload>({
      query: (request) => {
        return {
          url: "/work-order/project/selector/getAll",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["customer"],
      keepUnusedDataFor: 0,
    }),

    updateWorkOrderByCustomerProject: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/work-order/assign-project",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["customer"],
    }),
    removedCustomerProjectM: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/work-order/project/remove",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["customer"],
    }),
    createWorkOrderWithProject: builder.mutation<IApiResponseWorkOrder, Object>({
      query: (request) => {
        return {
          url: "/work-order/project-id",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["quote"],
    }),
    createEmail: builder.mutation<IEmail, Object>({
      query: (request) => {
        return {
          url: "/send-email",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["email"],
    }),

    createEmailInvoice: builder.mutation<IApiResponseInvoice, FormData>({
      query: (formData) => {
        return {
          url: "/send-invoice-email",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["email"],
    }),

    createText: builder.mutation<IText, Object>({
      query: (request) => {
        return {
          url: "/send-text",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["text"],
    }),
    
    
  
  createNote: builder.mutation<INote, Object>({
    query: (request) => {
      return {
        url: "/save-note",
        method: "POST",
        body: JSON.stringify(request),
      };
    },
    invalidatesTags: ["note"],
  }),

  createNoteEmail: builder.mutation<INoteEmail, Object>({
    query: (request) => {
      return {
        url: "/send-note-email",
        method: "POST",
        body: JSON.stringify(request),
      };
    },
    invalidatesTags: ["noteEmail"],
  }),

  createEmailTemplate: builder.mutation<IApiResponse, Object>({
    query: (request) => {
      return {
        url: "/email-template",
        method: "POST",
        body: JSON.stringify(request),
      };
    },
    invalidatesTags: ["emailTemplate"],
  }),
  createNoteTemplate: builder.mutation<IApiResponse, Object>({
    query: (request) => {
      return {
        url: "/note-template",
        method: "POST",
        body: JSON.stringify(request),
      };
    },
    invalidatesTags: ["noteTemplate"],
  }),
}),


});

export const {
  useGetUserQuery,
  useLoginMutation,
  useCreateCategoryMutation,
  useCreateCompanyMutation,
  useCreateDepartmentMutation,
  useCreateCoursingMutation,
  useCreateTaxMutation,
  useGetDepartmentQuery,
  useCreateProductBrandMutation,
  useCreateProductTagMutation,
  useCreateProductCategoryMutation,
  useCreateProductMutation,
  useCreatePrinterMutation,
  useGetCategoryQuery,
  useGetAllMainCategoryQuery,
  useDeleteCoursingMutation,
  useDeleteDepartmentMutation,
  useDeletePrinterMutation,
  useDeleteEmailTemplateMutation,
  useCreateEmailInvoiceMutation,
  useDeleteNoteTemplateMutation,
  useDeleteProductBrandMutation,
  useDeleteProductTagMutation,
  useDeleteTaxMutation,
  useDeleteProductCategoryMutation,
  useDeleteCategoryMutation,
  useGetProductQuery,
  useCreateGetAllProductMutation,
  useDeleteProductMutation,
  useGetLastProductIdQuery,
  useCreateModifierMutation,
  useGetAllModifierQuery,
  useDeleteModifierMutation,
  useGetTaxQuery,
  useGetCoursingQuery,
  useGetPrinterQuery,
  useGetProductTagQuery,
  useGetProductBrandQuery,
  useGetProductCategoryQuery,
  useGetSubCategoryByCategoryIdQuery,
  useGetSubCategoryByArrayQuery,
  useGetProductSubCategoryQuery,
  useGetAllCategoryNameQuery,
  useGetCtegoryFilterQuery,
  useGetLoopingTableQuery,
  useCreateLoopingTableMutation,
  useGetAllLoopingTableQuery,
  useDeleteLoopingTableMutation,
  useUpdateLoopingTableMutation,
  useInsertOrUpdateDataLoopingTableMutation,
  useGetCustomerQuery,
  useDeleteCustomerMutation,
  useCreateCustomerMutation,
  useGetCustomerTransactionQuery,
  useDeleteCustomerTransactionMutation,
  useCreateCustomerTransactionMutation,
  useDeleteCompanyMutation,
  useGetCompanyQuery,
  useGetAllSearchCompanyQuery,
  useCreateWorkOrderMutation,
  useGetLastWorkOrderIdQuery,
  useGetWorkOrderQuery,
  useUpdateInvoiceStatusQuery,
  useGetAllInvoiceQuery,
  useGetAllWorkOrderHistoryQuery,
  useCreateDriverMutation,
  useGetDriverQuery,
  useDeleteDriverMutation,
  useGetPriceHistoryQuery,
  useCreateCustomerProjectMutation,
  useDeleteCustomerProjectMutation,
  useGetCustomerProjectQuery,
  useGetWorkOrderByCustomerProjectQuery,
  useUpdateWorkOrderByCustomerProjectMutation,
  useGetSelectorWorkOrderByCustomerProjectQuery,
  useRemovedCustomerProjectMMutation,
  useCreateWorkOrderWithProjectMutation,
  useCreateEmailMutation,
  useCreateTextMutation,
  useCreateNoteMutation,
  useCreateNoteEmailMutation,
  useCreateEmailTemplateMutation,
  useCreateNoteTemplateMutation,
  useGetEmailTemplateQuery,
  useGetNoteTemplateQuery,
  useGetActivityHistoryQuery,
  useGetProjectActivityHistoryQuery,
} = attoDeskApi;
