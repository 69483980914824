import { useStyles } from "../../../theme/appStyles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  MenuItem,

  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import {
  useCreateCategoryMutation,
  useGetAllMainCategoryQuery,
  useGetCoursingQuery,
  useGetDepartmentQuery,
  useGetLoopingTableQuery,
  useGetPrinterQuery,
  useGetSubCategoryByArrayQuery,
  useGetTaxQuery,
} from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import {
  ICategory,
  ICategoryNew,
  ICoursing,
  IDepartment,
  ILoopingTable,
  IPrinter,
  ITaxes,
} from "../../../Api/Interface/api.interface";
import {
  ItemServiceChargeType,
  RooleType,
  SizeOfLevelType,
} from "../../../Core/Enum/enum";
import Switch, { SwitchProps } from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import CustomSwitch from "../../../Components/Switch/CustomSwitch";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { HexColorPicker } from "react-colorful";
import AppsIcon from "@mui/icons-material/Apps";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled, useTheme } from "@mui/material/styles";
import { uploadImage } from "../../../Components/UploadFile/upload";
import NewCoursing from "../../Coursing/Edit/index";
import NewTax from "../../Tax/Edit/index";
import NewDepartement from "../../Department/Edit/index";
import NewPopUpPrinter from "../../Printer/Edit/index";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 30, // Increased thumb size
    height: 30, // Increased thumb size
  },
  "& .MuiSwitch-track": {
    borderRadius: 34 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#757575" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`, // Using SVG path for check
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`, // Using SVG path for minus
      right: 12,
    },
  },
}));

const isPrinterArray = (data: any): data is IPrinter[] => {
  return (
    Array.isArray(data) &&
    data.every(
      (item) =>
        "id" in item && "printerName" in item && "printerDescription" in item
    )
  );
};

// Image imports
const img1 = require("../../../Images/img1.jpg");
const img2 = require("../../../Images/img2.jpg");
const img3 = require("../../../Images/img3.jpg");

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [newCategory, { isLoading }] = useCreateCategoryMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const [openPrinter, setOpenPrinter] = useState(false);
  const { data: categoryData } = useGetAllMainCategoryQuery({
    departmentId: "",
    coursingId: "",
    roleId: null,
    taxeId: "",
    createdDateStart: null,
    createdDateEnd: null,
    searchText: "",
    numberOfLength: 0,
    isActive: true,
    categoryIsLooping: null,
    createdDate: null,
  });

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<
    number | null
  >(null);

  const [selectedSubToSubCategoryId, setSelectedSubToSubCategoryId] = useState<
    number | null
  >(null);

  const { data: subCategoryData, refetch: refetchSubCategories } =
    useGetSubCategoryByArrayQuery({
      parent_ids: selectedCategoryId ? [Number(selectedCategoryId)] : [],
    });

  const { data: subCategory2Data, refetch: refetchS2bCategories } =
    useGetSubCategoryByArrayQuery({
      parent_ids: selectedSubCategoryId
        ? [Number(selectedCategoryId), Number(selectedSubCategoryId)]
        : [],
    });
  const { data: departmentData, isLoading: departmentLoading } =
    useGetDepartmentQuery({
      searchText: "",
    });
  const { data: coursingData, isLoading: coursingLoading } =
    useGetCoursingQuery({
      searchText: "",
    });
  const { data: taxData, isLoading: taxLoading } = useGetTaxQuery({
    searchText: "",
  });
  const { data: printerData, isLoading: PrinterLoading } = useGetPrinterQuery({
    searchText: "",
  });
  const { data: loopingTabeData, isLoading: loopingTableLoading } =
    useGetLoopingTableQuery({
      searchText: "",
    });
  const [openDepartment, setOpenDepartment] = useState(false);
  const [openCoursing, setOpenCoursing] = useState(false);
  const [openTax, setOpenTax] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>("#FFFFFF");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  const [openGallery, setOpenGallery] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  );
  const [isImageUploading, setIsImageUploading] = useState(false);

  const categoryList = useMemo(() => {
    return (categoryData?.data ?? []) as ICategory[];
  }, [categoryData?.data]);

  const subCategoryList = useMemo(() => {
    return subCategoryData?.data as ICategory[];
  }, [subCategoryData?.data]);

  const subToSubCategoryList = useMemo(() => {
    return subCategory2Data?.data as ICategory[];
  }, [subCategory2Data?.data]);

  const departmentList = useMemo(() => {
    return departmentData?.data as IDepartment[];
  }, [departmentData?.data]);

  const coursingList = useMemo(() => {
    return coursingData?.data as ICoursing[];
  }, [coursingData?.data]);

  const taxList = useMemo(() => {
    return taxData?.data as ITaxes[];
  }, [taxData?.data]);

  const loopingTableList = useMemo(() => {
    return loopingTabeData?.data as ILoopingTable[];
  }, [loopingTabeData?.data]);

  const printerList: IPrinter[] = useMemo(() => {
    if (!printerData || !isPrinterArray(printerData.data)) {
      return [];
    }
    return printerData.data;
  }, [printerData]);

  const formik = useFormik<ICategoryNew>({
    initialValues: {
      categoryName: "",
      departmentId: "",
      roleId: [],
      coursingId: "",
      servingSize: [],
      hidePos: false,
      hideOnlineOrder: false,
      hideKiosk: false,
      Conversational: false,
      itemServiceChargeId: "",
      itemServiceChargePrice: "",
      ageRestriction: false,
      excludeCheckTax: false,
      categoryPrinterIds: [],
      taxeId: "",
      categoryButtonColor: "",
      categoryIsLooping: false,
      categoryLoopingConstant: "",
      categoryImg: null,
      isActive: true,
      parent_ids: null,
      mainCatId: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        let categoryImg: string | undefined;

        if (selectedImage) {
          setIsImageUploading(true);
          formData.append("categoryImg", selectedImage);
          categoryImg = (await uploadImage(selectedImage)) || "";
          setIsImageUploading(false);
        }

        const parent_ids = selectedSubToSubCategoryId
          ? [
              Number(selectedCategoryId),
              Number(selectedSubCategoryId),
              Number(selectedSubToSubCategoryId),
            ]
          : selectedSubCategoryId
          ? [Number(selectedCategoryId), Number(selectedSubCategoryId)]
          : selectedCategoryId
          ? [Number(selectedCategoryId)]
          : null;

        const temData = {
          categoryName: values.categoryName,
          departmentId: values.departmentId,
          roleId: values.roleId,
          coursingId: values.coursingId,
          servingSize: values.servingSize,
          hidePos: values.hidePos,
          hideOnlineOrder: values.hideOnlineOrder,
          hideKiosk: values.hideKiosk,
          Conversational: values.Conversational,
          itemServiceChargeId: values.itemServiceChargeId,
          itemServiceChargePrice: values.itemServiceChargePrice,
          ageRestriction: values.ageRestriction,
          excludeCheckTax: values.excludeCheckTax,
          categoryPrinterIds: values.categoryPrinterIds,
          taxeId: values.taxeId,
          categoryButtonColor: values.categoryButtonColor,
          categoryIsLooping: values.categoryIsLooping,
          categoryLoopingConstant: values.categoryLoopingConstant,
          isActive: values.isActive,
          categoryImg,
          ...(parent_ids && { parent_ids }),
        };

        const addCategoryResponse = await newCategory(temData).unwrap();
        if (!addCategoryResponse.status) {
          showErrorMessage(addCategoryResponse.message);
        } else {
          showMessage(addCategoryResponse.message);
          resetForm();
          setSelectedImage(undefined);
          setOpenGallery(false);
          if (selectedCategoryId) {
            refetchSubCategories();
          }
          setSelectedCategoryId(null);
          setSelectedSubCategoryId(null);
          setSelectedSubToSubCategoryId(null);
          setSelectedImage(undefined);
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return formik.values.categoryName === "" ||
      formik.values.categoryName === undefined
      ? false
      : true;
  }, [formik]);

  const handleMainCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const categoryId = event.target.value as number;
    setSelectedCategoryId(categoryId);
    setSelectedSubCategoryId(null);
    setSelectedSubToSubCategoryId(null);
    formik.setFieldValue("mainCatId", categoryId);

    const selectedCategory = categoryList.find(
      (category) => category.id == categoryId
    );

    // if (selectedCategory) {
    //   // Update Formik values for categoryIsLooping and categoryLoopingConstant
    //   formik.setFieldValue(
    //     "categoryIsLooping",
    //     selectedCategory.categoryIsLooping
    //   );
    //   formik.setFieldValue(
    //     "categoryLoopingConstant",
    //     selectedCategory.categoryLoopingConstant
    //   );
    // }
  };

  const handleSubCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const subCategoryId = event.target.value as number;
    setSelectedSubCategoryId(subCategoryId);
    setSelectedSubToSubCategoryId(null);
  };

  const handleSubToSubCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedSubToSubCategoryId = event.target.value as number;
    setSelectedSubToSubCategoryId(selectedSubToSubCategoryId);
  };

  const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;
  
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          let width = img.width;
          let height = img.height;
  
          // Calculate the new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Canvas is empty"));
            }
          }, file.type);
        };
  
        img.onerror = (error) => reject(error);
      };
    });
  };
  
  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
  
    if (files && files.length > 0) {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
      const maxSize = 2048 * 1024;
  
      if (!validTypes.includes(file.type)) {
        showErrorMessage("The file must be an image (jpeg, png, jpg, gif).");
        setSelectedImage(undefined);
      } else if (file.size > maxSize) {
        showErrorMessage("The file must be smaller than 2 MB.");
        setSelectedImage(undefined);
      } else {
        try {
          const resizedImage = await resizeImage(file, 500, 500);
          setSelectedImage(resizedImage);
        } catch (error) {
          showErrorMessage("Failed to resize the image.");
          setSelectedImage(undefined);
        }
      }
    } else {
      setSelectedImage(undefined);
    }
  };
  
  const handleGallerySelection = (selectedImage: string) => {
    fetch(selectedImage)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "image.jpg", { type: blob.type });
        setSelectedImage(file);
      });
    setOpenGallery(false);
  };

  const openGalleryDialog = () => {
    setOpenGallery(true);
  };

  const closeGalleryDialog = () => {
    setOpenGallery(false);
  };

  const galleryImages = [img2, img3, img2, img3, img2, img3];

  const handlePrinterToggle = (printerId: number) => {
    const { categoryPrinterIds } = formik.values;
    const updatedPrinterIds = categoryPrinterIds.includes(printerId)
      ? categoryPrinterIds.filter((id) => id !== printerId)
      : [...categoryPrinterIds, printerId];
    formik.setFieldValue("categoryPrinterIds", updatedPrinterIds);
  };

  const handleColorChange = (newColor: string) => {
    setSelectedColor(newColor);
    formik.setFieldValue("categoryButtonColor", newColor);
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (isLoading || departmentLoading || coursingLoading || taxLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const cancelFunction = () => {
    formik.resetForm();
    setSelectedImage(undefined);
    setOpenGallery(false);
    if (selectedCategoryId) {
      refetchSubCategories();
    }
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
    setSelectedSubToSubCategoryId(null);
  };

  const gridHeight = isMobile ? "30px" : "400px";

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.stepperCard}>
            <Grid
              container
              justifyContent={"space-between"}
              spacing={5}
              sx={{ borderBottom: 2, padding: 2 }}
              className={classes.titleGrid}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pt={2}
                alignContent={"center"}
              >
                <Typography className={classes.cardTitle}>
                  Create New Category
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                        Add Category Image
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} p={"24px"}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.imgBox}>
                        {selectedImage && (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Uploaded"
                            className={classes.SelectImageBox}
                          />
                        )}
                        <div>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="icon-button-file-1"
                            type="file"
                            onChange={handleImageChange}
                          />
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <label htmlFor="icon-button-file-1">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <CloudUploadIcon sx={{ fontSize: 45 }} />
                              </IconButton>
                            </label>
                            <IconButton
                              color="primary"
                              aria-label="select from gallery"
                              component="span"
                              onClick={openGalleryDialog}
                            >
                              <AppsIcon sx={{ fontSize: 45 }} />
                            </IconButton>
                            <div>PNG, JPG and JPEG are allowed</div>
                          </div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                        Create Child Category
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} p={"24px"}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Main Category
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          display="flex"
                          alignItems="center"
                        >
                          <TextField
                            select
                            fullWidth
                            size="small"
                            className={classes.textField}
                            value={selectedCategoryId || ""}
                            onChange={handleMainCategoryChange}
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem
                              value=""
                              disabled
                              style={{ color: "gray" }}
                            >
                              Select an option
                            </MenuItem>
                            {categoryList &&
                              categoryList.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.categoryName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>

                    {selectedCategoryId && subCategoryList.length > 0 && (
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Sub Category
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              select
                              fullWidth
                              size="small"
                              className={classes.textField}
                              value={
                                selectedSubCategoryId === null
                                  ? ""
                                  : selectedSubCategoryId
                              }
                              onChange={handleSubCategoryChange}
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem
                                value=""
                                disabled
                                style={{ color: "gray" }}
                              >
                                Select an option
                              </MenuItem>
                              {subCategoryList.map((subcategory) => (
                                <MenuItem
                                  key={subcategory.id}
                                  value={subcategory.id}
                                >
                                  {subcategory.categoryName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {selectedCategoryId &&
                      selectedSubCategoryId &&
                      subToSubCategoryList.length > 0 && (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Sub-to-Sub Category
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              display="flex"
                              alignItems="center"
                            >
                              <TextField
                                select
                                fullWidth
                                size="small"
                                className={classes.textField}
                                value={
                                  selectedSubToSubCategoryId === null
                                    ? ""
                                    : selectedSubToSubCategoryId
                                }
                                onChange={handleSubToSubCategoryChange}
                                InputLabelProps={{ shrink: true }}
                              >
                                <MenuItem
                                  value=""
                                  disabled
                                  style={{ color: "gray" }}
                                >
                                  Select an option
                                </MenuItem>
                                {subToSubCategoryList.map(
                                  (subToSubcategory) => (
                                    <MenuItem
                                      key={subToSubcategory.id}
                                      value={subToSubcategory.id}
                                    >
                                      {subToSubcategory.categoryName}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                        Category Information
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} p={"24px"}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Category Name
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Category Name"
                            size="small"
                            {...formik.getFieldProps("categoryName")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                              inputProps: {
                                style: {
                                  fontSize: 14,
                                },
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Department
                          </Typography>
                        </Grid>
                        <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                            {...formik.getFieldProps("departmentId")}
                          >
                            <MenuItem
                              value=""
                              disabled
                              style={{ color: "gray" }}
                            >
                              Select an option
                            </MenuItem>
                            {departmentList &&
                              departmentList.map((department) => (
                                <MenuItem
                                  key={department.id}
                                  value={department.id}
                                >
                                  {department.departmentName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          display="flex"
                          sx={{
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            textAlign: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            className={classes.editButton}
                            onClick={() => {
                              setOpenDepartment(true);
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Roles</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            SelectProps={{
                              multiple: true,
                              native: false,
                            }}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                            {...formik.getFieldProps("roleId")}
                          >
                            
                              <MenuItem value="" disabled>
                                Select some option
                              </MenuItem>
                           
                            {Object.entries(RooleType).map(
                              ([key, value], index) => (
                                <MenuItem key={index} value={value}>
                                  {key}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Coursing</Typography>
                        </Grid>
                        <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                            {...formik.getFieldProps("coursingId")}
                          >
                            <MenuItem
                              value=""
                              disabled
                              style={{ color: "gray" }}
                            >
                              Select an option
                            </MenuItem>
                            {coursingList &&
                              coursingList.map((coursing) => (
                                <MenuItem key={coursing.id} value={coursing.id}>
                                  {coursing.coursingName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          display="flex"
                          sx={{
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            textAlign: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            className={classes.editButton}
                            onClick={() => {
                              setOpenCoursing(true);
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Taxes</Typography>
                        </Grid>
                        <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                            {...formik.getFieldProps("taxeId")}
                          >
                            <MenuItem
                              value=""
                              disabled
                              style={{ color: "gray" }}
                            >
                              Select an option
                            </MenuItem>
                            {taxList &&
                              taxList.map((tax) => (
                                <MenuItem key={tax.id} value={tax.id}>
                                  {tax.taxName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          display="flex"
                          sx={{
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            textAlign: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            className={classes.editButton}
                            onClick={() => {
                              setOpenTax(true);
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Serving Size Levels
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            SelectProps={{
                              multiple: true,
                              native: false,
                            }}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                            {...formik.getFieldProps("servingSize")}
                          >
                           
                              <MenuItem value="" disabled>
                                Select some option
                              </MenuItem>
                          
                            {Object.entries(SizeOfLevelType).map(
                              ([key, value], index) => (
                                <MenuItem key={index} value={value}>
                                  {key}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Item Service Charge
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            SelectProps={{
                              native: false,
                            }}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                            {...formik.getFieldProps("itemServiceChargeId")}
                          >
                            
                              <MenuItem value="" disabled>
                                Select some option
                              </MenuItem>
                           
                            {Object.entries(ItemServiceChargeType).map(
                              ([key, value], index) => (
                                <MenuItem key={index} value={value}>
                                  {key}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>

                    {formik.values.itemServiceChargeId == "1" && (
                      <>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">Price</Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter Item Service Price"
                                size="small"
                                {...formik.getFieldProps(
                                  "itemServiceChargePrice"
                                )}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Printer</Typography>
                        </Grid>
                        <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                          <Box className={classes.printerBox}>
                            <Grid container spacing={1}>
                              {printerList.map((printer) => (
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  key={printer.id}
                                >
                                  <Typography variant="subtitle1">
                                    {printer.printerName}
                                  </Typography>
                                  <IOSSwitch
                                    color="primary"
                                    sx={{ mr: 2 }}
                                    checked={formik.values.categoryPrinterIds.includes(
                                      printer.id
                                    )}
                                    onChange={() =>
                                      handlePrinterToggle(printer.id)
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          display="flex"
                          sx={{
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            textAlign: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            className={classes.editButton}
                            onClick={() => {
                              setOpenPrinter(true);
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Button Color
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Box className={classes.colorBox}>
                            <Grid container>
                              <Grid item lg={6} md={9} sm={12} xs={12}>
                                <Box
                                  sx={{
                                    marginLeft: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: 50,
                                      height: 50,
                                      backgroundColor: selectedColor,
                                      marginLeft: 1,
                                      border: "1px solid #d3d3d3",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                lg={6}
                                md={9}
                                sm={12}
                                xs={12}
                                alignItems={"flex-end"}
                                textAlign={"end"}
                              >
                                <IconButton
                                  color="primary"
                                  component="span"
                                  onClick={toggleColorPicker}
                                >
                                  <ColorLensIcon sx={{ fontSize: 45 }} />
                                </IconButton>
                                {showColorPicker && (
                                  <HexColorPicker
                                    color={selectedColor}
                                    onChange={handleColorChange}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Hide in POS
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CustomSwitch
                            color="primary"
                            sx={{ mr: 2 }}
                            formik={formik}
                            name="hidePos"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Hide in Online Order
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CustomSwitch
                            color="primary"
                            sx={{ mr: 2 }}
                            formik={formik}
                            name="hideOnlineOrder"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            {" "}
                            Hide in Kiosk
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CustomSwitch
                            color="primary"
                            sx={{ mr: 2 }}
                            formik={formik}
                            name="hideKiosk"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Conversational
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CustomSwitch
                            color="primary"
                            sx={{ mr: 2 }}
                            formik={formik}
                            name="Conversational"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Age Restriction
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CustomSwitch
                            color="primary"
                            sx={{ mr: 2 }}
                            formik={formik}
                            name="ageRestriction"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Exclude Check Tax
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CustomSwitch
                            color="primary"
                            sx={{ mr: 2 }}
                            formik={formik}
                            name="excludeCheckTax"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Active</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CustomSwitch
                            color="primary"
                            sx={{ mr: 2 }}
                            formik={formik}
                            name="isActive"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                        Create Looping Category
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} p={"24px"}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Looping</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <IOSSwitch
                            color="primary"
                            sx={{ mr: 2 }}
                            {...formik.getFieldProps("categoryIsLooping")}
                            checked={formik.values.categoryIsLooping}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {formik.values.categoryIsLooping == true && (
                      <>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Looping Category
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                select
                                size="small"
                                className={classes.textField}
                                defaultValue=""
                                InputLabelProps={{ shrink: true }}
                                {...formik.getFieldProps(
                                  "categoryLoopingConstant"
                                )}
                              >
                                <MenuItem
                                  value=""
                                  disabled
                                  style={{ color: "gray" }}
                                >
                                  Select an option
                                </MenuItem>
                                {loopingTableList &&
                                  loopingTableList.map((department) => (
                                    <MenuItem
                                      key={department.id}
                                      value={department.id}
                                    >
                                      {department.shortTableName}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid container spacing={3} p={"24px"}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        className={classes.addButton}
                        onClick={cancelFunction}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>

                      <Button
                        variant="contained"
                        startIcon={<SaveAltIcon />}
                        className={classes.addButton}
                        onClick={() => formik.handleSubmit()}
                        disabled={!formValid || isLoading}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Dialog open={openGallery} onClose={closeGalleryDialog}>
              <Grid className={classes.Dialog}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  spacing={5}
                  sx={{ borderBottom: 2, padding: 2 }}
                  className={classes.titleGrid}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    pt={2}
                    alignContent={"center"}
                  >
                    <Typography className={classes.cardTitle}>
                      Select an Image from Gallery
                    </Typography>
                  </Grid>
                </Grid>
                <DialogContent>
                  <ImageList
                    sx={{ width: 500, height: 450 }}
                    cols={3}
                    rowHeight={164}
                  >
                    {galleryImages.map((imagePath) => (
                      <ImageListItem key={imagePath}>
                        <img
                          src={`${imagePath}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${imagePath}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt="Gallery"
                          loading="lazy"
                          onClick={() => handleGallerySelection(imagePath)}
                          style={{ cursor: "pointer" }}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </DialogContent>
              </Grid>
            </Dialog>
            {openDepartment && (
              <NewDepartement
                handleCloseDialog={() => setOpenDepartment(false)}
                openModel={openDepartment}
              />
            )}

            {openCoursing && (
              <NewCoursing
                handleCloseDialog={() => setOpenCoursing(false)}
                openModel={openCoursing}
              />
            )}

            {openTax && (
              <NewTax
                handleCloseDialog={() => setOpenTax(false)}
                openModel={openTax}
              />
            )}

            {openPrinter && (
              <NewPopUpPrinter
                handleCloseDialog={() => setOpenPrinter(false)}
                openModel={openPrinter}
              />
            )}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
