import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useGetAllInvoiceQuery } from "../../../Api/attoDeskApi";
import { useStyles } from "../../../theme/appStyles";
import { useNavigate } from "react-router";
import {
  ICompanyDetails,
  ICustomer,
  ICustomerProject,
} from "../../../Api/Interface/api.interface";
import dayjs, { Dayjs } from "dayjs";
import {
  ProductType,
  TransferType,
  WorkOrderStatus,
} from "../../../Core/Enum/enum";
import { useParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactToPrint from "react-to-print";
import LocalPrintshopSharpIcon from "@mui/icons-material/LocalPrintshopSharp";

const getStatusLabel = (statusCode: number): string => {
  switch (statusCode) {
    case WorkOrderStatus.Paid:
      return "Paid";
    case WorkOrderStatus.Cancel:
      return "Canceled";
    case WorkOrderStatus.Pending:
      return "Pending";
    default:
      return "Unknown Status";
  }
};

const getTransferType = (statusCode: string): string => {
  switch (statusCode) {
    case TransferType.OneWayTransfer:
      return "One Way Transfer";
    case TransferType.RoundTrip:
      return "Round Trip";
    case TransferType.MultiDestination:
      return "Multi Destination";
    default:
      return "Unknown Status";
  }
};

interface IProduct {
  productName: string;
  productImg: String | null;
  numberOfPassengers: number | null;
  transferType: string;
  transferTypeFrom: string;
  transferTypeTo: string;
  transferTypeDescription: string;
  departureDateTime: Dayjs | null;
  returnDateTime: Dayjs | null;
  departureAddress: string;
  destinationAddress: string;
  multiDestination: string;
  quantity: number | null;
  price: number | null;
  tax: number | null;
  total: number;
  taxId: number | null;
  unitPriceWithDiscount: number | null;
  unitTotalWithDiscount: number;
  productType: string;
  productLongDescription: string;
  productShortDescription: string;
}

interface IDiscount {
  value: number | null;
  description: string;
}

interface IQuoteWorkOrder {
  id: number;
  quoteWorkOrderCompanyId: number;
  quoteWorkOrderCustomerId: string;
  quoteWorkOrderCustomerProjectId: string;
  quoteWorkOrderNo: string;
  quoteWorkOrderIssueDate: Dayjs;
  quoteWorkOrderDueDate: Dayjs;
  quoteWorkOrderAmount: number | null;
  quoteWorkOrderStatus: number;
  quoteWorkOrderSale: IProduct[];
  quoteWorkOrderDiscount: string;
  quoteWorkOrderSubTotal: string;
  quoteWorkOrderSaleGrandTotal: string;
  quoteWorkOrderSaleDiscountDescription: IDiscount[];
  customer: ICustomer;
  company: ICompanyDetails;
  project: ICustomerProject;
  quoteWorkOrderCustomerRemark: string;
  quoteWorkOrderCustomerMemo: string;
  quoteWorkOrderSaleDiscount: string;
  quoteWorkOrderSaleSubTotal: string;
  created_by: number;
  created_at: string;
  mainStatus: number;
  invoiceId: number;
  InvoiceNo: string;
}

const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { id: InvoiceId } = useParams<{ id: string }>();
  useEffect(() => {}, [InvoiceId]);
  const componentRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const {
    data: workOrderData,
    isLoading: workIsLoading,
    isError: workOrderError,
    refetch: workOrderRefetch,
  } = useGetAllInvoiceQuery({
    searchText: "",
    id: String(InvoiceId),
    quoteWorkOrderCustomerId: "",
    quoteWorkOrderCustomerProjectId: "",
  });

  const WorkOrderDataList = useMemo(() => {
    if (
      workOrderData &&
      Array.isArray(workOrderData.data) &&
      workOrderData.data.length > 0
    ) {
      return workOrderData.data[0] as IQuoteWorkOrder;
    }
    return null;
  }, [workOrderData]);

  const formatAddressReadOnly = (
    streetNumber: string,
    streetName: string,
    postalCode: string,
    city: string,
    province: string
  ) => {
    return `${streetNumber} ${streetName},\n${postalCode} ${city},\n${province}`;
  };
  const initialCompanyAddress = formatAddressReadOnly(
    WorkOrderDataList?.company.companyStreetNumber || "",
    WorkOrderDataList?.company.companyStreetName || "",
    WorkOrderDataList?.company.companyPostalCode || "",
    WorkOrderDataList?.company.companyCity || "",
    WorkOrderDataList?.company.companyProvince || ""
  );

  const coursings = Array.isArray(WorkOrderDataList?.quoteWorkOrderSale)
    ? WorkOrderDataList!.quoteWorkOrderSale
    : [];

  const discountList = Array.isArray(
    WorkOrderDataList?.quoteWorkOrderSaleDiscountDescription
  )
    ? WorkOrderDataList!.quoteWorkOrderSaleDiscountDescription
    : [];

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card className={classes.quotesCardInvoice}>
            <Grid container spacing={1} padding={"2px"}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-end"
                  sx={{ display: "flex" }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      endIcon={<ArrowForwardIosIcon />}
                      className={classes.addButton}
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div ref={componentRef}>
            <Card className={classes.quotesCardInvoice}>
              <Grid container spacing={1} padding={"2px"}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.titleGrid}
                >
                  <Grid
                    container
                    className={classes.titleGridInvoice}
                    justifyContent={"space-between"}
                    padding={3}
                  >
                    <Grid item lg={12} md={12} sm={12} xs={12} pb={2}>
                      <Grid container>
                        <Grid item lg={2} md={2} sm={3} xs={3}>
                          <img
                            src={WorkOrderDataList?.company.CompanyLogo}
                            alt="Company Logo"
                            className={classes.invoiceImg}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid container>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                              <Typography variant="subtitle1">
                                <strong>Invoice :</strong>
                              </Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sm={9} xs={9}>
                              <Typography variant="subtitle1">
                                <strong>
                                  {(WorkOrderDataList?.InvoiceNo || " ") + " "}
                                </strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid container>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                              <Typography variant="subtitle1">
                                <strong>Status :</strong>
                              </Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sm={9} xs={9}>
                              <Typography variant="subtitle1">
                                {getStatusLabel(
                                  WorkOrderDataList?.quoteWorkOrderStatus || 0
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4}></Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid container>
                            <Grid item lg={3} md={3} sm={4} xs={3}>
                              <Typography variant="subtitle1">
                                <strong>Issue Date :</strong>
                              </Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sm={8} xs={9}>
                              <Typography variant="subtitle1">
                                {WorkOrderDataList?.quoteWorkOrderIssueDate
                                  ? dayjs(
                                      WorkOrderDataList.quoteWorkOrderIssueDate
                                    ).format("D MMMM YYYY")
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid container>
                            <Grid item lg={3} md={3} sm={4} xs={3}>
                              <Typography variant="subtitle1">
                                <strong>Due Date :</strong>
                              </Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sm={8} xs={9}>
                              <Typography variant="subtitle1">
                                {WorkOrderDataList?.quoteWorkOrderDueDate
                                  ? dayjs(
                                      WorkOrderDataList.quoteWorkOrderDueDate
                                    ).format("D MMMM YYYY")
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.titleGrid}
                >
                  <Grid container padding={3}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            <strong>Issue From :</strong>
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            <strong>
                              {(WorkOrderDataList?.company.companyName || " ") +
                                " "}
                            </strong>
                          </Typography>
                          <Typography variant="subtitle1">
                            {initialCompanyAddress}
                          </Typography>
                          <Typography variant="subtitle1">
                            Phone :{" "}
                            {WorkOrderDataList?.company.companyMobileNumber ||
                              " "}
                          </Typography>
                          <Typography variant="subtitle1">
                            Email :{" "}
                            {WorkOrderDataList?.company.companyEmail || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4}></Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            <strong>Issue For :</strong>
                          </Typography>
                        </Grid>
                        {WorkOrderDataList?.quoteWorkOrderCustomerProjectId ? (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              <strong>
                                {WorkOrderDataList?.project.projectName || ""}
                              </strong>
                            </Typography>
                            <Typography variant="subtitle1">
                              {WorkOrderDataList?.project.projectAddress || " "}
                            </Typography>
                            <Typography variant="subtitle1">
                              Phone :{" "}
                              {WorkOrderDataList?.project.projectPhoneNumber ||
                                " "}
                            </Typography>
                            <Typography variant="subtitle1">
                              Email : {WorkOrderDataList?.project.projectEmail}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              <strong>
                                {(WorkOrderDataList?.customer
                                  .customerFirstName || "") +
                                  " " +
                                  (WorkOrderDataList?.customer
                                    .customerLastName || "")}
                              </strong>
                            </Typography>
                            <Typography variant="subtitle1">
                              {WorkOrderDataList?.customer.customerAddress ||
                                " "}
                            </Typography>
                            <Typography variant="subtitle1">
                              Phone :{" "}
                              {WorkOrderDataList?.customer.customerMobileNum ||
                                " "}
                            </Typography>
                            <Typography variant="subtitle1">
                              Email :{" "}
                              {WorkOrderDataList?.customer.customerEmail}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.titleGrid}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead className={classes.tableHeaderRow}>
                            <TableRow>
                              {/* <TableCell className={classes.tablheader}>
                              <strong>#</strong>
                            </TableCell> */}
                              <TableCell className={classes.tablheader}>
                                <strong>Product</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Quantity</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Price</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Tax</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Price</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Amount</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {workOrderError || coursings.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={8} align="center">
                                  {workOrderError
                                    ? "Error fetching data"
                                    : "No data available"}
                                </TableCell>
                              </TableRow>
                            ) : (
                              coursings
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((product, index) => (
                                  <TableRow
                                    key={index}
                                    className={classes.tableRowHover}
                                  >
                                    {/* <TableCell className={classes.tablCell}>
                                    {page * rowsPerPage + index + 1}
                                  </TableCell> */}
                                    <TableCell className={classes.tablCell}>
                                      {!isMobile ? (
                                        <Grid container spacing={1}>
                                          <Grid
                                            item
                                            sm={8}
                                            xs={12}
                                            textAlign={"left"}
                                            alignContent={"center"}
                                          >
                                            <Typography>
                                              <strong>
                                                {product.productName}
                                              </strong>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <Grid container spacing={1}>
                                          <Grid
                                            item
                                            textAlign={"left"}
                                            alignContent={"center"}
                                          >
                                            <Typography>
                                              <strong>
                                                {product.productName}
                                              </strong>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      className={classes.tablCell}
                                      align="right"
                                    >
                                      {product.quantity}
                                    </TableCell>
                                    <TableCell
                                      className={classes.tablCell}
                                      align="right"
                                    >
                                      {(product.price ?? 0).toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      className={classes.tablCell}
                                      align="right"
                                    >
                                      {product.tax || "0"}%
                                    </TableCell>
                                    <TableCell
                                      className={classes.tablCell}
                                      align="right"
                                    >
                                      {(
                                        product.unitPriceWithDiscount ?? 0
                                      ).toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      className={classes.tablCell}
                                      align="right"
                                    >
                                      {product.unitTotalWithDiscount != null
                                        ? product.unitTotalWithDiscount.toFixed(
                                            2
                                          )
                                        : "0.00"}
                                    </TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                        {/* <Grid
                    spacing={5}
                    p={"18px"}
                    py={4}
                    justifyContent={"end"}
                    container
                    textAlign={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid
                      sx={{
                        textAlign: "end",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Pagination
                        count={coursings.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                      />
                    </Grid>
                  </Grid> */}
                      </TableContainer>
                    </Grid>

                    {discountList.map((discount, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                        <Grid
                          container
                          spacing={2}
                          display={"end"}
                          justifyContent={"flex-end"}
                        >
                          {/* First column: Description */}
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={4}
                            xs={8}
                            textAlign={"end"}
                            // className={
                            //   index === discountList.length - 1
                            //     ? classes.tablCell
                            //     : ""
                            // }
                          >
                            <Typography>
                              {discount.description || "Discount"}
                              {" :"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={2}
                            xs={4}
                            textAlign={"end"}
                            // className={
                            //   index === discountList.length - 1
                            //     ? classes.tablCell
                            //     : ""
                            // }
                          >
                            <Typography>
                              {"$"}
                              {(discount.value !== null
                                ? discount.value
                                : 0
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        spacing={2}
                        display={"end"}
                        justifyContent={"flex-end"}
                      >
                        {/* First column: Description */}
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={4}
                          xs={8}
                          textAlign={"end"}
                          className={classes.tablCell}
                        >
                          <Typography>Total Discount :</Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={2}
                          xs={4}
                          textAlign={"end"}
                          className={classes.tablCell}
                        >
                          <Typography>
                            {"$"}
                            {parseFloat(
                              WorkOrderDataList?.quoteWorkOrderDiscount || "0"
                            ).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        spacing={2}
                        display={"end"}
                        justifyContent={"flex-end"}
                      >
                        {/* First column: Description */}
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={4}
                          xs={8}
                          textAlign={"end"}
                          // className={classes.tablCell}
                        >
                          <Typography className={classes.h4Quoto}>
                            Grand Amount :
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={2}
                          xs={4}
                          textAlign={"end"}
                          // className={classes.tablCell}
                        >
                          <Typography className={classes.h4Quoto}>
                            {"$"}
                            {parseFloat(
                              WorkOrderDataList?.quoteWorkOrderSaleGrandTotal ||
                                "0"
                            ).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card className={classes.quotesCardInvoice}>
            <Grid container spacing={1} padding={"2px"}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-end"
                  sx={{ display: "flex" }}
                >
                  <Grid item>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="contained"
                          startIcon={<LocalPrintshopSharpIcon />}
                          className={classes.addButton}
                        >
                          Invoice
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
