import React, { useMemo, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  useTheme,
  MenuItem,
  CircularProgress,
  Box,
  Card,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  InputAdornment,
} from "@mui/material";
import { FieldArray, FormikProvider, useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useStyles } from "../../../theme/appStyles";
import { useNotifier } from "../../../Core/Notifier";
import { useCreateDriverMutation } from "../../../Api/attoDeskApi";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { uploadImage } from "../../../Components/UploadFile/upload";
import CloseIcon from "@mui/icons-material/Close";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../Components/Validation/validationUtils";
import CustomSwitch from "../../../Components/Switch/CustomSwitch";
import { FaTrash } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import { IDriver } from "../../../Api/Interface/api.interface";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface TimeSlot {
  startTime: string;
  endTime: string;
  hourlySalary: string;
}

interface Availability {
  day: string;
  timeSlots: TimeSlot[];
}

interface IDriverpopup {
  openModel?: boolean;
  handleCloseDialog: (close: boolean) => void;
  data?: IDriver;
}

const Driver = ({
  openModel = false,
  handleCloseDialog,
  data,
}: IDriverpopup) => {
  const classes = useStyles();
  const [open] = React.useState(openModel);
  const theme = useTheme();
  const { showErrorMessage, showMessage } = useNotifier();
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  );
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [newCompany, { isLoading }] = useCreateDriverMutation();
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      id: data?.id,
      driverMobileNum: data?.driverMobileNum || "",
      driverFirstName: data?.driverFirstName || "",
      driverLastName: data?.driverLastName || "",
      driverAltMobileNum: data?.driverAltMobileNum || "",
      driverImg: data?.driverImg || "",
      driverCity: data?.driverCity || "",
      driverAddress: data?.driverAddress || "",
      driverEmail: data?.driverEmail || "",
      driverUserRole: data?.driverUserRole || "",
      driverUserName: data?.driverUserName || "",
      driverPassWord: data?.driverPassWord || "",
      driverDescription: data?.driverDescription || "",
      availability: data?.availability?.map((available) => ({
        day: available.day,
        timeSlots: available.timeSlots?.map((slot) => ({
          startTime: slot.startTime || "",
          endTime: slot.endTime || "",
          hourlySalary: slot.hourlySalary || "",
        })) || [{ startTime: "", endTime: "", hourlySalary: "" }],
      })) || [
        {
          day: "Sunday",
          timeSlots: [{ startTime: "", endTime: "", hourlySalary: "" }],
        },
        {
          day: "Monday",
          timeSlots: [{ startTime: "", endTime: "", hourlySalary: "" }],
        },
        {
          day: "Tuesday",
          timeSlots: [{ startTime: "", endTime: "", hourlySalary: "" }],
        },
        {
          day: "Wednesday",
          timeSlots: [{ startTime: "", endTime: "", hourlySalary: "" }],
        },
        {
          day: "Thursday",
          timeSlots: [{ startTime: "", endTime: "", hourlySalary: "" }],
        },
        {
          day: "Friday",
          timeSlots: [{ startTime: "", endTime: "", hourlySalary: "" }],
        },
        {
          day: "Saturday",
          timeSlots: [{ startTime: "", endTime: "", hourlySalary: "" }],
        },
      ],
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      const emailError = validateEmail(values.driverEmail);
      if (emailError) {
        errors.driverEmail = emailError;
      }

      const mobileNumberError = validatePhoneNumber(values.driverMobileNum);
      if (mobileNumberError) {
        errors.driverMobileNum = mobileNumberError;
      }

      const altMobileNumberError = validatePhoneNumber(
        values.driverAltMobileNum
      );
      if (altMobileNumberError) {
        errors.driverAltMobileNum = altMobileNumberError;
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        let driverImg: string | undefined = data?.driverImg;

        if (selectedImage) {
          setIsImageUploading(true);
          formData.append("driverImg", selectedImage);
          driverImg = (await uploadImage(selectedImage)) || "";
          setIsImageUploading(false);
        }
        const updatedData = {
          id: values.id,
          driverMobileNum: values.driverMobileNum,
          driverAltMobileNum: values.driverAltMobileNum,
          driverFirstName: values.driverFirstName,
          driverLastName: values.driverLastName,
          driverCity: values.driverCity,
          driverAddress: values.driverAddress,
          driverEmail: values.driverEmail,
          driverUserRole: values.driverUserRole,
          driverDescription: values.driverDescription,
          driverImg,
          availability: values.availability,
          driverUserName: values.driverUserName,
          driverPassWord: values.driverPassWord,
        };

        const response = await newCompany(updatedData).unwrap();
        if (!response.status) {
          showErrorMessage(response.message);
        } else {
          showMessage(response.message);
          handleClose();
          setSelectedImage(undefined);
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return formik.values.driverMobileNum === "" ||
      formik.values.driverMobileNum === undefined
      ? false
      : true;
  }, [formik]);

  const resizeImage = (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          // Calculate the new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Canvas is empty"));
            }
          }, file.type);
        };

        img.onerror = (error) => reject(error);
      };
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
      const maxSize = 2048 * 1024;

      if (!validTypes.includes(file.type)) {
        showErrorMessage("The file must be an image (jpeg, png, jpg, gif).");
        setSelectedImage(undefined);
      } else if (file.size > maxSize) {
        showErrorMessage("The file must be smaller than 2 MB.");
        setSelectedImage(undefined);
      } else {
        try {
          const resizedImage = await resizeImage(file, 500, 500);
          setSelectedImage(resizedImage);
        } catch (error) {
          showErrorMessage("Failed to resize the image.");
          setSelectedImage(undefined);
        }
      }
    } else {
      setSelectedImage(undefined);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: "1700px",
        },
      }}
    >
      <Grid className={classes.Dialog}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={5}
                sx={{ borderBottom: 2, padding: 2 }}
                className={classes.titleGrid}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  pt={2}
                  alignContent={"center"}
                >
                  <Typography className={classes.cardTitle}>
                    Edit Team
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.card}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        spacing={5}
                        sx={{ borderBottom: 2, padding: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          pt={2}
                          alignContent={"center"}
                        >
                          <Typography className={classes.cardTitle}>
                          Team Information
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} p={"24px"}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                First Name
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter First Name"
                                size="small"
                                {...formik.getFieldProps("driverFirstName")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Last Name
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter Last Name"
                                size="small"
                                {...formik.getFieldProps("driverLastName")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Mobile Number
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter Mobile Number"
                                size="small"
                                {...formik.getFieldProps("driverMobileNum")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                error={
                                  formik.touched.driverMobileNum &&
                                  Boolean(formik.errors.driverMobileNum)
                                }
                                helperText={
                                  formik.touched.driverMobileNum &&
                                  formik.errors.driverMobileNum
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Alt Mobile Number
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter Alt Mobile Number"
                                size="small"
                                {...formik.getFieldProps("driverAltMobileNum")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                error={
                                  formik.touched.driverAltMobileNum &&
                                  Boolean(formik.errors.driverAltMobileNum)
                                }
                                helperText={
                                  formik.touched.driverAltMobileNum &&
                                  formik.errors.driverAltMobileNum
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid> */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                E-mail
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter E-mail"
                                size="small"
                                type="email"
                                {...formik.getFieldProps("driverEmail")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                error={
                                  formik.touched.driverEmail &&
                                  Boolean(formik.errors.driverEmail)
                                }
                                helperText={
                                  formik.touched.driverEmail &&
                                  formik.errors.driverEmail
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">City</Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter City"
                                size="small"
                                {...formik.getFieldProps("driverCity")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid> */}
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Address</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Address"
                            size="small"
                            // multiline
                            rows={3}
                            {...formik.getFieldProps("driverAddress")}
                            className={classes.discriptionTextField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">User Role</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
      <TextField
        select
        placeholder="Select User Role"
        size="small"
        {...formik.getFieldProps("driverUserRole")}
        className={classes.discriptionTextField}
        InputProps={{
          sx: {
            fontSize: 14,
          },
        }}
        InputLabelProps={{
          sx: {
            fontSize: 14,
          },
        }}
        fullWidth
      >
        {/* Dropdown options */}
        <MenuItem value="super_admin">Super Admin</MenuItem>
        <MenuItem value="manager">Manager</MenuItem>
      </TextField>
    </Grid>
                      </Grid>
                    </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                User Name
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter User Name"
                                size="small"
                                {...formik.getFieldProps("driverUserName")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                error={
                                  formik.touched.driverEmail &&
                                  Boolean(formik.errors.driverEmail)
                                }
                                helperText={
                                  formik.touched.driverEmail &&
                                  formik.errors.driverEmail
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Password
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter Password"
                                size="small"
                                type={showPassword ? "text" : "password"}
                                {...formik.getFieldProps("driverPassWord")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                       
                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Description
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter Description .."
                                size="small"
                                multiline
                                rows={8}
                                {...formik.getFieldProps("driverDescription")}
                                className={classes.discriptionTextField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid> */}
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.card}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        spacing={5}
                        sx={{ borderBottom: 2, padding: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          pt={2}
                          alignContent={"center"}
                        >
                          <Typography className={classes.cardTitle}>
                            Add Image
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} p={"24px"}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Box className={classes.imgBox}>
                            {selectedImage && (
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="Uploaded"
                                className={classes.SelectImageBox}
                              />
                            )}
                            <div>
                              <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="icon-button-file-1"
                                type="file"
                                onChange={handleImageChange}
                              />
                              <div
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <label htmlFor="icon-button-file-1">
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <CloudUploadIcon sx={{ fontSize: 45 }} />
                                  </IconButton>
                                </label>
                              </div>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  {/* {formik.values.availability.map((day, index) => (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Card className={classes.card}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Typography className={classes.cardTitle}>
                              {day.day} Availability
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} p={"24px"}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            key={day.day}
                          >
                            <Grid container alignItems="center">
                              <FieldArray
                                name={`availability.${index}.timeSlots`}
                              >
                                {({ push, remove }) => (
                                  <>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <TableContainer>
                                          <Table>
                                            <TableHead>
                                              <TableRow>
                                                <TableCell
                                                  className={
                                                    classes.tablheaderCreate
                                                  }
                                                >
                                                  Start Time
                                                </TableCell>
                                                <TableCell
                                                  className={
                                                    classes.tablheaderCreate
                                                  }
                                                >
                                                  End Time
                                                </TableCell>
                                                <TableCell
                                                  className={
                                                    classes.tablheaderCreate
                                                  }
                                                >
                                                  Hourly Salary
                                                </TableCell>
                                                <TableCell
                                                  className={
                                                    classes.tablheaderCreate
                                                  }
                                                  sx={{ textAlign: "right" }}
                                                >
                                                  Action
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {formik.values.availability[
                                                index
                                              ].timeSlots.map(
                                                (slot, slotIndex) => (
                                                  <TableRow key={slotIndex}>
                                                    <TableCell>
                                                      <TextField
                                                        placeholder="Start Time"
                                                        size="small"
                                                        type="time"
                                                        {...formik.getFieldProps(
                                                          `availability.${index}.timeSlots.${slotIndex}.startTime`
                                                        )}
                                                        className={
                                                          classes.textField
                                                        }
                                                        InputProps={{
                                                          sx: {
                                                            fontSize: 14,
                                                          },
                                                        }}
                                                        InputLabelProps={{
                                                          sx: {
                                                            fontSize: 14,
                                                          },
                                                        }}
                                                      />
                                                    </TableCell>
                                                    <TableCell>
                                                      <TextField
                                                        placeholder="End Time"
                                                        size="small"
                                                        type="time"
                                                        {...formik.getFieldProps(
                                                          `availability.${index}.timeSlots.${slotIndex}.endTime`
                                                        )}
                                                        className={
                                                          classes.textField
                                                        }
                                                        InputProps={{
                                                          sx: {
                                                            fontSize: 14,
                                                          },
                                                        }}
                                                        InputLabelProps={{
                                                          sx: {
                                                            fontSize: 14,
                                                          },
                                                        }}
                                                      />
                                                    </TableCell>
                                                    <TableCell>
                                                      <TextField
                                                        placeholder="Hourly Salary"
                                                        size="small"
                                                        type="number"
                                                        {...formik.getFieldProps(
                                                          `availability.${index}.timeSlots.${slotIndex}.hourlySalary`
                                                        )}
                                                        className={
                                                          classes.textField
                                                        }
                                                        InputProps={{
                                                          sx: {
                                                            fontSize: 14,
                                                          },
                                                        }}
                                                        InputLabelProps={{
                                                          sx: {
                                                            fontSize: 14,
                                                          },
                                                        }}
                                                      />
                                                    </TableCell>
                                                    <TableCell>
                                                      <Grid
                                                        container
                                                        spacing={1}
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "flex-end",
                                                        }}
                                                      >
                                                        <Grid item>
                                                          <IconButton
                                                            color="error"
                                                            onClick={() =>
                                                              remove(slotIndex)
                                                            }
                                                            disabled={
                                                              formik.values
                                                                .availability[
                                                                index
                                                              ].timeSlots
                                                                .length === 1
                                                            }
                                                          >
                                                            <FaTrash />
                                                          </IconButton>
                                                        </Grid>
                                                      </Grid>
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      display="flex"
                                      sx={{
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                        textAlign: "end",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        className={classes.addButton}
                                        onClick={() =>
                                          push({
                                            startTime: "",
                                            endTime: "",
                                            hourlySalary: "",
                                          })
                                        }
                                      >
                                        Time Slot
                                      </Button>
                                    </Grid>
                                  </>
                                )}
                              </FieldArray>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))} */}

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.card}>
                      <Grid container spacing={3} p={"24px"}>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            variant="outlined"
                            startIcon={<CloseIcon />}
                            className={classes.addButton}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Box m={0.5}></Box>

                          <Button
                            variant="contained"
                            startIcon={<SaveAltIcon />}
                            className={classes.addButton}
                            onClick={() => formik.handleSubmit()}
                            disabled={!formValid}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            </FormikProvider>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Driver;
