import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, TextField, Button, Box, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStyles } from "../../../theme/appStyles";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useNotifier } from "../../../Core/Notifier";
import CircularProgress from "@mui/material/CircularProgress";

const EditExpenseForm = () => {
  const { id } = useParams(); // Get the expense ID from the URL
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [isHST, setIsHST] = useState(false);
  const [total, setTotal] = useState(0);
  const [hstAmount, setHstAmount] = useState(0);
  const { showErrorMessage, showMessage } = useNotifier();
  const [loading, setLoading] = useState(false); 
  const [expense, setExpense] = useState<any>(null); // State to store fetched expense data
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);

  interface Category {
    id: number;
    category_name: string;
    
  }

  const formik = useFormik({
    initialValues: {
      date: expense?.date || new Date().toISOString().split("T")[0],
      description: expense?.description || "",
      amount: expense?.amount || "",
      hst: expense?.hst || false,
      total: expense?.total || "",      
      category: expense?.category || "",
      status: expense?.status || "",
      file: null as File[] | null,   
      beforeImages: null as File[] | null,
      afterImages: null as File[] | null,
    },
    validationSchema: yup.object({    
      date: yup.date().required("Date is required"),
      description: yup.string().required("Description is required"),
      amount: yup
        .number()
        .required("Amount is required")
        .positive("Amount must be positive"),
      category: yup.string().required("Category is required"),
    }),
    onSubmit: async (values) => {
      //console.log("Formik Values on Submit:", values); 
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("date", values.date);
        formData.append("description", values.description);
        formData.append("amount", values.amount);
        formData.append("hst", isHST ? hstAmount.toString() : "0"); 
        formData.append("total", total.toString());
        formData.append("category", values.category);
        formData.append("status", values.status);

        if (values.file && Array.isArray(values.file)) {
          values.file.forEach((file: File) => {
            formData.append("file", file); // Append each file individually
          });
        }

        if (values.beforeImages && Array.isArray(values.beforeImages)) {
          values.beforeImages.forEach((beforeImages: File) => {
            formData.append("beforeImages", beforeImages); // Append each file individually
          });
        }
        
        
        if (values.afterImages && Array.isArray(values.afterImages)) {
          values.afterImages.forEach((afterImages: File) => {
            formData.append("afterImages", afterImages); // Append each file individually
          });
        }
        
       
        const response = await axios.post(`${API_URL}/expense/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        showMessage("Expense updated successfully!");
        navigate(`/expense/list`); // Redirect after update
      } catch (error) {
        showErrorMessage("An error occurred while updating the expense.");
      } finally {
        setLoading(false);
      }
    },
  });

 
  

  useEffect(() => {
    const fetchExpenseAndCategories = async () => {
      setLoading(true);
      try {
        // Fetch categories first
        const categoriesResponse = await axios.get(`${API_URL}/view-expense-category`);
        const fetchedCategories = categoriesResponse.data; // Assuming categories are an array
        setCategories(fetchedCategories);
  
        // Fetch expense details
        const expenseResponse = await axios.get(`${API_URL}/expense/${id}`);
        const fetchedExpense = expenseResponse.data;
  
        // Set expense state
        setExpense(fetchedExpense);
        console.log(fetchedExpense)
        setDescription(fetchedExpense.description);
        setAmount(fetchedExpense.amount);
        setTotal(fetchedExpense.total);
        setIsHST(fetchedExpense.hst > 0);
        setHstAmount(fetchedExpense.hst);
        setCategory(fetchedExpense.category);
  
        // Set formik values with category from fetched expense
        formik.setValues({
          date: fetchedExpense.date,
          description: fetchedExpense.description,
          amount: fetchedExpense.amount,
          hst: fetchedExpense.hst > 0,
          total: fetchedExpense.total,
          category: fetchedExpense.category, // Ensure this matches the `id` from categories
          status: fetchedExpense.status, 
          file: null,
          beforeImages: null,
          afterImages: null,
        });
      } catch (error) {
        showErrorMessage("Failed to fetch expense or categories data.");
      } finally {
        setLoading(false);
      }
    };
  
    if (id) {
      fetchExpenseAndCategories();
    }
  }, [id]);
  

 

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const amount = parseFloat(event.target.value || "0");
    formik.setFieldValue("amount", amount);
  
    const hstAmount = isHST ? amount * 0.13 : 0;
    setHstAmount(hstAmount);
  
    const updatedTotal = amount + hstAmount;
    setTotal(updatedTotal);
    formik.setFieldValue("total", updatedTotal);
  };

  const handleHSTToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsHST(checked);
  
    const amount = parseFloat(formik.values.amount || "0");
    const hstAmount = checked ? amount * 0.13 : 0;
    setHstAmount(hstAmount);
    const updatedTotal = amount + hstAmount;
    setTotal(updatedTotal);
    formik.setFieldValue("total", updatedTotal);
  };

 

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card>
            <Grid container justifyContent="space-between" sx={{ borderBottom: 2, padding: 2 }}>
              <Grid item lg={12} md={12} sm={12} xs={12} pt={2} alignContent={"center"}>
                <Typography className={classes.cardTitle}>Edit Expense</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item lg={9} md={9} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                    {/* Date Field */}
                    <Grid item xs={7}>
                      <Typography variant="subtitle1">Date</Typography>
                      <TextField
                        type="date"
                        size="small"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        name="date"
                        fullWidth
                      />
                    </Grid>

                    {/* Description Field */}
                    <Grid item xs={7}>
                      <Typography variant="subtitle1">Description</Typography>
                      <TextField
                        placeholder="Enter description"
                        size="small"
                        {...formik.getFieldProps("description")}
                        multiline
                        rows={4}
                        fullWidth
                      />
                    </Grid>

                    {/* Amount Field */}
                    <Grid item xs={7}>
                      <Typography variant="subtitle1">Amount</Typography>
                      <TextField
                        placeholder="Enter amount"
                        size="small"
                        type="number"
                        value={formik.values.amount}
                        onChange={(event) => handleAmountChange(event)}
                        fullWidth
                      />
                    </Grid>

                    {/* HST Checkbox */}
                    <Grid item xs={7}>
                      <Typography variant="subtitle1">Include HST(13%)?</Typography>
                      <Grid container alignItems="center">
                        <Grid item>
                          <FormControlLabel
                            control={<Checkbox checked={isHST} onChange={handleHSTToggle} color="primary" />}
                            label="Yes"
                          />
                        </Grid>
                        {isHST && (
                          <Grid item sx={{ marginLeft: 2 }}>
                            <Typography variant="body2">HST = ${hstAmount}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {/* Total Field */}
                    <Grid item xs={7}>
                      <Typography variant="subtitle1">Total</Typography>
                      <TextField
                        size="small"
                        value={total}
                        InputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>

                    {/* Category Dropdown */}
                    <Grid item xs={7}>
                      <Typography variant="subtitle1">Category</Typography>
                      <TextField
                        select
                        size="small"
                        {...formik.getFieldProps("category")}
                        value={formik.values.category || ""} 
                        fullWidth
                      >
                        {categories.map((category) => (
    <MenuItem key={category.id} value={category.id}>
      {category.category_name}
    </MenuItem>
  ))}
                      </TextField>
                    </Grid>

                      <Grid item xs={7}>
                       <Typography variant="subtitle1">Status</Typography>
                       <FormControlLabel
                         control={
                           <Checkbox
                             checked={formik.values.status === "Paid"} // Check if status is "Paid"
                             onChange={(e) => formik.setFieldValue("status", e.target.checked ? "Paid" : "Unpaid")} // Update Formik value based on checkbox state
                             color="primary"
                           />
                         }
                         label="Paid"
                       />
                     </Grid>

                    {/* File Upload Field */}
                     <Grid container spacing={2} mt={2} ml={2} mb={2}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Upload Invoice</Typography>
                      <input
                        type="file"
                        onChange={(event) => {
                          const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
                          const allFiles = [
                            ...(formik.values.file ?? []),  // Use an empty array if file is null
                            ...newFiles
                          ]; 
                          formik.setFieldValue("file", allFiles);  // Update Formik's file field
                        }}
                        
                        style={{ display: "none" }}
                        id="file-upload"
                      />
                      <Box>
                        <label htmlFor="file-upload">
                          <Button variant="outlined" component="span">Choose File</Button>
                        </label>
                        {formik.values.file && formik.values.file.length > 0 && (
  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
    Selected Files:
    <ul>
      {formik.values.file.map((file, index) => (
        <li key={index}>{file.name}</li>  // Display the name of each file
      ))}
    </ul>
  </Typography>
)}

                      </Box>
                    </Grid>

                    <Grid item xs={4}>
  <Typography variant="subtitle1">Before Images</Typography>
  <input
    type="file"
    onChange={(event) => {
      const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
      const allFiles = [
        ...(formik.values.beforeImages ?? []),  // Use an empty array if beforeImages is null
        ...newFiles
      ];
      formik.setFieldValue("beforeImages", allFiles);  // Update Formik's beforeImages field
    }}
    style={{ display: "none" }}
    id="before-images-upload"
  />
  <Box>
    <label htmlFor="before-images-upload">
      <Button variant="outlined" component="span">Choose File</Button>
    </label>
    {formik.values.beforeImages && formik.values.beforeImages.length > 0 && (
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
        Selected Before Images:
        <ul>
          {formik.values.beforeImages.map((file, index) => (
            <li key={index}>{file.name}</li>  // Display the name of each file
          ))}
        </ul>
      </Typography>
    )}
  </Box>
</Grid>

<Grid item xs={4}>
  <Typography variant="subtitle1">After Images</Typography>
  <input
    type="file"
    onChange={(event) => {
      const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
      const allFiles = [
        ...(formik.values.afterImages ?? []),  // Use an empty array if afterImages is null
        ...newFiles
      ];
      formik.setFieldValue("afterImages", allFiles);  // Update Formik's afterImages field
    }}
    style={{ display: "none" }}
    id="after-images-upload"
  />
  <Box>
    <label htmlFor="after-images-upload">
      <Button variant="outlined" component="span">Choose File</Button>
    </label>
    {formik.values.afterImages && formik.values.afterImages.length > 0 && (
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
        Selected After Images:
        <ul>
          {formik.values.afterImages.map((file, index) => (
            <li key={index}>{file.name}</li>  // Display the name of each file
          ))}
        </ul>
      </Typography>
    )}
  </Box>
</Grid>
</Grid>

<Grid item xs={7}>
              <Typography variant="subtitle1">Remarks</Typography>
              <TextField
                placeholder="Enter remarks"
                size="small"
                {...formik.getFieldProps("remarks")}
                multiline
                rows={4} 
                fullWidth
                
              />
            </Grid>
                  </Grid>
                </Card>
              </Grid>

              {/* Submit and Cancel Buttons */}
              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="outlined"
                        className={classes.addButton}
                        startIcon={<CloseIcon />}
                        // onClick={() => formik.resetForm()}
                        onClick={() =>navigate('/expense/list')}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>
                      <Button
                        variant="contained"
                        className={classes.addButton}
                        startIcon={<SaveAltIcon />}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : "Submit"}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditExpenseForm;
