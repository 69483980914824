import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CiSearch } from "react-icons/ci";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { useStyles } from "../../../theme/appStyles";
import { useGetDriverQuery } from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { IDriver } from "../../../Api/Interface/api.interface";
import NewPopUpDriver from "../Edit/index";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const { id: customerId } = useParams<{ id: string }>();

  const [openDialog, setOpenDialog] = useState(false);
  const [openAddTransaction, setOpenAddTransaction] = useState(false);
  const { showErrorMessage, showMessage } = useNotifier();
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [selectedCoursing, setSelectedCoursing] = useState<IDriver | null>(
    null
  );
  const [selectCustomer, setSelectCustomer] = useState<IDriver | null>(null);
  useEffect(() => {}, [customerId]);

  const [showPassword, setShowPassword] = useState(false);

  const { data, isLoading, isError } = useGetDriverQuery({
    searchText: "",
    id: String(customerId),
  });

  const customer = useMemo(() => {
    if (data && Array.isArray(data.data) && data.data.length > 0) {
      return data.data[0] as IDriver;
    }
    return null;
  }, [data]);

  const handleOpenEditSuites = () => {
    setSelectCustomer(customer);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectCustomer(null);
    setOpenDialog(false);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error">
        Error fetching Driver data. Please try again later.
      </Typography>
    );
  }

  if (!customer) {
    return <Typography>No customer data available.</Typography>;
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card className={classes.stepperCard}>
            <Grid
              container
              justifyContent={"space-between"}
              spacing={5}
              sx={{ borderBottom: 2, padding: 2 }}
              className={classes.titleGrid}
            >
              <Grid
                item
                lg={11}
                md={11}
                sm={11}
                xs={11}
                pt={2}
                alignContent={"center"}
              >
                <Typography variant="h2">
                  {customer.driverFirstName + " " + customer.driverLastName}
                </Typography>
              </Grid>
              <Grid
                item
                lg={1}
                md={1}
                sm={1}
                xs={1}
                pt={2}
                alignContent={"center"}
              >
                <Button
                  variant="outlined"
                  endIcon={<ArrowForwardIosIcon />}
                  className={classes.addButton}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
              <Card className={classes.stepperCard}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Driver Details
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Grid
                          container
                          spacing={1}
                          justifyContent="flex-end"
                          sx={{ display: "flex" }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.editButtonList}
                              onClick={handleOpenEditSuites}
                            >
                              <EditSharpIcon className={classes.editListIcon} />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>
                            Phone:
                          </span>{" "}
                          {customer.driverMobileNum}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>
                            E-mail:
                          </span>{" "}
                          {customer.driverEmail}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>
                            Phone Number:
                          </span>{" "}
                          {customer.driverMobileNum}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>
                            Alt Phone Number:
                          </span>{" "}
                          {customer.driverEmail}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>City:</span>{" "}
                          {customer.driverCity}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>
                            User Name:
                          </span>{" "}
                          {customer.driverUserName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={10}
                        md={10}
                        sm={10}
                        xs={10}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>
                            Password:
                          </span>{" "}
                          {showPassword ? customer.driverUserName : "●●●●●●●●"}{" "}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        direction={"column"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>
                            Address:
                          </span>{" "}
                          {customer.driverAddress}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
              <Card className={classes.stepperCard}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Description
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, px: 2 }}
                      className={classes.titleGrid}
                      minHeight={"400px"}
                    >
                      <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                        <Typography variant="subtitle1">
                          {customer.driverDescription}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {customer.availability && customer.availability.length > 0 ? (
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Card className={classes.card}>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={5}
                sx={{ borderBottom: 2, padding: 2 }}
                className={classes.titleGrid}
              >
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  pt={2}
                  alignContent={"center"}
                >
                  <Typography className={classes.cardTitle}>
                    Available Time Slots
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3} p={"24px"}>
                {customer.availability.map((day, index) => (
                  <Grid item lg={12} md={12} sm={12} xs={12} key={day.day}>
                    <Card className={classes.card}>
                      <Grid container spacing={3} p={"24px"}>
                        <Typography className={classes.cardTitle}>
                          {day.day} Availability
                        </Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tablheaderCreate}>
                                  <strong>Start Time</strong>
                                </TableCell>
                                <TableCell className={classes.tablheaderCreate}>
                                  <strong>End Time</strong>
                                </TableCell>
                                <TableCell className={classes.tablheaderCreate}>
                                  <strong>Hourly Salary</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {day.timeSlots.map((slot, slotIndex) => (
                                <TableRow key={slotIndex}>
                                  <TableCell>{slot.startTime}</TableCell>
                                  <TableCell>{slot.endTime}</TableCell>
                                  <TableCell>
                                    {"$"}
                                    {slot.hourlySalary
                                      ? Number(slot.hourlySalary).toFixed(2)
                                      : "0.00"}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        ) : (
          <div>No availability data to show</div>
        )}
        ;
      </Grid>
      {selectCustomer && (
        <NewPopUpDriver
          openModel={openDialog}
          handleCloseDialog={handleCloseDialog}
          data={selectCustomer}
        />
      )}
    </div>
  );
};

export default Index;
