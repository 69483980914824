import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Card,
} from "@mui/material";
import {
  useDeleteCategoryMutation,
  useGetAllCategoryNameQuery,
  useGetAllMainCategoryQuery,
  useGetCoursingQuery,
  useGetDepartmentQuery,
  useGetTaxQuery,
} from "../../../Api/attoDeskApi";
import {
  ICategory,
  ICoursing,
  IDepartment,
  ITaxes,
} from "../../../Api/Interface/api.interface";
import DeletePopup from "../../../Components/Delete/DeletePopup";
import { useNotifier } from "../../../Core/Notifier";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { RooleType } from "../../../Core/Enum/enum";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useStyles } from "../../../theme/appStyles";
import AlertPopUp from "../../../Components/AlertPopUp/AlertPopup";
import useDebounce from "../../../Components/SearchDebonce/useDebounce";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import NewPopUpCategory from "../Edit/index";
import CustomCheckbox from "../../../Components/Checkbox.tsx";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import Pagination from "../../../Components/Pagination";

const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const getFormattedDate = (createDate: Dayjs | null): string => {
    return createDate ? createDate.format("YYYY-MM-DD") : "";
  };
  const { showErrorMessage, showMessage } = useNotifier();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [selectedCoursingId, setSelectedCousingId] = useState<string>("");
  const [selectedProductTaxId, setSelectedProductTaxId] = useState<string>("");
  const [selectedProductRoles, setSelectedProducRoles] = useState<string[]>([]);
  const [selectedIsActive, setSelectedProductIsActive] =
    useState<boolean>(true);
  const [selectedIsLooping, setSelectedIsLooping] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [createDate, setCreateDate] = useState<Dayjs | null>(null);
  const startDateAsDate = startDate?.toDate() || null;
  const endDateAsDate = endDate?.toDate() || null;
  const [isPaperVisible, setIsPaperVisible] = useState(false);

  const { data, isLoading, isError, refetch } = useGetAllMainCategoryQuery({
    departmentId: selectedDepartment,
    coursingId: selectedCoursingId,
    roleId: selectedProductRoles,
    taxeId: selectedProductTaxId,
    createdDateStart: startDateAsDate as Date,
    createdDateEnd: endDateAsDate as Date,
    searchText: currentSearchQuery,
    numberOfLength: null,
    isActive: true,
    categoryIsLooping: selectedIsLooping,
    createdDate: getFormattedDate(createDate),
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedCoursing, setSelectedCoursing] = useState<ICategory | null>(
    null
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [coursingToDelete, setCoursingToDelete] = useState<ICategory | null>(
    null
  );
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const { data: departmentData, isLoading: departmentLoading } =
    useGetDepartmentQuery({
      searchText: "",
    });
  const { data: coursingData, isLoading: coursingLoading } =
    useGetCoursingQuery({
      searchText: "",
    });
  const { data: taxData, isLoading: taxLoading } = useGetTaxQuery({
    searchText: "",
  });
  const { data: categoryData, isLoading: categoryLoading } =
    useGetAllCategoryNameQuery();

  const departmentList = useMemo(() => {
    return departmentData?.data as IDepartment[];
  }, [departmentData?.data]);

  const coursingList = useMemo(() => {
    return coursingData?.data as ICoursing[];
  }, [coursingData?.data]);

  const taxList = useMemo(() => {
    return taxData?.data as ITaxes[];
  }, [taxData?.data]);

  const categoryList = useMemo(() => {
    return categoryData?.data as ICategory[];
  }, [categoryData?.data]);

  const categoryMap = useMemo(() => {
    const map = new Map();
    categoryList?.forEach((printer) => {
      map.set(printer.id, printer.categoryName);
    });
    return map;
  }, [categoryList]);

  const departmentMap = useMemo(() => {
    const map = new Map();
    departmentList?.forEach((category) => {
      map.set(category.id, category.departmentName);
    });
    return map;
  }, [departmentList]);

  const coursingMap = useMemo(() => {
    const map = new Map();
    coursingList?.forEach((category) => {
      map.set(category.id, category.coursingName);
    });
    return map;
  }, [coursingList]);

  const taxMap = useMemo(() => {
    const map = new Map();
    taxList?.forEach((category) => {
      map.set(category.id, category.taxName);
    });
    return map;
  }, [taxList]);

  useEffect(() => {
    if (debouncedSearchQuery.length >= 4 || debouncedSearchQuery === "") {
      setCurrentSearchQuery(debouncedSearchQuery);
      refetch();
    }
  }, [debouncedSearchQuery]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (coursing: ICategory) => {
    setSelectedCoursing(coursing);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedCoursing(null);
    setOpenDialog(false);
  };

  const handleOpenDeletePopup = (coursing: ICategory) => {
    setCoursingToDelete(coursing);
    setOpenDeleteCategory(true);
  };

  const handleCloseDeletePopup = () => {
    setCoursingToDelete(null);
    setOpenDeleteCategory(false);
  };

  const handleCoursing = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValues = event.target.value;
    setSelectedCousingId(selectedValues);
  };

  const handleDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValues = event.target.value;
    setSelectedDepartment(selectedValues);
  };

  const handleProductTax = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValues = event.target.value;
    setSelectedProductTaxId(selectedValues);
  };

  const handleProductIsActive = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValue = event.target.checked; // Use `checked` for boolean values from checkboxes
    setSelectedProductIsActive(selectedValue);
  };

  const handleIsLooping = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValues = event.target.value;
    setSelectedIsLooping(selectedValues);
  };

  const handleStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
  };

  const handleCreateDateChange = (newValue: Dayjs | null) => {
    setCreateDate(newValue);
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue);
  };

  const handleProductRoals = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSelectedProducRoles(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [deleteCategory] = useDeleteCategoryMutation();
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategory(id).unwrap();
      if (response.status) {
        showMessage(response.message);
        setOpenDeleteCategory(false);
      } else {
        setAlertMessage(response.message);
        setDeleteAlert(true);
      }
    } catch (error) {
      showErrorMessage("Failed to delete the category");
    }
  };

  const handleToggle = () => {
    setIsPaperVisible(!isPaperVisible);
  };

  if (isLoading || departmentLoading || coursingLoading || taxLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const coursings: ICategory[] = Array.isArray(data?.data)
    ? (data?.data as ICategory[])
    : [];

  const resetFields = () => {
    setSelectedDepartment("");
    setSelectedCousingId("");
    setSelectedProductTaxId("");
    setSelectedIsLooping("");
    setSelectedProducRoles([]);
    setStartDate(null);
    setEndDate(null);
    setSelectedProductIsActive(true);
    setCreateDate(null);
  };

  const handleSearch = () => {
    setCurrentSearchQuery(searchQuery);
  };

  const resetSearchFields = () => {
    setSearchQuery("");
    setCurrentSearchQuery("");
    refetch();
  };
  const handleAddProductClick = () => {
    navigate("/category/create");
  };

  return (
    <Card className={classes.card}>
      <Grid
        container
        justifyContent={"space-between"}
        spacing={5}
        sx={{ borderBottom: 2, padding: 2 }}
        className={classes.titleGrid}
      >
        <Grid item lg={4} md={4} sm={4} xs={4} pt={2} alignContent={"center"}>
          <Typography className={classes.cardTitle}>New Category</Typography>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          xs={8}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            sx={{ display: "flex" }}
          >
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CiSearch className={classes.searchWidgetIcon} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.formControlList,
                  },
                }}
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Category Name .."
                sx={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.addButton}
                onClick={handleToggle}
              >
                {isPaperVisible ? "Close Filter" : "Open Filter"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.addButton}
                onClick={handleAddProductClick}
              >
                Add Category
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isPaperVisible && (
        <>
          <Grid container spacing={2} my={5} px={1}>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Category Department
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    size="small"
                    sx={{ flexGrow: 1, width: "100%" }}
                    value={selectedDepartment || ""}
                    onChange={handleDepartment}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="" style={{ color: "gray" }}>
                      Select an option
                    </MenuItem>
                    {departmentList &&
                      departmentList.map((department) => (
                        <MenuItem key={department.id} value={department.id}>
                          {department.departmentName}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Category Coursing
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    size="small"
                    sx={{ flexGrow: 1, width: "100%" }}
                    value={selectedCoursingId || ""}
                    onChange={handleCoursing}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="" style={{ color: "gray" }}>
                      Select an option
                    </MenuItem>
                    {coursingList &&
                      coursingList.map((coursing) => (
                        <MenuItem key={coursing.id} value={coursing.id}>
                          {coursing.coursingName}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Category Tax
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    size="small"
                    sx={{ flexGrow: 1, width: "100%" }}
                    value={selectedProductTaxId || ""}
                    onChange={handleProductTax}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="" style={{ color: "gray" }}>
                      Select an option
                    </MenuItem>
                    {taxList &&
                      taxList.map((tax) => (
                        <MenuItem key={tax.id} value={tax.id}>
                          {tax.taxName}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Category Roles
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    size="small"
                    sx={{ width: "100%" }}
                    SelectProps={{
                      multiple: true,
                      native: false,
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedProductRoles || ""}
                    onChange={handleProductRoals}
                  >
                    {Object.entries(RooleType).map(([key, value], index) => (
                      <MenuItem key={index} value={value}>
                        {key}
                      </MenuItem>
                    ))}

                    <MenuItem value="" style={{ color: "gray" }}>
                      Select an option
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Looping
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    size="small"
                    sx={{ flexGrow: 1, width: "100%" }}
                    value={selectedIsLooping || ""}
                    onChange={handleIsLooping}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="" style={{ color: "gray" }}>
                      Select an option
                    </MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Create Date
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={handleCreateDateChange}
                      value={createDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: { width: "100%" },
                          InputProps: {
                            sx: { fontSize: 14 },
                          },
                          InputLabelProps: {
                            sx: { fontSize: 14 },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Start Date
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={handleStartDateChange}
                      value={startDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: { width: "100%" },
                          InputProps: {
                            sx: { fontSize: 14 },
                          },
                          InputLabelProps: {
                            sx: { fontSize: 14 },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    End Date
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={handleEndDateChange}
                      value={endDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: { width: "100%" },
                          InputProps: {
                            sx: { fontSize: 14 },
                          },
                          InputLabelProps: {
                            sx: { fontSize: 14 },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  startIcon={<RestartAltIcon />}
                  className={classes.addButton}
                  onClick={resetFields}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      <Grid>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeaderRow}>
              <TableRow>
                <TableCell className={classes.tablheader}>
                  <CustomCheckbox name="checkbox" />
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>#</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Category Name</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Perent Category</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Active</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Department</strong>
                </TableCell>
                <TableCell className={classes.tablheader} sx={{textAlign: "right"}}>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isError || coursings.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={20} align="center">
                    {isError ? "Error fetching data" : "No data available"}
                  </TableCell>
                </TableRow>
              ) : (
                coursings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: ICategory, index) => (
                    <TableRow key={row.id} className={classes.tableRowHover}>
                      <TableCell className={classes.tablCell}>
                        <CustomCheckbox name="checkbox" />
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {page * rowsPerPage + index + 1}
                      </TableCell>

                      <TableCell scope="row" className={classes.tablCell}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Box className={classes.SelectImageTableBox}>
                              <img
                                src={row.categoryImg}
                                alt="img"
                                // alt={row.productName.toLowerCase()}
                                className={classes.SelectImageTable}
                              />
                            </Box>
                          </Grid>
                          <Grid item textAlign={"center"} alignContent={"center"}>
                            <Typography className={classes.nameTableList}>
                              {row.categoryName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>

                      <TableCell scope="row" className={classes.tablCell}>
                        {row.parent_ids
                          ?.map((id) => categoryMap.get(id) || " ")
                          .join(" → ")}
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {row.isActive ? "Active" : "Inactive"}
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {departmentMap.get(row.departmentId) || ""}
                      </TableCell>
                      <TableCell className={classes.tablCell}>
                        <Grid
                          container
                          spacing={1}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.deleteButtonList}
                              onClick={() => handleOpenDialog(row)}
                            >
                              <EditSharpIcon className={classes.editListIcon} />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.deleteButtonList}
                              onClick={() => handleOpenDeletePopup(row)}
                            >
                              <DeleteOutlineSharpIcon
                                className={classes.deleteButtonList}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <Grid
            spacing={5}
            p={"18px"}
            py={4}
            justifyContent={"end"}
            container
            textAlign={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid
              sx={{
                textAlign: "end",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pagination
                count={coursings.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </TableContainer>
        {selectedCoursing && (
          <NewPopUpCategory
            openModel={openDialog}
            handleCloseDialog={handleCloseDialog}
            data={selectedCoursing}
          />
        )}
        {coursingToDelete && (
          <DeletePopup
            open={openDeleteCategory}
            handleCloseDelete={handleCloseDeletePopup}
            onConfirm={async () => {
              await handleDelete(coursingToDelete.id.toString());
            }}
            title="Delete Category"
            content={`Are you sure you want to delete "${coursingToDelete.categoryName}"?`}
          />
        )}

        <AlertPopUp
          open={deleteAlert}
          onCloseAlert={setDeleteAlert}
          message={alertMessage}
        />
      </Grid>
    </Card>
  );
};

export default Index;
