import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { useStyles } from "../../theme/appStyles";
import {
  Avatar,
  Collapse,
  Grid,
  InputAdornment,
  ListItemButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserImg from "../../Images/user_login_photo.webp";
import { useState } from "react";
import { useThemeContext } from "./ThemeContext";
import Notification from "../../Components/Popup/notification";
import LogoMain from "../../Images/logo_main_black.webp";

import { MdOutlineNotificationsNone } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import Profile from "../../Components/Popup/profile";
import { appColors } from "../../theme/appcolors";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleIcon from "@mui/icons-material/People";
import LoopIcon from "@mui/icons-material/Loop";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import StyleIcon from "@mui/icons-material/Style";
import FlagIcon from "@mui/icons-material/Flag";
import PrintIcon from "@mui/icons-material/Print";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StoreIcon from "@mui/icons-material/Store";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import SettingsApplicationsSharpIcon from '@mui/icons-material/SettingsApplicationsSharp';
import GroupsIcon from '@mui/icons-material/Groups';
import axios from 'axios';

const drawerWidth = 280;
const headerHeight = 100;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  height: headerHeight,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    paddingLeft: 0,
  }),
  ...(!open && {
    marginLeft: 0,
    width: "100%",
    paddingLeft: "75px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  }),
}));

const IsNotMobileDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
  }),
}));

const MobileDrawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: headerHeight,
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.background.default,
  paddingLeft: "75px",
  margin: "0px",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  flexShrink: 0,
}));

const DrawerContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mainMenuNav.mainNavBg,
  overflowY: "auto", // Enable vertical scrolling
  overflowX: "hidden", // Disable horizontal scrolling
  flexGrow: 1,
  "&::-webkit-scrollbar": {
    width: "0px", // Initially hidden
  },
  "&:hover::-webkit-scrollbar": {
    width: "4px", // Show scrollbar on hover
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor:
      appColors.dataBssThemeDarkMenuColorLight["--bs-main-nav-item-color"],
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
}));

const PaddingBody = styled("div")(({ theme }) => ({
  height: headerHeight,
}));

type SubmenuItem = {
  topBarTitle?: string;
  title: string;
  path: string;
  newPage?: boolean;
  icon: React.ReactElement;
};

type menuList = {
  topBarTitle: string;
  title: string;
  icon: React.ReactElement;
  path: string;
  newPage?: boolean;
  submenuOpen?: boolean;
  submenus?: SubmenuItem[];
};

const MainMenuList: {
  comomTitle: string;
  menuList?: menuList[];
}[] = [
  {
    comomTitle: "General",
    menuList: [
      // {
      //   title: "Dashboard",
      //   topBarTitle: "Welcome!",
      //   icon: <MenuIcon />,
      //   path: "/dashboard",
      //   submenuOpen: false,
      // },
      // {
      //   title: "Services",
      //   topBarTitle: "Services",
      //   icon: <ProductionQuantityLimitsIcon />,
      //   path: "/product",
      //   submenuOpen: true,
      //   submenus: [
      //     {
      //       topBarTitle: "Services List",
      //       title: "List",
      //       icon: <MenuIcon />,
      //       path: "/product/list",
      //     },
      //     {
      //       topBarTitle: "Create Services",
      //       title: "Create",
      //       icon: <MenuIcon />,
      //       path: "/product/create",
      //     },
      //   ],
      // },
      // {
      //   title: "Services",
      //   topBarTitle: "Services List",
      //   icon: <ProductionQuantityLimitsIcon />,
      //   path: "/product/list",
      //   submenuOpen: false,
      // },
      // {
      //   title: "Category",
      //   topBarTitle: "Category List",
      //   icon: <CategoryIcon />,
      //   path: "/category/list",
      //   submenuOpen: false,
      // },
      // {
      //   title: "Quotes",
      //   topBarTitle: "Quotes List",
      //   icon: <FormatQuoteIcon />,
      //   path: "/quote/list",
      //   submenuOpen: false,
      // },
      // {
      //   title: "Invoice",
      //   topBarTitle: "Invoice List",
      //   icon: <DescriptionIcon />,
      //   path: "/invoice/list",
      //   submenuOpen: false,
      // },
      {
        title: "Expense",
        topBarTitle: "Expense Management",
        icon: <AttachMoneyIcon />,
        path: "/expense/list",
        submenuOpen: false,
      },
      
      
      // {
      //   title: "Invoice",
      //   topBarTitle: "Invoice",
      //   icon: <DescriptionIcon />,
      //   path: "/invoice",
      //   submenuOpen: true,
      //   submenus: [
      //     {
      //       topBarTitle: "Invoice List",
      //       title: "List",
      //       icon: <MenuIcon />,
      //       path: "/invoice/list",
      //     },
      //   ],
      // },
    ],
  },
  {
    comomTitle: "Users",
    menuList: [
      // {
      //   title: "Company",
      //   topBarTitle: "Company",
      //   icon: <StoreIcon />,
      //   path: "/company",
      //   submenuOpen: true,
      //   submenus: [
      //     {
      //       topBarTitle: "Company List",
      //       title: "List",
      //       icon: <PeopleIcon />,
      //       path: "/company/list",
      //     },
      //     {
      //       topBarTitle: "Create Company",
      //       title: "Create",
      //       icon: <MenuIcon />,
      //       path: "/company/create",
      //     },
      //   ],
      // },
      {
        title: "Company",
        topBarTitle: "Company List",
        icon: <StoreIcon />,
        path: "/company/list",
        submenuOpen: false,
      },
      // {
      //   title: "Customer",
      //   topBarTitle: "Customer List",
      //   icon: <PeopleIcon />,
      //   path: "/customer/list",
      //   submenuOpen: false,
      // },
      {
        title: "Team",
        topBarTitle: "Team List",
        icon: <GroupsIcon />,
        path: "/team/list",
        submenuOpen: false,
      },
      // {
      //   title: "Team",
      //   topBarTitle: "Team",
      //   icon: <GroupsIcon />,
      //   path: "/team",
      //   submenuOpen: true,
      //   submenus: [
      //     {
      //       topBarTitle: "Team List",
      //       title: "List",
      //       icon: <PeopleIcon />,
      //       path: "/team/list",
      //     },
      //     {
      //       topBarTitle: "Create Team",
      //       title: "Create",
      //       icon: <MenuIcon />,
      //       path: "/team/create",
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   comomTitle: "Setting",
  //   menuList: [
      // {
      //   title: "Setting",
      //   topBarTitle: "Setting",
      //   icon: <SettingsApplicationsSharpIcon />,
      //   path: "/company",
      //   submenuOpen: true,
      //   submenus: [
          // {
          //   topBarTitle: "Looping List",
          //   title: "Looping",
          //   icon: <MenuIcon />,
          //   path: "/looping/list",
          // },
          // {
          //   topBarTitle: "Department List",
          //   title: "Department",
          //   icon: <MenuIcon />,
          //   path: "/department/list",
          // },
          // {
          //   topBarTitle: "Coursing List",
          //   title: "Coursing",
          //   icon: <MenuIcon />,
          //   path: "/coursing/list",
          // },
          // {
          //   topBarTitle: "Product Brand List",
          //   title: "Product Brand",
          //   icon: <MenuIcon />,
          //   path: "/brand/list",
          // },
          // {
          //   topBarTitle: "Product Tag List",
          //   title: "Product Tag",
          //   icon: <MenuIcon />,
          //   path: "/tag/list",
          // },
          // {
          //   topBarTitle: "Tax List",
          //   title: "Tax",
          //   icon: <PeopleIcon />,
          //   path: "/tax/list",
          // },
          // {
          //   topBarTitle: "Printer List",
          //   title: "Printer",
          //   icon: <PeopleIcon />,
          //   path: "/printer/list",
          // },
          // {
          //   topBarTitle: "Email Template List",
          //   title: "Email",
          //   icon: <PeopleIcon />,
          //   path: "/email/list",
          // },
          // {
          //   topBarTitle: "Note Template List",
          //   title: "Note",
          //   icon: <PeopleIcon />,
          //   path: "/note/list",
          // },
       
      //   ],
      // },
      
  //   ],
  // },
  
];

export default function MiniDrawer({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(true);

  const [notificationPopUp, setNotificationPopUp] =
    React.useState<HTMLElement | null>(null);
  const { mode, toggleTheme } = useThemeContext();
  const [profilePopUp, setProfilePopUp] = useState<HTMLElement | null>(null);
  const openProfilePopUp = Boolean(profilePopUp);
  const openNotificationPopUp = Boolean(notificationPopUp);

  const [currentMenuName, setCurrentMenuName] = React.useState("");
  const [hoveredMenuName, setHoveredMenuName] = React.useState("");
  const [expandMenu, setExpandMenu] = React.useState<string>("");
  const [topBarTitle, setTopBarTitle] = useState("");
  const [hoveredSubmenu, setHoveredSubmenu] = React.useState("");
  const [selectedSubmenu, setSelectedSubmenu] = React.useState("");

  interface UserInfo {
    id: number;
    username: string;
    token: string;
    user_id: number;
  }
  
  
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const API_URL = process.env.REACT_APP_API_URL;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentMenuName]);

  // React.useEffect(() => {
  //   for (const mainMenu of MainMenuList) {
  //     for (const menu of mainMenu.menuList || []) {
  //       if (menu.path === location.pathname) {
  //         setCurrentMenuName(menu.path);
  //         setSelectedSubmenu("");
  //         setExpandMenu(menu.title);
  //       }

  //       for (const submenu of menu.submenus || []) {
  //         if (submenu.path === location.pathname) {
  //           setSelectedSubmenu(submenu.path);
  //           setExpandMenu(menu.title);
  //         }
  //       }
  //     }
  //   }
  // }, [location.pathname]);

  React.useEffect(() => {
    let found = false;
    for (const mainMenu of MainMenuList) {
      for (const menu of mainMenu.menuList || []) {
        if (menu.path === location.pathname) {
          setCurrentMenuName(menu.path);
          setSelectedSubmenu("");
          setExpandMenu(menu.title);
          found = true;
        }

        for (const submenu of menu.submenus || []) {
          if (submenu.path === location.pathname) {
            setSelectedSubmenu(submenu.path);
            setExpandMenu(menu.title);
            setHoveredSubmenu(submenu.path);
            found = true;
          }
        }

        if (found) break;
      }
      if (found) break;
    }
  }, [location.pathname]);

  React.useEffect(() => {
    const findTopBarTitle = (): string => {
      // Check custom routes first
      if (location.pathname.startsWith("/customer-history/")) {
        return "Customer Details";
      }
      if (location.pathname.startsWith("/product-history/")) {
        return "Product Details";
      }
      if (location.pathname.startsWith("/company-history/")) {
        return "Company Details & Edit";
      }
      if (location.pathname.startsWith("/quote-history/")) {
        return "Work Order Edit";
      }
      if (location.pathname.startsWith("/work-order/history/")) {
        return "Work Order Details";
      }
      if (location.pathname.startsWith("/invoice/history/")) {
        return "Invoice Details";
      }
      if (location.pathname.startsWith("/team-history/")) {
        return "Team Details";
      }
      if (location.pathname.startsWith("/customer-history/project/")) {
        return "Customer's Project Details";
      }

      if (location.pathname.startsWith("/looping/create")) {
        return "Create Looping";
      }
      if (location.pathname.startsWith("/department/create")) {
        return "Create Department";
      }
      if (location.pathname.startsWith("/coursing/create")) {
        return "Create Coursing";
      }
      if (location.pathname.startsWith("/brand/create")) {
        return "Create Product Brand";
      }
      if (location.pathname.startsWith("/tag/create")) {
        return "Create Product Tag";
      }
      if (location.pathname.startsWith("/tax/create")) {
        return "Create Tax";
      }
      if (location.pathname.startsWith("/printer/create")) {
        return "Create Printer";
      }
      if (location.pathname.startsWith("/customer/create")) {
        return "Create Customer";
      }
      if (location.pathname.startsWith("/email/create")) {
        return "Create Email Template";
      }
      if (location.pathname.startsWith("/note/create")) {
        return "Create Note Template";
      }
      // if (location.pathname.startsWith("/expense/")) {
      //   return "Edit Expense";
      // }
     
   
   

      // Check MainMenuList for matching path
      for (const mainMenu of MainMenuList) {
        for (const menu of mainMenu.menuList || []) {
          if (menu.path === location.pathname) {
            return menu.topBarTitle;
          }

          for (const submenu of menu.submenus || []) {
            if (submenu.path === location.pathname) {
              return submenu.topBarTitle || "WELCOME!";
            }
          }
        }
      }

      // Default title if no matches are found
      // return "WELCOME!";
      return "";
    };

    setTopBarTitle(findTopBarTitle());
  }, [location.pathname]);

  
  React.useEffect(() => {
    const fetchUserInfo = async () => {
      try {
          const response = await axios.post(
              `${API_URL}/user/userInfo`,
              {}, // No body required
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${localStorage.getItem('token') || ''}`, // Include token from localStorage
                  },
              }
          );
          setUserInfo(response.data.data); // Update your state with the response
        
      } catch (error) {
          console.error('Error fetching user info', error);
      }
  };
  
   
    fetchUserInfo();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
    setExpandMenu("");
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMobileDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleMobileDrawerClose = () => {
    setMobileDrawerOpen(false);
  };

  const handleClickNotificationPopUp = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setNotificationPopUp(event.currentTarget);
  };

  const handleClickProfilePopUp = (event: React.MouseEvent<HTMLElement>) => {
    setProfilePopUp(event.currentTarget);
  };

  const handleCloseProfilePopUp = () => {
    setProfilePopUp(null);
  };

  const handleCloseNotificationPopUp = () => {
    setNotificationPopUp(null);
  };

  const handleImgClick = () => {
    navigate("/expense/list");
  };

  const handleMainMenuItemClick = (ml: menuList) => {
    if (ml.submenus && ml.submenus.length > 0) {
      setExpandMenu(ml.title === expandMenu ? "" : ml.title);
    } else if (ml.path) {
      setCurrentMenuName(ml.path);
      setSelectedSubmenu("");
      navigate(ml.path);
      setMobileDrawerOpen(false);
    }
    setTopBarTitle(ml.topBarTitle || "WELCOME!");
  };

  const handleSubmenuItemClicked = (submenu: SubmenuItem) => {
    setCurrentMenuName(submenu.path);
    setSelectedSubmenu(submenu.path);
    navigate(submenu.path);
    setTopBarTitle(submenu.topBarTitle || "WELCOME!");
    setMobileDrawerOpen(false);
  };


  return (
    <Box sx={{ display: "flex" }}>
      {!isMobile ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open} className={classes.topbar}>
            <Toolbar className={classes.toolbar}>
              <Typography width={"100%"} className={classes.h4}>
                {topBarTitle}
              </Typography>

            
              <Grid
                container
                data-testid="user-content"
                spacing={1}
                alignItems="center"
                justifyContent="flex-end"
                sx={{ display: "flex" }}
              >
                <Grid item>
                  <IconButton
                    onClick={handleClickNotificationPopUp}
                    className={classes.topbarButton}
                    aria-haspopup="true"
                  >
                    <MdOutlineNotificationsNone
                      className={classes.topbarIcon}
                    />
                  </IconButton>
                  <IconButton
                    onClick={toggleTheme}
                    aria-haspopup="true"
                    className={classes.topbarButton}
                  >
                    {mode == "light" ? (
                      <MdDarkMode className={classes.topbarIcon} />
                    ) : (
                      <MdDarkMode className={classes.topbarIcon} />
                    )}
                  </IconButton>
                  <IconButton
                    onClick={handleClickProfilePopUp}
                    className={classes.topbarButton}
                    aria-haspopup="true"
                  >
                    <Avatar
                      alt="Travis Howard"
                      src={UserImg}
                      className={classes.topbarIconProfile}
                    />
                  </IconButton>
                 
                </Grid>

               

                <Grid item>
                  <div className={classes.appSearch}>
                    {/* <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CiSearch className={classes.searchWidgetIcon} />
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.formControl,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      fullWidth
                      placeholder="Search .."
                      variant="outlined"
                      size="small"
                    /> */}

<Typography width={"100%"} className={classes.h5}>
                {userInfo?.username}
              </Typography>
                  </div>
                </Grid>
              </Grid>
             

              <Profile
                profile={profilePopUp}
                onClose={handleCloseProfilePopUp}
                open={openProfilePopUp}
              />
              <Notification
                notification={notificationPopUp}
                onClose={handleCloseNotificationPopUp}
                open={openNotificationPopUp}
              />
            </Toolbar>
          </AppBar>
          <IsNotMobileDrawer variant="permanent" open={open}>
            <DrawerHeader>
              {open ? (
                <img
                  src={LogoMain}
                  alt="AttoDesk Login"
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "block",
                    cursor: "pointer",
                  }}
                  onClick={handleImgClick}
                />
              ) : (
                ""
              )}

              <IconButton
                className={classes.topbarButton}
                onClick={handleDrawerToggle}
              >
                {open ? (
                  <ChevronRightIcon className={classes.topbarIcon} />
                ) : (
                  <ChevronLeftIcon className={classes.topbarIcon} />
                )}
              </IconButton>
            </DrawerHeader>

            <DrawerContent>
              {MainMenuList.map((ml, index) => (
                <React.Fragment key={index}>
                  <Box>
                    {open && (
                      <Typography className={classes.menuTitle}>
                        {ml.comomTitle}
                      </Typography>
                    )}
                  </Box>
                  {ml.menuList && (
                    <List>
                      {ml.menuList.map((menuList) => (
                        <ListItem
                          button
                          key={menuList.title}
                          disablePadding
                          sx={{
                            display: "block",
                          }}
                          onMouseEnter={() => setHoveredMenuName(menuList.path)}
                          onMouseLeave={() => setHoveredMenuName("")}
                        >
                          <ListItemButton
                            onClick={() => handleMainMenuItemClick(menuList)}
                            sx={{
                              transition: "none",
                              "&:hover": {
                                transition: "none",
                                "& .MuiListItemIcon-root, & .MuiTypography-root":
                                  {
                                    color:
                                      theme.palette.mainMenuNav.hoverColorNavBg,
                                    transition: "none",
                                  },
                              },
                              "& .MuiListItemIcon-root": {
                                color:
                                  selectedSubmenu?.startsWith(menuList.path) ||
                                  hoveredMenuName === menuList.path ||
                                  currentMenuName === menuList.path
                                    ? theme.palette.primary.main
                                    : currentMenuName === menuList.path
                                    ? theme.palette.mainMenuNav.hoverColorNavBg
                                    : theme.palette.mainMenuNav.hoverColorNavBg,
                                opacity:
                                  selectedSubmenu?.startsWith(menuList.path) ||
                                  hoveredMenuName === menuList.path ||
                                  currentMenuName === menuList.path
                                    ? 1
                                    : 0.6,
                                transition: "none",
                              },
                              "& .MuiTypography-root": {
                                color:
                                  selectedSubmenu?.startsWith(menuList.path) ||
                                  hoveredMenuName === menuList.path ||
                                  currentMenuName === menuList.path
                                    ? theme.palette.mainMenuNav.hoverColorNavBg
                                    : currentMenuName === menuList.path
                                    ? theme.palette.mainMenuNav.hoverColorNavBg
                                    : theme.palette.mainMenuNav.hoverColorNavBg,
                                opacity:
                                  selectedSubmenu?.startsWith(menuList.path) ||
                                  hoveredMenuName === menuList.path ||
                                  currentMenuName === menuList.path
                                    ? 1
                                    : 0.6,
                                transition: "none",
                              },
                              borderLeft:
                                selectedSubmenu?.startsWith(menuList.path) ||
                                currentMenuName === menuList.path
                                  ? `3px solid ${theme.palette.primary.main}`
                                  : currentMenuName === menuList.path
                                  ? "none"
                                  : "none",
                            }}
                          >
                            <ListItemIcon
                              className={classes.listItemIcomMainMenu}
                            >
                              {menuList.icon as React.ReactNode}
                            </ListItemIcon>
                            <ListItemText>{menuList.title}</ListItemText>
                            <ListItemIcon
                              className={classes.listItemIcomMainMenu}
                              sx={{
                                display: "flex",
                                textAlign: "end",
                                justifyContent: "flex-end",
                              }}
                            >
                              {menuList.submenus &&
                                (expandMenu === menuList.title ? (
                                  <ExpandLessIcon
                                    sx={{
                                      fontSize: "18px",
                                      display: "flex",
                                      textAlign: "end",
                                      justifyContent: "flex-end",
                                      "&:hover": {
                                        color:
                                          theme.palette.mainMenuNav
                                            .hoverColorNavBg,
                                      },
                                      color:
                                        selectedSubmenu?.startsWith(
                                          menuList.path
                                        ) ||
                                        hoveredMenuName === menuList.path ||
                                        currentMenuName === menuList.path
                                          ? theme.palette.mainMenuNav
                                              .hoverColorNavBg
                                          : currentMenuName === menuList.path
                                          ? theme.palette.mainMenuNav
                                              .hoverColorNavBg
                                          : theme.palette.mainMenuNav
                                              .hoverColorNavBg,
                                      opacity:
                                        selectedSubmenu?.startsWith(
                                          menuList.path
                                        ) ||
                                        hoveredMenuName === menuList.path ||
                                        currentMenuName === menuList.path
                                          ? 1
                                          : 0.6,
                                    }}
                                  />
                                ) : (
                                  <ExpandMoreIcon
                                    sx={{
                                      fontSize: "18px",
                                      display: "flex",
                                      textAlign: "end",
                                      justifyContent: "flex-end",
                                      "&:hover": {
                                        color:
                                          theme.palette.mainMenuNav
                                            .hoverColorNavBg,
                                      },
                                      color:
                                        selectedSubmenu?.startsWith(
                                          menuList.path
                                        ) ||
                                        hoveredMenuName === menuList.path ||
                                        currentMenuName === menuList.path
                                          ? theme.palette.mainMenuNav
                                              .hoverColorNavBg
                                          : currentMenuName === menuList.path
                                          ? theme.palette.mainMenuNav
                                              .hoverColorNavBg
                                          : theme.palette.mainMenuNav
                                              .hoverColorNavBg,
                                      opacity:
                                        selectedSubmenu?.startsWith(
                                          menuList.path
                                        ) ||
                                        hoveredMenuName === menuList.path ||
                                        currentMenuName === menuList.path
                                          ? 1
                                          : 0.6,
                                    }}
                                  />
                                ))}
                            </ListItemIcon>
                          </ListItemButton>
                          {menuList.submenus && (
                            <Collapse
                              in={expandMenu === menuList.title}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List>
                                {menuList.submenus.map((submenu) => (
                                  <ListItem
                                    disablePadding
                                    key={submenu.title}
                                    onMouseEnter={() =>
                                      setHoveredSubmenu(submenu.path)
                                    }
                                    onMouseLeave={() => setHoveredSubmenu("")}
                                  >
                                    <ListItemButton
                                      onClick={() =>
                                        handleSubmenuItemClicked(submenu)
                                      }
                                      sx={{
                                        pl: "72px",
                                        "&:hover": {
                                          "& .MuiListItemIcon-root, & .MuiTypography-root":
                                            {
                                              color:
                                                theme.palette.mainMenuNav
                                                  .hoverColorNavBg,
                                            },
                                        },
                                        "& .MuiTypography-root": {
                                          color:
                                            hoveredSubmenu === submenu.path ||
                                            currentMenuName === submenu.path
                                              ? theme.palette.mainMenuNav
                                                  .hoverColorNavBg
                                              : theme.palette.mainMenuNav
                                                  .hoverColorNavBg,
                                          opacity:
                                            hoveredSubmenu === submenu.path ||
                                            currentMenuName === submenu.path
                                              ? 1
                                              : 0.6,
                                        },
                                      }}
                                    >
                                      <ListItemText>
                                        <Typography>{submenu.title}</Typography>
                                      </ListItemText>
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                              </List>
                            </Collapse>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  )}
                </React.Fragment>
              ))}
            </DrawerContent>
          </IsNotMobileDrawer>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <AppBar position="fixed" className={classes.mobileTopbar}>
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                justifyContent="space-between"
                sx={{ width: "100%" }}
                alignItems="center"
              >
                <Grid item md={1} sm={1}>
                  <IconButton
                    onClick={handleMobileDrawerToggle}
                    edge="start"
                    sx={{
                      ...(mobileDrawerOpen && { display: "none" }),
                    }}
                    className={classes.topbarButton}
                  >
                    {mobileDrawerOpen ? (
                      <CloseIcon className={classes.topbarIcon} />
                    ) : (
                      <MenuIcon className={classes.topbarIcon} />
                    )}
                  </IconButton>
                </Grid>
                <Grid item md={2} sm={2}>
                  <Typography width={"100%"} className={classes.h4}>
                    {topBarTitle}
                  </Typography>
                </Grid>
                <Grid item md={9} sm={9} justifyContent="end" alignItems="end">
                  <Grid
                    container
                    spacing={1}
                    alignItems="end"
                    justifyContent="flex-end"
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid item>
                      <IconButton
                        onClick={handleClickNotificationPopUp}
                        className={classes.topbarButton}
                        aria-haspopup="true"
                      >
                        <MdOutlineNotificationsNone
                          className={classes.topbarIcon}
                        />
                      </IconButton>
                      <IconButton
                        onClick={toggleTheme}
                        aria-haspopup="true"
                        className={classes.topbarButton}
                      >
                        {mode == "light" ? (
                          <MdDarkMode className={classes.topbarIcon} />
                        ) : (
                          <MdDarkMode className={classes.topbarIcon} />
                        )}
                      </IconButton>
                      <IconButton
                        onClick={handleClickProfilePopUp}
                        className={classes.topbarButton}
                        aria-haspopup="true"
                      >
                        <Avatar
                          alt="Travis Howard"
                          src={UserImg}
                          className={classes.topbarIconProfile}
                        />
                      </IconButton>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          display: "none",
                        },
                      }}
                    >
                      <div className={classes.appSearch}>
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CiSearch
                                  className={classes.searchWidgetIcon}
                                />
                              </InputAdornment>
                            ),
                            classes: {
                              root: classes.formControl,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                          fullWidth
                          placeholder="Search .."
                          variant="outlined"
                          size="small"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Profile
                profile={profilePopUp}
                onClose={handleCloseProfilePopUp}
                open={openProfilePopUp}
              />
              <Notification
                notification={notificationPopUp}
                onClose={handleCloseNotificationPopUp}
                open={openNotificationPopUp}
              />
            </Toolbar>

            <>
              <MobileDrawer
                open={mobileDrawerOpen}
                onClose={handleMobileDrawerClose}
               
              >
                <DrawerHeader>
                  {open && (
                    <img
                      src={LogoMain}
                      alt="AttoDesk Login"
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={handleImgClick}
                    />
                  )}
                  <IconButton
                    className={classes.topbarButton}
                    onClick={handleMobileDrawerClose}
                  >
                    {theme.direction === "rtl" ? (
                      <ChevronRightIcon className={classes.topbarIcon} />
                    ) : (
                      <ChevronLeftIcon className={classes.topbarIcon} />
                    )}
                  </IconButton>
                </DrawerHeader>
                <DrawerContent>
                  {MainMenuList.map((ml, index) => (
                    <React.Fragment key={index}>
                      <Box>
                        {open && (
                          <Typography className={classes.menuTitle}>
                            {ml.comomTitle}
                          </Typography>
                        )}
                      </Box>
                      {ml.menuList && (
                        <List>
                          {ml.menuList.map((menuList) => (
                            <ListItem
                              button
                              key={menuList.title}
                              disablePadding
                              sx={{
                                display: "block",
                              }}
                              onMouseEnter={() =>
                                setHoveredMenuName(menuList.path)
                              }
                              onMouseLeave={() => setHoveredMenuName("")}
                            >
                              <ListItemButton
                                onClick={() =>
                                  handleMainMenuItemClick(menuList)
                                }
                                sx={{
                                  transition: "none",
                                  "&:hover": {
                                    transition: "none",
                                    "& .MuiListItemIcon-root, & .MuiTypography-root":
                                      {
                                        color:
                                          theme.palette.mainMenuNav
                                            .hoverColorNavBg,
                                        transition: "none",
                                      },
                                  },
                                  "& .MuiListItemIcon-root": {
                                    color:
                                      selectedSubmenu?.startsWith(
                                        menuList.path
                                      ) ||
                                      hoveredMenuName === menuList.path ||
                                      currentMenuName === menuList.path
                                        ? theme.palette.primary.main
                                        : currentMenuName === menuList.path
                                        ? theme.palette.mainMenuNav
                                            .hoverColorNavBg
                                        : theme.palette.mainMenuNav
                                            .hoverColorNavBg,
                                    opacity:
                                      selectedSubmenu?.startsWith(
                                        menuList.path
                                      ) ||
                                      hoveredMenuName === menuList.path ||
                                      currentMenuName === menuList.path
                                        ? 1
                                        : 0.6,
                                    transition: "none",
                                  },
                                  "& .MuiTypography-root": {
                                    color:
                                      selectedSubmenu?.startsWith(
                                        menuList.path
                                      ) ||
                                      hoveredMenuName === menuList.path ||
                                      currentMenuName === menuList.path
                                        ? theme.palette.mainMenuNav
                                            .hoverColorNavBg
                                        : currentMenuName === menuList.path
                                        ? theme.palette.mainMenuNav
                                            .hoverColorNavBg
                                        : theme.palette.mainMenuNav
                                            .hoverColorNavBg,
                                    opacity:
                                      selectedSubmenu?.startsWith(
                                        menuList.path
                                      ) ||
                                      hoveredMenuName === menuList.path ||
                                      currentMenuName === menuList.path
                                        ? 1
                                        : 0.6,
                                    transition: "none",
                                  },
                                  borderLeft:
                                    selectedSubmenu?.startsWith(
                                      menuList.path
                                    ) || currentMenuName === menuList.path
                                      ? `3px solid ${theme.palette.primary.main}`
                                      : currentMenuName === menuList.path
                                      ? "none"
                                      : "none",
                                }}
                              >
                                <ListItemIcon
                                  className={classes.listItemIcomMainMenu}
                                >
                                  {menuList.icon as React.ReactNode}
                                </ListItemIcon>
                                <ListItemText>{menuList.title}</ListItemText>
                                <ListItemIcon
                                  className={classes.listItemIcomMainMenu}
                                  sx={{
                                    display: "flex",
                                    textAlign: "end",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {menuList.submenus &&
                                    (expandMenu === menuList.title ? (
                                      <ExpandLessIcon
                                        sx={{
                                          fontSize: "18px",
                                          display: "flex",
                                          textAlign: "end",
                                          justifyContent: "flex-end",
                                          "&:hover": {
                                            color:
                                              theme.palette.mainMenuNav
                                                .hoverColorNavBg,
                                          },
                                          color:
                                            selectedSubmenu?.startsWith(
                                              menuList.path
                                            ) ||
                                            hoveredMenuName === menuList.path ||
                                            currentMenuName === menuList.path
                                              ? theme.palette.mainMenuNav
                                                  .hoverColorNavBg
                                              : currentMenuName ===
                                                menuList.path
                                              ? theme.palette.mainMenuNav
                                                  .hoverColorNavBg
                                              : theme.palette.mainMenuNav
                                                  .hoverColorNavBg,
                                          opacity:
                                            selectedSubmenu?.startsWith(
                                              menuList.path
                                            ) ||
                                            hoveredMenuName === menuList.path ||
                                            currentMenuName === menuList.path
                                              ? 1
                                              : 0.6,
                                        }}
                                      />
                                    ) : (
                                      <ExpandMoreIcon
                                        sx={{
                                          fontSize: "18px",
                                          display: "flex",
                                          textAlign: "end",
                                          justifyContent: "flex-end",
                                          "&:hover": {
                                            color:
                                              theme.palette.mainMenuNav
                                                .hoverColorNavBg,
                                          },
                                          color:
                                            selectedSubmenu?.startsWith(
                                              menuList.path
                                            ) ||
                                            hoveredMenuName === menuList.path ||
                                            currentMenuName === menuList.path
                                              ? theme.palette.mainMenuNav
                                                  .hoverColorNavBg
                                              : currentMenuName ===
                                                menuList.path
                                              ? theme.palette.mainMenuNav
                                                  .hoverColorNavBg
                                              : theme.palette.mainMenuNav
                                                  .hoverColorNavBg,
                                          opacity:
                                            selectedSubmenu?.startsWith(
                                              menuList.path
                                            ) ||
                                            hoveredMenuName === menuList.path ||
                                            currentMenuName === menuList.path
                                              ? 1
                                              : 0.6,
                                        }}
                                      />
                                    ))}
                                </ListItemIcon>
                              </ListItemButton>
                              {menuList.submenus && (
                                <Collapse
                                  in={expandMenu === menuList.title}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List>
                                    {menuList.submenus.map((submenu) => (
                                      <ListItem
                                        disablePadding
                                        key={submenu.title}
                                        onMouseEnter={() =>
                                          setHoveredSubmenu(submenu.path)
                                        }
                                        onMouseLeave={() =>
                                          setHoveredSubmenu("")
                                        }
                                      >
                                        <ListItemButton
                                          onClick={() =>
                                            handleSubmenuItemClicked(submenu)
                                          }
                                          sx={{
                                            pl: "72px",
                                            "&:hover": {
                                              "& .MuiListItemIcon-root, & .MuiTypography-root":
                                                {
                                                  color:
                                                    theme.palette.mainMenuNav
                                                      .hoverColorNavBg,
                                                },
                                            },
                                            "& .MuiTypography-root": {
                                              color:
                                                hoveredSubmenu ===
                                                  submenu.path ||
                                                currentMenuName === submenu.path
                                                  ? theme.palette.mainMenuNav
                                                      .hoverColorNavBg
                                                  : theme.palette.mainMenuNav
                                                      .hoverColorNavBg,
                                              opacity:
                                                hoveredSubmenu ===
                                                  submenu.path ||
                                                currentMenuName === submenu.path
                                                  ? 1
                                                  : 0.6,
                                            },
                                          }}
                                        >
                                          <ListItemText>
                                            <Typography>
                                              {submenu.title}
                                            </Typography>
                                          </ListItemText>
                                        </ListItemButton>
                                      </ListItem>
                                    ))}
                                  </List>
                                </Collapse>
                              )}
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </React.Fragment>
                  ))}
                </DrawerContent>
              </MobileDrawer>
            </>
          </AppBar>
        </Box>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
        }}
      >
        <PaddingBody />
        {children}
      </Box>
    </Box>
  );
}
