import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, TextField, Button, Box, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStyles } from "../../../theme/appStyles";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useNotifier } from "../../../Core/Notifier";
import CircularProgress from "@mui/material/CircularProgress";

const EditExpenseForm = () => {
  const { id } = useParams(); // Get the expense ID from the URL
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [isHST, setIsHST] = useState(false);
  const [total, setTotal] = useState(0);
  const [hstAmount, setHstAmount] = useState(0);
  const { showErrorMessage, showMessage } = useNotifier();
  const [loading, setLoading] = useState(false); 
  const [expense, setExpense] = useState<any>(null); // State to store fetched expense data
  const [category_name, setCategory_name] = useState('');
  
 
  const formik = useFormik({
    initialValues: {
      category_name: expense?.category_name || "",
     
    },
    validationSchema: yup.object({
      category_name: yup.string().required("Category name is required"),
    }),
    
    onSubmit: async (values) => { 
      setLoading(true);
      try {
        const formData = new FormData();
      
        formData.append("category_name", values.category_name);

        const response = await axios.post(`${API_URL}/expense-category/${id}`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        showMessage("Expense Category updated successfully!");
        navigate(`/expense-category/list`); // Redirect after update
      } catch (error) {
        showErrorMessage("An error occurred while updating the expense category.");
      } finally {
        setLoading(false);
      }
    },
  });
  

  useEffect(() => {
    // Fetch expense details based on ID
    const fetchExpenseData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/expense-category/${id}`);
        const fetchedExpense = response.data; // Populate the form with fetched data
        setExpense(fetchedExpense);
        setCategory_name(fetchedExpense.category_name);
      
  
        // Set formik values manually if expense is loaded
        formik.setValues({
          category_name: fetchedExpense.category_name,
        });
        
      } catch (error) {
        showErrorMessage("Failed to fetch expense category data.");
      } finally {
        setLoading(false);
      }
    };
  
    if (id) {
      fetchExpenseData();
    }
  }, []);


  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card>
            <Grid container justifyContent="space-between" sx={{ borderBottom: 2, padding: 2 }}>
              <Grid item lg={12} md={12} sm={12} xs={12} pt={2} alignContent={"center"}>
                <Typography className={classes.cardTitle}>Edit Expense</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item lg={9} md={9} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                    {/* Date Field */}
                    <Grid item xs={7}>
                      <Typography variant="subtitle1">Category Name</Typography>
                      <TextField
                        placeholder="Enter category name"
                        size="small"
                        {...formik.getFieldProps("category_name")}
                      
                        fullWidth
                      />
                    </Grid>
                    {/* Description Field */}
                   
                  </Grid>
                </Card>
              </Grid>

              {/* Submit and Cancel Buttons */}
              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="outlined"
                        className={classes.addButton}
                        startIcon={<CloseIcon />}
                        onClick={() => formik.resetForm()}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>
                      <Button
                        variant="contained"
                        className={classes.addButton}
                        startIcon={<SaveAltIcon />}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : "Submit"}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditExpenseForm;
