import React, { useMemo } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreateCoursingMutation,
  useCreateNoteTemplateMutation,
} from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { ICoursing, INoteTemplate } from "../../../Api/Interface/api.interface";
import { useStyles } from "../../../theme/appStyles";

interface INoteTemplatepopup {
  openModel?: boolean;
  handleCloseDialog: (close: boolean) => void;
  data?: INoteTemplate;
}

const Note = ({
  openModel = false,
  handleCloseDialog,
  data,
}: INoteTemplatepopup) => {
  const classes = useStyles();
  const [open] = React.useState(openModel);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [newCoursing, { isLoading }] = useCreateNoteTemplateMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const handleClose = () => {
    handleCloseDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      id: data?.id,
      templateName: data?.note_title,
      subject: data?.subject,
      message: data?.note_message,
    },
    onSubmit: async (values) => {
      try {
        const temData = {
          id: values?.id,
          note_title: values.templateName,
          subject: values.subject,
          note_message: values.message,
        };
        if (!data) {
          delete values.id;
        }

        const addCompanyResponse = await newCoursing(temData).unwrap();
        if (!addCompanyResponse.status) {
          showErrorMessage(addCompanyResponse.message);
        } else {
          showMessage(addCompanyResponse.message);
          handleClose();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return (
      formik.values.templateName !== "" &&
      formik.values.templateName !== undefined &&
      formik.values.subject !== "" &&
      formik.values.subject !== undefined &&
      formik.values.message !== "" &&
      formik.values.message !== undefined
    );
  }, [formik.values.templateName, formik.values.subject, formik.values.message]);


  const gridHeight = isMobile ? "30px" : "400px";

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: "1700px",
        },
      }}
    >
      <Grid className={classes.Dialog}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={5}
                sx={{ borderBottom: 2, padding: 2 }}
                className={classes.titleGrid}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  pt={2}
                  alignContent={"center"}
                >
                  <Typography className={classes.cardTitle}>
                    Edit Note Template
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Note Template Information
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Template Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                           placeholder="Enter Template Name"
                              size="small"
                              {...formik.getFieldProps("templateName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Subject
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                           placeholder="Enter Subject"
                              size="small"
                              {...formik.getFieldProps("subject")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Message
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Message"
                              size="small"
                              multiline
                              rows={8}
                              {...formik.getFieldProps("message")}
                              className={classes.discriptionTextField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                  <Card className={classes.card}>
                    <Grid container spacing={3} p={"24px"}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          className={classes.addButton}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Box m={0.5}></Box>

                        <Button
                          variant="contained"
                          startIcon={<SaveAltIcon />}
                          className={classes.addButton}
                          onClick={() => formik.handleSubmit()}
                          disabled={!formValid || isLoading}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Note;
