import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useCreateCustomerTransactionMutation } from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import {
  ICustomer,
  ICustomerTransaction,
} from "../../../Api/Interface/api.interface";
import { useStyles } from "../../../theme/appStyles";
import {
  CollectionType,
  ReminderMedianType,
  TableColumnTypeLevel,
  TransactionCollectionDate,
} from "../../../Core/Enum/enum";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CustomSwitch from "../../../Components/Switch/CustomSwitch";

interface ITaxpopup {
  openModel?: boolean;
  handleCloseDialog: (close: boolean) => void;
  data?: ICustomerTransaction;
  customerId?: string;
  projectId?: string;
}

const Customer = ({
  openModel = false,
  handleCloseDialog,
  data,
  customerId,
  projectId,
}: ITaxpopup) => {
  const classes = useStyles();
  const [open] = React.useState(openModel);
  const [newCustomer, { isLoading }] = useCreateCustomerTransactionMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const [error, setError] = useState<string | null>(null);
  const handleClose = () => {
    handleCloseDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      id: data?.id,
      transactionName: data?.transactionName || "",
      customerId: data?.customerId || customerId,
      projectId: data?.projectId || projectId,
      transactionAmount: data?.transactionAmount,
      transactionDescription: data?.transactionDescription || "",
      transactionCollection: data?.transactionCollection || "",
      transactionCollectionDate: data?.transactionCollection
        ? data.transactionCollection === CollectionType.Custom
          ? dayjs(data.transactionCollectionDate)
          : String(data.transactionCollectionDate)
        : null,
      istransactionReminder: data?.istransactionReminder || false,
      transactionReminderDate: data?.transactionReminderDate
        ? dayjs(data.transactionReminderDate)
        : null,
      transactionReminderMedia: data?.transactionReminderMedia || [],
    },
    onSubmit: async (values) => {
      try {
        const temData = {
          id: values?.id,
          transactionName: values.transactionName,
          customerId: values.customerId,
          projectId: values.projectId,
          transactionAmount: values.transactionAmount,
          transactionDescription: values.transactionDescription,
          transactionCollection: values.transactionCollection,
          transactionCollectionDate: values.transactionCollectionDate,
          istransactionReminder: values.istransactionReminder,
          transactionReminderDate: values.transactionReminderDate,
          transactionReminderMedia: values.transactionReminderMedia,
        };

        if (!data) {
          delete temData.id;
        }

        const addCompanyResponse = await newCustomer(temData).unwrap();
        if (!addCompanyResponse.status) {
          showErrorMessage(addCompanyResponse.message);
        } else {
          showMessage(addCompanyResponse.message);
          handleClose();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return formik.values.transactionAmount === "" ||
      formik.values.transactionAmount === undefined ||
      formik.values.transactionName === "" ||
      formik.values.transactionName === undefined
      ? false
      : true;
  }, [formik]);

  const handleTransactionCollectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    formik.setFieldValue("transactionCollection", value);

    // Reset transactionCollectionDate to null when changing transactionCollection
    if (value == CollectionType.Custom) {
      formik.setFieldValue("transactionCollectionDate", null);
    }

    if (value !== CollectionType.Custom) {
      formik.setFieldValue("transactionCollectionDate", "");
    }
  };

  const isCustomCollection =
    formik.values.transactionCollection === CollectionType.Custom;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (formik.values.transactionReminderMedia.includes(value)) {
      formik.setFieldValue(
        "transactionReminderMedia",
        formik.values.transactionReminderMedia.filter((item) => item !== value)
      );
    } else {
      formik.setFieldValue("transactionReminderMedia", [
        ...formik.values.transactionReminderMedia,
        value,
      ]);
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: "1700px",
        },
      }}
    >
      <Grid className={classes.Dialog}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={5}
                sx={{ borderBottom: 2, padding: 2 }}
                className={classes.titleGrid}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  pt={2}
                  alignContent={"center"}
                >
                  <Typography className={classes.cardTitle}>
                    Customer 
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Customer Subscription Information
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Subscription Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Subscription Name"
                              size="small"
                              {...formik.getFieldProps("transactionName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">Amount</Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Subscription Amount"
                              size="small"
                              {...formik.getFieldProps("transactionAmount")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Collection
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              select
                              size="small"
                              fullWidth
                              defaultValue=""
                              {...formik.getFieldProps("transactionCollection")}
                              onChange={handleTransactionCollectionChange}
                            >
                              {Object.entries(CollectionType).map(
                                ([key, value]) => (
                                  <MenuItem key={value} value={value}>
                                    {key}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      {isCustomCollection && (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Collection Date
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={
                                    formik.values.transactionCollectionDate
                                      ? dayjs(
                                          formik.values
                                            .transactionCollectionDate as
                                            | string
                                            | Dayjs
                                        )
                                      : null
                                  }
                                  onChange={(date) =>
                                    formik.setFieldValue(
                                      "transactionCollectionDate",
                                      date
                                    )
                                  }
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      sx: { width: "100%" },
                                      InputProps: {
                                        sx: { fontSize: 14 },
                                      },
                                      InputLabelProps: {
                                        sx: { fontSize: 14 },
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {!isCustomCollection && (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Collection Date
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                select
                                size="small"
                                className={classes.textField}
                                SelectProps={{
                                  native: false,
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 40 * 5,
                                      },
                                    },
                                  },
                                }}
                                defaultValue=""
                                InputLabelProps={{ shrink: true }}
                                {...formik.getFieldProps(
                                  "transactionCollectionDate"
                                )}
                              >
                                <MenuItem value="" disabled>
                                  Select some option
                                </MenuItem>
                                {Object.entries(TransactionCollectionDate).map(
                                  ([key, value], index) => (
                                    <MenuItem key={index} value={value}>
                                      {key}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Reminder
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CustomSwitch
                              color="primary"
                              sx={{ mr: 2 }}
                              formik={formik}
                              name="istransactionReminder"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {formik.values.istransactionReminder == true && (
                        <>
                          <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container alignItems="center">
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                  Reminder Media
                                </Typography>
                              </Grid>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                {Object.entries(ReminderMedianType).map(
                                  ([key, value]) => (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          value={value}
                                          checked={formik.values.transactionReminderMedia.includes(
                                            value
                                          )}
                                          onChange={handleCheckboxChange}
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 22,
                                            },
                                          }}
                                        />
                                      }
                                      label={value}
                                      sx={{ fontSize: 14 }}
                                    />
                                  )
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center">
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                  Reminder Date
                                </Typography>
                              </Grid>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    value={
                                      formik.values.transactionReminderDate
                                    }
                                    onChange={(date) =>
                                      formik.setFieldValue(
                                        "transactionReminderDate",
                                        date
                                      )
                                    }
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        sx: { width: "100%" },
                                        InputProps: {
                                          sx: { fontSize: 14 },
                                        },
                                        InputLabelProps: {
                                          sx: { fontSize: 14 },
                                        },
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Description
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Description .."
                              size="small"
                              multiline
                              rows={8}
                              {...formik.getFieldProps(
                                "transactionDescription"
                              )}
                              className={classes.discriptionTextField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                  <Card className={classes.card}>
                    <Grid container spacing={3} p={"24px"}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          className={classes.addButton}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Box m={0.5}></Box>

                        <Button
                          variant="contained"
                          startIcon={<SaveAltIcon />}
                          className={classes.addButton}
                          onClick={() => formik.handleSubmit()}
                          disabled={!formValid || isLoading}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Customer;
