import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Box,
  Button,
  TextField,
  InputAdornment,
  Card,
  Typography,
} from "@mui/material";
import {
  useDeleteCustomerMutation,
  useGetCompanyQuery,
  useGetCustomerQuery,
  useGetWorkOrderQuery,
} from "../../../Api/attoDeskApi";
import {
  ICompanyDetails,
  IQuoteWorkOrder,
} from "../../../Api/Interface/api.interface";
import DeletePopup from "../../../Components/Delete/DeletePopup";
import useDebounce from "../../../Components/SearchDebonce/useDebounce";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../theme/appStyles";
import { useNotifier } from "../../../Core/Notifier";
import { CiSearch } from "react-icons/ci";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import CustomCheckbox from "../../../Components/Checkbox.tsx";
import Pagination from "../../../Components/Pagination";
import { WorkOrderStatus } from "../../../Core/Enum/enum";

const getStatusLabel = (statusCode: number): string => {
  switch (statusCode) {
    case WorkOrderStatus.Paid:
      return "Paid";
    case WorkOrderStatus.Cancel:
      return "Canceled";
    case WorkOrderStatus.Pending:
      return "Pending";
    default:
      return "Unknown Status";
  }
};

const getStatusColor = (statusCode: number): string => {
  switch (statusCode) {
    case WorkOrderStatus.Paid:
      return "#4caf50";
    case WorkOrderStatus.Cancel:
      return "#f44336";
    case WorkOrderStatus.Pending:
      return "#ff9800";
    default:
      return "#9e9e9e";
  }
};

const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { showErrorMessage, showMessage } = useNotifier();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedCoursing, setSelectedCoursing] =
    useState<IQuoteWorkOrder | null>(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [coursingToDelete, setCoursingToDelete] =
    useState<IQuoteWorkOrder | null>(null);

  const { data, isLoading, isError, refetch } = useGetWorkOrderQuery({
    searchText: currentSearchQuery,
    id: "",
  });

  const { data: companyData, isLoading: companyLoading } = useGetCompanyQuery({
    searchText: "",
    id: "",
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (debouncedSearchQuery.length >= 4 || debouncedSearchQuery === "") {
      setCurrentSearchQuery(debouncedSearchQuery);
      refetch();
    }
  }, [debouncedSearchQuery]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (coursing: IQuoteWorkOrder) => {
    setSelectedCoursing(coursing);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedCoursing(null);
    setOpenDialog(false);
  };

  const handleOpenDeletePopup = (coursing: IQuoteWorkOrder) => {
    setCoursingToDelete(coursing);
    setOpenDeleteCategory(true);
  };

  const handleCloseDeletePopup = () => {
    setCoursingToDelete(null);
    setOpenDeleteCategory(false);
  };

  const [deleteCategory] = useDeleteCustomerMutation();
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategory(id).unwrap();
      if (response.status) {
        showMessage("Deleted successfully");
        setOpenDeleteCategory(false);
      } else {
        showErrorMessage("Failed to delete the customer");
      }
    } catch (error) {
      showErrorMessage("Failed to delete the customer");
    }
  };

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );

  const coursings: IQuoteWorkOrder[] = Array.isArray(data?.data)
    ? (data?.data as IQuoteWorkOrder[])
    : [];

  const handleSearch = () => {
    setCurrentSearchQuery(searchQuery);
  };

  const resetFields = () => {
    setSearchQuery("");
    setCurrentSearchQuery("");
    refetch();
  };

  const handleAddProductClick = () => {
    navigate("/quote/create");
  };

  return (
    <Card className={classes.card}>
      <Grid
        container
        justifyContent={"space-between"}
        spacing={5}
        sx={{ borderBottom: 2, padding: 2 }}
        className={classes.titleGrid}
      >
        <Grid item lg={4} md={4} sm={4} xs={4} pt={2} alignContent={"center"}>
          <Typography className={classes.cardTitle}>New Quotes</Typography>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          xs={8}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            sx={{ display: "flex" }}
          >
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CiSearch className={classes.searchWidgetIcon} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.formControlList,
                  },
                }}
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Work Order Number .."
                sx={{ width: "100%" }}
                size="small"
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                className={classes.addButton}
                onClick={handleAddProductClick}
              >
                Add Quotes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeaderRow}>
              <TableRow>
                <TableCell className={classes.tablheader}>
                  <CustomCheckbox name="checkbox" />
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>#</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Work Order Number</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Issue From</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Issue For</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Status</strong>
                </TableCell>
                <TableCell
                  className={classes.tablheader}
                  sx={{ textAlign: "right" }}
                >
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isError || coursings.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={20} align="center">
                    {isError ? "Error fetching data" : "No data available"}
                  </TableCell>
                </TableRow>
              ) : (
                coursings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: IQuoteWorkOrder, index) => {
                    const statusLabel = getStatusLabel(
                      row.quoteWorkOrderStatus
                    );
                    const statusColor = getStatusColor(
                      row.quoteWorkOrderStatus
                    );

                    return (
                      <TableRow key={row.id} className={classes.tableRowHover}>
                        <TableCell className={classes.tablCell}>
                          <CustomCheckbox name="checkbox" />
                        </TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tablCell}
                        >
                          {row.quoteWorkOrderNo}
                        </TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
                          {row.company.companyName || null || ""}
                        </TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
                          <Grid container >
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              {row.customer?.customerCompanyName || null || ""}
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              {"(" +
                                (row.customer?.customerFirstName || "") +
                                " " +
                                (row.customer?.customerLastName || "") +
                                ")"}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
                          <Button
                            className={classes.statusButtonList}
                            style={{
                              color: statusColor,
                              border: "solid 1px",
                              borderColor: statusColor,
                              borderRadius: "8px",
                            }}
                          >
                            {statusLabel}
                          </Button>
                        </TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
                          <Grid
                            container
                            spacing={1}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Grid item>
                              <Button
                                variant="contained"
                                className={classes.viewButtonList}
                                onClick={() =>
                                  navigate(`/work-order/history/${row.id}`)
                                }
                              >
                                <VisibilitySharpIcon
                                  className={classes.viewListIcon}
                                />
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                className={classes.editButtonList}
                                onClick={() =>
                                  navigate(`/quote-history/${row.id}`)
                                }
                              >
                                <EditSharpIcon
                                  className={classes.editListIcon}
                                />
                              </Button>
                            </Grid>

                            {/* <Grid item>
                              <Button
                                variant="contained"
                                className={classes.deleteButtonList}
                                // onClick={() => handleOpenDeletePopup(row)}
                              >
                                <DeleteOutlineSharpIcon
                                  className={classes.deleteButtonList}
                                />
                              </Button>
                            </Grid> */}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
          <Grid
            spacing={5}
            p={"18px"}
            py={4}
            justifyContent={"end"}
            container
            textAlign={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid
              sx={{
                textAlign: "end",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pagination
                count={coursings.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </TableContainer>

        {coursingToDelete && (
          <DeletePopup
            open={openDeleteCategory}
            handleCloseDelete={handleCloseDeletePopup}
            onConfirm={async () => {
              await handleDelete(coursingToDelete.id.toString());
            }}
            title="Delete Quote"
            content={`Are you sure you want to delete "${coursingToDelete.quoteWorkOrderNo}"?`}
          />
        )}
      </Grid>
    </Card>
  );
};

export default Index;
