import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreateDepartmentMutation,
  useGetPrinterQuery,
} from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { IPrinter } from "../../../Api/Interface/api.interface";
import NewPrinter from "../../Printer/Edit/index";
import AddIcon from "@mui/icons-material/Add";
import { useStyles } from "../../../theme/appStyles";

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [newDepartment, { isLoading }] = useCreateDepartmentMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const {
    data: printerData,
    isLoading: printerLoading,
    refetch: refetchPrinters,
  } = useGetPrinterQuery({
    searchText: "",
  });
  const [openPrinter, setOpenPrinter] = useState(false);
  const [newPrinterAdded, setNewPrinterAdded] = useState(false);

  const printerList = useMemo(() => {
    return printerData?.data as IPrinter[];
  }, [printerData?.data]);

  const formik = useFormik({
    initialValues: {
      departmentName: "",
      description: "",
      DepartmentPrinterIds: [],
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const temData = {
          departmentName: values.departmentName,
          description: values.description,
          DepartmentPrinterIds: values.DepartmentPrinterIds,
        };

        const addCompanyResponse = await newDepartment(temData).unwrap();
        if (!addCompanyResponse.status) {
          showErrorMessage(addCompanyResponse.message);
        } else {
          showMessage(addCompanyResponse.message);
          resetForm();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return (
      formik.values.departmentName !== "" &&
      formik.values.departmentName !== undefined
    );
  }, [formik]);

  const handlePrinterDialogClose = () => {
    setOpenPrinter(false);
    setNewPrinterAdded(true);
    refetchPrinters();
  };

  const gridHeight = isMobile ? "30px" : "400px";

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.stepperCard}>
            <Grid
              container
              justifyContent={"space-between"}
              spacing={5}
              sx={{ borderBottom: 2, padding: 2 }}
              className={classes.titleGrid}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pt={2}
                alignContent={"center"}
              >
                <Typography className={classes.cardTitle}>
                  Create Department
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                        Department Information
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} p={"24px"}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Department Name
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Department Name"
                            size="small"
                            {...formik.getFieldProps("departmentName")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Department Printers
                          </Typography>
                        </Grid>
                        <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            SelectProps={{
                              multiple: true,
                              native: false,
                            }}
                            defaultValue={[]}
                            InputLabelProps={{ shrink: true }}
                            {...formik.getFieldProps("DepartmentPrinterIds")}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              formik.setFieldValue(
                                "DepartmentPrinterIds",
                                typeof value === "string"
                                  ? value.split(",")
                                  : value
                              );
                            }}
                          >
                            {printerList && printerList.length > 0 ? (
                              printerList.map((printer: IPrinter) => (
                                <MenuItem key={printer.id} value={printer.id}>
                                  {printer.printerName}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem
                                value=""
                                disabled
                                style={{ color: "gray" }}
                              >
                                Select an option
                              </MenuItem>
                            )}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          display="flex"
                          sx={{
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            textAlign: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            className={classes.editButton}
                            onClick={() => {
                              setOpenPrinter(true);
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Description
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Description .."
                            size="small"
                            multiline
                            rows={8}
                            {...formik.getFieldProps("description")}
                            className={classes.discriptionTextField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid container spacing={3} p={"24px"}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        className={classes.addButton}
                        onClick={() => formik.resetForm()}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>

                      <Button
                        variant="contained"
                        startIcon={<SaveAltIcon />}
                        className={classes.addButton}
                        onClick={() => formik.handleSubmit()}
                        disabled={!formValid || isLoading}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>

            {openPrinter && (
              <NewPrinter
                handleCloseDialog={handlePrinterDialogClose}
                openModel={openPrinter}
              />
            )}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
