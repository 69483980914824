import React, { useMemo } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreateTaxMutation,
} from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { useStyles } from "../../../theme/appStyles";

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [newTax, { isLoading }] = useCreateTaxMutation();
  const { showErrorMessage, showMessage } = useNotifier();

  const formik = useFormik({
    initialValues: {
      taxName: "",
      taxType: "",
      applyTo: "",
      percentage: "",
      taxCode: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const temData = {
          taxName: values.taxName,
          taxType: values.taxType,
          applyTo: values.applyTo,
          percentage: values.percentage,
          taxCode: values.taxCode,
        };

        const addCompanyResponse = await newTax(temData).unwrap();
        if (!addCompanyResponse.status) {
          showErrorMessage(addCompanyResponse.message);
        } else {
          showMessage("Tax Created successfully");
          resetForm();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return formik.values.taxName === "" ||
      formik.values.taxName === undefined ||
      formik.values.taxCode === "" ||
      formik.values.taxCode === undefined
      ? false
      : true;
  }, [formik]);

  const gridHeight = isMobile ? "30px" : "400px";

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.stepperCard}>
            <Grid
              container
              justifyContent={"space-between"}
              spacing={5}
              sx={{ borderBottom: 2, padding: 2 }}
              className={classes.titleGrid}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pt={2}
                alignContent={"center"}
              >
                <Typography className={classes.cardTitle}>
                  Create Tax
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                        Tax Information
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} p={"24px"}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Name</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Tax Name"
                            size="small"
                            {...formik.getFieldProps("taxName")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Tax Code</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Tax Code"
                            size="small"
                            {...formik.getFieldProps("taxCode")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Tax Type</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            {...formik.getFieldProps("taxType")}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem value="" disabled color="gray">
                              Select an option
                            </MenuItem>
                            <MenuItem value="Percentage">Percentage</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1"> Apply To</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            {...formik.getFieldProps("applyTo")}
                            defaultValue=""
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem value="" disabled color="gray">
                              Select Apply To
                            </MenuItem>
                            <MenuItem value="Goverment">Goverment</MenuItem>
                            <MenuItem value="Private">Private</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Percentage
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Percentage"
                            size="small"
                            {...formik.getFieldProps("percentage")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid container spacing={3} p={"24px"}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        className={classes.addButton}
                        onClick={() => formik.resetForm()}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>

                      <Button
                        variant="contained"
                        startIcon={<SaveAltIcon />}
                        className={classes.addButton}
                        onClick={() => formik.handleSubmit()}
                        disabled={!formValid || isLoading}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
