import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
useGetActivityHistoryQuery,
} from "../../../Api/attoDeskApi";
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useCreateCustomerProjectMutation } from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { ICustomerProject } from "../../../Api/Interface/api.interface";
import { useStyles } from "../../../theme/appStyles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../Components/Validation/validationUtils";
import { uploadPDF } from "../../../Components/UploadPdf/fillPDF";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface IProjectpopup {
  openModel?: boolean;
  handleCloseDialog: (close: boolean) => void;
  data?: ICustomerProject;
  customerId?: string;
}

const CustomerProject = ({
  openModel = false,
  handleCloseDialog,
  data,
  customerId,
}: IProjectpopup) => {
  const classes = useStyles();
  const [open] = React.useState(openModel);
  const [newCustomer, { isLoading }] = useCreateCustomerProjectMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const [error, setError] = useState<string | null>(null);
  const handleClose = () => {
    handleCloseDialog(false);
  };
  const [selectedPdf, setSelectedPdf] = useState<File | undefined>(undefined);
  const [isPdfUploading, setIsPdfUploading] = useState(false);

  const {
    data: activityHistoryData,
    isLoading: activityHistoryIsLoading,
    refetch: refetchActivityHistory,
  } = useGetActivityHistoryQuery({
    searchText: "",
    id: String(customerId),  
  });


  const formik = useFormik({
    initialValues: {
      id: data?.id,
      projectName: data?.projectName || "",
      customerId: data?.customerId || customerId,
      projectType: data?.projectType || "",
      projectDomainName: data?.projectDomainName || "",
      projectPhoneNumber: data?.projectPhoneNumber || "",
      projectEmail: data?.projectEmail || "",
      projectAddress: data?.projectAddress || "",
      projectStartDate: data?.projectStartDate
        ? dayjs(data.projectStartDate)
        : null,
      projectEstimatedEndDate: data?.projectEstimatedEndDate
        ? dayjs(data.projectEstimatedEndDate)
        : null,
      projectProposalDate: data?.projectProposalDate
        ? dayjs(data.projectProposalDate)
        : null,
      projectUploadProposal: data?.projectUploadProposal || "",
      projectDescription: data?.projectDescription || "",
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      const companyMobileNumberError = validatePhoneNumber(
        values.projectPhoneNumber
      );
      if (companyMobileNumberError) {
        errors.projectPhoneNumber = companyMobileNumberError;
      }

      const projectEmailError = validateEmail(values.projectEmail);
      if (projectEmailError) {
        errors.projectEmail = projectEmailError;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        let projectUploadProposal: string | undefined =
          data?.projectUploadProposal;

        if (selectedPdf) {
          setIsPdfUploading(true);
          formData.append("projectUploadProposal", selectedPdf);
          projectUploadProposal = (await uploadPDF(selectedPdf)) || "";
          setIsPdfUploading(false);
        }
        const temData = {
          id: values?.id,
          projectName: values.projectName,
          customerId: values.customerId,
          projectType: values.projectType,
          projectDomainName: values.projectDomainName,
          projectPhoneNumber: values.projectPhoneNumber,
          projectEmail: values.projectEmail,
          projectAddress: values.projectAddress,
          projectStartDate: values.projectStartDate,
          projectEstimatedEndDate: values.projectEstimatedEndDate,
          projectProposalDate: values.projectProposalDate,
          projectDescription: values.projectDescription,
          projectUploadProposal,
        };

        if (!data) {
          delete temData.id;
        }

        const addCompanyResponse = await newCustomer(temData).unwrap();
        if (!addCompanyResponse.status) {
          showErrorMessage(addCompanyResponse.message);
        } else {
          showMessage(addCompanyResponse.message);
          handleClose();
          const refetchResult = await refetchActivityHistory();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const handlePdfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file && file.type === "application/pdf") {
      setSelectedPdf(file);
    } else {
      showErrorMessage("Please select a valid PDF file");
    }
  };

  const formValid = useMemo(() => {
    return formik.values.projectName === "" ||
      formik.values.projectName === undefined
      ? false
      : true;
  }, [formik]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: "1700px",
        },
      }}
    >
      <Grid className={classes.Dialog}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={5}
                sx={{ borderBottom: 2, padding: 2 }}
                className={classes.titleGrid}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  pt={2}
                  alignContent={"center"}
                >
                  <Typography className={classes.cardTitle}>
                    Customer Project
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Customer Transaction Information
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Project Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Project Name"
                              size="small"
                              {...formik.getFieldProps("projectName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Project Type
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Project Type"
                              size="small"
                              {...formik.getFieldProps("projectType")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Mobile Number
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Mobile Number"
                              size="small"
                              {...formik.getFieldProps("projectPhoneNumber")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.projectPhoneNumber &&
                                Boolean(formik.errors.projectPhoneNumber)
                              }
                              helperText={
                                formik.touched.projectPhoneNumber &&
                                formik.errors.projectPhoneNumber
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">E-mail</Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter E-mail"
                              size="small"
                              {...formik.getFieldProps("projectEmail")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.projectEmail &&
                                Boolean(formik.errors.projectEmail)
                              }
                              helperText={
                                formik.touched.projectEmail &&
                                formik.errors.projectEmail
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Start Date
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={formik.values.projectStartDate}
                                onChange={(date) =>
                                  formik.setFieldValue("projectStartDate", date)
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    sx: { width: "100%" },
                                    InputProps: {
                                      sx: { fontSize: 14 },
                                    },
                                    InputLabelProps: {
                                      sx: { fontSize: 14 },
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Estimated End date
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={formik.values.projectEstimatedEndDate}
                                onChange={(date) =>
                                  formik.setFieldValue(
                                    "projectEstimatedEndDate",
                                    date
                                  )
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    sx: { width: "100%" },
                                    InputProps: {
                                      sx: { fontSize: 14 },
                                    },
                                    InputLabelProps: {
                                      sx: { fontSize: 14 },
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Proposal date
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={formik.values.projectProposalDate}
                                onChange={(date) =>
                                  formik.setFieldValue(
                                    "projectProposalDate",
                                    date
                                  )
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    sx: { width: "100%" },
                                    InputProps: {
                                      sx: { fontSize: 14 },
                                    },
                                    InputLabelProps: {
                                      sx: { fontSize: 14 },
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              project Domain Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter project Domain Name"
                              size="small"
                              {...formik.getFieldProps("projectDomainName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">Address</Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Address .."
                              size="small"
                              multiline
                              rows={3}
                              {...formik.getFieldProps("projectAddress")}
                              className={classes.discriptionTextField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Description
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Description .."
                              size="small"
                              multiline
                              rows={8}
                              {...formik.getFieldProps("projectDescription")}
                              className={classes.discriptionTextField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Upload Project Proposal (PDF)
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} p={"24px"}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className={classes.imgBox}>
                          {selectedPdf && (
                            <Typography>
                              Selected PDF: {selectedPdf.name}
                            </Typography>
                          )}
                          {!selectedPdf &&
                          data &&
                          data.projectUploadProposal ? (
                            <Typography>
                              Current PDF:{" "}
                              <a
                                href={data.projectUploadProposal}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Proposal
                              </a>
                            </Typography>
                          ) : (
                            <></>
                          )}
                          <div>
                            <input
                              accept="application/pdf"
                              style={{ display: "none" }}
                              id="icon-button-file-pdf"
                              type="file"
                              onChange={handlePdfChange} // PDF change handler
                            />
                            <div style={{ textAlign: "center" }}>
                              <label htmlFor="icon-button-file-pdf">
                                <IconButton
                                  color="primary"
                                  aria-label="upload PDF"
                                  component="span"
                                >
                                  <CloudUploadIcon sx={{ fontSize: 45 }} />
                                </IconButton>
                              </label>
                              <div>Only PDF files are allowed</div>
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                  <Card className={classes.card}>
                    <Grid container spacing={3} p={"24px"}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          className={classes.addButton}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Box m={0.5}></Box>

                        <Button
                          variant="contained"
                          startIcon={<SaveAltIcon />}
                          className={classes.addButton}
                          onClick={() => formik.handleSubmit()}
                          disabled={!formValid || isLoading}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CustomerProject;
