import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  MenuItem,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from "../../theme/appStyles";
import {
  useDeleteCustomerProjectMutation,
  useDeleteCustomerTransactionMutation,
  useGetCustomerProjectQuery,
  useGetCustomerQuery,
  useGetCustomerTransactionQuery,
  useGetWorkOrderByCustomerProjectQuery,
  useRemovedCustomerProjectMMutation,
  useGetAllInvoiceQuery,
  useCreateEmailMutation, 
  useCreateTextMutation, 
  useCreateNoteMutation,
  useCreateNoteEmailMutation,
  useGetEmailTemplateQuery,
  useGetNoteTemplateQuery,
  useGetActivityHistoryQuery,
} from "../../Api/attoDeskApi";
import {
  ICustomer,
  ICustomerProject,
  ICustomerTransaction,
  IEmailTemplate,
  INoteTemplate,
  IInvoice,
  IQuoteWorkOrder,
  IActivityHistoryApiResponse,
} from "../../Api/Interface/api.interface";
import NewCustomerTransactionPopUp from "./Components/NewCustomerTransactionPopUp";
      import { useNotifier } from "../../Core/Notifier";
import DeletePopup from "../../Components/Delete/DeletePopup";
import DeletePopupMutaion from "../../Components/Delete/DeletePopupMutaion";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CiSearch } from "react-icons/ci";
import useDebounce from "../../Components/SearchDebonce/useDebounce";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import NewPopUpCustomer from "../Customer/Edit/index";
import AddProject from "./Components/AddProject";
import ViewInvoice from "./Components/ViewInvoice";
import dayjs from "dayjs";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AssignWorkOrder from "./Components/AssignWorkOrder";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from "@mui/icons-material/Info";
import CodeIcon from "@mui/icons-material/Code";
import HistoryIcon from "@mui/icons-material/History";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import NoteIcon from "@mui/icons-material/Note";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from "formik";
import ChatIcon from "@mui/icons-material/Chat";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  WorkOrderStatus,
} from "../../Core/Enum/enum";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const getStatusLabel = (statusCode: number): string => {
  switch (statusCode) {
    case WorkOrderStatus.Paid:
      return "Paid";
    case WorkOrderStatus.Cancel:
      return "Canceled";
    case WorkOrderStatus.Pending:
      return "Pending";
    default:
      return "Unknown Status";
  }
};


 

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const [searchQueryProject, setSearchQueryProject] = useState("");
  const [currentSearchQueryProject, setCurrentSearchQueryProject] =
    useState("");
  const debouncedSearchQueryProject = useDebounce(searchQueryProject, 300);

  const [searchQueryInvoice, setSearchQueryInvoice] = useState("");
  const [currentSearchQueryInvoice, setCurrentSearchQueryInvoice] =
    useState("");
  const debouncedSearchQueryInvoice = useDebounce(searchQueryInvoice, 300);
  const [searchQueryEmailTemplate, setSearchQueryEmailTemplate] = useState("");
  const [currentSearchQueryEmailTemplate, setCurrentSearchQueryEmailTemplate] =
    useState("");
  const debouncedSearchQueryEmailTemplate = useDebounce(searchQueryEmailTemplate, 300);
  const [searchQueryNoteTemplate, setSearchQueryNoteTemplate] = useState("");
  const [currentSearchQueryNoteTemplate, setCurrentSearchQueryNoteTemplate] =
    useState("");
  const debouncedSearchQueryNoteTemplate = useDebounce(searchQueryNoteTemplate, 300);
  const { id: customerId } = useParams<{ id: string }>();
  const [selectCustomer, setSelectCustomer] = useState<ICustomer | null>(null);
  const [transactionData, setTransactionData] =
    useState<ICustomerTransaction>();

  const [deleteCategory] = useDeleteCustomerTransactionMutation();
  const [deleteProject] = useDeleteCustomerProjectMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [openAddTransaction, setOpenAddTransaction] = useState(false);
  const [openAddProject, setOpenAddProject] = useState(false);
  const [openViewInvoice, setOpenViewInvoice] = useState(false);
  const [projectData, setProjecData] = useState<ICustomerProject>();

  const [openAddWorkOrder, setOpenAddWorkOrder] = useState(false);
  const [WorkOrderData, setWorkOrderData] = useState<IQuoteWorkOrder>();

  const { showErrorMessage, showMessage } = useNotifier();
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [coursingToDelete, setCoursingToDelete] =
    useState<ICustomerTransaction | null>(null);

  const [openDeleteProject, setOpenDeleteProject] = useState(false);
  const [projectToDelete, setProjectToDelete] =
    useState<ICustomerProject | null>(null);

  const [openDeleteProjectWrokOrder, setOpenDeleteProjectWrokOrder] =
    useState(false);
  const [projectWrokOrderToDelete, setProjectWrokOrderToDelete] =
    useState<IQuoteWorkOrder | null>(null);

  const [openProjectTable, setOpenProjectTable] = useState(false);
  const [projectId, setProjectId] = useState<number>(0);
  const [projectName, setProjectName] = useState<string>("");
  const [expandedSteps, setExpandedSteps] = useState<number[]>([]);

  const [newNote, { isLoading: isNoteSaving }] = useCreateNoteMutation();
  const [newNoteEmail, { isLoading: isNoteEmailSaving }] = useCreateNoteEmailMutation();

  useEffect(() => {}, [customerId]);

  const [showProject, setShowProject] = useState(true);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showTextForm, setShowTextForm] = useState(false);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [newEmail, { isLoading: isEmailSending }] = useCreateEmailMutation();
  const [newText, { isLoading: isTextSending }] = useCreateTextMutation();
  const [isSendAsEmail, setIsSendAsEmail] = useState(false);
  const ReactQuillRef = useRef(null);
  const [invoiceId, setInvoiceId] = useState("");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [invoiceEmailData, setInvoiceEmailData] = useState({ to: '', subject: '', message: '' , attachments: [] });
  const invoiceRef = useRef<HTMLDivElement | null>(null);

  interface InvoiceEmailData {
    customer_id: number;
    to: string;
    subject: string;
    message: string;
    attachments: File[]; // Ensure attachments is an array of File objects
  }

  interface TransactionType {
    id: number;
    InvoiceNo: string;
    // Add other properties of the transaction as needed
  }

  const { data, isLoading, isError } = useGetCustomerQuery({
    searchText: "",
    id: String(customerId),     
  });

  
  const {
    data: customerTransactionData,
    isLoading: customerDataIsLoading,
    refetch,
  } = useGetCustomerTransactionQuery({
    searchText: currentSearchQuery,
    customerId: String(customerId),
    projectId: "",
  });

  const {
    data: activityHistoryData,
    isLoading: activityHistoryIsLoading,
    refetch: refetchActivityHistory,
  } = useGetActivityHistoryQuery({
    searchText: "",
    id: String(customerId),  
  });

  

  const {
    data: customerProjectData,
    isLoading: customerProjectDataIsLoading,
    refetch: cutomerProjectRefetch,
  } = useGetCustomerProjectQuery({
    searchText: currentSearchQueryProject,
    customerId: String(customerId),
    id: "",
  });

  const {
    data: workOrderProjectData,
    isLoading: workOrderProjectDataIsLoading,
    refetch: workOrderProjectRefetch,
  } = useGetWorkOrderByCustomerProjectQuery({
    searchText: "",
    quoteWorkOrderCustomerId: String(customerId),
    quoteWorkOrderCustomerProjectId: String(projectId),
  });

  const {
    data: invoiceData,
    isLoading: invoiceIsLoading,
    isError: invoiceError,
    refetch: invoiceRefetch,
  } = useGetAllInvoiceQuery({
    searchText: currentSearchQueryInvoice,
    id: "",
    quoteWorkOrderCustomerId: String(customerId),
    quoteWorkOrderCustomerProjectId: "",
  });

  const {
    data: emailTemplateData,
    isLoading: emailTemplateIsLoading,
    isError: emailTemplateError,
    refetch: emailTemplateRefetch,
  } = useGetEmailTemplateQuery({
    searchText: currentSearchQueryEmailTemplate,
    // id: "",
  });

  const {
    data: noteTemplateData,
    isLoading: noteTemplateIsLoading,
    isError: noteTemplateError,
    refetch: noteTemplateRefetch,
  } = useGetNoteTemplateQuery({
    searchText: currentSearchQueryNoteTemplate,
    // id: "",
  });


  const [newCustomer, { isLoading: rmoveIsLoading }] =
    useRemovedCustomerProjectMMutation();

  const customer = useMemo(() => {
    if (data && Array.isArray(data.data) && data.data.length > 0) {
      return data.data[0] as ICustomer;
    }
    return null;
  }, [data]);

  const activityHistory = useMemo(() => {
    if (data && data.data) {
      return data as IActivityHistoryApiResponse; // Cast the entire response to the expected type
    }
    return null;
  }, [data]);
  

  const transactions: ICustomerTransaction[] = useMemo(() => {
    if (
      customerTransactionData &&
      Array.isArray(customerTransactionData.data)
    ) {
      return customerTransactionData.data as ICustomerTransaction[];
    }
    return [];
  }, [customerTransactionData]);

  const project: ICustomerProject[] = useMemo(() => {
    if (customerProjectData && Array.isArray(customerProjectData.data)) {
      return customerProjectData.data as ICustomerProject[];
    }
    return [];
  }, [customerProjectData]);

  const workOrderProject: IQuoteWorkOrder[] = useMemo(() => {
    if (workOrderProjectData && Array.isArray(workOrderProjectData.data)) {
      return workOrderProjectData.data as IQuoteWorkOrder[];
    }
    return [];
  }, [workOrderProjectData]);

  const invoiceList: IInvoice[] = useMemo(() => {
    if (invoiceData && Array.isArray(invoiceData.data)) {
      return invoiceData.data as IInvoice[];
    }
    return [];
  }, [invoiceData]);

  const emailTemplateList: IEmailTemplate[] = useMemo(() => {
    if (emailTemplateData && Array.isArray(emailTemplateData.data)) {
      return emailTemplateData.data as IEmailTemplate[];
    }
    return [];
  }, [emailTemplateData]);

  const noteTemplateList: INoteTemplate[] = useMemo(() => {
    if (noteTemplateData && Array.isArray(noteTemplateData.data)) {
      return noteTemplateData.data as INoteTemplate[];
    }
    return [];
  }, [noteTemplateData]);
  
   const handleSelectRow = (id: number) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
        setSelectedRows(invoiceList.map((transaction) => transaction.id));
    } else {
        setSelectedRows([]);
    }
};

  const handleEmailDialogOpen = () => setShowEmailDialog(true);
  const handleEmailDialogClose = () => setShowEmailDialog(false);

  const handleSendEmail = async () => {
    // Construct emailData object
    const emailData :InvoiceEmailData = {
      customer_id: customerId ? parseInt(customerId, 10) : 0,
      // customer_id: customerId,
      to: invoiceEmailData.to,
      subject: invoiceEmailData.subject,
      message: invoiceEmailData.message,
      attachments: [],
    };

    for (const selectedRowId of selectedRows) {
      const transaction = invoiceList.find((inv) => inv.id === selectedRowId);
      if (transaction) {
        const pdfBlob = await handleGeneratePDF(transaction);
        const file = new File([pdfBlob], `Invoice-${transaction.InvoiceNo}.pdf`, {
          type: 'application/pdf',
        });
        emailData.attachments.push(file);
      }
    }
    console.log("Attachments:", emailData.attachments);
      try {
        // Generate PDF files for each selected invoice and add them to attachments

      // Call the newEmail mutation API and await the response
      const emailResponse = await newEmail(emailData).unwrap();

      if (!emailResponse) {
        showErrorMessage("Failed to send email");
      } else {
        showMessage("Email sent successfully");

        // Refetch activity history after sending the email (optional)
        await refetchActivityHistory();

        // Reset the form values after the email is sent
        setInvoiceEmailData({
          ...invoiceEmailData,  // Preserve the "to" field
          subject: "",  // Reset subject
          message: "",  // Reset message
        });
      }
    } catch (error) {
      // Handle any error that occurs during email sending
      showErrorMessage("Failed to send email");
    } finally {
      // Close the email dialog after sending the email
      handleEmailDialogClose();
    }
  };


  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };


  

      const emailFormik = useFormik({
        initialValues: {
          to: "",
          subject: "",
          message: "",
          template: "",
        },
        validate: (values) => {
          const errors: { [key: string]: string } = {};
          if (!values.to) {
            errors.to = "Email address is required";
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.to)) {
            errors.to = "Invalid email address";
          }
         
          return errors;
        },
        onSubmit: async (values, { setValues  }) => {
          try {
            // Prepare the data to send via the newEmail API
            const emailData = {
              customer_id: customerId,
              to: values.to,
              subject: values.subject,
              message: values.message,
            };
      
            // Call the newEmail mutation API and wait for the response
            const emailResponse = await newEmail(emailData).unwrap();
            if (!emailResponse) {
              showErrorMessage("Failed to send email");
             // showErrorMessage(emailResponse.message);
            } else {
              showMessage("Email Sent successfully");
              const refetchResult = await refetchActivityHistory();
              setValues({
                ...values, // Preserve the "to" field
                subject: "", // Reset subject
                message: "", // Reset message
                template: "", // Reset template
              });
            }

          } catch (error) {
            showErrorMessage("Failed to send email");
          }
        },
      });

     


      const textFormik = useFormik({
        initialValues: {
          message: "",
        },
        
        onSubmit: async (values, { setValues  }) => {
          try {
            // Prepare the data to send via the newEmail API
            const textData = {
              customer_id: customerId,
              phone_no: customer?.customerMobileNum || "",
              message: stripHtmlTags(values.message),
            };
      
            // Call the newEmail mutation API and wait for the response
            const textResponse = await newText(textData).unwrap();
            if (!textResponse) {
              showErrorMessage("Failed to send sms");
             // showErrorMessage(emailResponse.message);
            } else {
              showMessage("Sms Sent successfully");
              const refetchResult = await refetchActivityHistory();
              setValues({
                ...values, // Preserve the "to" field
                message: "", // Reset message
              });
            }

          } catch (error) {
            showErrorMessage("Failed to send email");
          }
        },
      });

      const textFormValid = useMemo(() => {
        return (
          stripHtmlTags(textFormik.values.message) !=="" &&
          stripHtmlTags(textFormik.values.message) !==undefined 
        );
      }, [textFormik.values.message]);

      const emailFormValid = useMemo(() => {
        return (
          emailFormik.values.to !== "" &&
          emailFormik.values.to !== undefined &&
          emailFormik.values.subject !== "" &&
          emailFormik.values.subject !== undefined &&
          stripHtmlTags(emailFormik.values.message) !=="" &&
          stripHtmlTags(emailFormik.values.message) !==undefined 
        );
      }, [emailFormik.values.to, emailFormik.values.subject, emailFormik.values.message]);

      const handleNoteEmailSubmit = async (values: any, resetForm: () => void) => {
        try {
        
          const noteEmailData = {
            customer_id: customerId,
            to: customer?.customerEmail || "", // Assuming customer is available
            note_subject: values.subject,
            note_message: values.message,
          };
      
          // Call the send email API
          const emailResponse = await newNoteEmail(noteEmailData).unwrap();
          showMessage("Email Sent successfully");
          const refetchResult = await refetchActivityHistory();
          resetForm();
          setIsSendAsEmail(false);
        } catch (error) {
          showErrorMessage("Failed to send email");
        }
      };
      
      // Function to handle saving a note
      const handleNoteSaveSubmit = async (values: any, resetForm: () => void) => {
        try {
          
          const noteData = {
            customer_id: customerId,
            note_subject: values.subject,
            note_message: stripHtmlTags(values.message),
          };
      
          // Call the save note API
          const noteResponse = await newNote(noteData).unwrap();
          showMessage("Note saved successfully");
          const refetchResult = await refetchActivityHistory();
          resetForm(); // Reset the form on success
        } catch (error) {
          showErrorMessage("Failed to save note");
        } 
      };

      const noteFormik = useFormik({
        initialValues: {
          subject: "",
          message: "",
        },
     
        onSubmit: async (values, { resetForm }) => {
          if (isSendAsEmail) {
            // Submit as email
            await handleNoteEmailSubmit(values, resetForm);
          } else {
            // Save as note
            await handleNoteSaveSubmit(values, resetForm);
          }
        },
      });

      const noteFormValid = useMemo(() => {
        return (
          // noteFormik.values.to !== "" &&
          // noteFormik.values.to !== undefined &&
          noteFormik.values.subject !== "" &&
          noteFormik.values.subject !== undefined &&
          stripHtmlTags(noteFormik.values.message) !=="" &&
          stripHtmlTags(noteFormik.values.message) !==undefined 
        );
      }, [noteFormik.values.subject, noteFormik.values.message]);


      useEffect(() => {
        if (debouncedSearchQuery.length >= 4 || debouncedSearchQuery === "") {
          setCurrentSearchQuery(debouncedSearchQuery);
          refetch();
        }
      }, [debouncedSearchQuery]);

      useEffect(() => {
        if (
          debouncedSearchQueryProject.length >= 4 ||
          debouncedSearchQueryProject === ""
        ) {
          setCurrentSearchQueryProject(debouncedSearchQueryProject);
          refetch();
        }
      }, [debouncedSearchQueryProject]);

      useEffect(() => {
        if (
          debouncedSearchQueryInvoice.length >= 3 ||
          debouncedSearchQueryInvoice === ""
        ) {
          setCurrentSearchQueryInvoice(debouncedSearchQueryInvoice);
          refetch();
        }
      }, [debouncedSearchQueryInvoice]);

      useEffect(() => {
        if (
          debouncedSearchQueryEmailTemplate.length >= 3 ||
          debouncedSearchQueryEmailTemplate === ""
        ) {
          setCurrentSearchQueryEmailTemplate(debouncedSearchQueryEmailTemplate);
          refetch();
        }
      }, [debouncedSearchQueryEmailTemplate]);

      useEffect(() => {
        if (
          debouncedSearchQueryNoteTemplate.length >= 3 ||
          debouncedSearchQueryNoteTemplate === ""
        ) {
          setCurrentSearchQueryNoteTemplate(debouncedSearchQueryNoteTemplate);
          refetch();
        }
      }, [debouncedSearchQueryNoteTemplate]);

      useEffect(() => {
        if (customer && customer.customerEmail || customer?.customerMobileNum) {
          emailFormik.setFieldValue("to", customer.customerEmail);
          textFormik.setFieldValue("to", customer.customerMobileNum);
        }
      }, [customer]);

      const handleOpenDialog = (customer: ICustomer) => {
        setSelectCustomer(customer);
        setOpenDialog(true);
      };

      const handleCloseDialog = () => {
        setSelectCustomer(null);
        setOpenDialog(false);
      };

      const handleOpenAddTransaction = () => {
        setTransactionData(undefined);
        setOpenAddTransaction(true);
      };

      const handleCloseAddTransaction = () => {
        setTransactionData(undefined);
        setOpenAddTransaction(false);
      };

      const handleOpenAddProject = () => {
        setProjecData(undefined);
        setOpenAddProject(true);
      };

      const handleCloseAddProject = () => {
        setProjecData(undefined);
        setOpenAddProject(false);
      };

      const handleOpenViewInvoice = (transactionId: string) => {
        setInvoiceId(transactionId);
        setOpenViewInvoice(true);
        //console.log(transactionId);
      };
      

      const handleCloseViewInvoice = () => {
        setOpenViewInvoice(false);
        setInvoiceId("");
      };

      const handleOpenAddWorkOrder = () => {
        setWorkOrderData(undefined);
        setOpenAddWorkOrder(true);
      };

      const handleCloseAddWorkOrder = () => {
        setWorkOrderData(undefined);
        setOpenAddWorkOrder(false);
      };

      const handleOpenEditSuites = () => {
        setSelectCustomer(customer);
        setOpenDialog(true);
      };

      const handleInvoiceToggle = () => {
        setShowProject(false);
        setShowInvoice(true);
        setShowEmailForm(false); // Ensure email is hidden
        setShowNoteForm(false); 
        setShowTextForm(false); 
      };

      const handleProjectToggle = () => {
        setShowProject(true);
        setShowInvoice(false);
        setShowEmailForm(false); // Ensure email is hidden
        setShowNoteForm(false); 
        setShowTextForm(false); 
      };

      // Toggle Email
      const handleEmailToggle = () => {
        setShowEmailForm(true);
        setShowInvoice(false);
        setShowProject(false); // Ensure project is hidden
        setShowNoteForm(false); 
        setShowTextForm(false); 
      };

      const handleTextToggle = () => {
        setShowEmailForm(false);
        setShowInvoice(false);
        setShowProject(false); // Ensure project is hidden
        setShowNoteForm(false); 
        setShowTextForm(true); 
      };

      const handleNoteToggle = () => {
        setShowEmailForm(false);
        setShowInvoice(false);
        setShowProject(false); // Ensure project is hidden
        setShowNoteForm(true); 
        setShowTextForm(false); 
      };
      

      if (isLoading || customerDataIsLoading) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="10vh"
          >
            <CircularProgress />
          </Box>
        );
      }

      if (isError) {
        return (
          <Typography color="error">
            Error fetching customer data. Please try again later.
          </Typography>
        );
      }

      if (!customer) {
        return <Typography>No customer data available.</Typography>;
      }
      const handleOpenDeletePopup = (coursing: ICustomerTransaction) => {
        setCoursingToDelete(coursing);
        setOpenDeleteCategory(true);
      };

      const handleCloseDeletePopup = () => {
        setCoursingToDelete(null);
        setOpenDeleteCategory(false);
      };

      const handleDelete = async (id: string) => {
        try {
          const response = await deleteCategory(id).unwrap();
          if (response.status) {
            showMessage("Deleted successfully");
            setOpenDeleteCategory(false);
          } else {
            showErrorMessage("Failed to delete the customer");
          }
        } catch (error) {
          showErrorMessage("Failed to delete the customer");
        }
      };

      const handleOpenDeleteProjectPopup = (project: ICustomerProject) => {
        setProjectToDelete(project);
        setOpenDeleteProject(true);
      };

      const handleCloseDeleteProjectPopup = () => {
        setProjectToDelete(null);
        setOpenDeleteProject(false);
      };

      const handleDeleteProject = async (id: string) => {
        try {
          const response = await deleteProject(id).unwrap();
          if (response.status) {
            showMessage("Deleted successfully");
            setOpenDeleteProject(false);
            const refetchResult = await refetchActivityHistory();
          } else {
            showErrorMessage("Failed to delete the customer project");
          }
        } catch (error) {
          showErrorMessage("Failed to delete the customer project");
        }
      };

      const handleOpenDeleteWrokOrderPopup = (project: IQuoteWorkOrder) => {
        setProjectWrokOrderToDelete(project);
        setOpenDeleteProjectWrokOrder(true);
      };

      const handleCloseDeleteWrokOrderPopup = () => {
        setProjectWrokOrderToDelete(null);
        setOpenDeleteProjectWrokOrder(false);
      };

      const handleDeleteWrokOrder = async (id: string) => {
        try {
          const temData = {
            id: id,
            quoteWorkOrderCustomerId: String(customerId),
          };
          const response = await newCustomer(temData).unwrap();

          if (response.status) {
            showMessage("Deleted successfully");
            setOpenDeleteProjectWrokOrder(false);
          } else {
            showErrorMessage("Failed to remove the customer project work order");
          }
        } catch (error) {
          showErrorMessage("Failed to remove the customer project remove");
        }
      };

      const emailLogs = activityHistoryData?.data?.emailLogs || [];
      const smsLogs = activityHistoryData?.data?.smsLogs || [];
      const noteLogs = activityHistoryData?.data?.noteLogs || [];
      const notes = activityHistoryData?.data?.notes || [];
      const projectLogs = activityHistoryData?.data?.projectLogs || []; // Assuming you have this array
      
    
      const combinedLogs = [
        ...emailLogs.map((log) => ({
          ...log,
          type: "Email Sent",
        })),
        ...smsLogs.map((log) => ({
          ...log,
          type: "Sms Sent",
        })),
        ...noteLogs.map((log) => ({
          ...log,
          type: "Note Email Sent",
        })),
        ...notes.map((log) => ({
          ...log,
          type: "Note Saved",
        })),
        ...projectLogs.map((log) => {
          // Determine the type based on the action performed
          let type = "Project Created"; // Default to Created
          if (log.action === "updated") {
            type = "Project Updated";
          } else if (log.action === "deleted") {
            type = "Project Deleted";
          }
          return {
            ...log,
            type, // Use the determined type
          };
        }),
      ];

      const sortedCombinedLogs = combinedLogs.sort((a, b) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return dateB - dateA; // Sort descending
      });
      
      // Create timeLineSteps from sorted logs
      const timeLineSteps = sortedCombinedLogs.map((log, index) => {
        let description;
      
        if (log.type === "Sms Sent") {
          description = `Message | ${log.message || 'N/A'}`; // Customize description for SMS
        }else if (log.type.startsWith("Project")) {
        
          if (log.type === "Project Created") {
            description = `Project '${log.project_name}' has been created `;
        } else if (log.type === "Project Updated") {
            description = `Project '${log.project_name}' has been updated`;
        } else if (log.type === "Project Deleted") {
            description = `Project '${log.project_name}'  has been deleted`;
        } 
        } else {
          description = `Subject | ${log.subject || 'No Subject'}`; // Default for emails and note emails
        }
      
        return {
          key: `${log.type}-${log.created_at}-${log.id || index}`,
          label: log.type,
          description, // Use the customized description
          date: new Date(log.created_at).toLocaleDateString(),
          time: new Date(log.created_at).toLocaleTimeString(),
        };
      });

      // const timeLineSteps = [
      //   {
      //     label: "Project Initiated",
      //     description: "The project was initiated on January 1st, 2024.",
      //     date: "January 1st, 2024",
      //     time: "10:00 AM",
      //   },
      //   {
      //     label: "Requirement Gathering",
      //     description:
      //       "All project requirements were gathered by January 15th, 2024.",
      //     date: "January 15th, 2024",
      //     time: "2:00 PM",
      //   },
      //   {
      //     label: "Design Phase",
      //     description: "Design phase started on February 1st, 2024.",
      //     date: "February 1st, 2024",
      //     time: "9:30 AM",
      //   },
      //   {
      //     label: "Development Phase",
      //     description: "Development phase started on March 1st, 2024.",
      //     date: "March 1st, 2024",
      //     time: "11:00 AM",
      //   },
      //   {
      //     label: "Testing Phase",
      //     description: "Testing phase started on April 1st, 2024.",
      //     date: "April 1st, 2024",
      //     time: "3:45 PM",
      //   },
      //   {
      //     label: "Project Completed",
      //     description: "The project was successfully completed on May 1st, 2024.",
      //     date: "May 1st, 2024",
      //     time: "5:00 PM",
      //   },
      // ];

      const toggleStepDetails = (index: number) => {
        if (expandedSteps.includes(index)) {
          setExpandedSteps(
            expandedSteps.filter((stepIndex) => stepIndex !== index)
          );
        } else {
          setExpandedSteps([...expandedSteps, index]);
        }
      };

      const convertPlainTextToHTML= (text: string) => {
        return text
          .replace(/\n/g, '<br>') // Replace line breaks with <br>
          .replace(/ {2,}/g, match => '&nbsp;'.repeat(match.length)); // Replace multiple spaces with &nbsp;
      }
      const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSendAsEmail(event.target.checked); // Toggle the state when switch is changed
      };
    

      const handleGeneratePDF = async (transaction: TransactionType) => {
        const input = invoiceRef.current;
      
        if (input) {
          const canvas = await html2canvas(input);
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
      
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
      
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
          return pdf.output('blob');
        } else {
          throw new Error('Invoice element not found');
        }
      };
    

      return (
        <div>
          <Grid container spacing={3}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.profileBottomSetUp}
                      >
                        <Grid className={classes.profileBottomDwon}>
                          {customer.customerImg ? (
                            <img
                              src={customer.customerImg}
                              alt="Profile"
                              className={classes.profileImage}
                            />
                          ) : (
                            <Avatar className={classes.profileImage} />
                          )}
                        </Grid>

                        <Grid className={classes.profileDetails}>
                          <Typography
                            variant="h4"
                            className={classes.profileDetailItem}
                          >
                            {(customer.customerFirstName || "") +
                              " " +
                              (customer.customerLastName || "")}
                          </Typography>

                          <Typography
                            variant="body1"
                            className={classes.contactInfo}
                          >
                            <span className={classes.iconText}>
                              <PhoneIcon /> {customer.customerMobileNum}
                            </span>

                            <span className={classes.iconText}>
                              <EmailIcon /> {customer.customerEmail}
                            </span>
                          </Typography>
                          <Grid className={classes.buttonGroup}>
                            <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                                onClick={handleProjectToggle}
                              >
                                <AssignmentIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                Project
                              </Typography>
                            </div>

                            <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                                onClick={handleInvoiceToggle}
                              >
                                <DescriptionIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                Invoice
                              </Typography>
                            </div>

                            <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                                onClick={handleEmailToggle}
                              >   
                                <EmailIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                Email
                              </Typography>
                            </div>

                            <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                          
                              >   
                                <PhoneIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                Call
                              </Typography>
                            </div>

                            <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                                onClick={handleTextToggle}
                              >
                                <ChatIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                Text
                              </Typography>
                            </div>

                            <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                                onClick={handleNoteToggle}
                              >
                                <NoteIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                Note
                              </Typography>
                            </div>

                            <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                              >
                                <HistoryIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                History
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {showProject && (
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Card className={classes.card}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ borderBottom: 2, padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Typography className={classes.cardTitle}>
                              Projects
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Grid
                              container
                              spacing={1}
                              justifyContent="flex-end"
                              sx={{ display: "flex" }}
                            >
                              <Grid item>
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <CiSearch
                                          className={classes.searchWidgetIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                    classes: {
                                      root: classes.formControlList,
                                    },
                                  }}
                                  fullWidth
                                  value={searchQueryProject}
                                  onChange={(e) =>
                                    setSearchQueryProject(e.target.value)
                                  }
                                  placeholder="Name .."
                                  sx={{ width: "100%" }}
                                  size="small"
                                />
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="contained"
                                  className={classes.addButton}
                                  onClick={handleOpenAddProject}
                                >
                                  Add Project
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid>
                          <TableContainer>
                            <Table className={classes.table}>
                              <TableHead className={classes.tableHeaderRow}>
                                <TableRow>
                                  <TableCell className={classes.tablheader}>
                                    <strong>Project Name</strong>
                                  </TableCell>
                                  <TableCell className={classes.tablheader}>
                                    <strong>Create Date</strong>
                                  </TableCell>

                                  <TableCell
                                    className={classes.tablheader}
                                    sx={{ textAlign: "right" }}
                                  >
                                    <strong>Actions</strong>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {project.map((project) => (
                                  <TableRow
                                    key={project.id}
                                    className={classes.tableRowHover}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className={classes.tablCell}
                                    >
                                      {project.projectName}
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      className={classes.tablCell}
                                    >
                                      {project?.created_at
                                        ? dayjs(project.created_at).format(
                                            "MMMM D, YYYY [at] h:mm A"
                                          )
                                        : ""}
                                    </TableCell>
                                    <TableCell className={classes.tablCell}>
                                      <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <Grid item>
                                          <Button
                                            variant="contained"
                                            className={classes.viewButtonList}
                                            onClick={() =>
                                              navigate(
                                                `/customer-history/project/${project.id}`
                                              )
                                            }
                                          >
                                            <VisibilitySharpIcon
                                              className={classes.viewListIcon}
                                            />
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          <Button
                                            variant="contained"
                                            className={classes.editButtonList}
                                            onClick={() => {
                                              setProjecData(project);
                                              setOpenAddProject(true);
                                            }}
                                          >
                                            <EditSharpIcon
                                              className={classes.editListIcon}
                                            />
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          <Button
                                            variant="contained"
                                            className={classes.deleteButtonList}
                                            onClick={() =>
                                              handleOpenDeleteProjectPopup(project)
                                            }
                                          >
                                            <DeleteOutlineSharpIcon
                                              className={classes.deleteButtonList}
                                            />
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          {openAddProject && (
                            <AddProject
                              openModel={openAddProject}
                              handleCloseDialog={handleCloseAddProject}
                              data={projectData}
                              customerId={customerId}
                            />
                          )}
                          {projectToDelete && (
                            <DeletePopup
                              open={openDeleteProject}
                              handleCloseDelete={handleCloseDeleteProjectPopup}
                              onConfirm={async () => {
                                await handleDeleteProject(
                                  projectToDelete.id.toString()
                                );
                              }}
                              title="Delete Project"
                              content={`Are you sure you want to delete "${projectToDelete.projectName}"?`}
                            />
                          )}
                        </Grid>
                      </Card>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Card className={classes.card}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ borderBottom: 2, padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Typography className={classes.cardTitle}>
                              Invoices
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Grid
                              container
                              spacing={1}
                              justifyContent="flex-end"
                              sx={{ display: "flex" }}
                            >
                              <Grid item>
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <CiSearch
                                          className={classes.searchWidgetIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                    classes: {
                                      root: classes.formControlList,
                                    },
                                  }}
                              fullWidth
                              value={searchQueryInvoice}
                              onChange={(e) =>
                                setSearchQueryInvoice(e.target.value)
                              }
                              placeholder="Name .."
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.addButton}
                              onClick={() =>
                                navigate(`/invoice/create/${customerId}`)
                              }
                            >
                              Add Invoice
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead className={classes.tableHeaderRow}>
                            <TableRow>
                          
                              <TableCell className={classes.tablheader}>
                                <strong>Invoice No</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Status</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Create Date</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Total</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Actions</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoiceList.map((transaction) => {
                                const statusLabel = getStatusLabel(transaction.quoteWorkOrderStatus);
                              return(
                              <TableRow
                                key={transaction.id}
                                className={classes.tableRowHover}
                              >
                              
                               <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {transaction.InvoiceNo}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {statusLabel}
                                </TableCell>


                                <TableCell
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {transaction?.created_at
                                    ? dayjs(transaction.created_at).format(
                                        "MMMM D, YYYY [at] h:mm A"
                                      )
                                    : ""}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {"$"}
                                  {parseFloat(
                                    transaction?.quoteWorkOrderSaleGrandTotal ||
                                      "0"
                                  ).toFixed(2)}
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div ref={invoiceRef}>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        
                                        className={classes.viewButtonList}
                                        onClick={() => handleOpenViewInvoice(String(transaction.id))}
                                        // onClick={() =>
                                        //   navigate(
                                        //     `/invoice/history/${transaction.id}`
                                        //   )
                                        // }
                                      >
                                        <VisibilitySharpIcon
                                          className={classes.viewListIcon}
                                        />
                                      </Button>
                                    </Grid>
                                    </div>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                              )
})}
                          </TableBody>
                        </Table>
                      </TableContainer>


                   
                      

                      {openViewInvoice && (
  <Dialog open={openViewInvoice} onClose={handleCloseViewInvoice} maxWidth="xl" fullWidth
  >
    <DialogTitle><Typography variant="h5" component="div">
    Invoice Details
  </Typography></DialogTitle>
    <DialogContent >
    <ViewInvoice  invoiceId={invoiceId}/> 
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseViewInvoice}>Close</Button>
    </DialogActions>
  </Dialog>
)}


                 {/* {openViewInvoice && (
                        <ViewInvoice
                          openModel={openViewInvoice}
                          handleCloseDialog={handleCloseViewInvoice}
                          invoiceId={invoiceId}
                        />
                      )} */}


                      {selectCustomer && (
                        <NewPopUpCustomer
                          openModel={openDialog}
                          handleCloseDialog={handleCloseDialog}
                          data={selectCustomer}
                        />
                      )}
                      {openAddTransaction && (
                        <NewCustomerTransactionPopUp
                          openModel={openAddTransaction}
                          handleCloseDialog={handleCloseAddTransaction}
                          data={transactionData}
                          customerId={customerId}
                        />
                      )}
                      {coursingToDelete && (
                        <DeletePopup
                          open={openDeleteCategory}
                          handleCloseDelete={handleCloseDeletePopup}
                          onConfirm={async () => {
                            await handleDelete(coursingToDelete.id.toString());
                          }}
                          title="Delete Customer"
                          content={`Are you sure you want to delete "${coursingToDelete.transactionName}"?`}
                        />
                      )}
                    </Grid>
                  </Card>
                </Grid>



                    
                    </>

                    
                  )}


{showInvoice && (  
               
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Card className={classes.card}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ borderBottom: 2, padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Typography className={classes.cardTitle}>
                              Invoices
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Grid
                              container
                              spacing={1}
                              justifyContent="flex-end"
                              sx={{ display: "flex" }}
                            >
                              <Grid item>
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <CiSearch
                                          className={classes.searchWidgetIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                    classes: {
                                      root: classes.formControlList,
                                    },
                                  }}
                              fullWidth
                              value={searchQueryInvoice}
                              onChange={(e) =>
                                setSearchQueryInvoice(e.target.value)
                              }
                              placeholder="Name .."
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.addButton}
                              onClick={() =>
                                navigate(`/invoice/create/${customerId}`)
                              }
                            >
                              Add Invoice
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead className={classes.tableHeaderRow}>
                            <TableRow>
                            <TableCell>
                  <Checkbox
                    onChange={handleSelectAllRows}
                    checked={selectedRows.length === invoiceList.length}
                  />
                </TableCell>
              
                              <TableCell className={classes.tablheader}>
                                <strong>Invoice No</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Status</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Create Date</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Total</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Actions</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoiceList.map((transaction) =>  {
                                const statusLabel = getStatusLabel(transaction.quoteWorkOrderStatus);
                              return(
                              <TableRow
                                key={transaction.id}
                                className={classes.tableRowHover}
                              >
                                 <TableCell>
                    
                  
                                  <Checkbox
                        checked={selectedRows.includes(transaction.id)}
                        onChange={() => handleSelectRow(transaction.id)}
                      />
                        </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {transaction.InvoiceNo}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {statusLabel}
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {transaction?.created_at
                                    ? dayjs(transaction.created_at).format(
                                        "MMMM D, YYYY [at] h:mm A"
                                      )
                                    : ""}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {"$"}
                                  {parseFloat(
                                    transaction?.quoteWorkOrderSaleGrandTotal ||
                                      "0"
                                  ).toFixed(2)}
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        className={classes.viewButtonList}
                                        onClick={() => handleOpenViewInvoice(String(transaction.id))}
                                        // onClick={() =>
                                        //   navigate(
                                        //     `/invoice/history/${transaction.id}`
                                        //   )
                                        // }
                                      >
                                        <VisibilitySharpIcon
                                          className={classes.viewListIcon}
                                        />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                              )
})}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {selectedRows.length > 0 && (
          <Button variant="contained" color="primary" onClick={handleEmailDialogOpen}>
            Send Email
          </Button>
        )}

<Dialog open={showEmailDialog} onClose={handleEmailDialogClose} maxWidth="sm" fullWidth>
          <DialogTitle>Send Email</DialogTitle>
          <DialogContent>
            <TextField
              label="To"
              fullWidth
              margin="normal"
              value={invoiceEmailData.to}
              onChange={(e) => setInvoiceEmailData({ ...invoiceEmailData, to: e.target.value })}
            />
            <TextField
              label="Subject"
              fullWidth
              margin="normal"
              value={invoiceEmailData.subject}
              onChange={(e) => setInvoiceEmailData({ ...invoiceEmailData, subject: e.target.value })}
            />
            <TextField
              label="Message"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={invoiceEmailData.message}
              onChange={(e) => setInvoiceEmailData({ ...invoiceEmailData, message: e.target.value })}
            />
            <Typography variant="subtitle1" gutterBottom>
              Attachments: {selectedRows.length} invoices selected
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEmailDialogClose}>Cancel</Button>
            <Button onClick={handleSendEmail} color="primary" variant="contained">
              Send
            </Button>
          </DialogActions>
        </Dialog>



                      {openViewInvoice && (
  <Dialog open={openViewInvoice} onClose={handleCloseViewInvoice} maxWidth="xl" fullWidth
  >
    <DialogTitle><Typography variant="h5" component="div">
    Invoice Details
  </Typography></DialogTitle>
    <DialogContent >
    <ViewInvoice  invoiceId={invoiceId}/> 
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseViewInvoice}>Close</Button>
    </DialogActions>
  </Dialog>
)}

                 {/* {openViewInvoice && (
                        <ViewInvoice
                          openModel={openViewInvoice}
                          handleCloseDialog={handleCloseViewInvoice}
                          invoiceId={invoiceId}
                        />
                      )} */}


                      {selectCustomer && (
                        <NewPopUpCustomer
                          openModel={openDialog}
                          handleCloseDialog={handleCloseDialog}
                          data={selectCustomer}
                        />
                      )}
                      {openAddTransaction && (
                        <NewCustomerTransactionPopUp
                          openModel={openAddTransaction}
                          handleCloseDialog={handleCloseAddTransaction}
                          data={transactionData}
                          customerId={customerId}
                        />
                      )}
                      {coursingToDelete && (
                        <DeletePopup
                          open={openDeleteCategory}
                          handleCloseDelete={handleCloseDeletePopup}
                          onConfirm={async () => {
                            await handleDelete(coursingToDelete.id.toString());
                          }}
                          title="Delete Customer"
                          content={`Are you sure you want to delete "${coursingToDelete.transactionName}"?`}
                        />
                      )}
                    </Grid>
                  </Card>
                </Grid>
             

                    )}

          
       



{showEmailForm && (


<Grid item lg={12} md={12} sm={12} xs={12}>
          <form onSubmit={emailFormik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                       Compose Email
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} p={"24px"}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                             To
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Email"
                            size="small"
                            {...emailFormik.getFieldProps("to")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            error={
                              emailFormik.touched.to &&
                              Boolean(emailFormik.errors.to)
                            }
                            helperText={
                              emailFormik.touched.to &&
                              emailFormik.errors.to
                            }
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Subject
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Subject"
                            size="small"
                            {...emailFormik.getFieldProps("subject")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                           
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Message
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div
        style={{
        height: "210px",
       border:  "1px solid #ccc", 
       borderRadius: "4px", // Optional: Add some rounding to the corners
        overflow: "hidden", // Prevent content overflow
      }}
    >
                <ReactQuill
                  value={emailFormik.values.message}
                  onChange={(value) => emailFormik.setFieldValue("message", value)}
                  style={{
                    height: "210px",
                  }}
                />
                
    </div>

   
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Select Template</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            SelectProps={{
                              native: false,
                            }}
                            defaultValue=""
                            value={emailFormik.values.template}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => {
                              const selectedTemplateId = event.target.value;
                            
                              const selectedTemplate = emailTemplateList.find(template => template.id === Number(selectedTemplateId));
                          
                              if (selectedTemplate) {
                                // Set the form fields for subject and message based on the selected template
                                emailFormik.setFieldValue("subject", selectedTemplate.subject);
                                emailFormik.setFieldValue("message", convertPlainTextToHTML(selectedTemplate.message));
                            } else {
                                // Reset the subject and message if "Select an option" is chosen
                                emailFormik.setFieldValue("subject", "");
                                emailFormik.setFieldValue("message", "");
                            }
                              // Set the selected template value
                              emailFormik.setFieldValue("template", selectedTemplateId);
                            }}
                          >
                            <MenuItem value="Select option" >
                              Select option
                            </MenuItem>
                          
        {emailTemplateList.map((template) => (
          <MenuItem key={template.id} value={template.id}>
            {template.template_name} {/* Display the name of the template */}
          </MenuItem>
        ))}
                          
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>



                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid container spacing={3} p={"24px"}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                    
                      <Button
                        variant="contained"
                        startIcon={<SaveAltIcon />}
                        className={classes.addButton}
                        onClick={() => emailFormik.handleSubmit()}
                        disabled={!emailFormValid || isEmailSending}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>


        )}

{showTextForm && (
         <Grid item lg={12} md={12} sm={12} xs={12}>
         <form onSubmit={textFormik.handleSubmit}>
           <Grid container>
             <Grid item lg={12} md={12} sm={12} xs={12}>
               <Card className={classes.card}>
                 <Grid
                   container
                   justifyContent={"space-between"}
                   spacing={5}
                   sx={{ borderBottom: 2, padding: 2 }}
                   className={classes.titleGrid}
                 >
                   <Grid
                     item
                     lg={12}
                     md={12}
                     sm={12}
                     xs={12}
                     pt={2}
                     alignContent={"center"}
                   >
                     <Typography className={classes.cardTitle}>
                      New Message
                     </Typography>
                   </Grid>
                 </Grid>

                 <Grid container spacing={3} p={"24px"}>

                 
                   <Grid item lg={12} md={12} sm={12} xs={12}>
                     <Grid container alignItems="center">
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <Typography variant="subtitle1">
                           Text Message
                         </Typography>
                       </Grid>
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                       <div
       style={{
       height: "210px",
      border:  "1px solid #ccc", 
      borderRadius: "4px", // Optional: Add some rounding to the corners
       overflow: "hidden", // Prevent content overflow
     }}
   >
               <ReactQuill
                 value={textFormik.values.message}
                 onChange={(value) => textFormik.setFieldValue("message", value)}
                 style={{
                   height: "210px",
                 }}
               />
               
   </div>

                       </Grid>
                     </Grid>
                   </Grid>

               
 

                 </Grid>
               </Card>
             </Grid>

             <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
               <Card className={classes.card}>
                 <Grid container spacing={3} p={"24px"}>
                   <Grid
                     item
                     lg={12}
                     md={12}
                     sm={12}
                     xs={12}
                     sx={{ display: "flex", justifyContent: "flex-end" }}
                   >
                   
                     <Button
                       variant="contained"
                       startIcon={<SaveAltIcon />}
                       className={classes.addButton}
                       onClick={() => textFormik.handleSubmit()}
                       disabled={!textFormValid || isTextSending}
                     >
                        Send
                     </Button>
                   </Grid>
                 </Grid>
               </Card>
             </Grid>
           </Grid>
         </form>
       </Grid>
        )} 



     {showNoteForm && (
         <Grid item lg={12} md={12} sm={12} xs={12}>
         <form onSubmit={noteFormik.handleSubmit}>
           <Grid container>
             <Grid item lg={12} md={12} sm={12} xs={12}>
               <Card className={classes.card}>
                 <Grid
                   container
                   justifyContent={"space-between"}
                   spacing={5}
                   sx={{ borderBottom: 2, padding: 2 }}
                   className={classes.titleGrid}
                 >
                   <Grid
                     item
                     lg={12}
                     md={12}
                     sm={12}
                     xs={12}
                     pt={2}
                     alignContent={"center"}
                   >
                     <Typography className={classes.cardTitle}>
                      New Message
                     </Typography>
                   </Grid>
                 </Grid>

                 <Grid container spacing={3} p={"24px"}>

                   <Grid item lg={12} md={12} sm={12} xs={12}>
                     <Grid container alignItems="center">
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <Typography variant="subtitle1">
                           Subject
                         </Typography>
                       </Grid>
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <TextField
                           placeholder="Enter Subject"
                           size="small"
                           {...noteFormik.getFieldProps("subject")}
                           className={classes.textField}
                           InputProps={{
                             sx: {
                               fontSize: 14,
                             },
                           }}
                           InputLabelProps={{
                             sx: {
                               fontSize: 14,
                             },
                           }}
                          
                         />
                       </Grid>
                     </Grid>
                   </Grid>

                   <Grid item lg={12} md={12} sm={12} xs={12}>
                     <Grid container alignItems="center">
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <Typography variant="subtitle1">
                           Note Message
                         </Typography>
                       </Grid>
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                       <div
       style={{
       height: "210px",
      border:  "1px solid #ccc", 
      borderRadius: "4px", // Optional: Add some rounding to the corners
       overflow: "hidden", // Prevent content overflow
     }}
   >
               <ReactQuill
                 value={noteFormik.values.message}
                 onChange={(value) => noteFormik.setFieldValue("message", value)}
                 style={{
                   height: "210px",
                 }}
               />
               
   </div>

  
                       </Grid>
                     </Grid>
                   </Grid>

                   <Grid item lg={10} md={12} sm={12} xs={12}>
          <Grid container alignItems="center">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="subtitle1">Send as Email</Typography>
              <Switch
                checked={isSendAsEmail}
                onChange={handleToggleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        </Grid>
          {isSendAsEmail && (
                   <Grid item lg={10} md={12} sm={12} xs={12}>
                     <Grid container alignItems="center">
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <Typography variant="subtitle1">Select Template</Typography>
                       </Grid>
                       <Grid item lg={6} md={6} sm={12} xs={12}>
                         <TextField
                           select
                           size="small"
                           className={classes.textField}
                           SelectProps={{
                             native: false,
                           }}
                           defaultValue=""
                           InputLabelProps={{ shrink: true }}
                           onChange={(event) => {
                             const selectedTemplateId = event.target.value;
                           
                             const selectedTemplate = noteTemplateList.find(template => template.id === Number(selectedTemplateId));
                         
                             if (selectedTemplate) {
                               // Set the form fields for subject and message based on the selected template
                               noteFormik.setFieldValue("subject", selectedTemplate.subject);
                               noteFormik.setFieldValue("message", convertPlainTextToHTML(selectedTemplate.note_message));
                           }
                          
                            //  noteFormik.setFieldValue("template", selectedTemplateId);
                           }}
                         >
                           <MenuItem value="Select option" >
                             Select option
                           </MenuItem>
                         
       {noteTemplateList.map((template) => (
         <MenuItem key={template.id} value={template.id}>
           {template.note_title} {/* Display the name of the template */}
         </MenuItem>
       ))}
                         
                         </TextField>
                       </Grid>
                     </Grid>
                   </Grid>

)}

                 </Grid>
               </Card>
             </Grid>

             <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
               <Card className={classes.card}>
                 <Grid container spacing={3} p={"24px"}>
                   <Grid
                     item
                     lg={12}
                     md={12}
                     sm={12}
                     xs={12}
                     sx={{ display: "flex", justifyContent: "flex-end" }}
                   >
                   
                     <Button
                       variant="contained"
                       startIcon={<SaveAltIcon />}
                       className={classes.addButton}
                       onClick={() => noteFormik.handleSubmit()}
                       disabled={!noteFormValid || isNoteSaving ||isNoteEmailSaving}
                     >
                     { isSendAsEmail ?'Send' : 'Save'}
                     </Button>
                   </Grid>
                 </Grid>
               </Card>
             </Grid>
           </Grid>
         </form>
       </Grid>
        )}



          </Grid>
        </Grid>





      

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.stepperCard}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Activity History
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        className={classes.titleGrid}
                      >
                        <Box
                          sx={{
                            height: "600px",
                            overflow: "auto",
                            padding: "2px",
                            "&::-webkit-scrollbar": {
                              width: "4px",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#eeeeee",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "#eeeeee",
                              borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#eeeeee",
                            },
                          }}
                        >
                           <div>
    {timeLineSteps.length === 0 ? (
      <p style={{ textAlign: "center", padding: "20px" }}>No activity history found</p>
    ) : (
                          <Stepper
                            orientation="vertical"
                            activeStep={timeLineSteps.length}
                          >
                            {timeLineSteps.map((step, index) => (
                              <Step key={step.key} completed>
                                <StepLabel>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography className={classes.h5}>
                                      {step.label}
                                      <Typography
                                        variant="subtitle1"
                                        className={classes.showMoreLink}
                                        onClick={() => toggleStepDetails(index)}
                                      >
                                        {expandedSteps.includes(index)
                                          ? "Show less"
                                          : "Show more"}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        {step.time}
                                      </Typography>
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      {step.date}
                                    </Typography>
                                  </Box>
                                </StepLabel>

                                {expandedSteps.includes(index) && (
                                  <Box sx={{ ml: 4, mt: 1 }}>
                                    <Typography>{step.description}</Typography>
                                  </Box>
                                )}
                              </Step>
                            ))}

                           
                          </Stepper>
                          )}
  </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
              <Card className={classes.stepperCard}></Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
