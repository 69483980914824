import React, { useMemo } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Card,
  MenuItem,
} from "@mui/material";
import {
  useGetAllLoopingTableQuery,
  useInsertOrUpdateDataLoopingTableMutation,
} from "../../../Api/attoDeskApi";
import { ILoopingTable } from "../../../Api/Interface/api.interface";
import { useFormik } from "formik";
import CustomSwitch from "../../../Components/Switch/CustomSwitch";
import { useStyles } from "../../../theme/appStyles";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useNotifier } from "../../../Core/Notifier";

interface LoopingTableProps {
  productName: string;
  productId: string;
  productDetailsIsLooping: boolean;
  productsDetailsLoopingConstant: string;
  loopingTableComplete?: () => void;
}

const LoopingTable: React.FC<LoopingTableProps> = ({
  productName,
  productId,
  productDetailsIsLooping,
  productsDetailsLoopingConstant,
  loopingTableComplete,
}) => {
  const classes = useStyles();
  const { showErrorMessage, showMessage } = useNotifier();
  const [insertDataLoopingTable, { isLoading: loopingDataLoading }] =
    useInsertOrUpdateDataLoopingTableMutation();
  const { data, isLoading, isError, refetch } = useGetAllLoopingTableQuery({
    searchText: "",
    id: productsDetailsLoopingConstant,
  });

  const LoopingTableList = useMemo(() => {
    return data?.data as ILoopingTable[];
  }, [data?.data]);

  // Initialize form values based on the columns
  const initialValues = useMemo(() => {
    const values: { [key: string]: any } = {};
    LoopingTableList?.[0]?.tableColumns.forEach((column) => {
      if (column.type === "boolean") {
        values[column.name] = false;
      } else if (column.type === "integer") {
        values[column.name] = null;
      } else {
        values[column.name] = "";
      }
    });
    return values;
  }, [LoopingTableList]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        const temData = {
          tableName: LoopingTableList[0]?.shortTableName,
          data: values,
          productId: String(productId),
        };

        const addProductResponse = await insertDataLoopingTable(
          temData
        ).unwrap();
        if (!addProductResponse.status) {
          showErrorMessage(addProductResponse.message);
        } else {
          showMessage(addProductResponse.message);
          resetForm();
          if (loopingTableComplete) {
            loopingTableComplete();
          }
        }
      } catch (error) {
        showErrorMessage("Failed to insert data.");
      }
    },
  });

  // function isHTMLSelectElement(
  //   element: EventTarget | null
  // ): element is HTMLSelectElement {
  //   return element instanceof HTMLSelectElement;
  // }

  return (
    <Box>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="10vh"
        >
          <CircularProgress />
        </Box>
      )}
      {isError && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="10vh"
        >
          <Typography>Error loading data...</Typography>
          <CircularProgress />
        </Box>
      )}

      {LoopingTableList && LoopingTableList.length > 0 && (
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.card}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  spacing={5}
                  sx={{ borderBottom: 2, padding: 2 }}
                  className={classes.titleGrid}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    pt={2}
                    alignContent={"center"}
                  >
                    <Typography className={classes.cardTitle}>
                      Looping for {productName}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={3} p={"24px"}>
                  <Grid item lg={12} md={12} sm={12} xs={12} py={2}>
                    {LoopingTableList[0].tableColumns.map((column) => {
                      switch (column.columnField) {
                        case "TextField":
                          return (
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              sx={{ mt: 2 }}
                            >
                              <Grid item lg={2} md={3} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                  {column.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={3}
                                md={9}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  placeholder={column.name}
                                  size="small"
                                  className={classes.textField}
                                  // {...formik.getFieldProps("categoryName")}
                                  key={column.name}
                                  // label={column.name}
                                  type={
                                    column.type === "integer"
                                      ? "number"
                                      : "text"
                                  }
                                  value={formik.values[column.name]}
                                  onChange={formik.handleChange}
                                  name={column.name}
                                  InputProps={{
                                    sx: {
                                      fontSize: 14,
                                    },
                                  }}
                                  InputLabelProps={{
                                    sx: {
                                      fontSize: 14,
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        case "Switch":
                          return (
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              sx={{ mt: 2 }}
                            >
                              <Grid item lg={2} md={3} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                  {column.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={3}
                                md={9}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems="center"
                              >
                                <CustomSwitch
                                  color="primary"
                                  sx={{ mr: 2 }}
                                  formik={formik}
                                  name={column.name}
                                  onChange={formik.handleChange}
                                />
                              </Grid>
                            </Grid>
                          );
                        case "Selector":
                          return (
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              sx={{ mt: 2 }}
                            >
                              <Grid item lg={2} md={3} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                  {column.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={3}
                                md={9}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  select
                                  size="small"
                                  key={column.name}
                                  className={classes.textField}
                                  defaultValue=""
                                  InputLabelProps={{ shrink: true }}
                                  value={formik.values[column.name]}
                                  onChange={formik.handleChange}
                                  name={column.name}
                                >
                                  <MenuItem
                                    value=""
                                    disabled
                                    style={{ color: "gray" }}
                                  >
                                    Select an option
                                  </MenuItem>
                                  {(column.options || []).map(
                                    (option: string) => (
                                      <MenuItem key={option} value={option}>
                                        {option}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              </Grid>
                            </Grid>
                          );
                        // case "MultipleSelector":
                        //   return (
                        //     <Grid
                        //       container
                        //       direction="row"
                        //       alignItems="center"
                        //       spacing={2}
                        //       sx={{ mt: 2 }}
                        //     >
                        //       <Grid item lg={2} md={3} sm={12} xs={12}>
                        //         <Typography
                        //           variant="subtitle1"
                        //           className={classes.typography}
                        //         >
                        //           {column.name}
                        //         </Typography>
                        //       </Grid>
                        //       <Grid
                        //         item
                        //         lg={3}
                        //         md={9}
                        //         sm={12}
                        //         xs={12}
                        //         display="flex"
                        //         alignItems="center"
                        //       >
                        //         <TextField
                        //           select
                        //           size="small"
                        //           sx={{ width: "100%" }}
                        //           SelectProps={{
                        //             multiple: true,
                        //             native: false,
                        //           }}
                        //           InputLabelProps={{ shrink: true }}
                        //           value={formik.values[column.name] || []}
                        //           onChange={(event) => {
                        //             const { target } = event;
                        //             if (isHTMLSelectElement(target)) {
                        //               const selectedValues = Array.from(
                        //                 target.options
                        //               )
                        //                 .filter((option) => option.selected)
                        //                 .map((option) => option.value);
                        //               formik.setFieldValue(
                        //                 column.name,
                        //                 selectedValues
                        //               );
                        //             }
                        //           }}
                        //           name={column.name}
                        //         >
                        //           {!formik.values[column.name]?.length && (
                        //             <MenuItem value="" disabled>
                        //               Select some options
                        //             </MenuItem>
                        //           )}
                        //           {(column.options || []).map(
                        //             (option: string) => (
                        //               <MenuItem key={option} value={option}>
                        //                 {option}
                        //               </MenuItem>
                        //             )
                        //           )}
                        //         </TextField>
                        //       </Grid>
                        //     </Grid>
                        //   );
                        default:
                          return null;
                      }
                    })}
                  </Grid>
                </Grid>

                <Grid container spacing={3} p={"24px"}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<CloseIcon />}
                      className={classes.addButton}
                      onClick={() => formik.resetForm()}
                    >
                      Cancel
                    </Button>
                    <Box m={0.5}></Box>
                    <Button
                      variant="contained"
                      startIcon={<SaveAltIcon />}
                      className={classes.addButton}
                      onClick={() => formik.handleSubmit()}
                      // disabled={!formValid || isLoading || isImageUploading}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default LoopingTable;
