export const requiredValidateEmail = (value: string): string | undefined => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) {
    return "Email is required.";
  }
  if (!emailRegex.test(value)) {
    return "Invalid email address.";
  }
  return undefined;
};

export const requiredValidatePhoneNumber = (value: string): string | undefined => {
  const phoneNumberPattern = /^\d{10}$/;
  if (!value) {
    return "Phone number is required.";
  }
  if (!phoneNumberPattern.test(value)) {
    return "Phone number must be a 10-digit number.";
  }
  return undefined;
};

export const validateEmail = (value: string): string | undefined => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (value && !emailRegex.test(value)) {
    return "Invalid email address.";
  }
  return undefined;
};

export const validatePhoneNumber = (value: string): string | undefined => {
  const phoneNumberPattern = /^\d{10}$/;
  if (value && !phoneNumberPattern.test(value)) {
    return "Phone number must be a 10-digit number.";
  }
  return undefined;
};
