import React, { useMemo } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useCreateEmailTemplateMutation } from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { useStyles } from "../../../theme/appStyles";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [newEmailTemplate, { isLoading }] = useCreateEmailTemplateMutation();
  const { showErrorMessage, showMessage } = useNotifier();

  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const formik = useFormik({
    initialValues: {
      templateName: "",
      subject: "",
      message: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const temData = {
          template_name: values.templateName,
          subject: values.subject,
          message: stripHtmlTags(values.message),
        };

        const addTemplate = await newEmailTemplate(temData).unwrap();
        if (!addTemplate.status) {
          showErrorMessage(addTemplate.message);
        } else {
          showMessage("Template Created successfully");
          resetForm();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return (
      formik.values.templateName !== "" &&
      formik.values.templateName !== undefined &&
      formik.values.subject !== "" &&
      formik.values.subject !== undefined &&
      stripHtmlTags(formik.values.message) !=="" &&
      stripHtmlTags(formik.values.message) !==undefined 
    );
  }, [formik.values.templateName, formik.values.subject, formik.values.message]);

  const gridHeight = isMobile ? "30px" : "400px";

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.stepperCard}>
            <Grid
              container
              justifyContent={"space-between"}
              spacing={5}
              sx={{ borderBottom: 2, padding: 2 }}
              className={classes.titleGrid}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pt={2}
                alignContent={"center"}
              >
                <Typography className={classes.cardTitle}>
                  Create Email Template
                </Typography>
                </Grid>
                </Grid>
                <Grid container spacing={3} p={"24px"}>
  {/* Header for Email Placeholders */}
  <Grid item lg={12} md={12} sm={12} xs={12}>
    <Typography variant="h5">
      Email Placeholders
    </Typography>
  </Grid>



  {/* Placeholder 1: Customer's full name */}
  <Grid item xs={12}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1">
          Customer name
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" color="textSecondary">
          [customer_name]
        </Typography>
      </Grid>
    </Grid>
  </Grid>

  {/* Placeholder 2: Date of the event */}
  <Grid item xs={12}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1">
         Event date
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" color="textSecondary">
          [event_date]
        </Typography>
      </Grid>
    </Grid>
  </Grid>

  {/* Placeholder 3: Time of the event */}
  <Grid item xs={12}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1">
         Event time
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" color="textSecondary">
          [event_time]
        </Typography>
      </Grid>
    </Grid>
  </Grid>
</Grid>
             
          </Card>
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                        Template Information
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} p={"24px"}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Template Name
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Template Name"
                            size="small"
                            {...formik.getFieldProps("templateName")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Subject
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Subject"
                            size="small"
                            {...formik.getFieldProps("subject")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Message
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>

                        <div
        style={{
        height: "210px",
       border:  "1px solid #ccc", 
       borderRadius: "4px", // Optional: Add some rounding to the corners
        overflow: "hidden", // Prevent content overflow
      }}
    >
                <ReactQuill
                  value={formik.values.message}
                  onChange={(value) => formik.setFieldValue("message", value)}
                  style={{
                    height: "210px",
                  }}
                />
                
    </div>
                          


                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid container spacing={3} p={"24px"}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        className={classes.addButton}
                        onClick={() => formik.resetForm()}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>

                      <Button
                        variant="contained"
                        startIcon={<SaveAltIcon />}
                        className={classes.addButton}
                        onClick={() => formik.handleSubmit()}
                        disabled={!formValid || isLoading}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
