export const uploadPDF = async (file: File | Blob): Promise<string | null> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch('http://127.0.0.1:8000/api/utility/pdf/upload', {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token') || ''}`, 
      },
    });

    // const response = await fetch('https://dev2.cacart.ca/web/inv-back/public/api/utility/pdf/upload', {
    //   method: 'POST',
    //   body: formData,
    //   headers: {
    //     'Authorization': `Bearer ${localStorage.getItem('token') || ''}`, 
    //   },
    // });

    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
      return responseData.data;
    } else {
      throw new Error('Error uploading PDF');
    }
  } catch (error) {
    console.error(error);
    throw new Error('Error uploading PDF');
  }
};
