import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  useCreateCompanyMutation,
  useGetCompanyQuery,
} from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { ICompanyDetails } from "../../../Api/Interface/api.interface";
import { useStyles } from "../../../theme/appStyles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { uploadImage } from "../../../Components/UploadFile/upload";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useParams } from "react-router-dom";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../Components/Validation/validationUtils";
import CloseIcon from "@mui/icons-material/Close";

interface ICompanyPopUp {
  openModel?: boolean;
  handleCloseDialog: (close: boolean) => void;
  data?: ICompanyDetails;
}

const Company = ({
  openModel = false,
  handleCloseDialog,
  data,
}: ICompanyPopUp) => {
  const classes = useStyles();
  const [open] = React.useState(openModel);

  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  );
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [newCompany, { isLoading }] = useCreateCompanyMutation();
  const { showErrorMessage, showMessage } = useNotifier();

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id,
      companyName: data?.companyName || "",
      companyProvince: data?.companyProvince || "",
      companyCity: data?.companyCity || "",
      companyPostalCode: data?.companyPostalCode || "",
      companyStreetNumber: data?.companyStreetNumber || "",
      companyStreetName: data?.companyStreetName || "",
      companyPaymentMethod: data?.companyPaymentMethod || "",
      companyEmail: data?.companyEmail || "",
      companyMobileNumber: data?.companyMobileNumber || "",
      companyAltMobileNumber: data?.companyAltMobileNumber || "",
      companyWebSiteLink: data?.companyWebSiteLink || "",
      companyDescription: data?.companyDescription || "",
      CompanyLogo: data?.CompanyLogo || "",
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      const emailError = validateEmail(values.companyEmail);
      if (emailError) {
        errors.companyEmail = emailError;
      }

      const mobileNumberError = validatePhoneNumber(values.companyMobileNumber);
      if (mobileNumberError) {
        errors.companyMobileNumber = mobileNumberError;
      }

      const altMobileNumberError = validatePhoneNumber(
        values.companyAltMobileNumber
      );
      if (altMobileNumberError) {
        errors.companyAltMobileNumber = altMobileNumberError;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        let CompanyLogo: string | undefined = data?.CompanyLogo;

        if (selectedImage) {
          setIsImageUploading(true);
          formData.append("CompanyLogo", selectedImage);
          CompanyLogo = (await uploadImage(selectedImage)) || "";
          setIsImageUploading(false);
        }

        const temData = {
          id: values.id,
          companyName: values.companyName,
          companyProvince: values.companyProvince,
          companyCity: values.companyCity,
          companyPostalCode: values.companyPostalCode,
          companyStreetNumber: values.companyStreetNumber,
          companyStreetName: values.companyStreetName,
          companyPaymentMethod: values.companyPaymentMethod,
          companyEmail: values.companyEmail,
          companyMobileNumber: values.companyMobileNumber,
          companyAltMobileNumber: values.companyAltMobileNumber,
          companyWebSiteLink: values.companyWebSiteLink,
          companyDescription: values.companyDescription,
          CompanyLogo,
        };

        const addCompanyResponse = await newCompany(temData).unwrap();
        if (!addCompanyResponse.status) {
          showErrorMessage(addCompanyResponse.message);
        } else {
          showMessage(addCompanyResponse.message);
          handleClose();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return formik.values.companyName === "" ||
      formik.values.companyName === undefined
      ? false
      : true;
  }, [formik]);

  const resizeImage = (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          // Calculate the new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Canvas is empty"));
            }
          }, file.type);
        };

        img.onerror = (error) => reject(error);
      };
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
      const maxSize = 2048 * 1024;

      if (!validTypes.includes(file.type)) {
        showErrorMessage("The file must be an image (jpeg, png, jpg, gif).");
        setSelectedImage(undefined);
      } else if (file.size > maxSize) {
        showErrorMessage("The file must be smaller than 2 MB.");
        setSelectedImage(undefined);
      } else {
        try {
          const resizedImage = await resizeImage(file, 500, 500);
          setSelectedImage(resizedImage);
        } catch (error) {
          showErrorMessage("Failed to resize the image.");
          setSelectedImage(undefined);
        }
      }
    } else {
      setSelectedImage(undefined);
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: "1700px",
        },
      }}
    >
      <Grid className={classes.Dialog}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={5}
                sx={{ borderBottom: 2, padding: 2 }}
                className={classes.titleGrid}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  pt={2}
                  alignContent={"center"}
                >
                  <Typography className={classes.cardTitle}>
                    Add Company
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Add Company Logo
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} p={"24px"}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className={classes.imgBox}>
                          {selectedImage && (
                            <img
                              src={URL.createObjectURL(selectedImage)}
                              alt="Uploaded"
                              className={classes.SelectImageBox}
                            />
                          )}
                          {!selectedImage && data && data.CompanyLogo ? (
                            <img
                              alt="Selected"
                              height="160px"
                              src={data.CompanyLogo}
                              style={{ position: "inherit" }}
                              className={classes.SelectImageBox}
                            />
                          ) : (
                            <></>
                          )}
                          <div>
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="icon-button-file-1"
                              type="file"
                              onChange={handleImageChange}
                            />
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <label htmlFor="icon-button-file-1">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <CloudUploadIcon sx={{ fontSize: 45 }} />
                                </IconButton>
                              </label>

                              <div>PNG, JPG and JPEG are allowed</div>
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Company Details
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Name"
                              size="small"
                              {...formik.getFieldProps("companyName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Website Link
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Website Link"
                              size="small"
                              {...formik.getFieldProps("companyWebSiteLink")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Description
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Description"
                              size="small"
                              {...formik.getFieldProps("companyDescription")}
                              className={classes.discriptionTextField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              multiline
                              rows={8}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Company Address
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Province
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Province"
                              size="small"
                              {...formik.getFieldProps("companyProvince")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company City
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company City"
                              size="small"
                              {...formik.getFieldProps("companyCity")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Postal Code
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Postal Code"
                              size="small"
                              {...formik.getFieldProps("companyPostalCode")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Street Number
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Street Number"
                              size="small"
                              {...formik.getFieldProps("companyStreetNumber")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Street Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Street Name"
                              size="small"
                              {...formik.getFieldProps("companyStreetName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Company Contact Details
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Email
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Email"
                              size="small"
                              {...formik.getFieldProps("companyEmail")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.companyEmail &&
                                Boolean(formik.errors.companyEmail)
                              }
                              helperText={
                                formik.touched.companyEmail &&
                                formik.errors.companyEmail
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Mobile Number
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Mobile Number"
                              size="small"
                              {...formik.getFieldProps("companyMobileNumber")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.companyMobileNumber &&
                                Boolean(formik.errors.companyMobileNumber)
                              }
                              helperText={
                                formik.touched.companyMobileNumber &&
                                formik.errors.companyMobileNumber
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Alternate Mobile Number
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Alternate Mobile Number"
                              size="small"
                              {...formik.getFieldProps(
                                "companyAltMobileNumber"
                              )}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.companyAltMobileNumber &&
                                Boolean(formik.errors.companyAltMobileNumber)
                              }
                              helperText={
                                formik.touched.companyAltMobileNumber &&
                                formik.errors.companyAltMobileNumber
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid container spacing={3} p={"24px"}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          className={classes.addButton}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Box m={0.5}></Box>
                        <Button
                          variant="contained"
                          startIcon={<SaveAltIcon />}
                          className={classes.addButton}
                          onClick={() => formik.handleSubmit()}
                          disabled={!formValid}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Company;
