import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "../../theme/appStyles";

interface IDeletePopup {
  open: boolean;
  handleCloseDelete: (close: boolean) => void;
  onConfirm: () => Promise<void>;
  title: string;
  content: string;
}

const DeletePopup = ({
  open,
  handleCloseDelete,
  onConfirm,
  title,
  content,
}: IDeletePopup) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    handleCloseDelete(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await onConfirm();
    } catch (error) {
      console.error("Error while deleting:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ padding: 5, minWidth: 450 }}>
      <Grid className={classes.Dialog}>
        <Grid
          container
          justifyContent={"space-between"}
          spacing={5}
          sx={{ borderBottom: 2, padding: 2 }}
          className={classes.titleGrid}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            pt={2}
            alignContent={"center"}
          >
            <Typography className={classes.cardTitle}>{title}</Typography>
          </Grid>
        </Grid>
        <DialogContent>
          <DialogContentText sx={{ pt: 3 }}>{content}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: "24px", p: 4, py: 3, gap: 2 }}>
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                className={classes.addButton}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Box m={0.5}></Box>

              <Button
                variant="contained"
                startIcon={<Delete />}
                className={classes.addButton}
                onClick={handleDelete}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Delete"
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default DeletePopup;
