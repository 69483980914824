import { useStyles } from "../../../theme/appStyles";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useNotifier } from "../../../Core/Notifier";
import { useTheme } from "@mui/material/styles";
import { Formik, FieldArray } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCreateLoopingTableMutation } from "../../../Api/attoDeskApi";
import {
  TableColumnFieldLevel,
  TableColumnTypeLevel,
} from "../../../Core/Enum/enum";
import AddIcon from "@mui/icons-material/Add";

interface Column {
  name: string;
  type: string;
  columnField: string;
  options?: string[];
}

interface FormValues {
  tableName: string;
  columns: Column[];
}

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [newLooping, { isLoading }] = useCreateLoopingTableMutation();
  const { showErrorMessage, showMessage } = useNotifier();

  const initialValues: FormValues = {
    tableName: "",
    columns: [{ name: "", type: "", columnField: "", options: [] }],
  };

  const handleSubmit = async (values: FormValues, { resetForm }: any) => {
    try {
      const temData = {
        tableName: values.tableName,
        columns: values.columns,
      };

      const addProductTag = await newLooping(temData).unwrap();
      if (!addProductTag.status) {
        showErrorMessage(addProductTag.message);
      } else {
        showMessage(addProductTag.message);
        resetForm();
      }
    } catch (error) {
      showErrorMessage("Something went wrong");
    }
  };

  const formValid = (values: FormValues) =>
    values.tableName !== "" &&
    values.columns.some(
      (column) =>
        column.name !== "" && column.type !== "" && column.columnField !== ""
    );

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.stepperCard}>
            <Grid
              container
              justifyContent={"space-between"}
              spacing={5}
              sx={{ borderBottom: 2, padding: 2 }}
              className={classes.titleGrid}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pt={2}
                alignContent={"center"}
              >
                <Typography className={classes.cardTitle}>
                  Create Looping
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.card}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        spacing={5}
                        sx={{ borderBottom: 2, padding: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          pt={2}
                          alignContent={"center"}
                        >
                          <Typography className={classes.cardTitle}>
                            Looping Information
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} p={"24px"}>
                        <Grid item lg={6} md={6} sm={12} xs={12} pb={4}>
                          <Grid container alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="subtitle1">
                                Looping Table Name
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                placeholder="Enter Table Name"
                                size="small"
                                {...formik.getFieldProps("tableName")}
                                className={classes.textField}
                                InputProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <FieldArray name="columns">
                          {({ push, remove }) => (
                            <Grid item lg={12} md={12} sm={12} xs={12} py={2}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Column Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Column Field</TableCell>
                                        <TableCell>Options</TableCell>
                                        <TableCell sx={{ textAlign: "right" }}>
                                          Action
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {formik.values.columns.map(
                                        (column, index) => (
                                          <TableRow key={index}>
                                            <TableCell>
                                              <TextField
                                                placeholder="Enter Column Name"
                                                size="small"
                                                value={column.name}
                                                onChange={(e) =>
                                                  formik.setFieldValue(
                                                    `columns[${index}].name`,
                                                    e.target.value
                                                  )
                                                }
                                                className={classes.textField}
                                                InputProps={{
                                                  sx: {
                                                    fontSize: 14,
                                                  },
                                                }}
                                                InputLabelProps={{
                                                  sx: {
                                                    fontSize: 14,
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                select
                                                size="small"
                                                className={classes.textField}
                                                value={column.type}
                                                onChange={(e) =>
                                                  formik.setFieldValue(
                                                    `columns[${index}].type`,
                                                    e.target.value
                                                  )
                                                }
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              >
                                                {Object.entries(
                                                  TableColumnTypeLevel
                                                ).map(([key, value]) => (
                                                  <MenuItem
                                                    key={value}
                                                    value={value}
                                                  >
                                                    {key}
                                                  </MenuItem>
                                                ))}
                                              </TextField>
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                select
                                                size="small"
                                                className={classes.textField}
                                                value={column.columnField}
                                                onChange={(e) =>
                                                  formik.setFieldValue(
                                                    `columns[${index}].columnField`,
                                                    e.target.value
                                                  )
                                                }
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              >
                                                {Object.entries(
                                                  TableColumnFieldLevel
                                                ).map(([key, value]) => (
                                                  <MenuItem
                                                    key={value}
                                                    value={value}
                                                  >
                                                    {key}
                                                  </MenuItem>
                                                ))}
                                              </TextField>
                                            </TableCell>
                                            <TableCell>
                                              {column.columnField ===
                                                // TableColumnFieldLevel.MultipleSelector ||
                                                // column.columnField ===
                                                TableColumnFieldLevel.Selector && (
                                                <TextField
                                                  placeholder="Enter Options (comma separated)"
                                                  size="small"
                                                  value={
                                                    column.options?.join(
                                                      ", "
                                                    ) || ""
                                                  }
                                                  onChange={(e) =>
                                                    formik.setFieldValue(
                                                      `columns[${index}].options`,
                                                      e.target.value
                                                        .split(",")
                                                        .map((option) =>
                                                          option.trim()
                                                        )
                                                    )
                                                  }
                                                  className={classes.textField}
                                                  InputProps={{
                                                    sx: {
                                                      fontSize: 14,
                                                    },
                                                  }}
                                                  InputLabelProps={{
                                                    sx: {
                                                      fontSize: 14,
                                                    },
                                                  }}
                                                />
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              <Grid
                                                container
                                                spacing={1}
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                }}
                                              >
                                                <Grid item>
                                                  <Button
                                                    variant="contained"
                                                    className={classes.deleteButton}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <DeleteIcon />
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  py={2}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    startIcon={
                                      <AddIcon sx={{ fontSize: 40 }} />
                                    }
                                    className={classes.addButton}
                                    onClick={() =>
                                      push({
                                        name: "",
                                        type: "",
                                        columnField: "",
                                        options: [],
                                      })
                                    }
                                  >
                                    Add Column
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </FieldArray>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                    <Card className={classes.card}>
                      <Grid container spacing={3} p={"24px"}>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            variant="outlined"
                            startIcon={<CloseIcon />}
                            className={classes.addButton}
                            onClick={() => formik.resetForm()}
                          >
                            Cancel
                          </Button>
                          <Box m={0.5}></Box>

                          <Button
                            variant="contained"
                            startIcon={<SaveAltIcon />}
                            className={classes.addButton}
                            onClick={() => formik.handleSubmit()}
                            disabled={!formValid(formik.values) || isLoading}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
