type ColorPalette = {
  [key: string]: string;
};

interface AppColor {
  dataBssThemeDark: ColorPalette;
  dataMenuColorLight: ColorPalette;
  dataBssThemeDarkMenuColorLight: ColorPalette;
  dataTopbarColorLight: ColorPalette;
  dataTopbarColorDark: ColorPalette;
  dataBsThemeLight: ColorPalette;
  dataBsThemeDark: ColorPalette;
  dataCardColorLight: ColorPalette;
  dataCardColorDark: ColorPalette;
}

export const appColors: AppColor = {
  dataBssThemeDark: {
    "--bs-border-color": "#2f3944",
    "--bs-light": "#2f3943",
    "--bs-dark": "#f1f1f1",
    "--bs-light-rgb": "47, 57, 67",
    "--bs-dark-rgb": "241, 241, 241",
    "--bs-headings-color": "#aab8c5",
    "--bs-input-border-color": "#3a4551",
    "--bs-input-focus-border-color": "#4a5663",
  },
  dataMenuColorLight: {
    // "--bs-main-nav-bg": "#f8f5f1",
    "--bs-main-nav-bg": "#262d34",
    "--bs-main-nav-item-color": "#797b97",
    "--bs-main-nav-item-hover-bg": "transparent",
    "--bs-main-nav-item-hover-color": "#ff6c2f",
    "--bs-main-nav-border-color": "#eaedf1",
  },
  dataBssThemeDarkMenuColorLight: {
    "--bs-main-nav-bg": "#262d34",
    "--bs-main-nav-item-color": "#9097a7",
    "--bs-main-nav-item-hover-bg": "transparent",
    "--bs-main-nav-item-hover-color": "#ffffff",
    "--bs-main-nav-border-color": "#2f3944",
  },
  dataTopbarColorLight: {
    "--bs-topbar-bg": "#f9f7f7",
    "--bs-topbar-item-color": "#707793",
    "--bs-topbar-search-bg": "#eae8e8",
    "--bs-heading-color": "#5d7186",
  },
  dataTopbarColorDark: {
    "--bs-topbar-bg": "#22282e",
    "--bs-topbar-item-color": "#dee2e6",
    "--bs-topbar-search-bg": "#2c3238",
    "--bs-heading-color": "#aab8c5",
  },
  dataCardColorLight: {
    "--bs-heading-color": "#313b5e",
    // "--bs-border-color": "#eaedf1",
    "--bs-card-bg-color": "#ffffff",
    "--bs-table-hover-color": "#8c9cb717",
    "--bs-input-border-color": "#d8dfe7",
    "--bs-row-header-color": "#fcfcfd",
    "--bs-table-name-header-color": "#313b5e"
  },
  dataCardColorDark: {
    "--bs-heading-color": "#aab8c5",
    "--bs-card-bg-color": "#2f3944",
    // "--bs-border-color":"#000000",
    "--bs-table-hover-color": "#282828",
    "--bs-input-border-color": "#3a4551",
    "--bs-row-header-color": "#2f394326",
    "--bs-table-name-header-color": "#aab8c5"
  },
  dataBsThemeLight: {
    "--bs-white": "#ffffff",
    "--bs-black": "#000000",
    "--bs-gray": "#5d7186",
    "--bs-gray-dark": "#36404a",
    "--bs-gray-100": "#f8f9fa",
    "--bs-gray-200": "#eef2f7",
    "--bs-gray-300": "#d8dfe7", //ii
    "--bs-gray-400": "#b0b0bb",
    "--bs-gray-500": "#8486a7",
    "--bs-gray-600": "#5d7186",
    "--bs-gray-700": "#424e5a",
    "--bs-gray-800": "#36404a",
    "--bs-gray-900": "#323a46",
    "--bs-primary": "#ff6c2f",
    "--bs-secondary": "#5d7186",
    "--bs-success": "#22c55e",
    "--bs-warning": "#f9b931",
    "--bs-info": "#4ecac2",
    "--bs-danger": "#ef5f5f",
    "--bs-purple": "#7f56da",
    "--bs-pink": "#ff86c8",
    "--bs-blue": "#1c84ee",
    "--bs-orange": "#ff6c2f",
    "--bs-indigo": "#53389f",
    "--bs-red": "#ef5f5f",
    "--bs-yellow": "#f9b931",
    "--bs-green": "#22c55e",
    "--bs-teal": "#040505",
    "--bs-cyan": "#4ecac2",
    "--bs-light": "#eef2f7",
    "--bs-dark": "#323a46",
    "--bs-primary-rgb": "255, 108, 47",
    "--bs-secondary-rgb": "93, 113, 134",
    "--bs-success-rgb": "34, 197, 94",
    "--bs-warning-rgb": "249, 185, 49",
    "--bs-info-rgb": "78, 202, 194",
    "--bs-danger-rgb": "239, 95, 95",
    "--bs-purple-rgb": "127, 86, 218",
    "--bs-pink-rgb": "255, 134, 200",
    "--bs-blue-rgb": "28, 132, 238",
    "--bs-orange-rgb": "255, 108, 47",
    "--bs-indigo-rgb": "83, 56, 159",
    "--bs-red-rgb": "239, 95, 95",
    "--bs-yellow-rgb": "249, 185, 49",
    "--bs-green-rgb": "34, 197, 94",
    "--bs-teal-rgb": "4, 5, 5",
    "--bs-cyan-rgb": "78, 202, 194",
    "--bs-light-rgb": "238, 242, 247",
    "--bs-dark-rgb": "50, 58, 70",
    "--bs-primary-text-emphasis": "#662b13",
    "--bs-secondary-text-emphasis": "#252d36",
    "--bs-success-text-emphasis": "#0e4f26",
    "--bs-info-text-emphasis": "#1f514e",
    "--bs-warning-text-emphasis": "#644a14",
    "--bs-danger-text-emphasis": "#602626",
    "--bs-light-text-emphasis": "#424e5a",
    "--bs-dark-text-emphasis": "#424e5a",
    "--bs-primary-bg-subtle": "#ffe2d5",
    "--bs-secondary-bg-subtle": "#dfe3e7",
    "--bs-success-bg-subtle": "#d3f3df",
    "--bs-info-bg-subtle": "#dcf4f3",
    "--bs-warning-bg-subtle": "#fef1d6",
    "--bs-danger-bg-subtle": "#fcdfdf",
    "--bs-light-bg-subtle": "#fcfcfd",
    "--bs-dark-bg-subtle": "#b0b0bb",
    "--bs-primary-border-subtle": "#ffc4ac",
    "--bs-secondary-border-subtle": "#bec6cf",
    "--bs-success-border-subtle": "#a7e8bf",
    "--bs-info-border-subtle": "#b8eae7",
    "--bs-warning-border-subtle": "#fde3ad",
    "--bs-danger-border-subtle": "#f9bfbf",
    "--bs-light-border-subtle": "#eef2f7",
    "--bs-dark-border-subtle": "#8486a7",
    "--bs-white-rgb": "255, 255, 255",
    "--bs-black-rgb": "0, 0, 0",
    "--bs-font-sans-serif": '"Play", sans-serif',
    "--bs-font-monospace":
      'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    "--bs-gradient":
      "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))",
    "--bs-body-font-family": "var(--bs-font-sans-serif)",
    "--bs-body-font-size": "0.875rem",
    "--bs-body-font-weight": "400",
    "--bs-body-line-height": "1.5",
    "--bs-body-color": "#5d7186",
    "--bs-body-color-rgb": "93, 113, 134",
    "--bs-body-bg": "#f9f7f7",
    "--bs-body-bg-rgb": "249, 247, 247",
    "--bs-emphasis-color": "rgba(93, 113, 134, 0.75)",
    "--bs-emphasis-color-rgb": "93, 113, 134",
    "--bs-secondary-color": "#5d7186",
    "--bs-secondary-color-rgb": "93, 113, 134",
    "--bs-secondary-bg": "#ffffff",
    "--bs-secondary-bg-rgb": "255, 255, 255",
    "--bs-tertiary-color": "#424e5a",
    "--bs-tertiary-color-rgb": "66, 78, 90",
    "--bs-tertiary-bg": "#f8f9fa",
    "--bs-tertiary-bg-rgb": "248, 249, 250",
    "--bs-heading-color": "#aab8c5",
    "--bs-link-color": "#8486a7",
    "--bs-link-color-rgb": "132, 134, 167",
    "--bs-link-decoration": "none",
    "--bs-link-hover-color": "#d95c28",
    "--bs-link-hover-color-rgb": "217, 92, 40",
    "--bs-link-hover-decoration": "underline",
    "--bs-font-sans-serif-rgb": "30, 34, 36",
  },
  dataBsThemeDark: {
    "--bs-white": "#000000",
    "--bs-black": "#ffffff",
    "--bs-gray": "#8c9cb7",
    "--bs-gray-dark": "#6d767f",
    "--bs-gray-100": "#2d2d2d",
    "--bs-gray-200": "#3e3e3e",
    "--bs-gray-300": "#6b6b6b",
    "--bs-gray-400": "#9c9c9c",
    "--bs-gray-500": "#c2c3c8",
    "--bs-gray-600": "#c9c9c9",
    "--bs-gray-700": "#e0e0e0",
    "--bs-gray-800": "#e3e3e3",
    "--bs-gray-900": "#f1f1f1",
    "--bs-primary": "#ff6c2f",
    "--bs-secondary": "#8c9cb7",
    "--bs-success": "#22c55e",
    "--bs-warning": "#f9b931",
    "--bs-info": "#4ecac2",
    "--bs-danger": "#ef5f5f",
    "--bs-purple": "#7f56da",
    "--bs-pink": "#ff86c8",
    "--bs-blue": "#1c84ee",
    "--bs-orange": "#ff6c2f",
    "--bs-indigo": "#53389f",
    "--bs-red": "#ef5f5f",
    "--bs-yellow": "#f9b931",
    "--bs-green": "#22c55e",
    "--bs-teal": "#040505",
    "--bs-cyan": "#4ecac2",
    "--bs-light": "#282828",
    "--bs-dark": "#f9f9f9",
    "--bs-primary-rgb": "255, 108, 47",
    "--bs-secondary-rgb": "140, 156, 183",
    "--bs-success-rgb": "34, 197, 94",
    "--bs-warning-rgb": "249, 185, 49",
    "--bs-info-rgb": "78, 202, 194",
    "--bs-danger-rgb": "239, 95, 95",
    "--bs-purple-rgb": "127, 86, 218",
    "--bs-pink-rgb": "255, 134, 200",
    "--bs-blue-rgb": "28, 132, 238",
    "--bs-orange-rgb": "255, 108, 47",
    "--bs-indigo-rgb": "83, 56, 159",
    "--bs-red-rgb": "239, 95, 95",
    "--bs-yellow-rgb": "249, 185, 49",
    "--bs-green-rgb": "34, 197, 94",
    "--bs-teal-rgb": "4, 5, 5",
    "--bs-cyan-rgb": "78, 202, 194",
    "--bs-light-rgb": "40, 40, 40",
    "--bs-dark-rgb": "249, 249, 249",
    "--bs-primary-text-emphasis": "#f2d1c6",
    "--bs-secondary-text-emphasis": "#d1d1d1",
    "--bs-success-text-emphasis": "#a4d4a4",
    "--bs-info-text-emphasis": "#a4d4d4",
    "--bs-warning-text-emphasis": "#f2c2a4",
    "--bs-danger-text-emphasis": "#f2a4a4",
    "--bs-light-text-emphasis": "#b3b3b3",
    "--bs-dark-text-emphasis": "#b3b3b3",
    "--bs-primary-bg-subtle": "#8c5c2f",
    "--bs-secondary-bg-subtle": "#6d4b2f",
    "--bs-success-bg-subtle": "#a2d3a2",
    "--bs-info-bg-subtle": "#a2d3d3",
    "--bs-warning-bg-subtle": "#f2a76c",
    "--bs-danger-bg-subtle": "#f2a4a4",
    "--bs-light-bg-subtle": "#1e1e1e",
    "--bs-dark-bg-subtle": "#383838",
    "--bs-primary-border-subtle": "#7f4c2f",
    "--bs-secondary-border-subtle": "#5d3f2f",
    "--bs-success-border-subtle": "#7f9a7f",
    "--bs-info-border-subtle": "#7f9a9a",
    "--bs-warning-border-subtle": "#7f5a3f",
    "--bs-danger-border-subtle": "#7f3f3f",
    "--bs-light-border-subtle": "#2c2c2c",
    "--bs-dark-border-subtle": "#4c4c4c",
    "--bs-white-rgb": "0, 0, 0",
    "--bs-black-rgb": "255, 255, 255",
    "--bs-font-sans-serif": '"Play", sans-serif',
    "--bs-font-monospace":
      'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    "--bs-gradient":
      "linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0))",
    "--bs-body-font-family": "var(--bs-font-sans-serif)",
    "--bs-body-font-size": "0.875rem",
    "--bs-body-font-weight": "400",
    "--bs-body-line-height": "1.5",
    "--bs-body-color": "#8c9cb7",
    "--bs-body-color-rgb": "140, 156, 183",
    "--bs-body-bg": "#282828",
    "--bs-body-bg-rgb": "40, 40, 40",
    "--bs-emphasis-color": "rgba(140, 156, 183, 0.75)",
    "--bs-emphasis-color-rgb": "140, 156, 183",
    "--bs-secondary-color": "#8c9cb7",
    "--bs-secondary-color-rgb": "140, 156, 183",
    "--bs-secondary-bg": "#000000",
    "--bs-secondary-bg-rgb": "0, 0, 0",
    "--bs-tertiary-color": "#6d767f",
    "--bs-tertiary-color-rgb": "109, 118, 127",
    "--bs-tertiary-bg": "#1e1e1e",
    "--bs-tertiary-bg-rgb": "30, 30, 30",
    "--bs-heading-color": "#aab8c5",
    "--bs-link-color": "#c2c3c8",
    "--bs-link-color-rgb": "194, 195, 200",
    "--bs-link-decoration": "none",
    "--bs-link-hover-color": "#e5a4a4",
    "--bs-link-hover-color-rgb": "229, 164, 164",
    "--bs-link-hover-decoration": "underline",
    "--bs-font-sans-serif-rgb": "30, 34, 36",
  },
};
