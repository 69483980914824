import { PaletteOptions } from '@mui/material/styles';
import { appColors } from './appcolors';

const getPalette = (mode: 'light' | 'dark'): PaletteOptions => ({
  background: {
    default: mode === 'light' ? appColors.dataBsThemeLight['--bs-body-bg'] : appColors.dataBsThemeDark['--bs-body-bg'],
  },
  primary: {
    main: mode === 'light' ? appColors.dataBsThemeLight['--bs-primary'] : appColors.dataBsThemeDark['--bs-primary'],
    contrastText: mode === 'light' ? appColors.dataBsThemeLight['--bs-white'] : appColors.dataBsThemeDark['--bs-black'],
  },
  secondary: {
    main: mode === 'light' ? appColors.dataBsThemeLight['--bs-secondary'] : appColors.dataBsThemeDark['--bs-secondary'],
    contrastText: mode === 'light' ? appColors.dataBsThemeLight['--bs-white'] : appColors.dataBsThemeDark['--bs-black'],
  },
  success: {
    main: mode === 'light' ? appColors.dataBsThemeLight['--bs-success'] : appColors.dataBsThemeDark['--bs-success'],
  },
  warning: {
    main: mode === 'light' ? appColors.dataBsThemeLight['--bs-warning'] : appColors.dataBsThemeDark['--bs-warning'],
  },
  info: {
    main: mode === 'light' ? appColors.dataBsThemeLight['--bs-info'] : appColors.dataBsThemeDark['--bs-info'],
  },
  text: {
    primary: mode === 'light' ? appColors.dataBsThemeLight['--bs-body-color'] : appColors.dataBsThemeDark['--bs-body-color'],
    secondary: mode === 'light' ? appColors.dataBsThemeLight['--bs-secondary-text-emphasis'] : appColors.dataBsThemeDark['--bs-secondary-text-emphasis'],
  },
  divider: mode === 'light' ? appColors.dataBsThemeLight['--bs-gray-300'] : appColors.dataBsThemeDark['--bs-gray-300'],
  

  // Add custom topbar colors
  topbarButton: {
    topbarBg: mode === 'light' ? appColors.dataTopbarColorLight['--bs-topbar-bg'] : appColors.dataTopbarColorDark['--bs-topbar-bg'],
    topbarItemColor: mode === 'light' ? appColors.dataTopbarColorLight['--bs-topbar-item-color'] : appColors.dataTopbarColorDark['--bs-topbar-item-color'],
    topbarSearchBg: mode === 'light' ? appColors.dataTopbarColorLight['--bs-topbar-search-bg'] : appColors.dataTopbarColorDark['--bs-topbar-search-bg'],
  },
  mainMenuNav: {
    mainNavBg: mode === 'light' ? appColors.dataMenuColorLight['--bs-main-nav-bg'] : appColors.dataBssThemeDarkMenuColorLight['--bs-main-nav-bg'],
    hoverColorNavBg: mode === 'light' ? appColors.dataBssThemeDarkMenuColorLight['--bs-main-nav-item-hover-color'] : appColors.dataBssThemeDarkMenuColorLight['--bs-main-nav-item-hover-color'],
  },
  heading: {
    headingColor: mode === 'light' ? appColors.dataTopbarColorLight['--bs-heading-color'] : appColors.dataTopbarColorDark['--bs-heading-color'],
  },
  cardAdd: {
    headingColor: mode === 'light' ? appColors.dataCardColorLight['--bs-heading-color'] : appColors.dataCardColorDark['--bs-heading-color'],
    bgColor: mode === 'light' ? appColors.dataCardColorLight['--bs-card-bg-color'] : appColors.dataCardColorDark['--bs-card-bg-color'],
    tablehover: mode === 'light' ? appColors.dataCardColorLight['--bs-table-hover-color'] : appColors.dataCardColorDark['--bs-table-hover-color'],
    borderColor: mode === 'light' ? appColors.dataCardColorLight['--bs-input-border-color'] : appColors.dataCardColorDark['--bs-input-border-color'],
    textDescription: mode === 'light' ? appColors.dataBsThemeLight['--bs-body-bg'] : appColors.dataCardColorDark['--bs-card-bg-color'],
    rowHeaderColor: mode === 'light' ? appColors.dataCardColorLight['--bs-row-header-color'] : appColors.dataCardColorDark['--bs-row-header-color'],

  },
  table: {
    headerBgColor: mode === 'light' ? appColors.dataBssThemeDark['--bs-light-rgb'] : appColors.dataBssThemeDark['--bs-dark-rgb'],
    nameHeadingColor: mode === 'light' ? appColors.dataCardColorLight['--bs-table-name-header-color'] : appColors.dataCardColorDark['--bs-table-name-header-color'],

  },
});

// Export palettes for light and dark modes
export const paletteLight: PaletteOptions = getPalette('light');
export const paletteDark: PaletteOptions = getPalette('dark');
