import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useStyles } from "../../../theme/appStyles";
import useDebounce from "../../../Components/SearchDebonce/useDebounce";
import {
  useGetCategoryQuery,
  useGetPrinterQuery,
  useGetProductBrandQuery,
  useGetProductQuery,
  useGetProductTagQuery,
} from "../../../Api/attoDeskApi";
import {
  ICategory,
  IPrinter,
  IProduct,
  IProductBrand,
  IProductTag,
} from "../../../Api/Interface/api.interface";
import { SizeOfLevelType } from "../../../Core/Enum/enum";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Barcode from "react-barcode";

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const { id: ProductId } = useParams<{ id: string }>();

  useEffect(() => {}, [ProductId]);

  const { data, isLoading, isError } = useGetProductQuery({
    searchText: "",
    id: String(ProductId),
    productBrandId: null,
    productCategoryId: null,
    productTagIds: null,
    productViewOnline: null,
    createdDateStart: null,
    createdDateEnd: null,
    createdDate: null,
  });

  const { data: productBrandData, isLoading: productBrandLoading } =
    useGetProductBrandQuery({
      searchText: "",
      isActive: true,
    });

  const { data: tagData, isLoading: tagLoading } = useGetProductTagQuery({
    searchText: "",
    isActive: true,
  });

  const { data: printerData, isLoading: printerLoading } = useGetPrinterQuery({
    searchText: "",
  });

  const { data: categoryData, isLoading: categoryLoading } =
    useGetCategoryQuery();

  const product = useMemo(() => {
    if (data && Array.isArray(data.data) && data.data.length > 0) {
      return data.data[0] as IProduct;
    }
    return null;
  }, [data]);

  const productBrandList = useMemo(() => {
    return productBrandData?.data as IProductBrand[];
  }, [productBrandData?.data]);

  const productTagList = useMemo(() => {
    return tagData?.data as IProductTag[];
  }, [tagData?.data]);

  const printerList = useMemo(() => {
    return printerData?.data as IPrinter[];
  }, [printerData?.data]);

  const categoryList = useMemo(() => {
    return categoryData?.data as ICategory[];
  }, [categoryData?.data]);

  const productBrandMap = useMemo(() => {
    const map = new Map();
    productBrandList?.forEach((brand) => {
      map.set(brand.id, brand.productBrandName);
    });
    return map;
  }, [productBrandList]);

  const productTagMap = useMemo(() => {
    const map = new Map();
    productTagList?.forEach((tag) => {
      map.set(tag.id, tag.tagName);
    });
    return map;
  }, [productTagList]);

  const ProductPrinterMap = useMemo(() => {
    const map = new Map();
    printerList?.forEach((printer) => {
      map.set(printer.id, printer.printerName);
    });
    return map;
  }, [printerList]);

  const categoryMap = useMemo(() => {
    const map = new Map();
    categoryList?.forEach((category) => {
      map.set(category.id, category.categoryName);
    });
    return map;
  }, [categoryList]);

  if (
    isLoading ||
    productBrandLoading ||
    tagLoading ||
    printerLoading ||
    categoryLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error">
        Error fetching product details data. Please try again later.
      </Typography>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card className={classes.stepperCard}>
          <Grid
            container
            justifyContent={"space-between"}
            spacing={5}
            sx={{ borderBottom: 2, padding: 2 }}
            className={classes.titleGrid}
          >
            <Grid
              item
              lg={9}
              md={9}
              sm={9}
              xs={9}
              pt={2}
              alignContent={"center"}
            >
              <Typography variant="h2">{product?.productName}</Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
              xs={3}
              pt={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
                sx={{ display: "flex" }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowForwardIosIcon />}
                    className={classes.addButton}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      pb={5}
                      alignContent={"center"}
                    >
                      <Box className={classes.productBox}>
                        <img
                          src={product?.productImg}
                          alt="Product"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover", // Ensures the image covers the entire box
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      pb={5}
                      alignContent={"center"}
                    >
                      <Box className={classes.productBarCodeBox}>
                        <Barcode value={product?.productBarcode ?? ""} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      pb={5}
                      alignContent={"center"}
                    >
                      <Box className={classes.productBarCodeBox}>
                        <Box
                          sx={{
                            marginLeft: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: 50,
                              height: 50,
                              backgroundColor:
                                product?.productButtonColor ?? "",
                              marginLeft: 1,
                              border: "1px solid #d3d3d3",
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={9} md={9} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card
                  className={classes.stepperCard}
                  sx={{ minHeight: "150px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        spacing={5}
                        sx={{ borderBottom: 2, padding: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          pt={2}
                          alignContent={"center"}
                        >
                          <Typography variant="subtitle1">
                            <span className={classes.customerDetails}>
                              Active :
                            </span>{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                        sx={{ px: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                          textAlign="center"
                        >
                          <Typography variant="subtitle1">
                            {product?.productCanSell ? (
                              <CheckRoundedIcon
                                color="success"
                                sx={{
                                  fontSize: "42px",
                                  // backgroundColor: "red",
                                  // borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <ClearRoundedIcon
                                color="error"
                                sx={{
                                  fontSize: "42px",
                                  // backgroundColor: "red",
                                  // borderRadius: "50%",
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card
                  className={classes.stepperCard}
                  sx={{ minHeight: "150px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        spacing={5}
                        sx={{ borderBottom: 2, padding: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          pt={2}
                          alignContent={"center"}
                        >
                          <Typography variant="subtitle1">
                            <span className={classes.customerDetails}>
                              Can Sell :
                            </span>{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                        sx={{ px: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                          textAlign="center"
                        >
                          <Typography variant="subtitle1">
                            {product?.isActive ? (
                              <CheckRoundedIcon
                                color="success"
                                sx={{
                                  fontSize: "42px",
                                  // backgroundColor: "red",
                                  // borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <ClearRoundedIcon
                                color="error"
                                sx={{
                                  fontSize: "42px",
                                  // backgroundColor: "red",
                                  // borderRadius: "50%",
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card
                  className={classes.stepperCard}
                  sx={{ minHeight: "150px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        spacing={5}
                        sx={{ borderBottom: 2, padding: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          pt={2}
                          alignContent={"center"}
                        >
                          <Typography variant="subtitle1">
                            <span className={classes.customerDetails}>
                              Product View Online :
                            </span>{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                        sx={{ px: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                          textAlign="center"
                        >
                          <Typography variant="subtitle1">
                            {product?.productViewOnline ? (
                              <CheckRoundedIcon
                                color="success"
                                sx={{
                                  fontSize: "42px",
                                  // backgroundColor: "red",
                                  // borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <ClearRoundedIcon
                                color="error"
                                sx={{
                                  fontSize: "42px",
                                  // backgroundColor: "red",
                                  // borderRadius: "50%",
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card
                  className={classes.stepperCard}
                  sx={{ minHeight: "150px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        spacing={5}
                        sx={{ borderBottom: 2, padding: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          pt={2}
                          alignContent={"center"}
                        >
                          <Typography variant="subtitle1">
                            <span className={classes.customerDetails}>
                              Looping :
                            </span>{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                        sx={{ px: 2 }}
                        className={classes.titleGrid}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                          textAlign="center"
                        >
                          <Typography variant="subtitle1">
                            {product?.productDetailsIsLooping ? (
                              <CheckRoundedIcon
                                color="success"
                                sx={{
                                  fontSize: "42px",
                                  // backgroundColor: "red",
                                  // borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <ClearRoundedIcon
                                color="error"
                                sx={{
                                  fontSize: "42px",
                                  // backgroundColor: "red",
                                  // borderRadius: "50%",
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid container spacing={1} pb={5}>
                <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          {product?.productName}
                        </span>{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ px: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignContent={"center"}
                      direction={"column"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          Product Name :
                        </span>{" "}
                        {product?.productName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ px: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignContent={"center"}
                      direction={"column"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          Product Category :
                        </span>{" "}
                        {categoryMap.get(product?.productCategoryId) || ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ px: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignContent={"center"}
                      direction={"column"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          Product Brand :
                        </span>{" "}
                        {productBrandMap.get(product?.productBrandId) || ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ px: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignContent={"center"}
                      direction={"column"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          Product Tag :
                        </span>{" "}
                        {(product?.productTagIds || [])
                          .map((tagId) => productTagMap.get(tagId) || "")
                          .join(", ")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ px: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignContent={"center"}
                      direction={"column"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          Conversion unit :
                        </span>{" "}
                        {product?.productConversionUnit
                          ?.map((unit) => {
                            const unitLabel = Object.keys(SizeOfLevelType).find(
                              (key) =>
                                SizeOfLevelType[
                                  key as keyof typeof SizeOfLevelType
                                ] === unit
                            );
                            return unitLabel;
                          })
                          .join(", ") || " "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ px: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignContent={"center"}
                      direction={"column"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          Printers :
                        </span>{" "}
                        {(product?.productPrinterIds || [])
                          .map((tagId) => ProductPrinterMap.get(tagId) || "")
                          .join(", ")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ px: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignContent={"center"}
                      direction={"column"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          Short Description :
                        </span>{" "}
                        {product?.productShortDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.stepperCard} sx={{ minHeight: "250px" }}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography variant="subtitle1">
                        <span className={classes.customerDetails}>
                          Long Description :
                        </span>{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ px: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignContent={"center"}
                      direction={"column"}
                    >
                      <Typography variant="subtitle1">
                        {product?.productLongDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {product?.productDetailsIsLooping == true && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.stepperCard} sx={{ minHeight: "250px" }}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography variant="subtitle1">
                          <span className={classes.customerDetails}>
                            Looping :
                          </span>{" "}
                          {product?.loopingDetails?.shortTableName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {product?.productDetailsIsLooping && (
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                      <Card className={classes.stepperCard}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={2}
                          sx={{ padding: 2 }}
                          className={classes.titleGrid}
                        >
                          {product?.loopingDetails?.tableColumns.map(
                            (column, index) => (
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                pt={2}
                                alignContent={"center"}
                                key={index}
                                className={
                                  classes.productViewPageTableHeaderCell
                                }
                              >
                                <Typography>
                                  <span className={classes.customerDetails}>
                                    {column.name} :
                                  </span>{" "}
                                  {column.type === "boolean"
                                    ? column.value
                                      ? "True"
                                      : "False"
                                    : column.value}
                                </Typography>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
