import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useStyles } from "../../theme/appStyles";

interface IAlertPopUp {
  open: boolean;
  onCloseAlert: (close: boolean) => void;
  message: string;
}

const AlertPopUp: React.FC<IAlertPopUp> = ({ open, onCloseAlert, message }) => {
  const classes = useStyles();
  const onClose = () => {
    onCloseAlert(false);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ padding: 5, minWidth: 450 }}>
      <Grid className={classes.Dialog}>
        <Grid
          container
          justifyContent={"space-between"}
          spacing={5}
          sx={{ borderBottom: 2, padding: 2 }}
          className={classes.titleGrid}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            pt={2}
            alignContent={"center"}
          >
            <Typography className={classes.cardTitle}>Alert</Typography>
          </Grid>
        </Grid>
        <DialogContent>
          <DialogContentText sx={{ pt: 3 }}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant="contained"
            startIcon={<CheckCircleOutlineIcon />}
            className={classes.addButton}
          >
            okay
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default AlertPopUp;
