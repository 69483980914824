import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Switch,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from "../../../theme/appStyles";
import {
  useDeleteCustomerProjectMutation,
  useDeleteCustomerTransactionMutation,
  useGetCustomerProjectQuery,
  useGetCustomerQuery,
  useGetCustomerTransactionQuery,
  useGetWorkOrderByCustomerProjectQuery,
  useRemovedCustomerProjectMMutation,
  useGetAllInvoiceQuery,
  useCreateEmailMutation, 
  useCreateTextMutation, 
  useCreateNoteMutation,
  useCreateNoteEmailMutation,
  useGetEmailTemplateQuery,
  useGetNoteTemplateQuery,
  useGetProjectActivityHistoryQuery,
} from "../../../Api/attoDeskApi";
import {
  ICustomer,
  ICustomerProject,
  ICustomerTransaction,
  IInvoice,
  IQuoteWorkOrder,
  IEmailTemplate,
  INoteTemplate,
  IActivityHistoryProjectApiResponse,
} from "../../../Api/Interface/api.interface";
// import NewCustomerTransactionPopUp from "./Components/NewCustomerTransactionPopUp";
import { useNotifier } from "../../../Core/Notifier";
import DeletePopup from "../../../Components/Delete/DeletePopup";
import DeletePopupMutaion from "../../../Components/Delete/DeletePopupMutaion";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CiSearch } from "react-icons/ci";
import useDebounce from "../../../Components/SearchDebonce/useDebounce";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import NewPopUpCustomer from "../../Customer/Edit/index";
// import AddProject from "./Components/AddProject";
import dayjs from "dayjs";
import ReceiptIcon from "@mui/icons-material/Receipt";
// import AssignWorkOrder from "./Components/AssignWorkOrder";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from "@mui/icons-material/Info";
import CodeIcon from "@mui/icons-material/Code";
import HistoryIcon from "@mui/icons-material/History";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import ChatIcon from "@mui/icons-material/Chat";
import NoteIcon from "@mui/icons-material/Note";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import NewCustomerTransactionPopUp from "../Components/NewCustomerTransactionPopUp";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  WorkOrderStatus,
} from "../../../Core/Enum/enum";

const getStatusLabel = (statusCode: number): string => {
  switch (statusCode) {
    case WorkOrderStatus.Paid:
      return "Paid";
    case WorkOrderStatus.Cancel:
      return "Canceled";
    case WorkOrderStatus.Pending:
      return "Pending";
    default:
      return "Unknown Status";
  }
};

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const [searchQueryProject, setSearchQueryProject] = useState("");
  const [currentSearchQueryProject, setCurrentSearchQueryProject] =
    useState("");
  const debouncedSearchQueryProject = useDebounce(searchQueryProject, 300);

  const [searchQueryInvoice, setSearchQueryInvoice] = useState("");
  const [currentSearchQueryInvoice, setCurrentSearchQueryInvoice] =
    useState("");
  const debouncedSearchQueryInvoice = useDebounce(searchQueryInvoice, 300);

  const [searchQueryEmailTemplate, setSearchQueryEmailTemplate] = useState("");
  const [currentSearchQueryEmailTemplate, setCurrentSearchQueryEmailTemplate] =
    useState("");
  const debouncedSearchQueryEmailTemplate = useDebounce(searchQueryEmailTemplate, 300);
  const [searchQueryNoteTemplate, setSearchQueryNoteTemplate] = useState("");
  const [currentSearchQueryNoteTemplate, setCurrentSearchQueryNoteTemplate] =
    useState("");
  const debouncedSearchQueryNoteTemplate = useDebounce(searchQueryNoteTemplate, 300);

  const { id: projectId } = useParams<{ id: string }>();
  const [selectCustomer, setSelectCustomer] = useState<ICustomer | null>(null);
  const [transactionData, setTransactionData] =
    useState<ICustomerTransaction>();

  const [deleteCategory] = useDeleteCustomerTransactionMutation();
  const [deleteProject] = useDeleteCustomerProjectMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [openAddTransaction, setOpenAddTransaction] = useState(false);
  const [openAddProject, setOpenAddProject] = useState(false);
  const [projectData, setProjecData] = useState<ICustomerProject>();

  const [openAddWorkOrder, setOpenAddWorkOrder] = useState(false);
  const [WorkOrderData, setWorkOrderData] = useState<IQuoteWorkOrder>();

  const { showErrorMessage, showMessage } = useNotifier();
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [coursingToDelete, setCoursingToDelete] =
    useState<ICustomerTransaction | null>(null);

  const [openDeleteProject, setOpenDeleteProject] = useState(false);
  const [projectToDelete, setProjectToDelete] =
    useState<ICustomerProject | null>(null);

  const [openDeleteProjectWrokOrder, setOpenDeleteProjectWrokOrder] =
    useState(false);
  const [projectWrokOrderToDelete, setProjectWrokOrderToDelete] =
    useState<IQuoteWorkOrder | null>(null);

  const [openProjectTable, setOpenProjectTable] = useState(false);
  const [projectIdByCustomer, setProjectByCustomerId] = useState<number>(0);
  const [projectName, setProjectName] = useState<string>("");
  const [expandedSteps, setExpandedSteps] = useState<number[]>([]);

  const [newNote, { isLoading: isNoteSaving }] = useCreateNoteMutation();
  const [newNoteEmail, { isLoading: isNoteEmailSaving }] = useCreateNoteEmailMutation();

  useEffect(() => {}, [projectId]);

  const [showInfo, setShowInfo] = useState(true);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showTextForm, setShowTextForm] = useState(false);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [newEmail, { isLoading: isEmailSending }] = useCreateEmailMutation();
  const [newText, { isLoading: isTextSending }] = useCreateTextMutation();
  const [isSendAsEmail, setIsSendAsEmail] = useState(false);

  const { data, isLoading, isError } = useGetCustomerProjectQuery({
    searchText: "",
    customerId: "",
    id: String(projectId),
  });

  const project = useMemo(() => {
    if (data && Array.isArray(data.data) && data.data.length > 0) {
      return data.data[0] as ICustomerProject;
    }
    return null;
  }, [data]);

  const {
    data: invoiceData,
    isLoading: invoiceIsLoading,
    isError: invoiceError,
    refetch: invoiceRefetch,
  } = useGetAllInvoiceQuery({
    searchText: currentSearchQueryInvoice,
    id: "",
    quoteWorkOrderCustomerId: String(project?.customerId || ""),
    quoteWorkOrderCustomerProjectId: String(projectId),
  });

  const {
    data: customerTransactionData,
    isLoading: customerDataIsLoading,
    refetch,
  } = useGetCustomerTransactionQuery({
    searchText: currentSearchQuery,
    customerId: String(project?.customerId || ""),
    projectId: String(projectId),
  });

  const {
    data: activityHistoryData,
    isLoading: activityHistoryIsLoading,
    refetch: refetchActivityHistory,
  } = useGetProjectActivityHistoryQuery({
    searchText: "",
    customerId: String(project?.customerId || ""),
    id: String(projectId),
  });

  const {
    data: workOrderProjectData,
    isLoading: workOrderProjectDataIsLoading,
    refetch: workOrderProjectRefetch,
  } = useGetWorkOrderByCustomerProjectQuery({
    searchText: "",
    quoteWorkOrderCustomerId: String(project?.customerId || ""),
    quoteWorkOrderCustomerProjectId: String(projectId),
  });

  const {
    data: customerData,
    isLoading: customerIsloading,
    isError: customerIsError,
  } = useGetCustomerQuery({
    searchText: "",
    id: String(project?.customerId || ""),
  });

  const {
    data: emailTemplateData,
    isLoading: emailTemplateIsLoading,
    isError: emailTemplateError,
    refetch: emailTemplateRefetch,
  } = useGetEmailTemplateQuery({
    searchText: currentSearchQueryEmailTemplate,
    // id: "",
  });

  const {
    data: noteTemplateData,
    isLoading: noteTemplateIsLoading,
    isError: noteTemplateError,
    refetch: noteTemplateRefetch,
  } = useGetNoteTemplateQuery({
    searchText: currentSearchQueryNoteTemplate,
    // id: "",
  });

  const customerList = useMemo(() => {
    if (
      customerData &&
      Array.isArray(customerData.data) &&
      customerData.data.length > 0
    ) {
      return customerData.data[0] as ICustomer;
    }
    return null;
  }, [customerData]);

  const transactions: ICustomerTransaction[] = useMemo(() => {
    if (
      customerTransactionData &&
      Array.isArray(customerTransactionData.data)
    ) {
      return customerTransactionData.data as ICustomerTransaction[];
    }
    return [];
  }, [customerTransactionData]);

  const workOrderProject: IQuoteWorkOrder[] = useMemo(() => {
    if (workOrderProjectData && Array.isArray(workOrderProjectData.data)) {
      return workOrderProjectData.data as IQuoteWorkOrder[];
    }
    return [];
  }, [workOrderProjectData]);

  const invoiceList: IInvoice[] = useMemo(() => {
    if (invoiceData && Array.isArray(invoiceData.data)) {
      return invoiceData.data as IInvoice[];
    }
    return [];
  }, [invoiceData]);

  const activityHistory = useMemo(() => {
    if (data && data.data) {
      return data as IActivityHistoryProjectApiResponse; // Cast the entire response to the expected type
    }
    return null;
  }, [data]);

  const emailTemplateList: IEmailTemplate[] = useMemo(() => {
    if (emailTemplateData && Array.isArray(emailTemplateData.data)) {
      return emailTemplateData.data as IEmailTemplate[];
    }
    return [];
  }, [emailTemplateData]);

  const noteTemplateList: INoteTemplate[] = useMemo(() => {
    if (noteTemplateData && Array.isArray(noteTemplateData.data)) {
      return noteTemplateData.data as INoteTemplate[];
    }
    return [];
  }, [noteTemplateData]);


  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const emailFormik = useFormik({
    initialValues: {
      to: "",
      subject: "",
      message: "",
      template: "",
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {};
      if (!values.to) {
        errors.to = "Email address is required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.to)) {
        errors.to = "Invalid email address";
      }
     
      return errors;
    },
    onSubmit: async (values, { setValues  }) => {
      try {
        // Prepare the data to send via the newEmail API
        const emailData = {
          customer_id: project?.customerId,
          project_id: projectId,
          to: values.to,
          subject: values.subject,
          message: values.message,
        };
  
        // Call the newEmail mutation API and wait for the response
        const emailResponse = await newEmail(emailData).unwrap();
        if (!emailResponse) {
          showErrorMessage("Failed to send email");
         // showErrorMessage(emailResponse.message);
        } else {
          showMessage("Email Sent successfully");
          const refetchResult = await refetchActivityHistory();
          setValues({
            ...values, // Preserve the "to" field
            subject: "", // Reset subject
            message: "", // Reset message
            template: "", // Reset template
          });
        }

      } catch (error) {
        showErrorMessage("Failed to send email");
      }
    },
  });

  const emailFormValid = useMemo(() => {
    return (
      emailFormik.values.to !== "" &&
      emailFormik.values.to !== undefined &&
      emailFormik.values.subject !== "" &&
      emailFormik.values.subject !== undefined &&
      stripHtmlTags(emailFormik.values.message) !=="" &&
      stripHtmlTags(emailFormik.values.message) !==undefined 
    );
  }, [emailFormik.values.to, emailFormik.values.subject, emailFormik.values.message]);



  const textFormik = useFormik({
    initialValues: {
      message: "",
    },
    
    onSubmit: async (values, { setValues  }) => {
      try {
        // Prepare the data to send via the newEmail API
        const textData = {
          customer_id: project?.customerId,
          project_id: projectId,
          phone_no: project?.projectPhoneNumber || "",
          message: stripHtmlTags(values.message),
        };
  
        // Call the newEmail mutation API and wait for the response
        const textResponse = await newText(textData).unwrap();
        if (!textResponse) {
          showErrorMessage("Failed to send sms");
         // showErrorMessage(emailResponse.message);
        } else {
          showMessage("Sms Sent successfully");
          const refetchResult = await refetchActivityHistory();
          setValues({
            ...values, // Preserve the "to" field
            message: "", // Reset message
          });
        }

      } catch (error) {
        showErrorMessage("Failed to send email");
      }
    },
  });

  const textFormValid = useMemo(() => {
    return (
      stripHtmlTags(textFormik.values.message) !=="" &&
      stripHtmlTags(textFormik.values.message) !==undefined 
    );
  }, [textFormik.values.message]);





  const handleNoteEmailSubmit = async (values: any, resetForm: () => void) => {
    try {
    
      const noteEmailData = {
        customer_id: project?.customerId,
        to: project?.projectEmail || "", // Assuming customer is available
        project_id: projectId,
        note_subject: values.subject,
        note_message: values.message,
      };
  
      // Call the send email API
      const emailResponse = await newNoteEmail(noteEmailData).unwrap();
      showMessage("Email Sent successfully");
      const refetchResult = await refetchActivityHistory();
      resetForm();
      setIsSendAsEmail(false);
    } catch (error) {
      showErrorMessage("Failed to send email");
    }
  };
  
  // Function to handle saving a note
  const handleNoteSaveSubmit = async (values: any, resetForm: () => void) => {
    try {
      
      const noteData = {
        customer_id: project?.customerId,
        project_id: projectId,
        note_subject: values.subject,
        note_message: stripHtmlTags(values.message),
      };
  
      // Call the save note API
      const noteResponse = await newNote(noteData).unwrap();
      showMessage("Note saved successfully");
      const refetchResult = await refetchActivityHistory();
      resetForm(); // Reset the form on success
    } catch (error) {
      showErrorMessage("Failed to save note");
    } 
  };

  const noteFormik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
 
    onSubmit: async (values, { resetForm }) => {
      if (isSendAsEmail) {
        // Submit as email
        await handleNoteEmailSubmit(values, resetForm);
      } else {
        // Save as note
        await handleNoteSaveSubmit(values, resetForm);
      }
    },
  });

  const noteFormValid = useMemo(() => {
    return (
      // noteFormik.values.to !== "" &&
      // noteFormik.values.to !== undefined &&
      noteFormik.values.subject !== "" &&
      noteFormik.values.subject !== undefined &&
      stripHtmlTags(noteFormik.values.message) !=="" &&
      stripHtmlTags(noteFormik.values.message) !==undefined 
    );
  }, [noteFormik.values.subject, noteFormik.values.message]);


  useEffect(() => {
    if (debouncedSearchQuery.length >= 4 || debouncedSearchQuery === "") {
      setCurrentSearchQuery(debouncedSearchQuery);
      refetch();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (
      debouncedSearchQueryProject.length >= 4 ||
      debouncedSearchQueryProject === ""
    ) {
      setCurrentSearchQueryProject(debouncedSearchQueryProject);
      refetch();
    }
  }, [debouncedSearchQueryProject]);

  useEffect(() => {
    if (
      debouncedSearchQueryInvoice.length >= 3 ||
      debouncedSearchQueryInvoice === ""
    ) {
      setCurrentSearchQueryInvoice(debouncedSearchQueryInvoice);
      refetch();
    }
  }, [debouncedSearchQueryInvoice]);

  useEffect(() => {
    if (project && project.projectEmail || project?.projectPhoneNumber) {
      emailFormik.setFieldValue("to", project.projectEmail);
      textFormik.setFieldValue("to", project.projectPhoneNumber);
    }
  }, [project]);

  const handleOpenDialog = (customer: ICustomer) => {
    setSelectCustomer(customer);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectCustomer(null);
    setOpenDialog(false);
  };

  const handleOpenAddTransaction = () => {
    setTransactionData(undefined);
    setOpenAddTransaction(true);
  };

  const handleCloseAddTransaction = () => {
    setTransactionData(undefined);
    setOpenAddTransaction(false);
  };

  const handleOpenAddProject = () => {
    setProjecData(undefined);
    setOpenAddProject(true);
  };

  const handleCloseAddProject = () => {
    setProjecData(undefined);
    setOpenAddProject(false);
  };

  const handleOpenAddWorkOrder = () => {
    setWorkOrderData(undefined);
    setOpenAddWorkOrder(true);
  };

  const handleCloseAddWorkOrder = () => {
    setWorkOrderData(undefined);
    setOpenAddWorkOrder(false);
  };

  const handleInfoToggle = () => {
    setShowInfo(true);
    setShowInvoice(false);
    setShowEmailForm(false); // Ensure email is hidden
    setShowNoteForm(false); 
    setShowTextForm(false);
  };

  const handleInvoiceToggle = () => {
    setShowInfo(false);
    setShowInvoice(true);
    setShowEmailForm(false); // Ensure email is hidden
    setShowNoteForm(false); 
    setShowTextForm(false);
  };

  // Toggle Email
  const handleEmailToggle = () => {
    setShowInvoice(false);
    setShowEmailForm(true);
    setShowInfo(false); // Ensure project is hidden
    setShowNoteForm(false);
    setShowTextForm(false); 
  };

  const handleTextToggle = () => {
    setShowInvoice(false);
    setShowEmailForm(false);
    setShowInfo(false); // Ensure project is hidden
    setShowNoteForm(false); 
    setShowTextForm(true); 
  };

  const handleNoteToggle = () => {
    setShowInvoice(false);
    setShowEmailForm(false);
    setShowInfo(false); // Ensure project is hidden
    setShowNoteForm(true); 
    setShowTextForm(false);
  };

  
 

  if (isLoading || customerDataIsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error">
        Error fetching customer data. Please try again later.
      </Typography>
    );
  }

  if (!project) {
    return <Typography>No customer data available.</Typography>;
  }
  const handleOpenDeletePopup = (coursing: ICustomerTransaction) => {
    setCoursingToDelete(coursing);
    setOpenDeleteCategory(true);
  };

  const handleCloseDeletePopup = () => {
    setCoursingToDelete(null);
    setOpenDeleteCategory(false);
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategory(id).unwrap();
      if (response.status) {
        showMessage("Deleted successfully");
        setOpenDeleteCategory(false);
      } else {
        showErrorMessage("Failed to delete the customer");
      }
    } catch (error) {
      showErrorMessage("Failed to delete the customer");
    }
  };

  const handleOpenDeleteProjectPopup = (project: ICustomerProject) => {
    setProjectToDelete(project);
    setOpenDeleteProject(true);
  };

  const handleCloseDeleteProjectPopup = () => {
    setProjectToDelete(null);
    setOpenDeleteProject(false);
  };

  const handleDeleteProject = async (id: string) => {
    try {
      const response = await deleteProject(id).unwrap();
      if (response.status) {
        showMessage("Deleted successfully");
        setOpenDeleteProject(false);
      } else {
        showErrorMessage("Failed to delete the customer project");
      }
    } catch (error) {
      showErrorMessage("Failed to delete the customer project");
    }
  };

  const handleOpenDeleteWrokOrderPopup = (project: IQuoteWorkOrder) => {
    setProjectWrokOrderToDelete(project);
    setOpenDeleteProjectWrokOrder(true);
  };

  const handleCloseDeleteWrokOrderPopup = () => {
    setProjectWrokOrderToDelete(null);
    setOpenDeleteProjectWrokOrder(false);
  };

 

  const emailLogs = activityHistoryData?.data?.emailLogs || [];
  const smsLogs = activityHistoryData?.data?.smsLogs || [];
  const noteLogs = activityHistoryData?.data?.noteLogs || [];
  const notes = activityHistoryData?.data?.notes || [];

  const combinedLogs = [
    ...emailLogs.map((log) => ({
      ...log,
      type: "Email Sent",
    })),
    ...smsLogs.map((log) => ({
      ...log,
      type: "Sms Sent",
    })),
    ...noteLogs.map((log) => ({
      ...log,
      type: "Note Email Sent",
    })),
    ...notes.map((log) => ({
      ...log,
      type: "Note Saved",
    })),
  ];

  const sortedCombinedLogs = combinedLogs.sort((a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    return dateB - dateA; // Sort descending
  });
  
  const timeLineSteps = sortedCombinedLogs.map((log, index) => {
    let description;
  
    if (log.type === "Sms Sent") {
      description = `Message | ${log.message || 'N/A'}`; // Customize description for SMS
    } else {
      description = `Subject | ${log.subject || 'No Subject'}`; // Default for emails and note emails
    }
  
    return {
      key: `${log.type}-${log.created_at}-${log.id || index}`,
      label: log.type,
      description, // Use the customized description
      date: new Date(log.created_at).toLocaleDateString(),
      time: new Date(log.created_at).toLocaleTimeString(),
    };
  });

  const toggleStepDetails = (index: number) => {
    if (expandedSteps.includes(index)) {
      setExpandedSteps(
        expandedSteps.filter((stepIndex) => stepIndex !== index)
      );
    } else {
      setExpandedSteps([...expandedSteps, index]);
    }
  };

  const convertPlainTextToHTML= (text: string) => {
    return text
      .replace(/\n/g, '<br>') // Replace line breaks with <br>
      .replace(/ {2,}/g, match => '&nbsp;'.repeat(match.length)); // Replace multiple spaces with &nbsp;
  }
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSendAsEmail(event.target.checked); // Toggle the state when switch is changed
  };


  return (
    <div>
      <Grid container spacing={3}>

      
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.card}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.profileBottomSetUp}
                  >
                    <Grid className={classes.profileBottomDwonProfile}>
                      <Typography className={classes.profileTypograpy}>
                        {project.projectName.charAt(0)}
                      </Typography>
                    </Grid>

                    <Grid className={classes.profileDetails}>
                      <Typography
                        variant="h4"
                        className={classes.profileDetailItem}
                      >
                        {project.projectName}
                      </Typography>

                      <Typography
                        variant="body1"
                        className={classes.contactInfo}
                      >
                        <span className={classes.iconText}>
                          <PhoneIcon /> {project.projectPhoneNumber}
                        </span>

                        <span className={classes.iconText}>
                          <EmailIcon /> {project.projectEmail}
                        </span>
                      </Typography>
                      <Grid className={classes.buttonGroup}>
                        <div className={classes.buttonItem}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.editButtonProfile}
                            onClick={handleInfoToggle}
                          >
                            <InfoIcon />
                          </Button>
                          <Typography className={classes.buttonLabel}>
                            Info
                          </Typography>
                        </div>

                        <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                                onClick={handleInvoiceToggle}
                              >
                                <DescriptionIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                Invoice
                              </Typography>
                            </div>
                        <div className={classes.buttonItem}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.editButtonProfile}
                            onClick={handleEmailToggle}
                          >
                            <EmailIcon />
                          </Button>
                          <Typography className={classes.buttonLabel}>
                            Email
                          </Typography>
                        </div>

                        <div className={classes.buttonItem}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.editButtonProfile}
                          
                              >   
                                <PhoneIcon />
                              </Button>
                              <Typography className={classes.buttonLabel}>
                                Call
                              </Typography>
                            </div>

                        <div className={classes.buttonItem}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.editButtonProfile}
                            onClick={handleTextToggle}
                          >
                            <ChatIcon />
                          </Button>
                          <Typography className={classes.buttonLabel}>
                            Text
                          </Typography>
                        </div>

                        <div className={classes.buttonItem}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.editButtonProfile}
                            onClick={handleNoteToggle}
                          >
                            <NoteIcon />
                          </Button>
                          <Typography className={classes.buttonLabel}>
                            Note
                          </Typography>
                        </div>

                        <div className={classes.buttonItem}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.editButtonProfile}
                          >
                            <HistoryIcon />
                          </Button>
                          <Typography className={classes.buttonLabel}>
                            History
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            
            {showInfo && 
            <><Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                <Card className={classes.stepperCard}>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            spacing={5}
                            sx={{ borderBottom: 2, padding: 2 }}
                            className={classes.titleGrid}
                          >
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              xs={8}
                              pt={2}
                              alignContent={"center"}
                            >
                              <Typography className={classes.cardTitle}>
                                Project Details
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            spacing={5}
                            sx={{ borderBottom: 2, px: 2 }}
                            className={classes.titleGrid}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                <span className={classes.customerDetails}>
                                  Type
                                </span>{" "}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              xs={8}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                {project.projectType}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            spacing={5}
                            sx={{ borderBottom: 2, px: 2 }}
                            className={classes.titleGrid}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                <span className={classes.customerDetails}>
                                  Start Date
                                </span>{" "}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              xs={8}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                {project?.projectStartDate
                                  ? dayjs(project.projectStartDate).format(
                                    "MMMM D, YYYY [at] h:mm A"
                                  )
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            spacing={5}
                            sx={{ borderBottom: 2, px: 2 }}
                            className={classes.titleGrid}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                <span className={classes.customerDetails}>
                                  Estimated End Date
                                </span>{" "}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              xs={8}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                {project?.projectEstimatedEndDate
                                  ? dayjs(project.projectStartDate).format(
                                    "MMMM D, YYYY [at] h:mm A"
                                  )
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            spacing={5}
                            sx={{ borderBottom: 2, px: 2 }}
                            className={classes.titleGrid}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                <span className={classes.customerDetails}>
                                  Proposal Date
                                </span>{" "}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              xs={8}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                {project?.projectProposalDate
                                  ? dayjs(project.projectProposalDate).format(
                                    "MMMM D, YYYY [at] h:mm A"
                                  )
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>



                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            spacing={5}
                            sx={{ borderBottom: 2, px: 2 }}
                            className={classes.titleGrid}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                <span className={classes.customerDetails}>
                                  Address
                                </span>{" "}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              xs={8}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                {project.projectAddress}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            spacing={5}
                            sx={{ px: 2, pb: 2 }}
                            className={classes.titleGrid}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              <Typography variant="subtitle1">
                                <span className={classes.customerDetails}>
                                  Proposal
                                </span>{" "}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              xs={8}
                              pt={2}
                              alignContent={"center"}
                              direction={"column"}
                            >
                              {project.projectUploadProposal ? (
                                <Typography>
                                  <a
                                    href={project.projectUploadProposal}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      // textDecoration: "none",
                                      // color: "inherit",
                                    }}
                                  >
                                    <PictureAsPdfIcon
                                      style={{
                                        marginRight: "8px",
                                        color: "black",
                                      }} />{" "}
                                    {project.projectName || "Proposal"}{" "}
                                  </a>
                                </Typography>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              
              
              <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Invoices
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Grid
                          container
                          spacing={1}
                          justifyContent="flex-end"
                          sx={{ display: "flex" }}
                        >
                          <Grid item>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CiSearch
                                      className={classes.searchWidgetIcon} />
                                  </InputAdornment>
                                ),
                                classes: {
                                  root: classes.formControlList,
                                },
                              }}
                              fullWidth
                              value={searchQueryInvoice}
                              onChange={(e) => setSearchQueryInvoice(e.target.value)}
                              placeholder="Name .."
                              sx={{ width: "100%" }}
                              size="small" />
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.addButton}
                              onClick={() => navigate(
                                `/invoice/create/customer/${project.customerId}/project/${projectId}`
                              )}
                            >
                              Add Invoice
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead className={classes.tableHeaderRow}>
                            <TableRow>
                              <TableCell className={classes.tablheader}>
                                <strong>Invoice No</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Status</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Create Date</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Total</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Actions</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoiceList.map((transaction) =>  {
                                const statusLabel = getStatusLabel(transaction.quoteWorkOrderStatus);
                                return(
                              <TableRow
                                key={transaction.id}
                                className={classes.tableRowHover}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {transaction.InvoiceNo}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                {statusLabel}
                                </TableCell>
                              
                                <TableCell scope="row" className={classes.tablCell}>
                                  {transaction?.created_at
                                    ? dayjs(transaction.created_at).format(
                                      "MMMM D, YYYY [at] h:mm A"
                                    )
                                    : ""}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {"$"}
                                  {parseFloat(
                                    transaction?.quoteWorkOrderSaleGrandTotal || "0"
                                  ).toFixed(2)}
                                </TableCell>

                                <TableCell scope="row" className={classes.tablCell}>
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        className={classes.viewButtonList}
                                        onClick={() => navigate(
                                          `/invoice/history/${transaction.id}`
                                        )}
                                      >
                                        <VisibilitySharpIcon
                                          className={classes.viewListIcon} />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            )})}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Card>
                </Grid>

                
                
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Card className={classes.card}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ padding: 5 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Box className={classes.editButtonProfileBox}>
                              <ShoppingBasketIcon sx={{ fontSize: 32 }} />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            pt={2}
                            alignContent={"center"}
                            textAlign={"center"}
                          >
                            <Typography className={classes.h3}>$24</Typography>
                            <Typography className={classes.buttonLabel}>
                              Total Order
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Card className={classes.card}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ padding: 5 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Box className={classes.editButtonProfileBox}>
                              <ShoppingBasketIcon sx={{ fontSize: 32 }} />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            pt={2}
                            alignContent={"center"}
                            textAlign={"center"}
                          >
                            <Typography className={classes.h3}>$2500</Typography>
                            <Typography className={classes.buttonLabel}>
                              Cost
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Card className={classes.card}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ padding: 5 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Box className={classes.editButtonProfileBox}>
                              <ShoppingBasketIcon sx={{ fontSize: 32 }} />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            pt={2}
                            alignContent={"center"}
                            textAlign={"center"}
                          >
                            <Typography className={classes.h3}>$03</Typography>
                            <Typography className={classes.buttonLabel}>
                              Due
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Card className={classes.card}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ padding: 5 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Box className={classes.editButtonProfileBox}>
                              <ShoppingBasketIcon sx={{ fontSize: 32 }} />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            pt={2}
                            alignContent={"center"}
                            textAlign={"center"}
                          >
                            <Typography className={classes.h3}>$5000</Typography>
                            <Typography className={classes.buttonLabel}>
                              Cost
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>

        

        </>

      }


{showInvoice && (

<Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Invoices
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Grid
                          container
                          spacing={1}
                          justifyContent="flex-end"
                          sx={{ display: "flex" }}
                        >
                          <Grid item>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CiSearch
                                      className={classes.searchWidgetIcon} />
                                  </InputAdornment>
                                ),
                                classes: {
                                  root: classes.formControlList,
                                },
                              }}
                              fullWidth
                              value={searchQueryInvoice}
                              onChange={(e) => setSearchQueryInvoice(e.target.value)}
                              placeholder="Name .."
                              sx={{ width: "100%" }}
                              size="small" />
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.addButton}
                              onClick={() => navigate(
                                `/invoice/create/customer/${project.customerId}/project/${projectId}`
                              )}
                            >
                              Add Invoice
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead className={classes.tableHeaderRow}>
                            <TableRow>
                              <TableCell className={classes.tablheader}>
                                <strong>Invoice No</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Status</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Create Date</strong>
                              </TableCell>
                              <TableCell className={classes.tablheader}>
                                <strong>Total</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tablheader}
                                sx={{ textAlign: "right" }}
                              >
                                <strong>Actions</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoiceList.map((transaction) =>  {
                                const statusLabel = getStatusLabel(transaction.quoteWorkOrderStatus);
                              return(
                              <TableRow
                                key={transaction.id}
                                className={classes.tableRowHover}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {transaction.InvoiceNo}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {statusLabel}
                                </TableCell>
                                <TableCell scope="row" className={classes.tablCell}>
                                  {transaction?.created_at
                                    ? dayjs(transaction.created_at).format(
                                      "MMMM D, YYYY [at] h:mm A"
                                    )
                                    : ""}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tablCell}
                                >
                                  {"$"}
                                  {parseFloat(
                                    transaction?.quoteWorkOrderSaleGrandTotal || "0"
                                  ).toFixed(2)}
                                </TableCell>

                                <TableCell scope="row" className={classes.tablCell}>
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        className={classes.viewButtonList}
                                        onClick={() => navigate(
                                          `/invoice/history/${transaction.id}`
                                        )}
                                      >
                                        <VisibilitySharpIcon
                                          className={classes.viewListIcon} />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            )})}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Card>
                </Grid>
)}


{showEmailForm && (


<Grid item lg={12} md={12} sm={12} xs={12}>
          <form onSubmit={emailFormik.handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card className={classes.card}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={5}
                    sx={{ borderBottom: 2, padding: 2 }}
                    className={classes.titleGrid}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      alignContent={"center"}
                    >
                      <Typography className={classes.cardTitle}>
                       Compose Email
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} p={"24px"}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                             To
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Email"
                            size="small"
                            {...emailFormik.getFieldProps("to")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            error={
                              emailFormik.touched.to &&
                              Boolean(emailFormik.errors.to)
                            }
                            helperText={
                              emailFormik.touched.to &&
                              emailFormik.errors.to
                            }
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Subject
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            placeholder="Enter Subject"
                            size="small"
                            {...emailFormik.getFieldProps("subject")}
                            className={classes.textField}
                            InputProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                           
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">
                            Message
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div
        style={{
        height: "210px",
       border:  "1px solid #ccc", 
       borderRadius: "4px", // Optional: Add some rounding to the corners
        overflow: "hidden", // Prevent content overflow
      }}
    >
                <ReactQuill
                  value={emailFormik.values.message}
                  onChange={(value) => emailFormik.setFieldValue("message", value)}
                  style={{
                    height: "210px",
                  }}
                />
                
    </div>

   
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="subtitle1">Select Template</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            select
                            size="small"
                            className={classes.textField}
                            SelectProps={{
                              native: false,
                            }}
                            defaultValue=""
                            value={emailFormik.values.template}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => {
                              const selectedTemplateId = event.target.value;
                            
                              const selectedTemplate = emailTemplateList.find(template => template.id === Number(selectedTemplateId));
                          
                              if (selectedTemplate) {
                                // Set the form fields for subject and message based on the selected template
                                emailFormik.setFieldValue("subject", selectedTemplate.subject);
                                emailFormik.setFieldValue("message", convertPlainTextToHTML(selectedTemplate.message));
                            } else {
                                // Reset the subject and message if "Select an option" is chosen
                                emailFormik.setFieldValue("subject", "");
                                emailFormik.setFieldValue("message", "");
                            }
                              // Set the selected template value
                              emailFormik.setFieldValue("template", selectedTemplateId);
                            }}
                          >
                            <MenuItem value="Select option" >
                              Select option
                            </MenuItem>
                          
        {emailTemplateList.map((template) => (
          <MenuItem key={template.id} value={template.id}>
            {template.template_name} {/* Display the name of the template */}
          </MenuItem>
        ))}
                          
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>



                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                <Card className={classes.card}>
                  <Grid container spacing={3} p={"24px"}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                    
                      <Button
                        variant="contained"
                        startIcon={<SaveAltIcon />}
                        className={classes.addButton}
                        onClick={() => emailFormik.handleSubmit()}
                        disabled={!emailFormValid || isEmailSending}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>


        )}

{showTextForm && (
         <Grid item lg={12} md={12} sm={12} xs={12}>
         <form onSubmit={textFormik.handleSubmit}>
           <Grid container>
             <Grid item lg={12} md={12} sm={12} xs={12}>
               <Card className={classes.card}>
                 <Grid
                   container
                   justifyContent={"space-between"}
                   spacing={5}
                   sx={{ borderBottom: 2, padding: 2 }}
                   className={classes.titleGrid}
                 >
                   <Grid
                     item
                     lg={12}
                     md={12}
                     sm={12}
                     xs={12}
                     pt={2}
                     alignContent={"center"}
                   >
                     <Typography className={classes.cardTitle}>
                      New Message
                     </Typography>
                   </Grid>
                 </Grid>

                 <Grid container spacing={3} p={"24px"}>

                 
                   <Grid item lg={12} md={12} sm={12} xs={12}>
                     <Grid container alignItems="center">
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <Typography variant="subtitle1">
                           Text Message
                         </Typography>
                       </Grid>
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                       <div
       style={{
       height: "210px",
      border:  "1px solid #ccc", 
      borderRadius: "4px", // Optional: Add some rounding to the corners
       overflow: "hidden", // Prevent content overflow
     }}
   >
               <ReactQuill
                 value={textFormik.values.message}
                 onChange={(value) => textFormik.setFieldValue("message", value)}
                 style={{
                   height: "210px",
                 }}
               />
               
   </div>

                       </Grid>
                     </Grid>
                   </Grid>

               
 

                 </Grid>
               </Card>
             </Grid>

             <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
               <Card className={classes.card}>
                 <Grid container spacing={3} p={"24px"}>
                   <Grid
                     item
                     lg={12}
                     md={12}
                     sm={12}
                     xs={12}
                     sx={{ display: "flex", justifyContent: "flex-end" }}
                   >
                   
                     <Button
                       variant="contained"
                       startIcon={<SaveAltIcon />}
                       className={classes.addButton}
                       onClick={() => textFormik.handleSubmit()}
                       disabled={!textFormValid || isTextSending}
                     >
                        Send
                     </Button>
                   </Grid>
                 </Grid>
               </Card>
             </Grid>
           </Grid>
         </form>
       </Grid>
        )} 

     {showNoteForm && (
         <Grid item lg={12} md={12} sm={12} xs={12}>
         <form onSubmit={noteFormik.handleSubmit}>
           <Grid container>
             <Grid item lg={12} md={12} sm={12} xs={12}>
               <Card className={classes.card}>
                 <Grid
                   container
                   justifyContent={"space-between"}
                   spacing={5}
                   sx={{ borderBottom: 2, padding: 2 }}
                   className={classes.titleGrid}
                 >
                   <Grid
                     item
                     lg={12}
                     md={12}
                     sm={12}
                     xs={12}
                     pt={2}
                     alignContent={"center"}
                   >
                     <Typography className={classes.cardTitle}>
                      New Message
                     </Typography>
                   </Grid>
                 </Grid>

                 <Grid container spacing={3} p={"24px"}>

                   <Grid item lg={12} md={12} sm={12} xs={12}>
                     <Grid container alignItems="center">
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <Typography variant="subtitle1">
                           Subject
                         </Typography>
                       </Grid>
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <TextField
                           placeholder="Enter Subject"
                           size="small"
                           {...noteFormik.getFieldProps("subject")}
                           className={classes.textField}
                           InputProps={{
                             sx: {
                               fontSize: 14,
                             },
                           }}
                           InputLabelProps={{
                             sx: {
                               fontSize: 14,
                             },
                           }}
                          
                         />
                       </Grid>
                     </Grid>
                   </Grid>

                   <Grid item lg={12} md={12} sm={12} xs={12}>
                     <Grid container alignItems="center">
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <Typography variant="subtitle1">
                           Note Message
                         </Typography>
                       </Grid>
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                       <div
       style={{
       height: "210px",
      border:  "1px solid #ccc", 
      borderRadius: "4px", // Optional: Add some rounding to the corners
       overflow: "hidden", // Prevent content overflow
     }}
   >
               <ReactQuill
                 value={noteFormik.values.message}
                 onChange={(value) => noteFormik.setFieldValue("message", value)}
                 style={{
                   height: "210px",
                 }}
               />
               
   </div>

  
                       </Grid>
                     </Grid>
                   </Grid>

                   <Grid item lg={10} md={12} sm={12} xs={12}>
          <Grid container alignItems="center">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="subtitle1">Send as Email</Typography>
              <Switch
                checked={isSendAsEmail}
                onChange={handleToggleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        </Grid>
          {isSendAsEmail && (
                   <Grid item lg={10} md={12} sm={12} xs={12}>
                     <Grid container alignItems="center">
                       <Grid item lg={12} md={12} sm={12} xs={12}>
                         <Typography variant="subtitle1">Select Template</Typography>
                       </Grid>
                       <Grid item lg={6} md={6} sm={12} xs={12}>
                         <TextField
                           select
                           size="small"
                           className={classes.textField}
                           SelectProps={{
                             native: false,
                           }}
                           defaultValue=""
                           InputLabelProps={{ shrink: true }}
                           onChange={(event) => {
                             const selectedTemplateId = event.target.value;
                           
                             const selectedTemplate = noteTemplateList.find(template => template.id === Number(selectedTemplateId));
                         
                             if (selectedTemplate) {
                               // Set the form fields for subject and message based on the selected template
                               noteFormik.setFieldValue("subject", selectedTemplate.subject);
                               noteFormik.setFieldValue("message", convertPlainTextToHTML(selectedTemplate.note_message));
                           }
                          
                            //  noteFormik.setFieldValue("template", selectedTemplateId);
                           }}
                         >
                           <MenuItem value="Select option" >
                             Select option
                           </MenuItem>
                         
       {noteTemplateList.map((template) => (
         <MenuItem key={template.id} value={template.id}>
           {template.note_title} {/* Display the name of the template */}
         </MenuItem>
       ))}
                         
                         </TextField>
                       </Grid>
                     </Grid>
                   </Grid>

)}

                 </Grid>
               </Card>
             </Grid>

             <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
               <Card className={classes.card}>
                 <Grid container spacing={3} p={"24px"}>
                   <Grid
                     item
                     lg={12}
                     md={12}
                     sm={12}
                     xs={12}
                     sx={{ display: "flex", justifyContent: "flex-end" }}
                   >
                   
                     <Button
                       variant="contained"
                       startIcon={<SaveAltIcon />}
                       className={classes.addButton}
                       onClick={() => noteFormik.handleSubmit()}
                       disabled={!noteFormValid || isNoteSaving ||isNoteEmailSaving}
                     >
                     { isSendAsEmail ?'Send' : 'Save'}
                     </Button>
                   </Grid>
                 </Grid>
               </Card>
             </Grid>
           </Grid>
         </form>
       </Grid>
        )}
  </Grid>
  </Grid>



        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.card}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.profileBottomSetUpSmall}
                  >
                    <Grid className={classes.profileBottomDwon}>
                      {customerList ? (
                        customerList.customerImg ? (
                          <img
                            src={customerList.customerImg}
                            alt="Profile"
                            className={classes.profileImage}
                          />
                        ) : (
                          <Avatar className={classes.profileImage} />
                        )
                      ) : (
                        <Avatar className={classes.profileImage} />
                      )}
                    </Grid>

                    <Grid className={classes.profileDetails}>
                      <Typography
                        variant="h4"
                        className={classes.profileDetailItem}
                      >
                        {(customerList?.customerFirstName || "") +
                          " " +
                          (customerList?.customerLastName || "")}
                      </Typography>

                      <Typography
                        variant="body1"
                        className={classes.contactInfo}
                      >
                        <span className={classes.iconText}>
                          <PhoneIcon /> {customerList?.customerMobileNum}
                        </span>
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.contactInfo}
                      >
                        <span className={classes.iconText}>
                          <EmailIcon /> {customerList?.customerEmail}
                        </span>
                      </Typography>
                      <Button
                        variant="outlined"
                        endIcon={<ArrowForwardIosIcon />}
                        className={classes.addButton}
                        onClick={() => navigate(-1)}
                        sx={{ mt: 2 }}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.stepperCard}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Activity History
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                        className={classes.titleGrid}
                      >
                        <Box
                          sx={{
                            height: "600px",
                            overflow: "auto",
                            padding: "2px",
                            "&::-webkit-scrollbar": {
                              width: "4px",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#eeeeee",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "#eeeeee",
                              borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#eeeeee",
                            },
                          }}
                        >
                          <div>
    {timeLineSteps.length === 0 ? (
      <p style={{ textAlign: "center", padding: "20px" }}>No activity history found</p>
    ) : (
                          <Stepper
                            orientation="vertical"
                            activeStep={timeLineSteps.length}
                          >
                            {timeLineSteps.map((step, index) => (
                              <Step key={step.label} completed>
                                <StepLabel>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography className={classes.h5}>
                                      {step.label}
                                      <Typography
                                        variant="subtitle1"
                                        className={classes.showMoreLink}
                                        onClick={() => toggleStepDetails(index)}
                                      >
                                        {expandedSteps.includes(index)
                                          ? "Show less"
                                          : "Show more"}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        {step.time}
                                      </Typography>
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      {step.date}
                                    </Typography>
                                  </Box>
                                </StepLabel>

                                {expandedSteps.includes(index) && (
                                  <Box sx={{ ml: 4, mt: 1 }}>
                                    <Typography>{step.description}</Typography>
                                  </Box>
                                )}
                              </Step>
                            ))}

                          
                          </Stepper>
                            )}
  </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.card}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Payment Information
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        alignContent={"left"}
                      >
                        <img
                          src="/path-to-your-card-image.png"
                          alt="Card"
                          // alt={row.productName.toLowerCase()}
                          className={classes.SelectImageTableBoxProject}
                        />
                      </Grid>
                      <Grid item lg={9} md={9} sm={12} xs={12} pt={2}>
                        <Typography variant="subtitle1">Master Card</Typography>
                        <Typography variant="subtitle1">
                          XXXX XXXX XXXX 0985
                        </Typography>
                        <Typography variant="subtitle1">
                          Card Holdr Name: User 1
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Subscriptions 
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ padding: 2 }}
                      className={classes.titleGrid}
                    >
                      {transactions.map((transaction) => (
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          key={transaction.id}
                        >
                        

                          <Grid container spacing={1} sx={{ borderBottom: 0.5  }}>
                            <Grid item lg={5} md={5} sm={5} xs={5}
                              >
                              <Typography
                                variant="subtitle1"
                               
                              >
                                {transaction.transactionName}
                              </Typography>
                            </Grid>
                            <Grid item lg={7} md={7} sm={7} xs={7}>
                                <Typography
                                variant="subtitle1" >
                                {transaction?.transactionCollection}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                              
                              >
                                {transaction?.created_at
                                  ? dayjs(transaction.created_at).format(
                                      "MMMM D, YYYY [at] h:mm A"
                                    )
                                  : ""}
                              </Typography>
                              
                              
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Grid
                          container
                          spacing={1}
                          justifyContent="flex-end"
                          sx={{ display: "flex" }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.addButton}
                              onClick={handleOpenAddTransaction}
                            >
                              Add Subscription
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>



                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openAddTransaction && (
        <NewCustomerTransactionPopUp
          openModel={openAddTransaction}
          handleCloseDialog={handleCloseAddTransaction}
          data={transactionData}
          customerId={project.customerId}
          projectId={projectId}
        />
      )}
    </div>
  );
};

export default Index;
