import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, TextField, Button, Box, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStyles } from "../../../theme/appStyles";
import axios from "axios";
import { useNotifier } from "../../../Core/Notifier";
import CircularProgress from "@mui/material/CircularProgress";
import { CiBoxList } from "react-icons/ci";
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

const ExpenseForm = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [isHST, setIsHST] = useState(false);
  const [total, setTotal] = useState(0);
  const [hstAmount, setHstAmount] = useState(0);
  const { showErrorMessage, showMessage } = useNotifier();
  const [loading, setLoading] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState<ExpenseCategory[]>([]); // Type expenseCategories as an array of ExpenseCatego
  const navigate = useNavigate();
  
  interface ExpenseCategory {
    id: number;
    category_name: string;
  }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/view-expense-category`); // Replace with your endpoint
        setExpenseCategories(response.data || []); // Update state with categories

      } catch (error) {
        showErrorMessage("Failed to fetch categories");
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      date: new Date().toISOString().split("T")[0],
      description: "",
      amount: "",
      hst: false,
      total: "",      
      category: "",
      status: "Unpaid",
      file: [] as File[],
      beforeImages: [] as File[],
      afterImages: [] as File[],
      remarks:"",
    },
    validationSchema: yup.object({
      date: yup.date().required("Date is required"),
      description: yup.string().required("Description is required"),
      amount: yup
        .number()
        .required("Amount is required")
        .positive("Amount must be positive"),
      category: yup.string().required("Category is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Prepare the form data to be sent
        const formData = new FormData();
        formData.append("date", values.date);
        formData.append("description", values.description);
        formData.append("amount", values.amount);
        formData.append("hst", isHST ? hstAmount.toString() : "0"); 
        formData.append("total", total.toString());
        formData.append("category", values.category);
        formData.append("status", values.status);
        formData.append("remarks", values.remarks);

        if (values.file && values.file.length > 0) {
          values.file.forEach((file) => {
            formData.append("files[]", file); // Use `files[]` or appropriate field name
          });
        }

        if (values.beforeImages && values.beforeImages.length > 0) {
          values.beforeImages.forEach((beforeImages) => {
            formData.append("beforeImages[]", beforeImages); // Use `files[]` or appropriate field name
          });
        }

        if (values.afterImages && values.afterImages.length > 0) {
          values.afterImages.forEach((afterImages) => {
            formData.append("afterImages[]", afterImages); // Use `files[]` or appropriate field name
          });
        }
        // Send the data to the Laravel backend using axios
        const response = await axios.post(`${API_URL}/save-expenses`, formData, {
         
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

      //  console.log("Response:", response.data);
        showMessage("Expense saved successfully!");
        formik.resetForm();
      } catch (error) {
       // console.error("Error saving expense:", error);
        showErrorMessage("An error occurred while saving the expense.");
            
      }finally {
        setLoading(false); // Reset loading state after the submission completes
      }
    },
  });

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const amount = parseFloat(event.target.value || "0");
    formik.setFieldValue("amount", amount);
  
    const hstAmount = isHST ? amount * 0.13 : 0;
    setHstAmount(hstAmount);
  
    const updatedTotal = amount + hstAmount;
    setTotal(updatedTotal);
    formik.setFieldValue("total", updatedTotal);
  };
  
  
  const handleHSTToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsHST(checked);
  
    const amount = parseFloat(formik.values.amount || "0");
    const hstAmount = checked ? amount * 0.13 : 0;
    setHstAmount(hstAmount);
    const updatedTotal = amount + hstAmount;
    setTotal(updatedTotal);
    formik.setFieldValue("total", updatedTotal);
  };

  

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card>
            <Grid
              container
              justifyContent="space-between"
              sx={{ borderBottom: 2, padding: 2 }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pt={2}
                alignContent={"center"}
              >
                <Typography className={classes.cardTitle}>
                  Create Expense
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item lg={9} md={9} sm={12} xs={12}>
  <form onSubmit={formik.handleSubmit}>
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card>
          <Grid container spacing={3} sx={{ padding: 3 }}>
            {/* Date Field */}
            <Grid item xs={7}>
              <Typography variant="subtitle1">Date</Typography>
              <TextField
                type="date"
                size="small"
                value={formik.values.date}
                onChange={formik.handleChange}
                name="date"
                fullWidth
              />
            </Grid>

            {/* Description Field */}
            <Grid item xs={7}>
              <Typography variant="subtitle1">Description</Typography>
              <TextField
                placeholder="Enter description"
                size="small"
                {...formik.getFieldProps("description")}
                multiline
                rows={4} 
                fullWidth
                
              />
            </Grid>

            {/* Amount Field */}
            <Grid item xs={7}>
              <Typography variant="subtitle1">Amount</Typography>
              <TextField
               placeholder="Enter amount"
               size="small"
               type="number"
               value={formik.values.amount}
               onChange={(event) => handleAmountChange(event)}
               fullWidth
             />
            </Grid>

            {/* HST Checkbox */}
            <Grid item xs={7}>
  <Typography variant="subtitle1">Include HST(13%)?</Typography>
  <Grid container alignItems="center">
    <Grid item>
      <FormControlLabel
        control={
          <Checkbox
            checked={isHST}
            onChange={handleHSTToggle}
            color="primary"
          />
        }
        label="Yes"
      />
    </Grid>
    {isHST && (
      <Grid item sx={{ marginLeft: 2 }}>
        <Typography variant="body2">
          HST = ${hstAmount.toFixed(2)}
        </Typography>
      </Grid>
    )}
  </Grid>
</Grid>
         

            {/* Total Field */}
            <Grid item xs={7}>
              <Typography variant="subtitle1">Total</Typography>
              <TextField
                size="small"
                value={total.toFixed(2)}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            {/* Category Dropdown */}
            <Grid item xs={7}>
              <Typography variant="subtitle1">Category</Typography>
              <TextField
                select
                size="small"
                {...formik.getFieldProps("category")}
                fullWidth
              >
                 {expenseCategories.map((category, index) => (
                          <MenuItem key={index} value={category.id}>
                            {category.category_name}
                          </MenuItem>
                        ))}
              </TextField>
            </Grid>


            <Grid item xs={7}>
  <Typography variant="subtitle1">Status</Typography>
  <FormControlLabel
    control={
      <Checkbox
        checked={formik.values.status === "Paid"} // Check if status is "Paid"
        onChange={(e) => formik.setFieldValue("status", e.target.checked ? "Paid" : "Unpaid")} // Update Formik value based on checkbox state
        color="primary"
      />
    }
    label="Paid"
  />
</Grid>



            <Grid container spacing={2} mt={2} ml={2} mb={2}>
            {/* File Upload Field */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">Upload Invoice</Typography>
              <input
                type="file"
               multiple
                onChange={(event) =>{
                  const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
                  const allFiles = [...formik.values.file, ...newFiles]; // Merge the new files with the already selected files
                  formik.setFieldValue("file", allFiles); // Update Formik's file field
                }}
                
                style={{ display: "none" }}
                id="file-upload"
              /><Box>
                 <label htmlFor="file-upload">
                  <Button variant="outlined" component="span">
                    Choose Files
                  </Button>
                </label>
                {formik.values.file && formik.values.file.length > 0 && (
      <Box sx={{ mt: 1 }}>
        <Typography variant="body2" color="textSecondary">
          Selected Invoice:
        </Typography>
        <ul>
          {formik.values.file.map((file, index) => (
            <li key={index}>
              {file.name}
              {/* Remove file button */}
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  // Remove the selected file
                  const updatedFiles = formik.values.file.filter((_, i) => i !== index);
                  formik.setFieldValue("file", updatedFiles); // Update Formik's file field
                }}
              >
                <CancelIcon sx={{ color: 'red', backgroundColor: '#f8d7da', borderRadius: '50%' }} />
              </Button>
            </li>
          ))}
        </ul>
      </Box>
    )}
                </Box>
            </Grid>



           
  {/* Before Section */}
  <Grid item xs={4}>
    <Typography variant="subtitle1">Before Image</Typography>
    <input
      type="file"
      multiple
      onChange={(event) => {
        // Collect selected files for the "Before" section
        const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
        const allFiles = [...formik.values.beforeImages, ...newFiles]; // Merge new files with already selected files
        formik.setFieldValue("beforeImages", allFiles); // Update Formik's field for Before images
      }}
      style={{ display: "none" }}
      id="before-image-upload"
    />
    <Box>
      <label htmlFor="before-image-upload">
        <Button variant="outlined" component="span">
          Choose Files
        </Button>
      </label>
      {formik.values.beforeImages && formik.values.beforeImages.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" color="textSecondary">
            Selected Before Images:
          </Typography>
          <ul>
            {formik.values.beforeImages.map((file, index) => (
              <li key={index}>
                {file.name}
                {/* Remove button for Before images */}
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => {
                    const updatedFiles = formik.values.beforeImages.filter((_, i) => i !== index);
                    formik.setFieldValue("beforeImages", updatedFiles); // Update Formik's field for Before images
                  }}
                >
                   <CancelIcon
                  sx={{
                    color: "red",
                    backgroundColor: "#f8d7da",
                    borderRadius: "50%",
                  }}
                />
                </Button>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  </Grid>

  {/* After Section */}
  <Grid item xs={4}>
    <Typography variant="subtitle1">After Image</Typography>
    <input
      type="file"
      multiple
      onChange={(event) => {
        // Collect selected files for the "After" section
        const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
        const allFiles = [...formik.values.afterImages, ...newFiles]; // Merge new files with already selected files
        formik.setFieldValue("afterImages", allFiles); // Update Formik's field for After images
      }}
      style={{ display: "none" }}
      id="after-image-upload"
    />
    <Box>
      <label htmlFor="after-image-upload">
        <Button variant="outlined" component="span">
          Choose Files
        </Button>
      </label>
      {formik.values.afterImages && formik.values.afterImages.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" color="textSecondary">
            Selected After Images:
          </Typography>
          <ul>
            {formik.values.afterImages.map((file, index) => (
              <li key={index}>
                {file.name}
                {/* Remove button for After images */}
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => {
                    const updatedFiles = formik.values.afterImages.filter((_, i) => i !== index);
                    formik.setFieldValue("afterImages", updatedFiles); // Update Formik's field for After images
                  }}
                >
                  <CancelIcon
                  sx={{
                    color: "red",
                    backgroundColor: "#f8d7da",
                    borderRadius: "50%",
                  }}
                />
                </Button>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  </Grid>

</Grid>

<Grid item xs={7}>
              <Typography variant="subtitle1">Remarks</Typography>
              <TextField
                placeholder="Enter remarks"
                size="small"
                {...formik.getFieldProps("remarks")}
                multiline
                rows={4} 
                fullWidth
                
              />
            </Grid>


          </Grid>
        </Card>
      </Grid>

      {/* Submit and Cancel Buttons */}
      <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
        <Card>
          <Grid container spacing={3} sx={{ padding: 3 }}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="outlined"
                className={classes.addButton}
                startIcon={<CloseIcon />}
                onClick={() =>navigate('/expense/list')}
                // onClick={() => formik.resetForm()}
              >
                Cancel
              </Button>
              <Box m={0.5}></Box>
                      <Button variant="contained"   className={classes.addButton} startIcon={<SaveAltIcon />} type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : "Submit"} {/* Show loader when submitting */}
                      </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </form>
</Grid>

      </Grid>
    </div>
  );
};

export default ExpenseForm;
