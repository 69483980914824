import { Card, CardHeader } from "@mui/material";
import { useStyles } from "../../../theme/appStyles";
import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import {
  useDeleteProductMutation,
  useGetProductQuery,
  useGetProductCategoryQuery,
  useGetProductBrandQuery,
  useGetProductTagQuery,
  useGetCategoryQuery,
  useGetAllMainCategoryQuery,
  useGetSubCategoryByArrayQuery,
} from "../../../Api/attoDeskApi";
import {
  ICategory,
  IProductBrand,
  IProductCategory,
  IProductPopUP,
  IProductTag,
} from "../../../Api/Interface/api.interface";
import DeletePopup from "../../../Components/Delete/DeletePopup";
import { useNotifier } from "../../../Core/Notifier";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import useDebounce from "../../../Components/SearchDebonce/useDebounce";
import { CiSearch } from "react-icons/ci";
import NewPopUpProduct from "../Edit/index";
import { useNavigate } from "react-router-dom";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import CustomCheckbox from "../../../Components/Checkbox.tsx";
import Pagination from "../../../Components/Pagination";

const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const getFormattedDate = (createDate: Dayjs | null): string => {
    return createDate ? createDate.format("YYYY-MM-DD") : "";
  };
  const { showErrorMessage, showMessage } = useNotifier();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [selectedProductBrandId, setSelectedProductBrandId] =
    useState<string>("");
  const [selectedProductCategoryId, setSelectedProductCategoryId] =
    useState<string>("");
  const [selectedProductTagIds, setSelectedProductTagIds] = useState<string[]>(
    []
  );
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const [selectedProductViewOnline, setSelectedProductViewOnline] =
    useState<string>("");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const startDateAsDate = startDate?.toDate() || null;
  const endDateAsDate = endDate?.toDate() || null;
  const [createDate, setCreateDate] = useState<Dayjs | null>(null);
  const [isPaperVisible, setIsPaperVisible] = useState(false);

  const { data: categoryData } = useGetAllMainCategoryQuery({
    departmentId: "",
    coursingId: "",
    roleId: null,
    taxeId: "",
    createdDateStart: null,
    createdDateEnd: null,
    searchText: "",
    numberOfLength: 0,
    isActive: true,
    categoryIsLooping: null,
    createdDate: null,
  });

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<
    number | null
  >(null);

  const [selectedSubToSubCategoryId, setSelectedSubToSubCategoryId] = useState<
    number | null
  >(null);

  const [selectedSubToSubToSubCategoryId, setSelectedSubToSubToSubCategoryId] =
    useState<number | null>(null);

  const { data: subCategoryData, refetch: refetchSubCategories } =
    useGetSubCategoryByArrayQuery({
      parent_ids: selectedCategoryId ? [Number(selectedCategoryId)] : [],
    });

  const { data: subCategory2Data, refetch: refetchS2bCategories } =
    useGetSubCategoryByArrayQuery({
      parent_ids: selectedSubCategoryId
        ? [Number(selectedCategoryId), Number(selectedSubCategoryId)]
        : [],
    });

  const { data: subCategory3Data, refetch: refetchS3bCategories } =
    useGetSubCategoryByArrayQuery({
      parent_ids: selectedSubCategoryId
        ? [
            Number(selectedCategoryId),
            Number(selectedSubCategoryId),
            Number(selectedSubToSubCategoryId),
          ]
        : [],
    });
  const categoryList = useMemo(() => {
    return categoryData?.data as ICategory[];
  }, [categoryData?.data]);

  const subCategoryList = useMemo(() => {
    return subCategoryData?.data as ICategory[];
  }, [subCategoryData?.data]);

  const subToSubCategoryList = useMemo(() => {
    return subCategory2Data?.data as ICategory[];
  }, [subCategory2Data?.data]);

  const subToSubToSubCategoryList = useMemo(() => {
    return subCategory3Data?.data as ICategory[];
  }, [subCategory3Data?.data]);

  const productCategoryId = selectedSubToSubToSubCategoryId
    ? Number(selectedSubToSubToSubCategoryId)
    : selectedSubToSubCategoryId
    ? Number(selectedSubToSubCategoryId)
    : selectedSubCategoryId
    ? Number(selectedSubCategoryId)
    : selectedCategoryId
    ? Number(selectedCategoryId)
    : String("");

  const { data, isLoading, isError, refetch } = useGetProductQuery({
    productBrandId: selectedProductBrandId,
    productCategoryId: String(productCategoryId),
    productTagIds: selectedProductTagIds,
    productViewOnline: selectedProductViewOnline,
    createdDateStart: startDateAsDate as Date,
    createdDateEnd: endDateAsDate as Date,
    searchText: currentSearchQuery,
    createdDate: getFormattedDate(createDate),
    id: null,
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedProduct, setSelectedProduct] = useState<IProductPopUP | null>(
    null
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [productToDelete, setProductToDelete] = useState<IProductPopUP | null>(
    null
  );

  const { data: productBrandData, isLoading: productBrandLoading } =
    useGetProductBrandQuery({
      searchText: "",
      isActive: true,
    });
  const { data: coursingData, isLoading: coursingLoading } =
    useGetCategoryQuery();
  const { data: taxData, isLoading: taxLoading } = useGetProductTagQuery({
    searchText: "",
    isActive: true,
  });
  const { data: productCategoryData, isLoading: productCategoryLoading } =
    useGetProductCategoryQuery({
      searchText: "",
      isActive: true,
    });
  const { data: productTagData, isLoading: ProductTagLoading } =
    useGetProductTagQuery({
      searchText: "",
      isActive: true,
    });

  const productBrandList = useMemo(() => {
    return productBrandData?.data as IProductBrand[];
  }, [productBrandData?.data]);

  const coursingList = useMemo(() => {
    return coursingData?.data as ICategory[];
  }, [coursingData?.data]);

  const taxList = useMemo(() => {
    return taxData?.data as IProductTag[];
  }, [taxData?.data]);

  const productList = useMemo(() => {
    return productCategoryData?.data as IProductCategory[];
  }, [productCategoryData?.data]);

  const productTagList = useMemo(() => {
    return productTagData?.data as IProductTag[];
  }, [productTagData?.data]);

  const productBrandMap = useMemo(() => {
    const map = new Map();
    productBrandList?.forEach((brand) => {
      map.set(brand.id, brand.productBrandName);
    });
    return map;
  }, [productBrandList]);

  const coursingMap = useMemo(() => {
    const map = new Map();
    coursingList?.forEach((category) => {
      map.set(category.id, category.categoryName);
    });
    return map;
  }, [coursingList]);

  const taxMap = useMemo(() => {
    const map = new Map();
    taxList?.forEach((tag) => {
      map.set(tag.id, tag.tagName);
    });
    return map;
  }, [taxList]);

  const handleMainCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const categoryId = event.target.value as number;
    setSelectedCategoryId(categoryId);
    setSelectedSubCategoryId(null);
    setSelectedSubToSubCategoryId(null);
    setSelectedSubToSubToSubCategoryId(null);

    const selectedCategory = categoryList.find(
      (category) => category.id == categoryId
    );
  };

  const handleSubCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const subCategoryId = event.target.value as number;
    setSelectedSubCategoryId(subCategoryId);
    setSelectedSubToSubCategoryId(null);
    setSelectedSubToSubToSubCategoryId(null);

    const selectedCategory = subCategoryList.find(
      (category) => category.id == subCategoryId
    );
  };

  const handleSubToSubCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedSubToSubCategoryId = event.target.value as number;
    setSelectedSubToSubCategoryId(selectedSubToSubCategoryId);
    setSelectedSubToSubToSubCategoryId(null);

    const selectedCategory = subToSubCategoryList.find(
      (category) => category.id == selectedSubToSubCategoryId
    );
  };

  const handleSubToSubToSubCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedSubToSubToSubCategoryId = event.target.value as number;
    setSelectedSubToSubToSubCategoryId(selectedSubToSubToSubCategoryId);

    const selectedCategory = subToSubToSubCategoryList.find(
      (category) => category.id == selectedSubToSubToSubCategoryId
    );
  };

  useEffect(() => {
    if (debouncedSearchQuery.length >= 4 || debouncedSearchQuery === "") {
      setCurrentSearchQuery(debouncedSearchQuery);
      refetch();
    }
  }, [debouncedSearchQuery]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (product: IProductPopUP) => {
    setSelectedProduct(product);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedProduct(null);
    setOpenDialog(false);
  };

  const handleOpenDeletePopup = (product: IProductPopUP) => {
    setProductToDelete(product);
    setOpenDeleteProduct(true);
  };

  const handleCloseDeletePopup = () => {
    setProductToDelete(null);
    setOpenDeleteProduct(false);
  };

  const handleProductBrandChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValues = event.target.value;
    setSelectedProductBrandId(selectedValues);
  };

  const handleProductViewOnline = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValues = event.target.value;
    setSelectedProductViewOnline(selectedValues);
  };

  const handleProductCategory = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValues = event.target.value;
    setSelectedProductCategoryId(selectedValues);
  };

  const handleStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue);
  };

  const handleCreateDateChange = (newValue: Dayjs | null) => {
    setCreateDate(newValue);
  };

  const handleProductTag = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSelectedProductTagIds(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [deleteCategory] = useDeleteProductMutation();
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategory(id).unwrap();
      if (response.status) {
        showMessage("Deleted successfully");
        setOpenDeleteProduct(false);
      } else {
        showErrorMessage("Failed to delete the product");
      }
    } catch (error) {
      showErrorMessage("Failed to delete the product");
    }
  };

  const handleToggle = () => {
    setIsPaperVisible(!isPaperVisible);
  };

  if (
    isLoading ||
    productBrandLoading ||
    coursingLoading ||
    taxLoading ||
    productCategoryLoading ||
    ProductTagLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const coursings: IProductPopUP[] = Array.isArray(data?.data)
    ? (data?.data as IProductPopUP[])
    : [];

  const resetFields = () => {
    setSelectedProductBrandId("");
    setSelectedProductCategoryId("");
    setSelectedProductViewOnline("");
    setSelectedProductTagIds([]);
    setStartDate(null);
    setEndDate(null);
    setCreateDate(null);
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
    setSelectedSubToSubCategoryId(null);
    setSelectedSubToSubToSubCategoryId(null);
  };

  const handleAddProductClick = () => {
    navigate("/product/create");
  };

  return (
    <Card className={classes.card}>
      <Grid
        container
        justifyContent={"space-between"}
        spacing={5}
        sx={{ borderBottom: 2, padding: 2 }}
        className={classes.titleGrid}
      >
        <Grid item lg={4} md={4} sm={4} xs={4} pt={2} alignContent={"center"}>
          <Typography className={classes.cardTitle}>
            New Services
          </Typography>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          xs={8}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            sx={{ display: "flex" }}
          >
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CiSearch className={classes.searchWidgetIcon} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.formControlList,
                  },
                }}
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Service Name .."
                sx={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.addButton}
                onClick={handleToggle}
              >
                {isPaperVisible ? "Close Filter" : "Open Filter"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.addButton}
                onClick={handleAddProductClick}
              >
                Add Services
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isPaperVisible && (
        <>
          <Grid container spacing={2} my={5} px={1}>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    View Online
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    size="small"
                    className={classes.textField}
                    value={selectedProductViewOnline || ""}
                    onChange={handleProductViewOnline}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="" style={{ color: "gray" }}>
                      Select an option
                    </MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Brand
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    size="small"
                    className={classes.textField}
                    value={selectedProductBrandId || ""}
                    onChange={handleProductBrandChange}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="" style={{ color: "gray" }}>
                      Select an option
                    </MenuItem>
                    {productBrandList.map((productBrand) => (
                      <MenuItem key={productBrand.id} value={productBrand.id}>
                        {productBrand.productBrandName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Main Category
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    className={classes.textField}
                    value={selectedCategoryId || ""}
                    onChange={handleMainCategoryChange}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="" disabled style={{ color: "gray" }}>
                      Select an option
                    </MenuItem>
                    {categoryList &&
                      categoryList.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.categoryName}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            {selectedCategoryId && subCategoryList.length > 0 && (
              <Grid item lg={2} md={3} sm={12} xs={12}>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="subtitle1" className={classes.body2}>
                      Sub Category
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      className={classes.textField}
                      value={
                        selectedSubCategoryId === null
                          ? ""
                          : selectedSubCategoryId
                      }
                      onChange={handleSubCategoryChange}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="" disabled style={{ color: "gray" }}>
                        Select an option
                      </MenuItem>
                      {subCategoryList.map((subcategory) => (
                        <MenuItem key={subcategory.id} value={subcategory.id}>
                          {subcategory.categoryName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedCategoryId &&
              selectedSubCategoryId &&
              subToSubCategoryList.length > 0 && (
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="subtitle1" className={classes.body2}>
                        Sub-to-Sub Category
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        className={classes.textField}
                        value={
                          selectedSubToSubCategoryId === null
                            ? ""
                            : selectedSubToSubCategoryId
                        }
                        onChange={handleSubToSubCategoryChange}
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value="" disabled style={{ color: "gray" }}>
                          Select an option
                        </MenuItem>
                        {subToSubCategoryList.map((subToSubcategory) => (
                          <MenuItem
                            key={subToSubcategory.id}
                            value={subToSubcategory.id}
                          >
                            {subToSubcategory.categoryName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            {selectedCategoryId &&
              selectedSubCategoryId &&
              selectedSubToSubCategoryId &&
              subToSubToSubCategoryList.length > 0 && (
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="subtitle1" className={classes.body2}>
                        Sub-to-Sub-to-Sub Category
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        className={classes.textField}
                        value={
                          selectedSubToSubToSubCategoryId === null
                            ? ""
                            : selectedSubToSubToSubCategoryId
                        }
                        onChange={handleSubToSubToSubCategoryChange}
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value="" disabled style={{ color: "gray" }}>
                          Select an option
                        </MenuItem>
                        {subToSubToSubCategoryList.map(
                          (subToSubToSubcategory) => (
                            <MenuItem
                              key={subToSubToSubcategory.id}
                              value={subToSubToSubcategory.id}
                            >
                              {subToSubToSubcategory.categoryName}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Tag
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    select
                    size="small"
                    className={classes.textField}
                    SelectProps={{
                      multiple: true,
                      native: false,
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedProductTagIds || ""}
                    onChange={handleProductTag}
                  >
                    {productTagList && productTagList.length > 0 ? (
                      productTagList.map((productTag: IProductTag) => (
                        <MenuItem key={productTag.id} value={productTag.id}>
                          {productTag.tagName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" style={{ color: "gray" }}>
                        Select an option
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Create Date
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={handleCreateDateChange}
                      value={createDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: { width: "100%" },
                          InputProps: {
                            sx: { fontSize: 14 },
                          },
                          InputLabelProps: {
                            sx: { fontSize: 14 },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    Start Date
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={handleStartDateChange}
                      value={startDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: { width: "100%" },
                          InputProps: {
                            sx: { fontSize: 14 },
                          },
                          InputLabelProps: {
                            sx: { fontSize: 14 },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className={classes.body2}>
                    End Date
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={handleEndDateChange}
                      value={endDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: { width: "100%" },
                          InputProps: {
                            sx: { fontSize: 14 },
                          },
                          InputLabelProps: {
                            sx: { fontSize: 14 },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  startIcon={<RestartAltIcon />}
                  className={classes.addButton}
                  onClick={resetFields}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      <Grid>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeaderRow}>
              <TableRow>
                <TableCell className={classes.tablheader}>
                  <CustomCheckbox name="checkbox" />
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>#</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Services</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong> Brand</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>Category</strong>
                </TableCell>
                <TableCell className={classes.tablheader}>
                  <strong>View Online</strong>
                </TableCell>
                <TableCell
                  className={classes.tablheader}
                  sx={{ textAlign: "right" }}
                >
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isError || coursings.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={20} align="center">
                    {isError ? "Error fetching data" : "No data available"}
                  </TableCell>
                </TableRow>
              ) : (
                coursings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: IProductPopUP, index) => (
                    <TableRow key={row.id} className={classes.tableRowHover}>
                      <TableCell className={classes.tablCell}>
                        <CustomCheckbox name="checkbox" />
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Box className={classes.SelectImageTableBox}>
                              <img
                                src={row.productImg}
                                alt="img"
                                // alt={row.productName.toLowerCase()}
                                className={classes.SelectImageTable}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            textAlign={"center"}
                            alignContent={"center"}
                          >
                            <Typography className={classes.nameTableList}>
                              {row.productName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {productBrandMap.get(row.productBrandId) || ""}
                      </TableCell>
                      <TableCell scope="row" className={classes.tablCell}>
                        {coursingMap.get(row.productCategoryId) || ""}
                      </TableCell>

                      <TableCell scope="row" className={classes.tablCell}>
                        {row.productViewOnline ? "Yes" : "No"}
                      </TableCell>
                      <TableCell className={classes.tablCell}>
                        <Grid
                          container
                          spacing={1}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.viewButtonList}
                              onClick={() =>
                                navigate(`/product-history/${row.id}`)
                              }
                            >
                              <VisibilitySharpIcon
                                className={classes.viewListIcon}
                              />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.editButtonList}
                              onClick={() => handleOpenDialog(row)}
                            >
                              <EditSharpIcon className={classes.editListIcon} />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.deleteButtonList}
                              onClick={() => handleOpenDeletePopup(row)}
                            >
                              <DeleteOutlineSharpIcon
                                className={classes.deleteButtonList}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={coursings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            // className={classes.tablePagination}
          /> */}
          <Grid
            spacing={5}
            p={"18px"}
            py={4}
            justifyContent={"end"}
            container
            textAlign={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid
              sx={{
                textAlign: "end",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pagination
                count={coursings.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </TableContainer>
        {selectedProduct && (
          <NewPopUpProduct
            openModel={openDialog}
            handleCloseDialog={handleCloseDialog}
            data={selectedProduct}
          />
        )}
        {productToDelete && (
          <DeletePopup
            open={openDeleteProduct}
            handleCloseDelete={handleCloseDeletePopup}
            onConfirm={async () => {
              await handleDelete(productToDelete.id.toString());
            }}
            title="Delete Product"
            content={`Are you sure you want to delete "${productToDelete.productName}"?`}
          />
        )}
      </Grid>
    </Card>
  );
};

export default Index;
