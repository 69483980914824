import React, { useEffect, useMemo, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Card,
} from "@mui/material";


import { useNotifier } from "../../../Core/Notifier";
import { useStyles } from "../../../theme/appStyles";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import Pagination from "../../../Components/Pagination";
import axios from "axios";

interface Expense {
  id: number;
  category_name: string;
}

const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expenses, setExpenses] = useState<Expense[]>([]); 
  const { showErrorMessage, showMessage } = useNotifier();
  const API_URL = process.env.REACT_APP_API_URL;
  const [searchQuery, setSearchQuery] = useState(""); 
  const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(50);

  const filteredExpenses = expenses.filter(
    (expense) =>
      expense.category_name.toLowerCase().includes(searchQuery.toLowerCase()) 
  );

  const handleEditClick = (id: number) => {
    navigate(`/expense-category/${id}`);
  };

  const handleDeleteClick = (id: number) => {
    // Delete expense logic
    axios.delete(`${API_URL}/delete-expense-category/${id}`).then(() => {
      setExpenses(expenses.filter((expense) => expense.id !== id));
      showMessage("Deleted successfully");
    }).catch((error) => {
     // console.error("Failed to delete expense", error);
      showErrorMessage("Failed to delete the category");
    });
  };

 

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axios.get(`${API_URL}/view-expense-category`);
        setExpenses(response.data); // Set the fetched data to state
 
      } catch (error) {
        console.error("Failed to load expense category", error);
      }
    };
    fetchExpenses();
  }, [API_URL]);

 
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  
  

  const handleAddExpenseCategoryClick = () => {
    navigate("/expense-category/create");
  };

  




  return (
    <Card className={classes.card}>
      <Grid
        container
        justifyContent={"space-between"}
        spacing={5}
        sx={{ borderBottom: 2, padding: 2 }}
        className={classes.titleGrid}
      >
        <Grid item lg={4} md={4} sm={4} xs={4} pt={2} alignContent={"center"}>
          <Typography className={classes.cardTitle}>Expense Category</Typography>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          xs={8}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            sx={{ display: "flex" }}
          >
             
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CiSearch className={classes.searchWidgetIcon} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.formControlList,
                  },
                }}
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Expense Name .."
                sx={{ width: "100%" }}
                size="small"
              />
            </Grid>
           
            <Grid item>
              <Button
                variant="contained"
                className={classes.addButton}
                onClick={handleAddExpenseCategoryClick}
              >
                Add Expense Category
              </Button>

            </Grid>

         


            
          </Grid>
        </Grid>
      </Grid>

     

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card sx={{ padding: 3 }}>
        <TableContainer>
        <Table className={classes.table}>
        <TableHead className={classes.tableHeaderRow}>
              <TableRow className={classes.tableRowHover}>
                <TableCell className={classes.tablheader}>  <strong>Category Name</strong></TableCell>
                <TableCell className={classes.tablheader} sx={{textAlign: "right"}}>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredExpenses.length > 0 ? (
            filteredExpenses.map((expense) => (
              <TableRow key={expense.id}>
                <TableCell  scope="row" className={classes.tablCell}>{expense.category_name}</TableCell>
                <TableCell scope="row" className={classes.tablCell}>
                        <Grid
                          container
                          spacing={1}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.editButtonList}
                              onClick={() => handleEditClick(expense.id)}
                            >
                              <EditSharpIcon className={classes.editListIcon} />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.deleteButtonList}
                              onClick={() => handleDeleteClick(expense.id)} 
                            >
                              <DeleteOutlineSharpIcon
                                className={classes.deleteButtonList}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No Data Available
              </TableCell>
            </TableRow>
          )}
            </TableBody>
          </Table>

          <Grid
            spacing={5}
            p={"18px"}
            py={4}
            justifyContent={"end"}
            container
            textAlign={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid
              sx={{
                textAlign: "end",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
             
         <Pagination
        count={Math.ceil(filteredExpenses.length / rowsPerPage)}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
          </Grid>
        </Grid>
        
    </TableContainer>
           
        
        </Card>
      </Grid>

    </Card>
       

  );
};

export default Index;
