import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useCreateCustomerMutation } from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { ICustomer } from "../../../Api/Interface/api.interface";
import { useStyles } from "../../../theme/appStyles";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../Components/Validation/validationUtils";
import { uploadImage } from "../../../Components/UploadFile/upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface ITaxpopup {
  openModel?: boolean;
  handleCloseDialog: (close: boolean) => void;
  data?: ICustomer;
}

const Customer = ({
  openModel = false,
  handleCloseDialog,
  data,
}: ITaxpopup) => {
  const classes = useStyles();
  const [open] = React.useState(openModel);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [newCustomer, { isLoading }] = useCreateCustomerMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const handleClose = () => {
    handleCloseDialog(false);
  };
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  );
  const [isImageUploading, setIsImageUploading] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: data?.id,
      customerFirstName: data?.customerFirstName || "",
      customerLastName: data?.customerLastName || "",
      customerMobileNum: data?.customerMobileNum || "",
      customerEmail: data?.customerEmail || "",
      customerAddress: data?.customerAddress || "",
      customerDescription: data?.customerDescription || "",
      customerCompanyName: data?.customerCompanyName || "",
      customerCompanyPhoneNumebr: data?.customerCompanyPhoneNumebr || "",
      customerCompanyEmail: data?.customerCompanyEmail || "",
      customerPreferTimeToCall: data?.customerPreferTimeToCall || "",
      customerImg: data?.customerImg || "",
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      const companyEmailError = validateEmail(values.customerCompanyEmail);
      if (companyEmailError) {
        errors.customerCompanyEmail = companyEmailError;
      }

      const customerEmailError = validateEmail(values.customerEmail);
      if (customerEmailError) {
        errors.customerEmail = customerEmailError;
      }

      const companyMobileNumberError = validatePhoneNumber(
        values.customerCompanyPhoneNumebr
      );
      if (companyMobileNumberError) {
        errors.customerCompanyPhoneNumebr = companyMobileNumberError;
      }

      const cutomerMobileNumberError = validatePhoneNumber(
        values.customerMobileNum
      );
      if (cutomerMobileNumberError) {
        errors.customerMobileNum = cutomerMobileNumberError;
      }

      const cutomerAltMobileNumberError = validatePhoneNumber(
        values.customerMobileNum
      );
      if (cutomerAltMobileNumberError) {
        errors.customerMobileNum = cutomerAltMobileNumberError;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        let customerImg: string | undefined = data?.customerImg;

        if (selectedImage) {
          setIsImageUploading(true);
          formData.append("customerImg", selectedImage);
          customerImg = (await uploadImage(selectedImage)) || "";
          setIsImageUploading(false);
        }

        const temData = {
          id: values?.id,
          customerFirstName: values.customerFirstName,
          customerLastName: values.customerLastName,
          customerMobileNum: values.customerMobileNum,
          customerEmail: values.customerEmail,
          customerAddress: values.customerAddress,
          customerDescription: values.customerDescription,
          customerCompanyName: values.customerCompanyName,
          customerCompanyPhoneNumebr: values.customerCompanyPhoneNumebr,
          customerCompanyEmail: values.customerCompanyEmail,
          customerPreferTimeToCall: values.customerPreferTimeToCall,
          customerImg,
        };
        if (!data) {
          delete values.id;
        }

        const addCompanyResponse = await newCustomer(temData).unwrap();
        if (!addCompanyResponse.status) {
          showErrorMessage(addCompanyResponse.message);
        } else {
          showMessage(addCompanyResponse.message);
          handleClose();
        }
      } catch (error) {
        showErrorMessage("Something went wrong");
      }
    },
  });

  const formValid = useMemo(() => {
    return formik.values.customerMobileNum === "" ||
      formik.values.customerMobileNum === undefined
      ? false
      : true;
  }, [formik]);

  const resizeImage = (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          // Calculate the new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Canvas is empty"));
            }
          }, file.type);
        };

        img.onerror = (error) => reject(error);
      };
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
      const maxSize = 2048 * 1024;

      if (!validTypes.includes(file.type)) {
        showErrorMessage("The file must be an image (jpeg, png, jpg, gif).");
        setSelectedImage(undefined);
      } else if (file.size > maxSize) {
        showErrorMessage("The file must be smaller than 2 MB.");
        setSelectedImage(undefined);
      } else {
        try {
          const resizedImage = await resizeImage(file, 500, 500);
          setSelectedImage(resizedImage);
        } catch (error) {
          showErrorMessage("Failed to resize the image.");
          setSelectedImage(undefined);
        }
      }
    } else {
      setSelectedImage(undefined);
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: "1700px",
        },
      }}
    >
      <Grid className={classes.Dialog}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.stepperCard}>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={5}
                sx={{ borderBottom: 2, padding: 2 }}
                className={classes.titleGrid}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  pt={2}
                  alignContent={"center"}
                >
                  <Typography className={classes.cardTitle}>
                    Edit Customer
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Add Cunstomer Image
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} p={"24px"}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className={classes.imgBox}>
                          {selectedImage && (
                            <img
                              src={URL.createObjectURL(selectedImage)}
                              alt="Uploaded"
                              className={classes.SelectImageBox}
                            />
                          )}
                          {!selectedImage && data && data.customerImg ? (
                            <img
                              alt="Selected"
                              height="160px"
                              src={data.customerImg}
                              style={{ position: "inherit" }}
                              className={classes.SelectImageBox}
                            />
                          ) : (
                            <></>
                          )}
                          <div>
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="icon-button-file-1"
                              type="file"
                              onChange={handleImageChange}
                            />
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <label htmlFor="icon-button-file-1">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <CloudUploadIcon sx={{ fontSize: 45 }} />
                                </IconButton>
                              </label>

                              <div>PNG, JPG and JPEG are allowed</div>
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Customer Company Information
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Name"
                              size="small"
                              {...formik.getFieldProps("customerCompanyName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Company Phone Number
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Company Phone Number"
                              size="small"
                              {...formik.getFieldProps(
                                "customerCompanyPhoneNumebr"
                              )}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.customerCompanyPhoneNumebr &&
                                Boolean(
                                  formik.errors.customerCompanyPhoneNumebr
                                )
                              }
                              helperText={
                                formik.touched.customerCompanyPhoneNumebr &&
                                formik.errors.customerCompanyPhoneNumebr
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">E-mail</Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter E-mail"
                              size="small"
                              {...formik.getFieldProps("customerCompanyEmail")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.customerCompanyEmail &&
                                Boolean(formik.errors.customerCompanyEmail)
                              }
                              helperText={
                                formik.touched.customerCompanyEmail &&
                                formik.errors.customerCompanyEmail
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Customer Information
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} p={"24px"}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              First Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter First Name"
                              size="small"
                              {...formik.getFieldProps("customerFirstName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Last Name
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Last Name"
                              size="small"
                              {...formik.getFieldProps("customerLastName")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Mobile Number
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Mobile Number"
                              size="small"
                              {...formik.getFieldProps("customerMobileNum")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.customerMobileNum &&
                                Boolean(formik.errors.customerMobileNum)
                              }
                              helperText={
                                formik.touched.customerMobileNum &&
                                formik.errors.customerMobileNum
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">E-mail</Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter E-mail"
                              size="small"
                              type="email"
                              {...formik.getFieldProps("customerEmail")}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              error={
                                formik.touched.customerEmail &&
                                Boolean(formik.errors.customerEmail)
                              }
                              helperText={
                                formik.touched.customerEmail &&
                                formik.errors.customerEmail
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Prefer Time to call
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Prefer Time to call"
                              size="small"
                              {...formik.getFieldProps(
                                "customerPreferTimeToCall"
                              )}
                              className={classes.textField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">Address</Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Address"
                              size="small"
                              {...formik.getFieldProps("customerAddress")}
                              className={classes.discriptionTextField}
                              multiline
                              rows={3}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">
                              Description
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                              placeholder="Enter Description .."
                              size="small"
                              multiline
                              rows={8}
                              {...formik.getFieldProps("customerDescription")}
                              className={classes.discriptionTextField}
                              InputProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: 14,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                  <Card className={classes.card}>
                    <Grid container spacing={3} p={"24px"}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          className={classes.addButton}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Box m={0.5}></Box>

                        <Button
                          variant="contained"
                          startIcon={<SaveAltIcon />}
                          className={classes.addButton}
                          onClick={() => formik.handleSubmit()}
                          disabled={!formValid || isLoading}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Customer;
