import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import {
  useCreateCustomerMutation,
  useCreateWorkOrderMutation,
  useGetAllSearchCompanyQuery,
  useGetAllWorkOrderHistoryQuery,
  useGetCompanyQuery,
  useGetCustomerQuery,
  useGetLastWorkOrderIdQuery,
  useGetProductQuery,
  useGetTaxQuery,
  useGetWorkOrderQuery,
  useUpdateInvoiceStatusQuery,
} from "../../../Api/attoDeskApi";
import { useNotifier } from "../../../Core/Notifier";
import { useStyles } from "../../../theme/appStyles";
import { useNavigate } from "react-router";
import useDebounce from "../../../Components/SearchDebonce/useDebounce";
import { CiSearch } from "react-icons/ci";
import {
  ICompanyDetails,
  ICustomer,
  IProductPopUP,
  ITaxes,
} from "../../../Api/Interface/api.interface";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../Components/Validation/validationUtils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toDate } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import {
  ProductType,
  TransferType,
  WorkOrderStatus,
} from "../../../Core/Enum/enum";
import AddIcon from "@mui/icons-material/Add";
import { FaTrash } from "react-icons/fa";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import NewCompanyPopUp from "../../CompanyInfo/Components/NewCompanyPopUp";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { uploadImage } from "../../../Components/UploadFile/upload";
import { useParams } from "react-router-dom";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CustomCheckbox from "../../../Components/Checkbox.tsx";
import Pagination from "../../../Components/Pagination";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import ExpandLessSharpIcon from "@mui/icons-material/ExpandLessSharp";

const getStatusLabel = (statusCode: number): string => {
  switch (statusCode) {
    case WorkOrderStatus.Paid:
      return "Paid";
    case WorkOrderStatus.Cancel:
      return "Canceled";
    case WorkOrderStatus.Pending:
      return "Pending";
    default:
      return "Unknown Status";
  }
};

const getStatusColor = (statusCode: number): string => {
  switch (statusCode) {
    case WorkOrderStatus.Paid:
      return "#4caf50";
    case WorkOrderStatus.Cancel:
      return "#f44336";
    case WorkOrderStatus.Pending:
      return "#ff9800";
    default:
      return "#9e9e9e";
  }
};

const getTransferType = (statusCode: string): string => {
  switch (statusCode) {
    case TransferType.OneWayTransfer:
      return "One Way Transfer";
    case TransferType.RoundTrip:
      return "Round Trip";
    case TransferType.MultiDestination:
      return "Multi Destination";
    default:
      return "Unknown Status";
  }
};

const steps = ["Work Order", "Invoice", "Payment", "Assign Driver", "Deliver"];

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundColor: theme.palette.success.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 7,
    border: 0,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 1,
  },
}));

const CustomStepLabel = styled(StepLabel)(() => ({
  "& .MuiStepLabel-iconContainer": {
    borderRadius: "50%",
    border: "2px solid",
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
}));

interface IProduct {
  productName: string;
  productImg: String | null;
  numberOfPassengers: number | null;
  transferType: string;
  transferTypeFrom: string;
  transferTypeTo: string;
  transferTypeDescription: string;
  departureDateTime: Dayjs | null;
  returnDateTime: Dayjs | null;
  departureAddress: string;
  destinationAddress: string;
  multiDestination: string;
  quantity: number | null;
  price: number | null;
  tax: number | null;
  total: number;
  taxId: number | null;
  unitPriceWithDiscount: number | null;
  unitTotalWithDiscount: number;
  productType: string;
  productLongDescription: string;
  productShortDescription: string;
}

interface IDiscount {
  value: number | null;
  description: string;
}

interface IQuoteWorkOrder {
  id: number;
  quoteWorkOrderCompanyId: number;
  quoteWorkOrderCustomerId: string;
  quoteWorkOrderNo: string;
  quoteWorkOrderIssueDate: Dayjs;
  quoteWorkOrderDueDate: Dayjs;
  quoteWorkOrderAmount: number | null;
  quoteWorkOrderStatus: number;
  quoteWorkOrderSale: IProduct[];
  quoteWorkOrderDiscount: string;
  quoteWorkOrderSubTotal: string;
  quoteWorkOrderSaleGrandTotal: string;
  quoteWorkOrderSaleDiscountDescription: IDiscount[];
  customer: ICustomer;
  company: ICompanyDetails;
  quoteWorkOrderCustomerRemark: string;
  quoteWorkOrderCustomerMemo: string;
  quoteWorkOrderSaleDiscount: string;
  quoteWorkOrderSaleSubTotal: string;
  created_by: number;
  created_at: string;
  mainStatus: number;
  invoiceId: number;
}

const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { id: QuoteId } = useParams<{ id: string }>();
  useEffect(() => {}, [QuoteId]);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [activeStep, setActiveStep] = useState(0);
  const [mainStatus, setMainStatus] = useState("");
  const [id, setId] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [expandedSteps, setExpandedSteps] = useState<number[]>([]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const {
    data: workOrderData,
    isLoading: workIsLoading,
    isError: workOrderError,
    refetch: workOrderRefetch,
  } = useGetWorkOrderQuery({
    searchText: "",
    id: String(QuoteId),
  });

  const {
    data: updateData,
    isLoading: updateIsLoading,
    isError: updateError,
  } = useUpdateInvoiceStatusQuery({
    mainStatus: mainStatus,
    id: id,
  });

  const {
    data: workOrderHistoryData,
    isLoading: workOrderHistoryIsLoading,
    isError: workOrderHistoryError,
    refetch: workOrderHistoryRefetch,
  } = useGetAllWorkOrderHistoryQuery({
    id: String(QuoteId),
  });

  const WorkOrderDataList = useMemo(() => {
    if (
      workOrderData &&
      Array.isArray(workOrderData.data) &&
      workOrderData.data.length > 0
    ) {
      return workOrderData.data[0] as IQuoteWorkOrder;
    }
    return null;
  }, [workOrderData]);

  const timeLineSteps = React.useMemo(() => {
    if (
      workOrderHistoryData &&
      Array.isArray(workOrderHistoryData.data) &&
      workOrderHistoryData.data.length > 0
    ) {
      return workOrderHistoryData.data.map((order, index) => {
        const label =
          index === 0
            ? "Work order created"
            : order.isInvoice
            ? "Work order covered as invoice"
            : "Work order edited";

        const description = (
          <div>
            {index === 0 && (
              <div>
                <Typography variant="body1">
                  <strong>Created By :</strong> {order.updated_by}
                </Typography>
                <Typography variant="body1">
                  <strong>Created At :</strong>{" "}
                  {order?.created_at
                    ? dayjs(order.created_at).format("MMMM D, YYYY [at] h:mm A")
                    : ""}
                </Typography>
              </div>
            )}

            {order.isInvoice == true && (
              <div>
                <Typography variant="body1">
                  <strong>Invoice Number :</strong> {order.InvoiceNo}
                </Typography>
                <Typography variant="body1">
                  <strong>Create By :</strong> {order.updated_by}
                </Typography>
                <Typography variant="body1">
                  <strong>Created At :</strong>{" "}
                  {order?.created_at
                    ? dayjs(order.created_at).format("MMMM D, YYYY [at] h:mm A")
                    : ""}
                </Typography>
                <Grid item textAlign={"end"} justifyContent={"flex-end"}>
                  <Button
                    variant="contained"
                    startIcon={<VisibilitySharpIcon />}
                    className={classes.addButton}
                    onClick={() =>
                      navigate(
                        `/invoice/history/${WorkOrderDataList?.invoiceId}`
                      )
                    }
                  >
                    Invoice
                  </Button>
                </Grid>
              </div>
            )}
            {order.isInvoice == false && index != 0 && (
              <div>
                <Typography variant="body1">
                  <strong>Edited By :</strong> {order.updated_by}
                </Typography>
                <Typography variant="body1">
                  <strong>Edited At :</strong>{" "}
                  {order?.updated_at
                    ? dayjs(order.updated_at).format("MMMM D, YYYY [at] h:mm A")
                    : ""}
                </Typography>
              </div>
            )}
            <Typography variant="body1"> </Typography>
            <TableContainer sx={{ marginTop: 2 }}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHeaderRow}>
                  <TableRow>
                    <TableCell className={classes.tablheader}>
                      <strong>Product</strong>
                    </TableCell>
                    <TableCell
                      className={classes.tablheader}
                      sx={{ textAlign: "right" }}
                    >
                      <strong>Quantity</strong>
                    </TableCell>
                    <TableCell
                      className={classes.tablheader}
                      sx={{ textAlign: "right" }}
                    >
                      <strong>Price</strong>
                    </TableCell>
                    <TableCell
                      className={classes.tablheader}
                      sx={{ textAlign: "right" }}
                    >
                      <strong>Tax</strong>
                    </TableCell>
                    <TableCell
                      className={classes.tablheader}
                      sx={{ textAlign: "right" }}
                    >
                      <strong>Price After Discount</strong>
                    </TableCell>
                    <TableCell
                      className={classes.tablheader}
                      sx={{ textAlign: "right" }}
                    >
                      <strong>Amount</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workOrderError || order.quoteWorkOrderSale.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        {workOrderError
                          ? "Error fetching data"
                          : "No data available"}
                      </TableCell>
                    </TableRow>
                  ) : (
                    order.quoteWorkOrderSale.map(
                      (
                        row: IProduct,
                        index: number 
                      ) => (
                        <TableRow key={index} className={classes.tableRowHover}>
                          <TableCell scope="row" className={classes.tablCell}>
                            {row.productName}
                          </TableCell>
                          <TableCell className={classes.tablCell} align="right">
                            {row.quantity}
                          </TableCell>
                          <TableCell className={classes.tablCell} align="right">
                            {row.price !== null && row.price !== undefined
                              ? row.price.toFixed(2)
                              : "0.00"}
                          </TableCell>
                          <TableCell className={classes.tablCell} align="right">
                            {row.tax}%
                          </TableCell>
                          <TableCell className={classes.tablCell} align="right">
                            {(row.unitPriceWithDiscount ?? 0).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.tablCell} align="right">
                            {row.unitTotalWithDiscount != null
                              ? row.unitTotalWithDiscount.toFixed(2)
                              : "0.00"}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container>
              {workOrderError ||
              order.quoteWorkOrderSaleDiscountDescription.length === 0 ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {workOrderError ? "Error fetching data" : "No data available"}
                </Grid>
              ) : (
                order.quoteWorkOrderSaleDiscountDescription.map(
                  (discount: IDiscount, index: number) => (
                    <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                      <Grid
                        container
                        spacing={2}
                        display={"end"}
                        justifyContent={"flex-end"}
                      >
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={4}
                          xs={8}
                          textAlign={"end"}
                        >
                          <Typography>
                            {discount.description || "Discount"}
                            {" :"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={2}
                          xs={4}
                          textAlign={"end"}
                        >
                          <Typography>
                            {"$"}
                            {(discount.value ?? 0).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid
                  container
                  spacing={2}
                  display={"end"}
                  justifyContent={"flex-end"}
                >
                  <Grid
                    item
                    lg={2}
                    md={2}
                    sm={4}
                    xs={8}
                    textAlign={"end"}
                    className={classes.tablCell}
                  >
                    <Typography>Total Discount :</Typography>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={2}
                    sm={2}
                    xs={4}
                    textAlign={"end"}
                    className={classes.tablCell}
                  >
                    <Typography>
                      {"$"}
                      {Number(order.quoteWorkOrderDiscount || 0).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid
                  container
                  spacing={2}
                  display={"end"}
                  justifyContent={"flex-end"}
                >
                  <Grid item lg={2} md={2} sm={4} xs={8} textAlign={"end"}>
                    <Typography className={classes.h4Quoto}>
                      Grand Amount :
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={4} textAlign={"end"}>
                    <Typography className={classes.h4Quoto}>
                      {"$"}
                      {Number(order.quoteWorkOrderSaleGrandTotal || 0).toFixed(
                        2
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );

        return {
          label: label,
          description: description,
        };
      });
    }
    return [];
  }, [workOrderHistoryData]);

  const toggleStepDetails = (index: number) => {
    if (expandedSteps.includes(index)) {
      setExpandedSteps(
        expandedSteps.filter((stepIndex) => stepIndex !== index)
      );
    } else {
      setExpandedSteps([...expandedSteps, index]);
    }
  };

  const coursings = Array.isArray(WorkOrderDataList?.quoteWorkOrderSale)
    ? WorkOrderDataList!.quoteWorkOrderSale
    : [];

  // const activeStep = WorkOrderDataList?.quoteWorkOrderStatus || 0;

  useEffect(() => {
    if (WorkOrderDataList) {
      setActiveStep(WorkOrderDataList.mainStatus || 0);
    }
  }, [WorkOrderDataList]);

  const handleWorkOrder = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirmDialog = () => {
    setOpen(false);
    proceedWithInvoiceSuccess();
  };

  const proceedWithInvoiceSuccess = () => {
    setActiveStep((prevStep) => prevStep + 1);

    const newMainStatus =
      WorkOrderDataList?.mainStatus === 1
        ? WorkOrderDataList.mainStatus + 1
        : "";

    setMainStatus(String(newMainStatus));
    setId(WorkOrderDataList?.id ? String(WorkOrderDataList.id) : id);
  };
  // React.useEffect(() => {
  //   if (!updateIsLoading && !updateError && UpdateStatusInvoiceDataList) {
  //     navigate(`/invoice/history/${UpdateStatusInvoiceDataList.invoiceId || null}`);
  //   }
  // }, [updateIsLoading, updateError, UpdateStatusInvoiceDataList, navigate]);

  const handlePayment = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleOnRoute = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleDeliverComplete = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const getNextButtonLabel = () => {
    switch (activeStep) {
      case 0:
        return "Proceed to Invoice";
      case 1:
        return "Proceed to Invoice";
      case 2:
        return "Process Payment";
      case 3:
        return "On Route";
      case 4:
        return "Complete Delivery";
      default:
        return "Finish";
    }
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        handleWorkOrder();
        break;
      case 1:
        handleOpenDialog();
        break;
      case 2:
        handlePayment();
        break;
      case 3:
        handleOnRoute();
        break;
      case 4:
        handleDeliverComplete();
        break;
      default:
        break;
    }
  };

  const isLoading =
    workIsLoading || updateIsLoading || workOrderHistoryIsLoading;

  useEffect(() => {
    if (!updateIsLoading) {
      workOrderRefetch();
    }
  }, [updateIsLoading, workOrderRefetch]);

  useEffect(() => {
    if (!workOrderHistoryIsLoading) {
      workOrderHistoryRefetch();
    }
  }, [workOrderHistoryIsLoading, workOrderHistoryRefetch]);

  const discountList = Array.isArray(
    WorkOrderDataList?.quoteWorkOrderSaleDiscountDescription
  )
    ? WorkOrderDataList!.quoteWorkOrderSaleDiscountDescription
    : [];

  if (isLoading || workIsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="10vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      {isLoading ? (
        <div>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="10vh"
          >
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.stepperCard}>
                    <Grid container spacing={2}>
                      <Grid item lg={8} md={8} sm={12} xs={12} pt={2}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={1}
                          sx={{ padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Typography className={classes.h4Quoto}>
                              <strong>
                                <span className={classes.customerDetails}>
                                  {(WorkOrderDataList?.quoteWorkOrderNo ||
                                    " ") + " "}
                                </span>

                                <span className={classes.customerDetails}>
                                  {" "}
                                </span>

                                <span className={classes.customerDetails}>
                                  {/* Using Box to add margin-left */}
                                  <Box component="span" ml={2}>
                                    {" "}
                                    {/* Adjust the "ml" value as needed */}
                                    <Button
                                      className={
                                        classes.statusButtonListWorkOrderList
                                      }
                                      style={{
                                        color: getStatusColor(
                                          WorkOrderDataList?.quoteWorkOrderStatus ||
                                            0
                                        ),
                                        border: "solid 1px",
                                        borderColor: getStatusColor(
                                          WorkOrderDataList?.quoteWorkOrderStatus ||
                                            0
                                        ),
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {getStatusLabel(
                                        WorkOrderDataList?.quoteWorkOrderStatus ||
                                          0
                                      )}
                                    </Button>
                                  </Box>
                                </span>
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Typography className={classes.h5}>
                              <Typography component="span">
                                Order / Order Details /
                              </Typography>{" "}
                              <Typography component="span">
                                {WorkOrderDataList?.quoteWorkOrderNo || ""} /
                              </Typography>{" "}
                              <Typography component="span">
                                {WorkOrderDataList?.created_at
                                  ? dayjs(WorkOrderDataList.created_at).format(
                                      "MMMM D, YYYY [at] h:mm A"
                                    )
                                  : ""}
                              </Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12} pt={2}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={1}
                          className={classes.titleGrid}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: 2,
                          }}
                        >
                          <Grid item>
                            <Button className={classes.refundButton}>
                              Refund
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button className={classes.refundButton}>
                              Terminate
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              startIcon={
                                WorkOrderDataList?.mainStatus === 1 ? (
                                  <EditSharpIcon />
                                ) : (
                                  <VisibilitySharpIcon />
                                )
                              }
                              className={classes.addButton}
                              onClick={() =>
                                navigate(
                                  `/quote-history/${WorkOrderDataList?.id}`
                                )
                              }
                            >
                              Order
                            </Button>
                          </Grid>
                          {WorkOrderDataList?.mainStatus != 1 && (
                            <Grid item>
                              <Button
                                variant="contained"
                                startIcon={<VisibilitySharpIcon />}
                                className={classes.addButton}
                                onClick={() =>
                                  navigate(
                                    `/invoice/history/${WorkOrderDataList?.invoiceId}`
                                  )
                                }
                              >
                                Invoice
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={1}
                          sx={{ padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Typography className={classes.h4Quoto}>
                              <strong>Progress</strong>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Stepper
                                activeStep={activeStep}
                                alternativeLabel
                                connector={<CustomStepConnector />}
                              >
                                {steps.map((label, index) => (
                                  <Step key={label}>
                                    <CustomStepLabel>
                                      {label}
                                      {activeStep === index && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            py: 1,
                                          }}
                                        >
                                          <CircularProgress size={20} />
                                        </Box>
                                      )}
                                    </CustomStepLabel>
                                  </Step>
                                ))}
                              </Stepper>

                              {activeStep === steps.length ? (
                                <>
                                  <Typography sx={{ mt: 2, mb: 1 }}>
                                    completed
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "end",
                                      mt: 2,
                                      mr: 2,
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      className={classes.addButton}
                                      onClick={handleNext}
                                    >
                                      {getNextButtonLabel()}
                                    </Button>
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.stepperCard}>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          sx={{ padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              className={classes.h4Quoto}
                              sx={{ flexGrow: 1, textAlign: "center" }}
                            >
                              {WorkOrderDataList?.customer.customerFirstName +
                                " " +
                                WorkOrderDataList?.customer.customerLastName}
                            </Typography>
                            <Button
                              variant="contained"
                              className={classes.viewButtonList}
                              onClick={() =>
                                navigate(
                                  `/customer-history/${
                                    WorkOrderDataList?.quoteWorkOrderCustomerId ||
                                    null
                                  }`
                                )
                              }
                            >
                              <VisibilitySharpIcon
                                className={classes.viewListIcon}
                              />
                            </Button>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              className={classes.h5}
                              sx={{ flexGrow: 1, textAlign: "center" }}
                            >
                              <Typography component="span">
                                {WorkOrderDataList?.customer
                                  .customerCompanyName || " "}{" "}
                                /
                              </Typography>{" "}
                              <Typography component="span">
                                {WorkOrderDataList?.customer
                                  .customerCompanyPhoneNumebr || ""}{" "}
                                /
                              </Typography>{" "}
                              <Typography component="span">
                                {WorkOrderDataList?.customer
                                  .customerCompanyEmail || ""}
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              className={classes.h5}
                              sx={{ flexGrow: 1, textAlign: "center" }}
                            >
                              <Typography component="span">
                                {WorkOrderDataList?.customer
                                  .customerMobileNum || " "}{" "}
                                /
                              </Typography>{" "}
                              <Typography component="span">
                                {WorkOrderDataList?.customer.customerEmail ||
                                  ""}{" "}
                              </Typography>{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.stepperCard}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      spacing={5}
                      sx={{ borderBottom: 2, padding: 2 }}
                      className={classes.titleGrid}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        alignContent={"center"}
                      >
                        <Typography className={classes.cardTitle}>
                          Services
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} pb={5}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TableContainer>
                          <Table className={classes.table}>
                            <TableHead className={classes.tableHeaderRow}>
                              <TableRow>
                                <TableCell className={classes.tablheader}>
                                  <strong>Services</strong>
                                </TableCell>
                                <TableCell
                                  className={classes.tablheader}
                                  sx={{ textAlign: "right" }}
                                >
                                  <strong>Quantity</strong>
                                </TableCell>
                                <TableCell
                                  className={classes.tablheader}
                                  sx={{ textAlign: "right" }}
                                >
                                  <strong>Price</strong>
                                </TableCell>
                                <TableCell
                                  className={classes.tablheader}
                                  sx={{ textAlign: "right" }}
                                >
                                  <strong>Tax</strong>
                                </TableCell>
                                <TableCell
                                  className={classes.tablheader}
                                  sx={{ textAlign: "right" }}
                                >
                                  <strong>Price</strong>
                                </TableCell>
                                <TableCell
                                  className={classes.tablheader}
                                  sx={{ textAlign: "right" }}
                                >
                                  <strong>Amount</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {workOrderError || coursings.length === 0 ? (
                                <TableRow>
                                  <TableCell colSpan={8} align="center">
                                    {workOrderError
                                      ? "Error fetching data"
                                      : "No data available"}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                coursings
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((product, index) => (
                                    <TableRow
                                      key={index}
                                      className={classes.tableRowHover}
                                    >
                                      <TableCell className={classes.tablCell}>
                                        {!isMobile ? (
                                          <Grid container spacing={1}>
                                            <Grid item>
                                              <Box
                                                className={
                                                  classes.SelectImagInvoiceBox
                                                }
                                              >
                                                <img
                                                  src={String(
                                                    product.productImg
                                                  )}
                                                  alt="img"
                                                  // alt={row.productName.toLowerCase()}
                                                  className={
                                                    classes.SelectImageInvoice
                                                  }
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              sm={8}
                                              xs={12}
                                              textAlign={"left"}
                                              alignContent={"center"}
                                            >
                                              <Typography>
                                                <strong>
                                                  {product.productName}
                                                </strong>
                                              </Typography>
                                              {product.productType ==
                                                ProductType.Service && (
                                                <div>
                                                  <Typography>
                                                    {getTransferType(
                                                      product.transferType
                                                    ) || ""}{" "}
                                                    {product.transferType !=
                                                      TransferType.MultiDestination && (
                                                      <>
                                                        (
                                                        {
                                                          product.transferTypeFrom
                                                        }{" "}
                                                        to{" "}
                                                        {product.transferTypeTo}
                                                        )
                                                      </>
                                                    )}
                                                    {product.transferType ==
                                                      TransferType.MultiDestination && (
                                                      <>
                                                        (
                                                        {
                                                          product.transferTypeDescription
                                                        }
                                                        )
                                                      </>
                                                    )}
                                                  </Typography>
                                                  <Typography>
                                                    <strong>
                                                      Departure address :
                                                    </strong>{" "}
                                                    {product.departureAddress} (
                                                    {product?.departureDateTime
                                                      ? dayjs(
                                                          product.departureDateTime
                                                        ).format(
                                                          "MMMM D, YYYY [at] h:mm A"
                                                        )
                                                      : ""}
                                                    )
                                                  </Typography>
                                                  <Typography>
                                                    <strong>
                                                      Destination address :
                                                    </strong>{" "}
                                                    {product.destinationAddress}{" "}
                                                    (
                                                    {product?.returnDateTime
                                                      ? dayjs(
                                                          product.returnDateTime
                                                        ).format(
                                                          "MMMM D, YYYY [at] h:mm A"
                                                        )
                                                      : ""}
                                                    )
                                                  </Typography>
                                                </div>
                                              )}
                                            </Grid>
                                          </Grid>
                                        ) : (
                                          <Grid container spacing={1}>
                                            <Grid item>
                                              <Box
                                                className={
                                                  classes.SelectImagInvoiceBox
                                                }
                                              >
                                                <img
                                                  src={String(
                                                    product.productImg
                                                  )}
                                                  alt="img"
                                                  // alt={row.productName.toLowerCase()}
                                                  className={
                                                    classes.SelectImageInvoice
                                                  }
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              textAlign={"left"}
                                              alignContent={"center"}
                                            >
                                              <Typography>
                                                <strong>
                                                  {product.productName}
                                                </strong>
                                              </Typography>
                                              {product.productType ==
                                                ProductType.Service && (
                                                <div>
                                                  <Typography>
                                                    {getTransferType(
                                                      product.transferType
                                                    ) || ""}{" "}
                                                    {product.transferType !=
                                                      TransferType.MultiDestination && (
                                                      <>
                                                        (
                                                        {
                                                          product.transferTypeFrom
                                                        }{" "}
                                                        to{" "}
                                                        {product.transferTypeTo}
                                                        )
                                                      </>
                                                    )}
                                                    {product.transferType ==
                                                      TransferType.MultiDestination && (
                                                      <>
                                                        (
                                                        {
                                                          product.transferTypeDescription
                                                        }
                                                        )
                                                      </>
                                                    )}
                                                  </Typography>
                                                  <Typography>
                                                    <strong>
                                                      Departure address :
                                                    </strong>{" "}
                                                    {product.departureAddress} (
                                                    {product?.departureDateTime
                                                      ? dayjs(
                                                          product.departureDateTime
                                                        ).format(
                                                          "MMMM D, YYYY [at] h:mm A"
                                                        )
                                                      : ""}
                                                    )
                                                  </Typography>
                                                  <Typography>
                                                    <strong>
                                                      Destination address :
                                                    </strong>{" "}
                                                    {product.destinationAddress}{" "}
                                                    (
                                                    {product?.returnDateTime
                                                      ? dayjs(
                                                          product.returnDateTime
                                                        ).format(
                                                          "MMMM D, YYYY [at] h:mm A"
                                                        )
                                                      : ""}
                                                    )
                                                  </Typography>
                                                </div>
                                              )}
                                            </Grid>
                                          </Grid>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tablCell}
                                        align="right"
                                      >
                                        {product.quantity}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tablCell}
                                        align="right"
                                      >
                                        {(product.price ?? 0).toFixed(2)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tablCell}
                                        align="right"
                                      >
                                        {product.tax}%
                                      </TableCell>
                                      <TableCell
                                        className={classes.tablCell}
                                        align="right"
                                      >
                                        {(
                                          product.unitPriceWithDiscount ?? 0
                                        ).toFixed(2)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tablCell}
                                        align="right"
                                      >
                                        {product.unitTotalWithDiscount != null
                                          ? product.unitTotalWithDiscount.toFixed(
                                              2
                                            )
                                          : "0.00"}
                                      </TableCell>
                                    </TableRow>
                                  ))
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      {discountList.map((discount, index) => (
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          px={2}
                          key={index}
                        >
                          <Grid
                            container
                            spacing={2}
                            display={"end"}
                            justifyContent={"flex-end"}
                          >
                            <Grid
                              item
                              lg={2}
                              md={2}
                              sm={4}
                              xs={8}
                              textAlign={"end"}
                            >
                              <Typography>
                                {discount.description || "Discount"}
                                {" :"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={2}
                              md={2}
                              sm={2}
                              xs={4}
                              textAlign={"end"}
                            >
                              <Typography>
                                {"$"}
                                {(discount.value !== null
                                  ? discount.value
                                  : 0
                                ).toFixed(2)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                      <Grid item lg={12} md={12} sm={12} xs={12} px={2}>
                        <Grid
                          container
                          spacing={2}
                          display={"end"}
                          justifyContent={"flex-end"}
                        >
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={4}
                            xs={8}
                            textAlign={"end"}
                            className={classes.tablCell}
                          >
                            <Typography>Total Discount :</Typography>
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={2}
                            xs={4}
                            textAlign={"end"}
                            className={classes.tablCell}
                          >
                            <Typography>
                              {"$"}
                              {parseFloat(
                                WorkOrderDataList?.quoteWorkOrderDiscount || "0"
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} px={2}>
                        <Grid
                          container
                          spacing={2}
                          display={"end"}
                          justifyContent={"flex-end"}
                        >
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={4}
                            xs={8}
                            textAlign={"end"}
                          >
                            <Typography className={classes.h4Quoto}>
                              Grand Amount :
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={2}
                            xs={4}
                            textAlign={"end"}
                          >
                            <Typography className={classes.h4Quoto}>
                              {"$"}
                              {parseFloat(
                                WorkOrderDataList?.quoteWorkOrderSaleGrandTotal ||
                                  "0"
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Card className={classes.stepperCard}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ borderBottom: 2, padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            alignContent={"center"}
                          >
                            <Typography className={classes.cardTitle}>
                              Order Timeline
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent={"space-between"}
                          spacing={5}
                          sx={{ borderBottom: 2, padding: 2 }}
                          className={classes.titleGrid}
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            alignContent={"center"}
                            className={classes.titleGrid}
                          >
                            <Box>
                              {workOrderHistoryIsLoading ? (
                                <CircularProgress />
                              ) : workOrderHistoryError ? (
                                <Typography color="error">
                                  Error fetching work order history
                                </Typography>
                              ) : (
                                <>
                                  <Stepper
                                    orientation="vertical"
                                    activeStep={timeLineSteps.length}
                                  >
                                    {timeLineSteps
                                      .slice()
                                      // .reverse() 
                                      .map((step, index) => (
                                        <Step key={step.label} completed>
                                          <StepLabel>
                                            <Box
                                              display="flex"
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              <Typography
                                                className={classes.h5}
                                              >
                                                {step.label}
                                              </Typography>
                                              <Button
                                                variant="contained"
                                                className={
                                                  classes.editButtonList
                                                }
                                                onClick={() =>
                                                  toggleStepDetails(index)
                                                }
                                              >
                                                {expandedSteps.includes(
                                                  index
                                                ) ? (
                                                  <ExpandLessSharpIcon
                                                    className={
                                                      classes.editListIcon
                                                    }
                                                  />
                                                ) : (
                                                  <ExpandMoreSharpIcon
                                                    className={
                                                      classes.editListIcon
                                                    }
                                                  />
                                                )}
                                              </Button>
                                            </Box>
                                          </StepLabel>

                                          {expandedSteps.includes(index) && (
                                            <Box sx={{ ml: 4, mt: 1 }}>
                                              <Typography>
                                                {step.description}
                                              </Typography>
                                            </Box>
                                          )}
                                        </Step>
                                      ))}

                                    {/* Last step + 1 with loading spinner */}
                                    <Step>
                                      <StepLabel>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography>Next...</Typography>
                                          <Button
                                            variant="contained"
                                            className={classes.editButtonList}
                                          >
                                            <CircularProgress size={24} />
                                          </Button>
                                        </Box>
                                      </StepLabel>
                                    </Step>
                                  </Stepper>
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Dialog open={open} onClose={handleCloseDialog}>
              <Grid className={classes.Dialog}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  spacing={5}
                  sx={{ borderBottom: 2, padding: 2 }}
                  className={classes.titleGrid}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    pt={2}
                    alignContent={"center"}
                  >
                    <Typography className={classes.cardTitle}>
                      Confirm Action
                    </Typography>
                  </Grid>
                </Grid>
                <DialogContent>
                  <DialogContentText sx={{ pt: 3 }}>
                    Are you sure you want to proceed with invoice success?
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  sx={{ paddingRight: "24px", p: 4, py: 3, gap: 2 }}
                >
                  <Grid container spacing={3}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        className={classes.addButton}
                        onClick={handleCloseDialog}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>

                      <Button
                        variant="contained"
                        startIcon={<CheckCircleOutlineSharpIcon />}
                        className={classes.addButton}
                        onClick={handleConfirmDialog}
                      >
                        Yes
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </Dialog>
          </Grid>
        </>
      )}
    </div>
  );
};
export default Index;
